import React, { useEffect, useState, useRef } from 'react';
import { Form, FormBuilder, Errors } from '@formio/react';
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.min.css';
// import 'bootstrap-css-only/css/bootstrap.min.css';
// import 'mdbreact/dist/css/mdb.css';
import config from '../../config.js'
const Dashboard = () => {

        var empty = {
                "components": [
                        {
                                "type": "button",
                                "label": "Submit",
                                "key": "submit",
                                "size": "md",
                                "block": false,
                                "action": "submit",
                                "disableOnInvalid": true,
                                "theme": "primary",
                                "input": true,
                                "placeholder": "",
                                "prefix": "",
                                "customClass": "",
                                "suffix": "",
                                "multiple": false,
                                "defaultValue": null,
                                "protected": false,
                                "unique": false,
                                "persistent": false,
                                "hidden": false,
                                "clearOnHide": true,
                                "refreshOn": "",
                                "redrawOn": "",
                                "tableView": false,
                                "modalEdit": false,
                                "dataGridLabel": true,
                                "labelPosition": "top",
                                "description": "",
                                "errorLabel": "",
                                "tooltip": "",
                                "hideLabel": false,
                                "tabindex": "",
                                "disabled": false,
                                "autofocus": false,
                                "dbIndex": false,
                                "customDefaultValue": "",
                                "calculateValue": "",
                                "calculateServer": false,
                                "widget": {
                                        "type": "input"
                                },
                                "attributes": {},
                                "validateOn": "change",
                                "validate": {
                                        "required": false,
                                        "custom": "",
                                        "customPrivate": false,
                                        "strictDateValidation": false,
                                        "multiple": false,
                                        "unique": false
                                },
                                "conditional": {
                                        "show": null,
                                        "when": null,
                                        "eq": ""
                                },
                                "overlay": {
                                        "style": "",
                                        "left": "",
                                        "top": "",
                                        "width": "",
                                        "height": ""
                                },
                                "allowCalculateOverride": false,
                                "encrypted": false,
                                "showCharCount": false,
                                "showWordCount": false,
                                "properties": {},
                                "allowMultipleMasks": false,
                                "leftIcon": "",
                                "rightIcon": "",
                                "id": "e39ts6x"
                        }
                ]
        };
        const [data, setData] = useState({});


        const [pages, setPages] = useState([]);
        const [currentPageId, setCurrentPageId] = useState(0);
        const [currentPageIndex, setCurrentPageIndex] = useState(-1);
        const [templateValue, setTemplateValue] = useState(-1);
        const [templates, setTemplates] = useState([]);
        const [refresh, setRefresh] = useState(0);
        const [isFilter, setIsFilter] = useState(false);
        const [filterValue, setFilterValue] = useState(-1);
        const [formValue, setFormValue] = useState(-1);


        useEffect(() => {
                axios.get(config.SystemUrl + 'Page').then(res => {
                        setPages(res.data.data)
                })

                axios.get(config.SystemUrl + 'FormTemplate').then(res => {
                        setTemplates(res.data)
                })

        }, [refresh])
        const getForm = (e) => {
                setTemplateValue(-1);
                setFilterValue(-1)
                let index = e.target.value;
                if (index != -1) {
                        var PageSchema = pages[index].json_schema
                                .replace(/&#039;/g, "'")
                                .replace(/&quot;/g, '"')
                                .replace(/\n/g, "\\n")
                                .replace(/\r/g, "\\r")
                                .replace(/\t/g, "\\t")
                                .replace(/\f/g, "\\f");
                        PageSchema = JSON.parse(PageSchema)
                        setData(PageSchema.length == 0 ? empty : PageSchema)
                        setCurrentPageId(pages[index].id);
                } else {
                        setData(empty)
                }
                setFormValue(index);
                setIsFilter(false)
                setCurrentPageIndex(index);

        }
        const getFormFilter = (e) => {
                setTemplateValue(-1);
                setFormValue(-1);
                let index = e.target.value;
                if (index != -1) {
                        var PageSchema = pages[index].filter_schema
                                .replace(/&#039;/g, "'")
                                .replace(/&quot;/g, '"')
                                .replace(/\n/g, "\\n")
                                .replace(/\r/g, "\\r")
                                .replace(/\t/g, "\\t")
                                .replace(/\f/g, "\\f");
                        console.log(PageSchema)
                        PageSchema = JSON.parse(PageSchema)
                        setData(PageSchema.length == 0 ? empty : PageSchema)
                        setCurrentPageId(pages[index].id);
                } else {
                        setData(empty)
                }
                setFilterValue(index)
                setIsFilter(true)
                setCurrentPageIndex(index);

        }


        const ChangeSchema = (e) => {

                let index = e.target.value;
                setTemplateValue(index);
                if (index != -1) {
                        let schema = templates[index].json_schema.length == 0 ? empty : templates[index].json_schema;
                        setData(schema)
                        // setOldTemplateSchema(schema)
                } else {
                        if (pages[currentPageIndex]) {
                                setData(pages[currentPageIndex].json_schema.length == 0 ? empty : pages[currentPageIndex].json_schema)
                        } else {
                                setData(empty);
                        }

                }
        }


        const updateFormSchema = () => {

                axios.post(config.SystemUrl + `setFormSchema?id=${currentPageId}`, { json_schema: JSON.stringify(data, undefined, 2), is_filter: isFilter }).then(res => {
                        setRefresh(old => old + 1)
                });
        }
        return (<div className="col-md-12" >
                <div className="row main">

                        <div className="col-sm-8">
                                {/* <FormBuilder form={data} onChange={(schema) =>  console.log(schema) } /> */}
                                <FormBuilder form={data} onChange={(schema) => {
                                        // document.getElementById("json").textContent = JSON.stringify(schema, undefined, 2)
                                        setData(schema)
                                }} />
                                <label htmlFor="getForm">
                                        Form to edit
                                </label>
                                <select onChange={getForm} value={formValue} id="getForm" className="col-md-2 form-control">
                                        <option value="-1">Select</option>
                                        {pages.map((page, index) => {

                                                return <option key={index} value={index}>{page.name_en}</option>
                                        })}
                                </select>
                                <div className="accordion builder-sidebar builder-sidebar_scroll">
                                        <label htmlFor="ChangeSchema">
                                                form template
                                        </label>
                                        <select value={templateValue} disabled={currentPageIndex == -1} onChange={ChangeSchema} id="ChangeSchema" className="col-md-2 form-control">
                                                <option value="-1">Select</option>

                                                {templates.map((template, index) => {

                                                        return <option key={index} value={index}>{template.title}</option>
                                                })}
                                        </select>
                                        <label htmlFor="getForm">
                                                Filter template
                                        </label>
                                        <select onChange={getFormFilter} value={filterValue} id="getFormFilter" className="col-md-2 form-control">
                                                <option value="-1">Select</option>
                                                {pages.map((page, index) => {

                                                        return <option key={index} value={index}>{page.name_en}</option>
                                                })}
                                        </select>

                                        <button onClick={updateFormSchema} className="btn btn-success"> Save </button>
                                </div>
                        </div>
                        <div className="col-sm-4">
                                <h3 className="text-center text-muted">as JSON Schema</h3>
                                <div className="card card-body bg-light jsonviewer">
                                        <pre id="json">{JSON.stringify(data, undefined, 2)}</pre>
                                </div>
                        </div>
                </div>
                <div className="main">
                        <br></br><h1>Form Preview</h1>
                        <Form form={data} onChange={(submission) => { console.log(submission) }} onSubmit={(schema) => console.log(schema)} />
                </div>
        </div>);
}

export default Dashboard
