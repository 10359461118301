import React,{useState} from 'react';
import { trans } from '../utils';
import axios from "axios";
import {SystemUrl} from "../../App";
import ENIcon from "../../assets/img/en.svg";
import ARIcon from "../../assets/img/ar.png";



const Language = () => {

    const [showLang, setShowLang] = useState(false);
    const changeLang = (lang) => {

        axios.post(SystemUrl + 'changeLang', { lang: lang }).then(res => {
            if (res.data.status == true) {
                localStorage.setItem('lang', lang);
                localStorage.setItem('styleUrl', urls[lang])
                window.location.reload(false);
            }
        });
    }
    const lang = localStorage.getItem('lang') == 'en' ? 'ar' : 'en';
    const urls = { en: "/assets/css/style.bundle.css", ar: '/assets/css/style.bundle.rtl.css' }
    const toggleMenu = () => {
        setShowLang(!showLang);
    };
    return (
        <>
            <button 
                     className="btn btn-icon btn-lg btn-borderless"
                     data-bs-toggle="dropdown"
                     data-bs-offset="0px,0px"
                     onClick={toggleMenu}
            >
                        <span className="svg-icon svg-icon-xxl">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" fill="none">
                                    <rect x="0" y="0" width="24" height="24"/>
                                    <path d="M13,18.9450712 L13,20 L14,20 C15.1045695,20 16,20.8954305 16,22 L8,22 C8,20.8954305 8.8954305,20 10,20 L11,20 L11,18.9448245 C9.02872877,18.7261967 7.20827378,17.866394 5.79372555,16.5182701 L4.73856106,17.6741866 C4.36621808,18.0820826 3.73370941,18.110904 3.32581341,17.7385611 C2.9179174,17.3662181 2.88909597,16.7337094 3.26143894,16.3258134 L5.04940685,14.367122 C5.46150313,13.9156769 6.17860937,13.9363085 6.56406875,14.4106998 C7.88623094,16.037907 9.86320756,17 12,17 C15.8659932,17 19,13.8659932 19,10 C19,7.73468744 17.9175842,5.65198725 16.1214335,4.34123851 C15.6753081,4.01567657 15.5775721,3.39010038 15.903134,2.94397499 C16.228696,2.49784959 16.8542722,2.4001136 17.3003976,2.72567554 C19.6071362,4.40902808 21,7.08906798 21,10 C21,14.6325537 17.4999505,18.4476269 13,18.9450712 Z" fill="#000000" fillRule="nonzero"/>
                                    <circle fill="#000000" opacity="0.3" cx="12" cy="10" r="6"/>
                                </g>
                            </svg>
                        </span>
            </button>
            <div className={showLang? 'show dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-left' : 'dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-left'}>
                <ul className="navi navi-hover py-4">
                    <li className="navi-item">
                        <a href="#" className="navi-link"
                           onClick={() => changeLang(lang)}>
                        <span className="symbol symbol-20 mr-3">
                            <img src={ENIcon} alt="" />
                        </span>
                            <span className="navi-text">{trans('English')}</span>
                        </a>
                    </li>
                    <li className="navi-item">
                        <a href="#" className="navi-link"
                           onClick={() => changeLang(lang)}>
                        <span className="symbol symbol-20 mr-3">
                            <img src={ARIcon} alt="" />
                        </span>
                            <span className="navi-text">{trans('Arabic')}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </>

    );
}
export default Language;
