import React, {useEffect, useState} from 'react'
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../config.js";
import {trans} from "../../utils";

const Index = (ComponentToRenderData) => {
    let history = useHistory();
    const params = useParams();
    const SystemUrl = ComponentToRenderData.SystemUrl;
    const token = localStorage.getItem('token')
    const [data, setData ] = useState([]);
    const search = window.location.search;
    let URLParams = new URLSearchParams(search);
    const routeChange = path => {
        history.push(path);
    }

    useEffect(() => {
        axios(config.SystemUrl + `AssetItem/${params.id}?include=category,group,type,utility,ecri,uom,risk_class,pm_frequency,qa_frequency,calibration_frequency,documentations`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => setData(result.data.data));

    }, [])


    return (
        <>
            <div className="d-flex flex-column flex-xl-row">
                <div className="flex-column flex-lg-row-auto w-100 w-xl-350px row">
                    <div className="col-md-4">
                        <div className="card mb-5">
                            <span  className="p-20 text-center badge-light-primary">
                                <h4> {trans(data.common_name)}</h4>
                                <h6>{trans(data.name)}</h6>
                            </span>
                            <span className="p-2 bg-light text-center">
                                <h4>{trans(data.reference_code)}</h4>
                            </span>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
                            <li className="nav-item">
                                <a className="nav-link text-active-primary pb-4 active" data-toggle="tab" href="#kt_techincal_tab">
                                    {trans('Technical Details')}
                                </a>
                            </li>
                            { (data.pm_frequency && data.pm_frequency.hasOwnProperty("title") ||
                                data.qa_frequency && data.qa_frequency.hasOwnProperty("title") ||
                                data.calibration_frequency && data.calibration_frequency.hasOwnProperty("title") ) &&
                            <li className="nav-item">
                                <a className="nav-link text-active-primary pb-4" data-toggle="tab" href="#kt_service">
                                    {trans('Services & Maintenance')}
                                </a>
                            </li>
                            }
                            {data.documentations && data.documentations.length > 0 ?
                            <li className="nav-item">
                                <a className="nav-link text-active-primary pb-4" data-toggle="tab" href="#kt_docs_tab">
                                    {trans('Documentations')}
                                </a>
                            </li> : ""
                            }
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade active show" id="kt_techincal_tab" role="tabpanel">
                                <div className="card">
                                    <div className="card-header card-header-stretch">
                                        <div className="card-title d-flex align-items-center">
                                            <h4 className="mb-1">{trans('Technical Details')}</h4>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <div className="fs-6">
                                            {data.ecri && data.ecri.hasOwnProperty("ecri_name") &&
                                            <>
                                                <div className="fw-bolder mt-3">{trans('ECRI Name')}</div>
                                                <div className="text-gray-600">{trans(data.ecri.ecri_name)}</div>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('ECRI Code')}</div>
                                                <div className="text-gray-600">{trans(data.ecri.ecri_code)}</div>
                                                <div className="separator mb-2 mt-2"></div>
                                            </>
                                            }
                                            { data.risk_class   &&
                                            <>

                                                <div className="fw-bolder mt-3">{trans('Risk Class')}</div>
                                                <div className="text-gray-600">{trans(data.risk_class.title)}</div>
                                            </>
                                            }

                                            { data.uom &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('UOM')}</div>
                                                <div className="text-gray-600">{trans(data.uom.title)}</div>
                                            </>
                                            }

                                            { data.utility_data &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Utilities')}</div>
                                                <div className="fw-bold mt-1">{trans(data.utility.title)} :</div>
                                                { data.utility_data && data.utility_data.map(utility => (
                                                    <ul className="mt-3">
                                                        <li> <div className="text-gray-600">{trans(utility.input.title)}</div></li>
                                                    </ul>
                                                ))}
                                            </>
                                            }


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="kt_service" role="tabpanel">
                                <div className="card">
                                    <div className="card-header card-header-stretch">
                                        <div className="card-title d-flex align-items-center">
                                            <h4 className="mb-1">{trans('Services & Maintenance')}</h4>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <div className="fs-6">

                                            { data.pm_frequency && data.pm_frequency.hasOwnProperty("title") &&
                                            <>

                                                <div className="fw-bolder mt-3">{trans('PM Frequency')}</div>
                                                <div className="text-gray-600">{trans(data.pm_frequency.title)}</div>
                                            </>
                                            }

                                            { data.qa_frequency && data.qa_frequency.hasOwnProperty("title") &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('QA Frequency')}</div>
                                                <div className="text-gray-600">{trans(data.qa_frequency.title)}</div>
                                            </>
                                            }


                                            { data.calibration_frequency  && data.calibration_frequency.hasOwnProperty("title") &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Calibration Frequency')}</div>
                                                <div className="text-gray-600">{trans(data.calibration_frequency.title)}</div>
                                            </>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="kt_docs_tab" role="tabpanel">
                                <div className="card">
                                    <div className="card-header card-header-stretch">
                                        <div className="card-title d-flex align-items-center">
                                            <h4 className="mb-1">{trans('Documentations')}</h4>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <table className="table mt-10">
                                            <thead>
                                                <tr>
                                                    <th scope="col">{trans('Document Group')}</th>
                                                    <th scope="col">{trans('Document Type')}</th>
                                                    <th scope="col">{trans('Document')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {data.documentations && data.documentations.map(documentation => (
                                                <tr>
                                                    <td>{trans(documentation.parent_category)}</td>
                                                    <td>{trans(documentation.child_category)}</td>
                                                    <td>
                                                        <a href={documentation.file} target="_blank">{trans('Show Attachment')}</a>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Index;
