import {NavLink} from 'react-router-dom';
import Header from "../Layout/Header";
import LeftSideBar from "../Layout/LeftSideBar";
import User from "./user";

const Users = ({data, onDelete}) => {

    return (
        <div id="kt_body"
             className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed toolbar-tablet-and-mobile-fixed aside-enabled aside-fixed">
            <div className="d-flex flex-column flex-root">
                <div className="page d-flex flex-row flex-column-fluid">
                    <LeftSideBar></LeftSideBar>
                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                        <Header/>
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="toolbar" id="kt_toolbar">

                                <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">

                                    <div data-kt-place="true" data-kt-place-mode="prepend"
                                         data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                                         className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">

                                        <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Users</h1>

                                        <span className="h-20px border-gray-200 border-start mx-4"></span>

                                        <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">

                                            <li className="breadcrumb-item text-muted">
                                                <NavLink to='/'
                                                         className="text-muted text-hover-primary">Home</NavLink>
                                            </li>

                                            <li className="breadcrumb-item">
                                                <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                            </li>

                                            <li className="breadcrumb-item text-dark">Users</li>

                                        </ul>

                                    </div>

                                    <div className="d-flex align-items-center py-1">

                                        <div className="me-4">

                                            <NavLink to="/addUser"
                                                     className="btn btn-sm btn-primary"
                                                     data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                                                     data-kt-menu-flip="top-end">

                                                    <span className="svg-icon svg-icon-5 svg-icon-gray-500 me-1">
											<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px"
                                                 viewBox="0 0 24 24" version="1.1">
																	<path
                                                                        d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                                                                        fill="#000000" fillRule="nonzero"
                                                                        opacity="0.3"></path>
																	<path
                                                                        d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                                                                        fill="#000000" fillRule="nonzero"></path>
																</svg>
										</span>
                                                Create Users</NavLink>

                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                <div className="container" id="kt_content_container">
                                    <div className="card mb-5 mb-xl-8">
                                        <div className="card-body pt-3">

                                            <div className="table-responsive">

                                                <table
                                                    className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">

                                                    <thead>
                                                    <tr className="border-0">
                                                        <th className="p-0">User</th>
                                                        <th className="p-0 min-w-150px"></th>
                                                        <th className="p-0 min-w-200px"></th>
                                                        <th className="p-0 min-w-150px"></th>
                                                        <th className="p-0 min-w-100px text-end"></th>
                                                    </tr>
                                                    </thead>

                                                    <tbody>
                                                    {data.map((user) => (
                                                        <User key={user.id} user={user} onDelete={onDelete}/>
                                                    ))}
                                                    </tbody>

                                                </table>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>

    );
}
export default Users;