import React, { useState } from 'react';
import Header from "../Layout/Header";
import LeftSideBar from "../Layout/LeftSideBar";
import {NavLink} from "react-router-dom";

const AddUser = ( {onAdd} ) => {

        const [username, setUsername] = useState('');
        const [name, setName] = useState('');
        const onSubmit = (e) =>{
            e.preventDefault()
            if (! username){
                alert ("Please add username")
                return
            }
            onAdd({username , name })
            setUsername('')
            setName('')
        }

        return (
            <div id="kt_body"
                 className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed toolbar-tablet-and-mobile-fixed aside-enabled aside-fixed">
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LeftSideBar></LeftSideBar>
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <Header/>
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="toolbar" id="kt_toolbar">

                                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">

                                        <div data-kt-place="true" data-kt-place-mode="prepend"
                                             data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                                             className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">

                                            <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Create User</h1>

                                            <span className="h-20px border-gray-200 border-start mx-4"></span>

                                            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">

                                                <li className="breadcrumb-item text-muted">
                                                    <NavLink to='/'
                                                             className="text-muted text-hover-primary">Home</NavLink>
                                                </li>

                                                <li className="breadcrumb-item">
                                                    <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                                </li>

                                                <li className="breadcrumb-item text-dark">Create User</li>

                                            </ul>

                                        </div>
                                    </div>

                                </div>
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div className="container" id="kt_content_container">
                                        <div className="card mb-5 mb-xl-8">
                                            <div className="card-body pt-3">

                                                <form onSubmit={onSubmit}>
                                                    <div className="form-group">
                                                        <label>Add User Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter an username"
                                                            value={username}
                                                            onChange={(e) => setUsername(e.target.value)}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Name"
                                                            value={name}
                                                            onChange={(e) => setName(e.target.value)}/>
                                                    </div>
                                                    <button type="submit" className="btn btn-primary">Submit</button>
                                                </form>

                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
}

export default AddUser