import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import DataTableComp from "../../DataTables";
import { trans } from '../utils'
import config from '../../config.js'
import { Form } from '@formio/react';
import json_trans from '../translation';
import $ from "jquery";
import logo from "../../assets/img/logo-login.png";

const View = (ComponentToRenderData) => {
    // Define CURRENT Module
    const $ = require("jquery");
    const CurrentModule = ComponentToRenderData.CurrentModule;
    //Get System URL
    const SystemUrl = ComponentToRenderData.SystemUrl;
    const roleParam = ComponentToRenderData.RoleId != 0 ? '?role_id=' + ComponentToRenderData.RoleId : "";
    const [params, setParams] = useState(roleParam);
    const [columns, setColumns] = useState(false);
    const [filter, setFilter] = useState(false)
    const [filterData, setFilterData] = useState({})
    const [filterParams, setFilterParams] = useState([]);
    useEffect(() => {
        axios.get(config.SystemUrl + CurrentModule + `?get_columns=1`).then(res => {
            var parameters = params
            if(parameters == 0){
                parameters = parameters +"?"
            }else{
                parameters = parameters +"&"
            }
            for (let i = 0; i < res.data.cols.length; i++) {
                res.data.cols[i].title = trans(res.data.cols[i].title)
                // console.log(res.data.cols[i]);
                if(res.data.cols[i].hasOwnProperty('include')){


                    if(parameters.search("include") == -1){
                        parameters = parameters +"include="
                    }
                    else{
                        parameters = parameters +","
                    }
                    parameters = parameters +res.data.cols[i].include
                    setParams(parameters);
                }
            }
            setFilterParams(res.data.search_cols);
            // res.data.cols.splice(1, 0, {"title":"checkbox"});

            // console.log(res.data.cols)
            setColumns(res.data.cols);


            if(res.data.filters.components){
                setFilter(res.data.filters)
            }
            else{
                setFilter(false)
            }
        })
    }, [])

    const submitFilter = () => {
        $("#datatable").DataTable().ajax.reload()
    }
    const resetFilter = () => {
        filterParams.forEach(el => {
            // if (el != "role_id" && !!Params) {
            $(`[name="data[${el}]"]`).val(null);
            // }
        })
        setFilterData({});
        submitFilter();

    }

    const options = {
        language: localStorage.getItem('lang') || "en",
        i18n: json_trans,
        template: 'bootstrap'
    }
    return (
        <>

            {
                filter && <>
                <div className="card card-custom mb-5">
                    <div className="card-body pt-3">
                        <Form form={filter} submission={filterData} options={options} onChange={data => setFilterData(data.data)} />
                    <div className="text-center">
                        <button className="btn btn-primary p-4 mr-5" onClick={submitFilter}>{trans('Search')}</button>
                        <button className="btn btn-secondary p-4" onClick={resetFilter}>{trans('Reset')}</button>

                    </div>
                    </div>
                </div>
                </>
            }


            {columns &&
            <>
                           {CurrentModule =="Extract"  && <div className="row">
                                       <div className="col-md-4">
                                           <h5>Kingdom of Saudi Arabia</h5>
                                           
                                           <h5>Contractor : </h5>
                                       </div>
                                       <div className="col-md-4 text-center">
                                           <img alt="Logo" src={logo} className="h-100px m-2"/>
                                           <h4>
                                               مستخلصات  <br/>
                                               Extracts
                                           </h4>
                                       </div>
                                       <div className="col-md-4">
                                           <h5 className="text-right">المملكة العربية السعودية</h5>
                                           <h5 className="text-right">المقاول : </h5>
                                       </div>
                                   </div>}
                <div className="card card-custom mt-5 p-10">
                    <DataTableComp Params={params} RoleId={ComponentToRenderData.RoleId} FilterParams={filterParams} SystemUrl={SystemUrl} Columns={columns} CurrentModule={CurrentModule} />
                </div>
                </>
            }
        </>
    );
}
export default View;
