import React, {useEffect,useState} from 'react'
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../config.js";
import {toast, ToastContainer} from "react-toastify";
import InventoryTakingReports from "../InventoryTakingReports";
import $ from "jquery";
import {trans} from "../../utils";


const Scan = (ComponentToRenderData) => {
    let history = useHistory();
    const params = useParams();
    const token = localStorage.getItem('token')
    //Get System URL
    const SystemUrl = ComponentToRenderData.SystemUrl;
    const routeChange = path => {
        history.push(path);
    }

    const [dataFacility, setDataFacility ] = useState([]);
    const [dataBuilding, setDataBuilding ] = useState([]);
    const [dataFloor, setDataFloor ] = useState([]);
    const [dataRoom, setDataRoom ] = useState([]);
    const [chainedBuilding , setChainedBuilding] = useState([]);
    const [chainedFloor , setChainedFloor] = useState([]);
    const [chainedRoom , setChainedRoom] = useState([]);

    useEffect(() => {
        axios(config.SystemUrl + `Facility`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => setDataFacility(result.data.data));
    }, [])

    useEffect(() => {
        axios(config.SystemUrl + `Building`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => setDataBuilding(result.data.data));
    }, [])

    useEffect(() => {
        axios(config.SystemUrl + `Floor`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => setDataFloor(result.data.data));
    }, [])

    useEffect(() => {
        axios(config.SystemUrl + `RoomSpace`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => setDataRoom(result.data.data));
    }, [])

    const [data, setData] = useState({
        facility_id: '-1',
        building_id: '-1',
        floor_id:'-1',
        room_id:'-1',
    });

    const [changesCount,setChangesCount] = useState(0)

    const handleFacilityChange = event => {
        setData({...data,facility_id: event.target.value} );
        let buildings = dataBuilding.filter(el=>el.facility_id == event.target.value);
        setChainedBuilding(buildings);
    };
    const handleBuildingChange = event => {
        setData({...data,building_id: event.target.value} );
        let floors = dataFloor.filter(el=>el.building_id == event.target.value);
        setChainedFloor(floors)
    };
    const handleFloorChange = event => {
        setData({...data,floor_id: event.target.value} );
        let rooms = dataRoom.filter(el=>el.floor_id == event.target.value);
        setChainedRoom(rooms)

    };
    const handleRoomChange = event => {
        setData({...data,room_id: event.target.value} );
    };
    const handleSubmitChange = event => {
        event.preventDefault();
        if(data.room_id == -1){
            toast.error('Please select the room', { position: toast.POSITION.UPPER_RIGHT });
            return false;
        }

        setChangesCount((old)=>old+1);
        $("#exists,#missing,#mislocated").DataTable().ajax.reload()
        // let url = "/InventoryTaking/Reports/" + params.id +'/' + data.room_id;
        // routeChange(url)
    };

    const resetFilter = async () => {
        await setData({
                facility_id: '-1',
                building_id: '-1',
                floor_id:'-1',
                room_id:'-1',
        })
        setChangesCount((old)=>old+1);
        $("#exists,#missing,#mislocated").DataTable().ajax.reload()
        setChainedBuilding([]);
        setChainedFloor([]);
        setChainedRoom([]);
    }
    return (
        <>
            <div className="card mb-5 mb-xl-8">
                <div className="card-body pt-3">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <div>
                                <div className=" align-items-center bg-template p-3">
                                    <h3 className="card-title fw-bolder text-white text-left mb-0">{trans('Choose room')} </h3>
                                </div>
                                <div className="card-body pt-4">
                                    <form onSubmit={handleSubmitChange} className="row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="recipient-name"
                                                   className="col-form-label">{trans('Facility')} :</label>
                                            <select className="form-select form-select-sm"
                                                    aria-label=".form-select-sm example"
                                                    type="text"
                                                    onChange={handleFacilityChange}
                                                    value={data.facility_id}
                                                    required >
                                                <option value="-1"  disabled>{trans('Select an option')}</option>
                                                {dataFacility.map(name => (
                                                    <option key={name.id} value={name.id} >{name.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="recipient-name"
                                                   className="col-form-label">{trans('Building')} :</label>
                                            <select className="form-select form-select-sm"
                                                    aria-label=".form-select-sm example"
                                                    type="text"
                                                    onChange={handleBuildingChange}
                                                    value={data.building_id}
                                                    required >
                                                <option value="-1"  disabled>Select an option</option>
                                                {chainedBuilding.map(name => (
                                                    <option key={name.id} value={name.id} >{trans(name.title)}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="recipient-name"
                                                   className="col-form-label">{trans('Floor')} :</label>
                                            <select className="form-select form-select-sm"
                                                    aria-label=".form-select-sm example"
                                                    type="text"
                                                    onChange={handleFloorChange}
                                                    value={data.floor_id}
                                                    required >
                                                <option value="-1"  disabled>{trans('Select an option')}</option>
                                                {chainedFloor.map(name => (
                                                    <option key={name.id} value={name.id} >{trans(name.name)}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="recipient-name"
                                                   className="col-form-label">{trans('Room')} :</label>
                                            <select className="form-select form-select-sm"
                                                    aria-label=".form-select-sm example"
                                                    type="text"
                                                    onChange={handleRoomChange}
                                                    value={data.room_id}
                                                    name="data[room_space_id]"
                                                    required >
                                                <option value="-1"  disabled>{trans('Select an option')}</option>
                                                {chainedRoom.map(name => (
                                                    <option key={name.id} value={name.id} >{name.room_space_name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="text-center">
                                            <button className="bg-template btn text-white"  type="submit">{trans('Submit')}</button>
                                            <button className="bg-danger btn text-white" type="button" onClick={resetFilter}>{trans('Reset')}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <InventoryTakingReports room_id={data.room_id} it_id={params.id} changesCount={changesCount} />
            <ToastContainer />
        </>
    )
}
export default Scan;
