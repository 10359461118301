import React, {useEffect, useState , useRef} from 'react'
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../config.js";
import Assignee from "./Assignee";
import Actions from "./Actions";
import SpareParts from "./SpareParts";
import StatusHistory from './StatusHistory'
import Attachment from './Attachments'
import Reopen from './Reopen'
import Finish from './Finish'
import {trans} from "../../utils";
import {toast, ToastContainer} from "react-toastify";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";


const Index = (ComponentToRenderData) => {
    let history = useHistory();
    const params = useParams();
    const SystemUrl = ComponentToRenderData.SystemUrl;
    const token = localStorage.getItem('token')
    const [data, setData ] = useState([]);
    const [data2, setData2 ] = useState([]);
    const [data3, setData3 ] = useState([]);
    const [dataStatus, setDataStatus ] = useState([]);
    const [dataSpare, setDataSpare ] = useState([]);
    const [newSpare, setNewSpare ] = useState(false);
    const [resetData, setResetData ] = useState(false);
    const [dataDocument, setDataDocument ] = useState([]);
    const [dataDocumentType, setDataDocumentType ] = useState([]);
    const [data5, setData5 ] = useState([]);
    const [AttachmentShow, setAttachmentShow ] = useState(false);
    const [ReopenShow, setReopenShow ] = useState(false);
    const [FinishShow, setFinishShow ] = useState(false);
    const [StatusHistoryShow, setStatusHistoryShow ] = useState(false);
    const [CostsShow, setCostsShow ] = useState(false);
    const [ActionsShow, setActionsShow ] = useState(false);
    const search = window.location.search;
    let URLParams = new URLSearchParams(search);
    const statusColor = {
        "Assigned" : "bg-info text-white",
        "Reopened":"bg-primary text-white",
        "Active":"bg-light-info text-info",
        "Report Reopened":"bg-light-info text-info",
        "Finished":"bg-light-dark text-dark",
        "Closed":"bg-light-success text-success",
        "Skipped":"bg-light-warning text-warning",
        "Signed Off":"bg-light-success text-success",
        "Pending for Approval":"bg-light-dark text-dark",
        "Pending":"bg-light-dark text-dark"
    }
    useEffect(() => {
        axios(config.SystemUrl + `WorkOrder/${params.id}?include=wo_finished_date,room_space_item.ecri,room_space_item.ecri.options,room_space_item.ecri.group,room_space_item.department,room_space_item.sub_department,ticket.created_by_user,admin,supervisor,department_head,requested_by_user,work_order_spare_part.spare_part,assigned_to_user,room_space_item.risk_class,room_space_item.manufacturer,contract,priority,ticket,room_space_item,status_data,finish_data,room_space_item.facility,room_space_item.facility.customer,room_space_item.facility.sub_customer,room_space_item.building,room_space_item.floor,room_space_item.room,room_space_item.room.department`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => setData(result.data.data));

    }, [resetData])

    useEffect(() => {
        axios(config.SystemUrl + `select/Admin?type=admin`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => setData2(result.data.data) );
    }, [])

    useEffect(() => {
        axios(config.SystemUrl + `select/SparePart?wo_id=${params.id}`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => setData3(result.data.data) );
    }, [])

    useEffect(() => {
        axios(config.SystemUrl + `select/WorkOrderStatus`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => setDataStatus(result.data.data) );
    }, [])

    useEffect(() => {
        axios(config.SystemUrl + `select/WOSparePart?include=spare_part&wo_id=${params.id}`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => setDataSpare(result.data.data) );
    }, [newSpare])

    useEffect(() => {
        axios(config.SystemUrl + `select/AssetItemDocumentationGroup`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => setDataDocument(result.data.data) );
    }, [])

    useEffect(() => {
        axios(config.SystemUrl + `select/AssetItemDocumentationGroupType`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => setDataDocumentType(result.data.data) );
    }, [])


    useEffect(() => {
        axios(config.SystemUrl + `select/WorkOrderFailureType`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => setData5(result.data.data) );
    }, [])





    const sendRequest = (data,url =null,callback = function(response){ window.location.reload(); }) =>
    {
        url = url ? url : `WorkOrder/${params.id}`
        var formData = new FormData();
        for ( let key in data ) {
            formData.append(key, data[key]);
        }
        formData.append('work_order_id', params.id);
        axios.post(config.SystemUrl + url , formData,
            { headers:{
        'content-type': 'multipart/form-data'
        }})
            .then(response => {
                toast.success('Success');
                callback(response);
            }).catch(err => {
                console.log(err.response)

                if(err.response.data.msg){
                    toast.error(err.response.data.msg, { position: toast.POSITION.UPPER_RIGHT });

                }else{
                    toast.error(err.response.data.message[0], { position: toast.POSITION.UPPER_RIGHT });

                }
            // toast(error)
        });

    }

    const componentRef = useRef();

    return (
        <div className="card card-custom mb-5 p-6 bg-gray-100">
       <div className="row">

           <div className="col-md-4">
               <div className="card card-custom mb-5">
                   { data.status_data &&
                   <span className={"p-20 text-center " + statusColor[data.status_data.title_en]}>

                       <h4>{trans(data.status_data.title)}</h4>

                       <h6  ref={componentRef}>{trans('WO')} #{data.id}</h6>
                       {data.ticket &&
                           <h6>{data.ticket.work_order_type}</h6>
                       }
                   </span>
                   }
                   {data.ticket &&
                       <span className="p-2 bg-white text-center">
                          <h4  ref={componentRef}>  {trans('Ticket')} #{data.ticket.id}</h4>
                        </span>
                    }
               </div>
            <div className="card card-custom mb-5">
               <div className="nav flex-column nav-pills menu-rounded menu-column menu-title-gray-700 menu-active-bg-light-primary fw-bold" id="v-pills-tab" role="tablist"
                    aria-orientation="vertical">
                   <div className="menu-item active" id="v-pills-asset-tab" data-toggle="pill" href="#v-pills-asset"
                        role="tab" aria-controls="v-pills-asset" aria-selected="false">
                       <a className={URLParams.get('track')==1 ? "nav-link": "nav-link menu-link py-3"} >
                           <h5 className="mb-0">{trans('WO Information')} </h5>
                       </a>
                   </div>

                   <div className="separator"></div>
                    <div className="menu-item"  id="v-pills-actions-tab" data-toggle="pill" href="#v-pills-actions"
                        role="tab" aria-controls="v-pills-actions" aria-selected="false"
                        onClick={()=> {
                            if(!ActionsShow){
                                setActionsShow(true);
                            }
                        }}>
                       <a className="nav-link menu-link py-3">
                           <h5 className="mb-0">{trans('Reports')}</h5>
                       </a>
                   </div>

                   <div className="separator"></div>
                   {data && data.is_internal_wo && <div className="menu-item"  id="v-pills-costs-tab" data-toggle="pill" href="#v-pills-costs"
                        role="tab" aria-controls="v-pills-costs" aria-selected="false"
                        onClick={()=> {
                            if(!CostsShow){
                                setCostsShow(true);
                            }
                        }}>
                       <a className="nav-link menu-link py-3">
                           <h5 className="mb-0">{trans('Spare Parts')}</h5>
                       </a>
                   </div>}

                   <div className="separator"></div>
                   <div className="menu-item"  id="v-pills-status-tab" data-toggle="pill" href="#v-pills-status"
                        role="tab" aria-controls="v-status-status" aria-selected="false"
                        onClick={()=> {
                            if(!StatusHistoryShow){
                                setStatusHistoryShow(true);
                            }
                        }}>
                       <a className="nav-link menu-link py-3">
                           <h5 className="mb-0">{trans('Status History')}</h5>
                       </a>
                   </div>

                   <div className="separator"></div>
                   <div className="menu-item"  id="v-pills-attachments-tab" data-toggle="pill" href="#v-pills-attachments"
                        role="tab" aria-controls="v-status-attachments" aria-selected="false"
                        onClick={()=> {
                            if(!AttachmentShow){
                                setAttachmentShow(true);
                            }
                        }}>
                       <a className="nav-link menu-link py-3">
                           <h5 className="mb-0">{trans('Attachments')}</h5>
                       </a>
                   </div>
                       {/*<>*/}
                       {/*    <div className="separator"></div>*/}
                       {/*    <div className="menu-item"  id="v-pills-reopen-tab" data-toggle="pill" href="#v-pills-reopen"*/}
                       {/*         role="tab" aria-controls="v-status-reopen" aria-selected="false"*/}
                       {/*         onClick={()=> {*/}
                       {/*             if(!ReopenShow){*/}
                       {/*                 setReopenShow(true);*/}
                       {/*             }*/}
                       {/*         }}>*/}
                       {/*        <a className="nav-link menu-link py-3">*/}
                       {/*            <h5>{trans('Reopened Comments')}</h5>*/}
                       {/*        </a>*/}
                       {/*    </div>*/}
                       {/*</>*/}

                       <>
                           <div className="separator"></div>
                           <div className="menu-item"  id="v-pills-finish-tab" data-toggle="pill" href="#v-pills-finish"
                                role="tab" aria-controls="v-status-finish" aria-selected="false"
                                onClick={()=> {
                                    if(!FinishShow){
                                        setFinishShow(true);
                                    }
                                }}>
                               <a className="nav-link menu-link py-3">
                                   <h5>{trans('Comments')}</h5>
                               </a>
                           </div>
                       </>


               </div>
           </div>
           </div>
           <div className="col-md-8">
               <div className="card card-custom">
                   <div className="tab-content" id="v-pills-tabContent">
                       <div className="tab-pane fade show active card"  id="v-pills-asset" role="tabpanel" aria-labelledby="v-pills-asset-tab">
                        <Assignee data={data} sendRequest={sendRequest} data2={data2} data5={data5}  params={params}/>
                       </div>
                       <div className="tab-pane fade card"  id="v-pills-actions" role="tabpanel" aria-labelledby="v-pills-actions-tab">
                           {ActionsShow && <Actions dataSpare={dataSpare} resetData={resetData} setResetData={setResetData} data={data} data3={data3} data2={data2}    sendRequest={sendRequest}  params={params}/> }
                       </div>
                       {data && data.is_internal_wo && <div className="tab-pane fade card"  id="v-pills-costs" role="tabpanel" aria-labelledby="v-pills-costs-tab">
                           {CostsShow && <SpareParts data={data} data3={data3}  sendRequest={sendRequest}  params={params}   newSpare={newSpare}   dataSpare={dataSpare}  setNewSpare={setNewSpare} />}
                       </div>}
                       <div className="tab-pane fade card"  id="v-pills-status" role="tabpanel" aria-labelledby="v-pills-status-tab">
                           {StatusHistoryShow && <StatusHistory sendRequest={sendRequest} dataStatus={dataStatus} data={data} params={params}/> }
                       </div>
                       <div className="tab-pane fade card"  id="v-pills-attachments" role="tabpanel" aria-labelledby="v-pills-status-tab">
                           {AttachmentShow && <Attachment sendRequest={sendRequest} dataDocumentType={dataDocumentType} dataDocument={dataDocument} params={params} data={data}/>}
                       </div>
                       {/*<div className="tab-pane fade card"  id="v-pills-reopen" role="tabpanel" aria-labelledby="v-pills-status-tab">*/}
                       {/*    {ReopenShow && <Reopen  params={params} data={data}/>}*/}
                       {/*</div>*/}
                       <div className="tab-pane fade card"  id="v-pills-finish" role="tabpanel" aria-labelledby="v-pills-status-tab">
                           {FinishShow && <Finish  sendRequest={sendRequest} params={params} data={data}/>}
                       </div>
                   </div>
               </div>
           </div>
        </div>
            <ToastContainer />

        </div>
    )
}
export default Index;
