import translations from './translation'
import axios from 'axios';
import {SystemUrl } from '../App';
import GetPagesData from "./Routes";

export function trans(key) {
    let lang = localStorage.getItem('lang') || "en"
    return translations[lang][key] ? translations[lang][key] : key
}


export function resetPermissions(CurrentModule) {
    axios.get(`${SystemUrl}getPermissions`).then(res => {

        var pages=res.data.data;
        var lang = localStorage.getItem('lang') || "en";
        var styleUrl = localStorage.getItem('styleUrl') || "/assets/css/style.bundle.css"

        localStorage.clear();
        localStorage.setItem('lang',lang)
        localStorage.setItem('styleUrl',styleUrl)
        localStorage.setItem('isLoggedIn', true)
        GetPagesData(pages);
        window.location.href = '/'+CurrentModule;

    })
}

export function getCustomModules(){
    return [
        {
            route: "AssetItem/View/:id",
            name_en: trans("AssetItem View"),
            module : 'AssetItem'
        },
        {
            route: "Customer/View/:id",
            name_en: trans("Customer View"),
            module : 'Customer'
        },
        {
            route: "Project/View/:id",
            name_en: trans("Project View"),
            module : 'Project'
        }
        ,
        {
            route: "Ticket/View/:id",
            name_en: trans("Ticket Information"),
            module : 'Ticket'
        },
        {
            route: "WorkOrder/View/:id",
            name_en: trans("WorkOrder Information"),
            module : 'WorkOrder'
        },
        {
            route: "Profile/:id",
            name_en: trans("Profile"),
            module : 'Profile'
        },
        {
            route: "RoomSpaceItem/View/:id",
            name_en: trans("Allocate Asset / Item"),
            module : 'RoomSpaceItem'
        },
        {
            route: "AssignAssetItem/View/:id",
            name_en: trans("Assign Asset / Item"),
            module : 'AssignAssetItem'
        },
        {
            route: "Contract/View/:id",
            name_en: trans("Contract Details"),
            module : 'Contract'
        },
        {
            route: "RoomSpace/View/:id",
            name_en: trans("Room Space Details"),
            module : 'RoomSpace'
        },
        {
            route: "SupplierManufacturer/View/:id",
            name_en: trans("Supplier/Manufacturer/Supplier Details"),
            module : 'SupplierManufacturer'
        },
        {
            route: "InventoryTaking/View/:id",
            name_en: trans("Inventory Taking"),
            module : 'InventoryTaking'
        },
        {
            route: "InventoryTaking/Scan/:it_id",
            name_en: trans("Inventory Taking"),
            module : 'Scan',
        },
        {
            route: "InventoryTaking/AssetItemList/:it_id/:room_id",
            name_en: trans("Asset / Item List"),
            module : 'AssetItemList',
        },
        {
            route: "InventoryTaking/Charts/:it_id/:room_id",
            name_en: trans("Inventory Taking Charts"),
            module : 'finishTaking',
        },
        {
            route: "Space/View/:id",
            name_en: trans("Space"),
            module : 'Space',
        },
        {
            route: "ActivityLog/:page/:id",
            name_en: trans("ActivityLog"),
            module : 'ActivityLog',
        },
        {
            route: "ActivityLogPerUser/:user_id",
            name_en: trans("ActivityLogPerUser"),
            module : 'ActivityLogPerUser',
        }

    ];
}
