import React, {useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../config.js";
import {resetPermissions, trans} from "../../utils";
import CustomDataTable from "../CustomDataTable";
import Swal from "sweetalert2";
import { ToastContainer, toast } from 'react-toastify';


const Index = (ComponentToRenderData) => {
    let history = useHistory();
    const params = useParams();
    const SystemUrl = ComponentToRenderData.SystemUrl;
    const token = localStorage.getItem('token')
    const [data, setData ] = useState([]);
    const [pm_date, setPmDate ] = useState('');
    const [close_reason, setCloseReason ] = useState('');
    const routeChange = path => {
        history.push(path);
    }
    const statusColor = {
        "open" : "bg-light-info text-info",
        "wo":"bg-light-primary text-primary",
        "closed":"bg-light-success text-success",
        "planned":"bg-light-warning text-warning",
        "delayed":"bg-light-danger text-danger",
        "reopened":"bg-light-primary text-primary",
        "replanned":"bg-light-warning text-warning",
        "ended":"bg-light-success text-success",
    }
    const columns = [
        {data:"status",title:trans("Status")},
        {data:"close_reason",title:trans("close reason")},
        {data:"date",title:trans("Date")},
        {data:"time",title:trans("Time")}
    ]
    useEffect(() => {
        axios(config.SystemUrl + `Ticket/${params.id}?include=room_space_item.ecri,room_space_item.building,room_space_item.floor,department,building,created_by_user,sub_ticket_type,ticket_type,work_order`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
        .then(result => setData(result.data.data));

    }, [])

    const handleReplanForm = event =>
    {
        event.preventDefault();
        axios.delete(SystemUrl + "Ticket/" + data.id + '?status=replan&pm_date='+pm_date).then(res=>{
            Swal.fire(
                "Replan",
                'Ticket has been replanned' ,
                'success'
            ).then( jsonRes =>{
                window.location.reload()
            });
        }).catch(err =>{
            var error = err.response.data.message
            toast.error(error, { position: toast.POSITION.UPPER_RIGHT });
        } )

    }

    const handleCloseForm = event =>
    {
        event.preventDefault();
        axios.delete(SystemUrl + "Ticket/" + data.id + '?status=closed&close_reason='+close_reason).then(res=>{
            Swal.fire(
                "Close Reason",
                'Ticket has been closed' ,
                'success'
            ).then( jsonRes =>{
                window.location.reload()
            });
        }).catch(err =>{
            var error = err.response.data.message
            toast.error(error, { position: toast.POSITION.UPPER_RIGHT });
        } )

    }
    const ReplanDateChange = event =>
    {
        setPmDate(event.target.value)
    }

    const CloseReasonChange = event =>
    {
        setCloseReason(event.target.value)
    }
    let deleteRecord = (url, type = "Delete") => {
        //Show Sweetalert for confirmation first before submit
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warsetColumns(res.data)ning',
            showCancelButton: true,
            confirmButtonColor: '#3f91ec',
            cancelButtonColor: '#d33',
            confirmButtonText: `Yes, ${type} it!`
        }).then((result) => {
            if (result.isConfirmed) {
                //Submit to delete
                axios.delete(url)
                    .then(jsonRes => {
                        Swal.fire(
                            type == "Close"  ? `${type}d` : `${type}ed`,
                            'Ticket has been ' + type == "Close"  ? `${type}d` : `${type}ed`,
                            'success'
                        ).then( jsonRes =>{
                            window.location.reload()
                        });
                    }).catch(err=>{
                    console.log(err)
                    var error = err.response.data.message
                    toast.error(error, { position: toast.POSITION.UPPER_RIGHT });
                })
            }
        });
    }
    return (
        <div className="card card-custom mb-5 p-6 bg-gray-100">
            <div className="row">
                <div className="col-md-3">
                    <div className="card card-custom mb-5">
                        <span className={"p-20 text-center " + statusColor[data.status]}>
                           <h4>{trans('Ticket')} #{data.id}</h4>
                            <h6>{trans('Status')}: {trans(data.status)}</h6>
                       </span>
                        {data.room_space_item && data.room_space_item.ecri &&
                        <div className="p-2 bg-light text-center">
                            <h5>
                                {trans(data.room_space_item.ecri.ecri_name)}</h5>
                        </div>}
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="card card-custom mb-5">
                        <div className="card-header">
                            <div className="card-title m-0">
                                <h3 className="fw-bolder m-0">{trans('Ticket Information')}</h3>
                            </div>
                            <div className="d-flex my-4">



                                    { data.status && data.status == 'open' ?
                                    <>
                                        { data.actions && data.actions.can_convert_ticket_to_wo == true &&
                                            <button
                                                onClick={() => {
                                                    let url = "/" + "WorkOrder" + "/Add/" + data.id;
                                                    routeChange(url);
                                                }}
                                                className="btn btn-sm btn-light-primary me-3 mr-3">{trans('Convert to WO')}</button>
                                        }
                                        { data.actions && data.actions.can_close_ticket == true &&

                                            <button className="btn btn-light-success waves-effect waves-light mr-3" data-toggle="modal" data-target="#CloseModal">
                                                <span className="indicator-label">{trans('Close')}</span>
                                            </button>
                                        }
                                    </>
                                    :''
                                }
                                { data.status && data.status == 'planned' ?
                                    <>
                                        <button className="btn btn-light-info waves-effect waves-light mr-3"
                                                onClick={() => {let url = SystemUrl + "Ticket/" + data.id + '?status=open';
                                                    deleteRecord(url,"Open");}}>
                                            <span className="indicator-label">{trans('Open')}</span>
                                        </button>
                                        <button className="btn btn-light-success waves-effect waves-light mr-3" data-toggle="modal" data-target="#ReplanModal">
                                            <span className="indicator-label">{trans('Replan')}</span>
                                        </button>
                                    </>

                                    : ""
                                }
                                {data.actions && data.actions.can_reopen_ticket == true && data.status && data.status == 'closed' ?
                                    <>
                                        <button className="btn btn-light-success waves-effect waves-light mr-3"
                                                onClick={() => {
                                                    let url = SystemUrl + "Ticket/" + data.id + '?status=open';
                                                    deleteRecord(url, "Open");
                                                }}
                                        >
                                            <span className="indicator-label">{trans('Reopen')}</span>
                                        </button>
                                    </>
                                    : ""
                                }
                                {data?.work_order?.id && <button
                                    onClick={() => {
                                        let url = "/" + "WorkOrder"+"/View/" + data.work_order.id;
                                        routeChange(url);
                                    }}
                                    className="btn btn-sm btn-light-info me-3 mr-3">{trans('Related WO')}</button>}
                                { data.status && data.status == 'delayed'  &&
                                <button className="btn btn-light-success waves-effect waves-light mr-3" data-toggle="modal" data-target="#ReplanModal">
                                    <span className="indicator-label">{trans('Replan')}</span>
                                </button>
                                }
                                { data.status && data.status == 'replanned'  &&
                                    <>
                                        <button className="btn btn-light-info waves-effect waves-light mr-3"
                                                onClick={() => {let url = SystemUrl + "Ticket/" + data.id + '?status=open';
                                                    deleteRecord(url,"Open");}}>
                                            <span className="indicator-label">{trans('Open')}</span>
                                        </button>
                                        <button className="btn btn-light-success waves-effect waves-light mr-3" data-toggle="modal" data-target="#ReplanModal">
                                            <span className="indicator-label">{trans('Replan')}</span>
                                        </button>
                                    </>
                                }
                            </div>
                        </div>

                        <div className="modal fade" id="ReplanModal" tabIndex="-1" role="dialog" aria-labelledby="ReplanModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">{trans('Change PM Date')}</h5>
                                        <button type="button" className="close" data-dismiss="modal"
                                                aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <form onSubmit={handleReplanForm}>
                                        <div className="modal-body">
                                            <div className="form-group">
                                                <label htmlFor="date">{trans('Add New Date')} :</label>
                                                <input type="date" id="date" name="pm_date"
                                                       value={pm_date}
                                                       onChange={ReplanDateChange}
                                                       required
                                                       className="form-control flatpickr-input"
                                                />
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary"
                                                    data-dismiss="modal">{trans('Close')}
                                            </button>
                                            <button className="btn btn-primary"  type="submit">{trans('Submit')}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="CloseModal" tabIndex="-1" role="dialog"
                             aria-labelledby="CloseModal" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">{trans('Closed Reason')}</h5>
                                        <button type="button" className="close" data-dismiss="modal"
                                                aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <form onSubmit={handleCloseForm}>
                                        <div className="modal-body">
                                            <div className="form-group">
                                                <label htmlFor="date">{trans('Add Closed Reason')} :</label>
                                                <input type="text" name="close_reason"
                                                       value={close_reason}
                                                       onChange={CloseReasonChange}
                                                       required
                                                       className="form-control flatpickr-input"
                                                />
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary"
                                                    data-dismiss="modal">{trans('Close')}
                                            </button>
                                            <button className="btn btn-primary" type="submit">{trans('Submit')}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="card-body p-9">
                            <div className="row">
                                <div className="col-lg-6 col-xxl-4">
                                    <div className="d-flex flex-column justify-content-center flex-row-fluid pe-11 mb-5">

                                            { data.room_space_item && data.room_space_item.ecri &&
                                            <>
                                                <div className="fw-bolder mt-3">{trans('Equipment Name')}</div>
                                                <div className="text-gray-600">
                                                    <a href={'/RoomSpaceItem/View/'+data.room_space_item.id} target="_blank">
                                                        {trans(data.room_space_item.ecri.ecri_name)}
                                                    </a>
                                                </div>
                                            </>
                                            }

                                        {data.room_space_item && data.room_space_item.ecri && data.room_space_item.ecri.length > 0 ?
                                        <>
                                            <div className="separator mb-2 mt-2"></div>
                                            <div className="fw-bolder mt-3">{trans('ECRI Name')} :</div>
                                            <div className="text-gray-600">{data.room_space_item.ecri.ecri_name}</div>
                                        </> : ''
                                        }

                                        {data.room_space_item && data.room_space_item.ecri.length > 0 ?
                                        <>
                                            <div className="separator mb-2 mt-2"></div>
                                            <div className="fw-bolder mt-3">{trans('ECRI Code')} :</div>
                                            <div className="text-gray-600">{data.room_space_item.ecri.ecri_code}</div>
                                        </> : ''
                                        }



                                        {data.room_space_item && data.room_space_item.floor &&
                                        <>
                                            <div className="separator mb-2 mt-2"></div>
                                            <div className="fw-bolder mt-3">{trans('Building')} :</div>
                                            <div className="text-gray-600">{data.room_space_item.floor.name}</div>
                                        </>
                                        }
                                        {data.room_space_item && data.room_space_item.building &&
                                        <>
                                            <div className="separator mb-2 mt-2"></div>
                                            <div className="fw-bolder mt-3">{trans('Department')} :</div>
                                            <div className="text-gray-600">{data.room_space_item.building.title}</div>
                                        </>
                                        }
                                        { data.description &&
                                        <>
                                            <div className="separator mb-2 mt-2"></div>
                                            <div className="fw-bolder mt-3">{trans('Description')} :</div>
                                            <div className="text-gray-600">{trans(data.description)}</div>

                                        </>
                                        }
                                        { data.attachment &&
                                        <>
                                            <div className="separator mb-2 mt-2"></div>
                                            <div className="fw-bolder mt-3">{trans('Attachment')} :</div>

                                            <a href={data.attachment} target='_blank'>{trans('Preview Attachment')}</a>

                                        </>
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xxl-4">
                                    <div className="d-flex flex-column justify-content-center flex-row-fluid pe-11 mb-5">
                                        {data.ticket_type && data.ticket_type.hasOwnProperty("title")  &&
                                        <>
                                            <div className="fw-bolder mt-3">{trans('Ticket Category')}:</div>
                                            <div className="text-gray-600">{trans(data.ticket_type.title)}</div>
                                        </>
                                        }
                                        {data.sub_ticket_type  && data.sub_ticket_type.hasOwnProperty("title") &&
                                        <>
                                            <div className="separator mb-2 mt-2"></div>
                                            <div className="fw-bolder mt-3">{trans('Ticket Sub Category')}:</div>
                                            <div className="text-gray-600">{trans(data.sub_ticket_type.title)}</div>
                                            <div className="separator mb-2 mt-2"></div>
                                        </>
                                        }
                                        {data.created_by_user  &&
                                        <>

                                            <div className="fw-bolder mt-3">{trans('Created by')} :</div>
                                            <div className="text-gray-600">{data.created_by_user.name}</div>
                                        </>
                                        }
                                        <>
                                            <div className="separator mb-2 mt-2"></div>
                                            <div className="fw-bolder mt-3">{trans('Created on')} :</div>
                                            <div className="text-gray-600">{data.created_at}</div>
                                        </>
                                        {data.pm_date && data.pm_date.length > 0 ?
                                        <>
                                            <div className="separator mb-2 mt-2"></div>
                                            <div className="fw-bolder mt-3">{trans('PM Date')} :</div>
                                            <div className="text-gray-600">{data.pm_date}</div>
                                        </> : ""
                                        }
                                        {data.work_order && data.work_order > 0  &&
                                        <>
                                            <div className="separator mb-2 mt-2"></div>
                                            <div className="fw-bolder mt-3">{trans('WO Reference')} :</div>
                                            <div className="text-gray-600">{data.work_order.wo_reference}</div>
                                        </>
                                        }
                                        {data.work_order  && data.work_order > 0 &&
                                        <>
                                            <div className="separator mb-2 mt-2"></div>
                                            <div className="fw-bolder mt-3">{trans('WO Created Date')} :</div>
                                            <div className="text-gray-600">{data.work_order.created_date}</div>
                                        </>
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card card-custom mb-5">
                        <div className="card-header cursor-pointer">
                            <div className="card-title m-0">
                                <h3 className="fw-bolder m-0">{trans('Ticket History')}</h3>
                            </div>
                        </div>
                        <div className="card-body p-9">
                            <CustomDataTable columns={columns} url={"TicketHistory?id="+params.id} table_id="TicketHistory" order={ [[0, "asc"]]} />
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    )
}
export default Index;
