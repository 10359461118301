import React, {useEffect, useState} from 'react';
import CustomDataTable from "../CustomDataTable";
import {trans} from "../../utils";

const Reopen = ({params,data}) =>{

    const columns = [
        {data :"user.name",title:trans("Causer")},
        {data :"comment",title:trans("Comments")},
    ];
    return(
       <>
            <div className="card-header cursor-pointer">
                <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">{trans('Reopened Comments')}</h3>
                </div>
            </div>
            <div className="card-body p-9">
                <CustomDataTable columns={columns} url={"getWorkOrderReopenComments?wo_id="+params.id} table_id="reopen" />
            </div>
        </>
    )

}
export default Reopen
