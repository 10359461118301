import React, {useEffect, useState} from 'react'
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../config.js";
import {trans} from "../../utils";

const Index = (ComponentToRenderData) => {
    let history = useHistory();
    const params = useParams();
    const SystemUrl = ComponentToRenderData.SystemUrl;
    const token = localStorage.getItem('token')
    const [data, setData ] = useState([]);
    const search = window.location.search;
    let URLParams = new URLSearchParams(search);
    const routeChange = path => {
        history.push(path);
    }

    useEffect(() => {
        axios(config.SystemUrl + `AssignAssetItem/${params.id}?include=documentations,asset_item.documentations,ownership,contract,supplier,status_data,manufacturer,asset_item,asset_item.category,asset_item.group,asset_item.type,asset_item.utility,asset_item.ecri,asset_item.uom,asset_item.risk_class,asset_item.pm_frequency,asset_item.qa_frequency,asset_item.calibration_frequency`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => setData(result.data.data));

    }, [])


    return (
        <>
            <div className="d-flex flex-column flex-xl-row">
                <div className="flex-column flex-lg-row-auto w-100 w-xl-350px row">
                    <div className="col-md-4">
                        <div className="card mb-5">
                            {data.asset_item &&
                            <span className="p-20 text-center badge-light-primary">
                                <h4> {trans(data.asset_item.common_name)}</h4>
                                <h6>{trans(data.asset_item.name)}</h6>
                                {data.status_data &&
                                <h6 className="badge badge-light">{data.status_data.title}</h6>
                                }
                            </span>
                            }
                            {data.asset_item &&
                            <span className="p-2 bg-light text-center">
                                <h4>{trans(data.reference)}</h4>
                            </span>
                            }
                        </div>
                        <div className="card">
                            <div className="card-body pt-0">
                                <div className="fs-6">
                                    { data.asset_item && data.asset_item.ecri && data.asset_item.ecri.hasOwnProperty("ecri_name") &&
                                    <>
                                        <div className="fw-bolder mt-3">{trans('ECRI Name')}</div>
                                        <div className="text-gray-600">{trans(data.asset_item.ecri.ecri_name)}</div>
                                        <div className="separator mb-2 mt-2"></div>
                                        <div className="fw-bolder mt-3">{trans('ECRI Code')}</div>
                                        <div className="text-gray-600">{trans(data.asset_item.ecri.ecri_code)}</div>
                                        <div className="separator mb-2 mt-2"></div>
                                    </>
                                    }
                                    { data.asset_item && data.asset_item.risk_class   &&
                                    <>
                                        <div className="fw-bolder mt-3">{trans('Risk Class')}</div>
                                        <div className="text-gray-600">{trans(data.asset_item.risk_class.title)}</div>
                                    </>
                                    }

                                    {data.asset_item && data.asset_item.uom &&
                                    <>
                                        <div className="separator mb-2 mt-2"></div>
                                        <div className="fw-bolder mt-3">{trans('UOM')}</div>
                                        <div className="text-gray-600">{trans(data.asset_item.uom.title)}</div>
                                    </>
                                    }
                                    { data.asset_item && data.asset_item.pm_frequency && data.asset_item.pm_frequency.hasOwnProperty("title") &&
                                    <>
                                        <div className="separator mb-2 mt-2"></div>
                                        <div className="fw-bolder mt-3">{trans('PM Frequency')}</div>
                                        <div className="text-gray-600">{trans(data.asset_item.pm_frequency.title)}</div>
                                    </>
                                    }

                                    {data.asset_item && data.asset_item.qa_frequency && data.asset_item.qa_frequency.hasOwnProperty("title") &&
                                    <>
                                        <div className="separator mb-2 mt-2"></div>
                                        <div className="fw-bolder mt-3">{trans('QA Frequency')}</div>
                                        <div className="text-gray-600">{trans(data.asset_item.qa_frequency.title)}</div>
                                    </>
                                    }


                                    {data.asset_item &&  data.asset_item.calibration_frequency  && data.asset_item.calibration_frequency.hasOwnProperty("title") &&
                                    <>
                                        <div className="separator mb-2 mt-2"></div>
                                        <div className="fw-bolder mt-3">{trans('Calibration Frequency')}</div>
                                        <div className="text-gray-600">{trans(data.asset_item.calibration_frequency.title)}</div>
                                    </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
                            <li className="nav-item">
                                <a className="nav-link text-active-primary pb-4 active" data-toggle="tab" href="#kt_techincal_tab">
                                    {trans('Asset / Item Master Data')}
                                </a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link text-active-primary pb-4" data-toggle="tab" href="#kt_service">
                                    {trans('Procurement / Installation')}
                                </a>
                            </li>

                            { (data.documentations && data.asset_item.documentations && data.documentations.length > 0) ||
                                (data.documentations && data.asset_item.documentations && data.asset_item.documentations.length > 0) ?
                                <li className="nav-item">
                                    <a className="nav-link text-active-primary pb-4" data-toggle="tab" href="#kt_docs_tab">
                                        {trans('Documentations')}
                                    </a>
                                </li> : ""
                            }
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade active show" id="kt_techincal_tab" role="tabpanel">
                                <div className="card">
                                    <div className="card-header card-header-stretch">
                                        <div className="card-title d-flex align-items-center">
                                            <h4 className="mb-1">{trans('Asset / Item Master Data')}</h4>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <div className="fs-6">
                                            { data.manufacturer  &&
                                            <>

                                                <div className="fw-bolder mt-3">{trans('Manufacturer')}</div>
                                                <div className="text-gray-600">{trans(data.manufacturer.name)}</div>
                                            </>
                                            }
                                            {data.manufacturing_date &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Manufacturing Date')}</div>
                                                <div className="text-gray-600">{trans(data.manufacturing_date)}</div>
                                            </>
                                            }
                                            {data.sw_version &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Software Version')}</div>
                                                <div className="text-gray-600">{trans(data.sw_version)}</div>
                                            </>
                                            }

                                            {data.model &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Model')}</div>
                                                <div className="text-gray-600">{trans(data.model)}</div>
                                            </>
                                            }
                                            {data.quantity &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Quantity')}</div>
                                                <div className="text-gray-600">{data.quantity}</div>
                                            </>
                                            }

                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Serial Number')}</div>
                                                <div className="text-gray-600">{data.serial_number}</div>
                                            </>

                                            { data.expire_date &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Expire Date')}</div>
                                                <div className="text-gray-600">{trans(data.expire_date)}</div>
                                            </>
                                            }
                                            { data.lot_no &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('LOT No.')}</div>
                                                <div className="text-gray-600">{trans(data.lot_no)}</div>
                                            </>
                                            }
                                            { data.batch_no &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Batch No.')}</div>
                                                <div className="text-gray-600">{trans(data.batch_no)}</div>
                                            </>
                                            }

                                            { data.barcode &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Barcode')}</div>
                                                <div className="text-gray-600">{trans(data.barcode)}</div>
                                            </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="kt_service" role="tabpanel">
                                <div className="card">
                                    <div className="card-header card-header-stretch">
                                        <div className="card-title d-flex align-items-center">
                                            <h4 className="mb-1">{trans('Procurement / Installation')}</h4>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <div className="fs-6">
                                            { data.supplier  &&
                                            <>

                                                <div className="fw-bolder mt-3">{trans('Supplier')}</div>
                                                <div className="text-gray-600">{trans(data.supplier.name)}</div>
                                            </>
                                            }
                                            { data.installation_date  &&
                                            <>

                                                <div className="fw-bolder mt-3">{trans('Installation Date')}</div>
                                                <div className="text-gray-600">{trans(data.installation_date)}</div>
                                            </>
                                            }
                                            { data.in_service_date  &&
                                            <>

                                                <div className="fw-bolder mt-3">{trans('In Service Date')}</div>
                                                <div className="text-gray-600">{trans(data.in_service_date)}</div>
                                            </>
                                            }
                                            { data.contract  && data.contract.length > 0  ?
                                            <>

                                                <div className="fw-bolder mt-3">{trans('Contract')}</div>
                                                <div className="text-gray-600">{trans(data.contract_ref)}</div>
                                            </> : ""
                                            }
                                            { data.ownership  &&
                                            <>

                                                <div className="fw-bolder mt-3">{trans('Ownership')}</div>
                                                <div className="text-gray-600">{trans(data.ownership.title)}</div>
                                            </>
                                            }
                                            { data.depreciation_rate  &&
                                            <>

                                                <div className="fw-bolder mt-3">{trans('Depreciation Rate')}</div>
                                                <div className="text-gray-600">{trans(data.depreciation_rate)}</div>
                                            </>
                                            }
                                            { data.po_date  &&
                                            <>

                                                <div className="fw-bolder mt-3">{trans('Purchase Order Date')}</div>
                                                <div className="text-gray-600">{trans(data.po_date)}</div>
                                            </>
                                            }
                                            { data.po_value  &&
                                            <>

                                                <div className="fw-bolder mt-3">{trans('Purchase Order Value')}</div>
                                                <div className="text-gray-600">{trans(data.po_value)}</div>
                                            </>
                                            }

                                            { data.po_reference  &&
                                            <>

                                                <div className="fw-bolder mt-3">{trans('Purchase Order Reference')}</div>
                                                <div className="text-gray-600">{trans(data.po_reference)}</div>
                                            </>
                                            }
                                            { data.warranty_start_date  &&
                                            <>

                                                <div className="fw-bolder mt-3">{trans('Warranty start date')}</div>
                                                <div className="text-gray-600">{trans(data.warranty_start_date)}</div>
                                            </>
                                            }
                                            { data.warranty_end_date  &&
                                            <>

                                                <div className="fw-bolder mt-3">{trans('Warranty end date')}</div>
                                                <div className="text-gray-600">{trans(data.warranty_end_date)}</div>
                                            </>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="kt_docs_tab" role="tabpanel">
                                <div className="card mb-5">
                                    <div className="card-header card-header-stretch">
                                        <div className="card-title d-flex align-items-center">
                                            <h4 className="mb-1">{trans('Asset / Item Documentations')}</h4>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <table className="table mt-10">
                                            <thead>
                                            <tr>
                                                <th scope="col">{trans('Document Group')}</th>
                                                <th scope="col">{trans('Document Type')}</th>
                                                <th scope="col">{trans('Document')}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            { data.asset_item && data.asset_item.documentations && data.asset_item.documentations.map(documentation => (
                                                <tr>
                                                    <td>{trans(documentation.parent_category)}</td>
                                                    <td>{trans(documentation.child_category)}</td>
                                                    <td>
                                                        <a href={documentation.file} target="_blank">{trans('Show Attachment')}</a>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header card-header-stretch">
                                        <div className="card-title d-flex align-items-center">
                                            <h4 className="mb-1">{trans('Assign Asset / Item Documentations')}</h4>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <table className="table mt-10">
                                            <thead>
                                            <tr>
                                                <th scope="col">{trans('Document Group')}</th>
                                                <th scope="col">{trans('Document Type')}</th>
                                                <th scope="col">{trans('Document')}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {data.documentations && data.documentations.map(documentation => (
                                                <tr>
                                                    <td>{trans(documentation.parent_category)}</td>
                                                    <td>{trans(documentation.child_category)}</td>
                                                    <td>
                                                        <a href={documentation.file} target="_blank">{trans('Show Attachment')}</a>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Index;
