import React, { useEffect } from 'react';
import {  trans } from '../utils';
import {Link} from 'react-router-dom';
import Language from "./Language";
import { useDispatch } from 'react-redux'
import { activated} from "../../reducer"
import Notifications from "./Notifications";
import UserProfile from "./UserProfile";


const LeftSideBar = ({asideVisible}) => {
    const dispatch = useDispatch()
    let SystemModules = JSON.parse(localStorage.getItem('SystemModules'));
    let all = {};

    useEffect(() => {

        if(!JSON.parse(localStorage.getItem("redux_menu_active")) ){
            SystemModules.forEach((MenuItem, index) => {
                defaultActive(MenuItem,all);
            });
            dispatch(activated(all))
        }



    }, [])

    const defaultActive =(MenuItem,all)=>{

        if (Array.isArray(MenuItem.child)) {
            all[MenuItem.id] =""
            MenuItem.child.forEach((MenuItemNew) => {
                defaultActive(MenuItemNew,all);
            })
        }
    }
    
    const modulesToRetrieve = [
        'RoomSpaceItem',
        'Ticket',
        'WorkOrder',
        'PlanPreventiveMaintenance',
        'Facility',
        'Building',
      ];
      
      const userPermissions = {};
      
      modulesToRetrieve.forEach((module) => {
        const modulePermission = JSON.parse(localStorage.getItem(module));
        userPermissions[module] = modulePermission;
      });

      
    const modulesToDisplay = [
        { 
            name: 'RoomSpaceItem', 
            title: trans('Equipment') ,
            svg: `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" fill="none">
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5"></rect>
                        <path
                            d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z"
                            fill="#000000"
                            opacity="0.3"
                        ></path>
                    </g>
                </svg>`,
        },
        { 
            name: 'Ticket', 
            title: trans('Ticket'),
            svg:`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" fill="none">
                <rect x="0" y="0" width="24" height="24"></rect>
                <rect fill="#000000" opacity="0.3" x="13" y="4" width="3" height="16" rx="1.5"></rect>
                <rect fill="#000000" x="8" y="9" width="3" height="11" rx="1.5"></rect>
                <rect fill="#000000" x="18" y="11" width="3" height="9" rx="1.5"></rect>
                <rect fill="#000000" x="3" y="13" width="3" height="7" rx="1.5"></rect>
            </g>
        </svg>`
         },
        { 
            name: 'WorkOrder', 
            title: trans('Work Order'),
            svg:`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" fill="none">
                <rect x="0" y="0" width="24" height="24"></rect>
                <path d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z" fill="#000000" opacity="0.3"></path>
                <path d="M11.1750002,14.75 C10.9354169,14.75 10.6958335,14.6541667 10.5041669,14.4625 L8.58750019,12.5458333 C8.20416686,12.1625 8.20416686,11.5875 8.58750019,11.2041667 C8.97083352,10.8208333 9.59375019,10.8208333 9.92916686,11.2041667 L11.1750002,12.45 L14.3375002,9.2875 C14.7208335,8.90416667 15.2958335,8.90416667 15.6791669,9.2875 C16.0625002,9.67083333 16.0625002,10.2458333 15.6791669,10.6291667 L11.8458335,14.4625 C11.6541669,14.6541667 11.4145835,14.75 11.1750002,14.75 Z" fill="#000000"></path>
            </g>
        </svg>`
         },
        { 
            name: 'PlanPreventiveMaintenance', 
            title: trans('Plan Preventive Maintenance'),
            svg:`   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" fill="none">
                <rect x="0" y="0" width="24" height="24"></rect>
                <path d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z" fill="#000000"></path>
                <rect fill="#000000" opacity="0.3" transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519) " x="16.3255682" y="2.94551858" width="3" height="18" rx="1"></rect>
            </g>
        </svg>`
         },
        { 
            name: 'Facility', 
            title: trans('Site'),
            svg:`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" fill="none">
                <rect x="0" y="0" width="24" height="24"/>
                <rect fill="#000000" opacity="0.3" x="5" y="8" width="2" height="8" rx="1"/>
                <path d="M6,21 C7.1045695,21 8,20.1045695 8,19 C8,17.8954305 7.1045695,17 6,17 C4.8954305,17 4,17.8954305 4,19 C4,20.1045695 4.8954305,21 6,21 Z M6,23 C3.790861,23 2,21.209139 2,19 C2,16.790861 3.790861,15 6,15 C8.209139,15 10,16.790861 10,19 C10,21.209139 8.209139,23 6,23 Z" fill="#000000" fillRule="nonzero"/>
                <rect fill="#000000" opacity="0.3" x="17" y="8" width="2" height="8" rx="1"/>
                <path d="M18,21 C19.1045695,21 20,20.1045695 20,19 C20,17.8954305 19.1045695,17 18,17 C16.8954305,17 16,17.8954305 16,19 C16,20.1045695 16.8954305,21 18,21 Z M18,23 C15.790861,23 14,21.209139 14,19 C14,16.790861 15.790861,15 18,15 C20.209139,15 22,16.790861 22,19 C22,21.209139 20.209139,23 18,23 Z" fill="#000000" fillRule="nonzero"/>
                <path d="M6,7 C7.1045695,7 8,6.1045695 8,5 C8,3.8954305 7.1045695,3 6,3 C4.8954305,3 4,3.8954305 4,5 C4,6.1045695 4.8954305,7 6,7 Z M6,9 C3.790861,9 2,7.209139 2,5 C2,2.790861 3.790861,1 6,1 C8.209139,1 10,2.790861 10,5 C10,7.209139 8.209139,9 6,9 Z" fill="#000000" fillRule="nonzero"/>
                <path d="M18,7 C19.1045695,7 20,6.1045695 20,5 C20,3.8954305 19.1045695,3 18,3 C16.8954305,3 16,3.8954305 16,5 C16,6.1045695 16.8954305,7 18,7 Z M18,9 C15.790861,9 14,7.209139 14,5 C14,2.790861 15.790861,1 18,1 C20.209139,1 22,2.790861 22,5 C22,7.209139 20.209139,9 18,9 Z" fill="#000000" fillRule="nonzero"/>
            </g>
        </svg>`
         },
        { 
            name: 'Building', 
            title: trans('Department'),
            svg:`<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" version="1.1">
            <g stroke="none" fill="none" >
                <rect x="0" y="0" width="24" height="24"/>
                <path d="M5,5 L19,5 C19.5522847,5 20,5.44771525 20,6 C20,6.55228475 19.5522847,7 19,7 L5,7 C4.44771525,7 4,6.55228475 4,6 C4,5.44771525 4.44771525,5 5,5 Z M5,13 L19,13 C19.5522847,13 20,13.4477153 20,14 C20,14.5522847 19.5522847,15 19,15 L5,15 C4.44771525,15 4,14.5522847 4,14 C4,13.4477153 4.44771525,13 5,13 Z" fill="#000000" opacity="0.3"/>
                <path d="M5,9 L19,9 C19.5522847,9 20,9.44771525 20,10 C20,10.5522847 19.5522847,11 19,11 L5,11 C4.44771525,11 4,10.5522847 4,10 C4,9.44771525 4.44771525,9 5,9 Z M5,17 L19,17 C19.5522847,17 20,17.4477153 20,18 C20,18.5522847 19.5522847,19 19,19 L5,19 C4.44771525,19 4,18.5522847 4,18 C4,17.4477153 4.44771525,17 5,17 Z" fill="#000000"/>
            </g>
        </svg>`
         },
    ];

    const shouldDisplayModule = (moduleName) => {
        if (userPermissions) {
            const modulePermission = userPermissions[moduleName];
            return modulePermission && modulePermission.preview === 1;
        }
        return false;
    };

    
    return (
        <div className={asideVisible ? 'aside aside-left d-flex flex-column aside-on' : 'aside aside-left d-flex flex-column'}>
            <div className="aside-brand d-flex flex-column align-items-center flex-column-auto py-9">
                <UserProfile></UserProfile>
            </div>

            <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid">
                <ul className="nav flex-column">

                {modulesToDisplay.map((module) => (
                    shouldDisplayModule(module.name) && (
                        <li
                            key={module.name}
                            className="nav-item mb-2"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-container="body"
                            data-boundary="window"
                            title={module.title}
                        >
                            <Link to={`/${module.name}`} className="nav-link btn btn-icon btn-lg btn-borderless">
                                <span className="svg-icon svg-icon-xxl" dangerouslySetInnerHTML={{ __html: module.svg }} />
                            </Link>
                        </li>
                    )
                ))}
                </ul>
            </div>

            <div className="aside-footer d-flex flex-column align-items-center flex-column-auto py-8">
                <Notifications></Notifications>
                {/*<a href="#" className="btn btn-icon btn-lg btn-borderless mb-1" id="kt_quick_panel_toggle" data-toggle="tooltip" data-placement="right" data-container="body" data-boundary="window" title="Quick Panel">*/}
                {/*    <span className="svg-icon svg-icon-xxl">*/}
                {/*        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">*/}
                {/*            <g stroke="none" fill="none">*/}
                {/*                <rect x="0" y="0" width="24" height="24"></rect>*/}
                {/*                <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5"></rect>*/}
                {/*                <path d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z" fill="#000000" opacity="0.3"></path>*/}
                {/*            </g>*/}
                {/*        </svg>*/}
                {/*             </span>*/}
                {/*</a>*/}

                <div className="dropdown" data-toggle="tooltip" data-placement="right" data-container="body" data-boundary="window" title="Languages">
                    <Language></Language>
                </div>
            </div>
        </div>
    );
}
export default LeftSideBar;
