import axios from 'axios';
import React, {useEffect, useRef, useState} from 'react';
import {trans} from '../utils';
import './style.css';
import {Chart} from "react-google-charts";
import Calendar from 'react-awesome-calendar';
import config from "../../config.js";
import {Link, useHistory, useParams} from 'react-router-dom';
import ReactToPrint from "react-to-print";
import DataTableComp from "../../DataTables";
import { useDownloadExcel } from 'react-export-table-to-excel';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Spinner from 'react-bootstrap/Spinner';

const Dashboard = (ComponentToRenderData) => {
    const [isLoading, setIsLoading] = useState(true);

    const [data, setData] = useState({});
    useEffect(() => {
        axios.get(SystemUrl + "Dashboard").then(res => {
            setData(res.data);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
        });
    }, [])
    const componentRef = useRef();
    const calendarChange = (mode) => {
        axios.post(config.SystemUrl + `getCalendarData`, mode)
            .then(result => {
                setData({...data, planned_tickets: result.data.data});
            })
            .catch(async (error) => {
                if (error.response.status == 422) {
                    alert(Object.values(error.response.data.errors)[0])
                } else {
                    alert(error.response.data.message);
                }
            });
    }

    const history = useHistory();

    const onClickTicket = (ticket_id) => {
        history.push(`/Ticket/View/${ticket_id}`);
    }
    const CurrentModule = "WorkOrder" ;
    //Get System URL
    const SystemUrl = ComponentToRenderData.SystemUrl;
    const roleParam = ComponentToRenderData.RoleId != 0 ? '?role_id=' + ComponentToRenderData.RoleId : "";
    const [columns, setColumns] = useState(false);
    const [params, setParams] = useState(roleParam);
    const [filter, setFilter] = useState(false)
    const [filterData, setFilterData] = useState({})
    const [filterParams, setFilterParams] = useState([]);
    const tableRef = useRef(null);

    const { onDownload } = useDownloadExcel({
        currentTableRef : tableRef.current,
        filename : 'مؤشر الاعطال',
        sheet :'react-export-table-to-excel',
    })

    useEffect(() => {
        axios.get(config.SystemUrl + "WorkOrder" + `?get_columns=1&`).then(res => {
            var parameters = params
            if(parameters == 0){
                parameters = parameters +"?"
            }else{
                parameters = parameters +"&"
            }
            for (let i = 0; i < res.data.cols.length; i++) {
                res.data.cols[i].title = trans(res.data.cols[i].title)
                // console.log(res.data.cols[i]);
                if(res.data.cols[i].hasOwnProperty('include')){


                    if(parameters.search("include") == -1){
                        parameters = parameters +"include="
                    }
                    else{
                        parameters = parameters +","
                    }
                    parameters = parameters +res.data.cols[i].include
                    setParams(parameters);
                }
            }
            setFilterParams(res.data.search_cols);

            // console.log(res.data.cols)
            setColumns(res.data.cols);


            if(res.data.filters.components){
                setFilter(res.data.filters)
            }
            else{
                setFilter(false)
            }
        })
    }, [])

    const ppmData = [
        [
            trans('Element'), 
            "",
            { role: "style" },
            {
                sourceColumn: 0,
                role: "annotation",
                type: "string",
                calc: "stringify",
            },
        ],
        [
            trans('PPM This Year'),
            data.total_plan_preventive_maintenance,
            "#d1d3e0",
            null,
        ],
        [
            trans('PPM Ticket'), 
            data.tickets_has_plan_preventive,
            "#181c32",
            null,
        ],
        [
            trans('PPM WO'),
            data.wo_has_plan_preventive,
            "#3f91ec",
            null,
        ],
    ];

    const isArabic = localStorage.getItem('lang') === 'ar';

    const chartOptions = {
        colors: ['#fff', '#3f91ec', '#181c32'],
        theme: isArabic ? 'rtl' : 'default',
      };
    
    const modulePermissionSite = JSON.parse(localStorage.getItem("Facility"));


    return (
      <div>
        {isLoading ? (
                <div className="d-flex flex-column-fluid" style={{height: "600px"}}>
                    <div className="container-fluid">
                        <div className="pos-center">
                            <Spinner animation="border" variant="primary" style={{
                                    width:"50px",
                                    height:"50px",
                                    borderWidth: "4px",
                                    borderBottomColor: "rgb(243, 243, 243)",
                                    borderRightColor: "rgb(243, 243, 243)",
                                    borderTopColor: "rgb(243, 243, 243)"
                                }} />
                        </div>
                    </div>
                 </div>
          )
          : (

                <div>
                    {data && <div className="row">
                       
                            <div className="col-xl-4">
                                <div className="card card-custom card-stretch  bg-dark">
                                    <div className="card-header border-0 pt-7  pb-7  bg-dark">
                                        <h3 className="card-title font-weight-bolder font-size-h3-xl text-white">
                                            {trans('Work Orders')}
                                        </h3>
                                        <div className="card-toolbar">
                                            <span className="font-weight-bolder text-white py-1 font-size-h1-xl">{data.wo}</span>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="bg-dark">
                                            <div className="row ml-4 mr-4">
                                                {data.work_order_status_chart_data && data.work_order_status_chart_data.map((wo ,index) => (
                                                    index !=0 && wo[1] != 0 &&
                                                    <div className="col-md-6">
                                                        <div className="align-items-center mb-6 bg-light-dark rounded p-5  w-100">
                                                            <div className="font-size-sm text-dark font-weight-bolder"> {wo[0]}
                                                            </div>
                                                            <div className="font-size-h4 text-dark font-weight-bolder">{wo[1]}</div>
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className="text-center ml-4 mt-6 mr-4 w-100">
                                                    <p className="font-size-sm text-white font-weight-bolder">
                                                        {trans("There are many actions to do for your work orders now !")}
                                                    </p>
                                                    <Link to={"/WorkOrder"}  className="btn mt-4 btn-light-dark text-dark btn-hover-light-dark">
                                                    {trans("View All")}
                                                        <span className="svg-icon svg-icon-dark svg-icon-1x">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none"  fill="none" fillRule="evenodd">
                                                                    <polygon points="0 0 24 0 24 24 0 24"/>
                                                                    <path
                                                                        d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                                                        fill="#000000" fillRule="nonzero"
                                                                        transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "/>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        <div className="col-xl-4">
                            <Link to="/RoomSpaceItem">
                            <div className="card card-custom mb-2">
                                <div className="card-header border-0 pt-0">
                                    <h3 className="card-title font-weight-bolder">
                                        {trans('Total Equipments')}
                                    </h3>
                                    <div className="card-toolbar">
                                        <span className="font-weight-bolder text-dark py-1 font-size-lg">{data.asset_items}</span>
                                    </div>
                                </div>
                                <div className="card-body p-0 d-flex flex-column">
                                    <div className="mr-4 ml-4">
                                        <div className="row row-paddingless">
                                            {data.asset_items_classifications && data.asset_items_classifications.slice(1).map((classification) => (
                                                <div className="d-flex align-items-center mb-2 bg-light-dark rounded p-5  w-100">
                                                    <span className="label label-lg label-dark label-inline font-weight-bold py-4 text-uppercase mr-4">
                                                        {classification[0]}
                                                    </span>
                                                    <div className="d-flex flex-column flex-grow-1 mr-2">
                                                        <a href="#" className="font-weight-bold text-dark-75 text-hover-dark font-size-lg mb-1">
                                                        {trans('Equ. in class')} <span className='text-uppercase'> {classification[0]}</span>
                                                        </a>
                                                    </div>
                                                    <span className="font-weight-bolder text-dark py-1 font-size-lg">{classification[1]}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </Link>
                            <Link to="/Ticket">
                                <div className="card card-custom">
                                    <div className="card-header border-0 pt-0"><h3
                                        className="card-title font-weight-bolder">{trans("Tickets Status")}</h3>
                                        <div className="card-toolbar"><span
                                            className="font-weight-bolder text-dark py-1 font-size-lg">{data.tickets}</span></div>
                                    </div>
                                    <div className="card-body p-0 d-flex flex-column d-n">
                                        <Chart
                                            chartType="PieChart"
                                            loader={<div>Loading Chart</div>}
                                            width="100%"
                                            height="200px"
                                            data={data.ticket_status_chart_data}
                                            rootProps={{'data-testid': '1'}}
                                            options={{
                                                pieHole: 0.4,
                                                is3D: false,
                                                colors: ['#cfd1de', '#3f91ec', '#181c32'], // Specify the colors you want to use
                                            }}
                                        />
                                    </div>
                                </div>
                            </Link>
                        </div>
                            <div className="col-md-4">
                                {data && data.is_admin &&
                                <Link to="/PlanPreventiveMaintenance">
                                    <div className="card card-custom">
                                        <div className="card-header border-0 pt-0">
                                            <h3 className="card-title font-weight-bolder"> {trans('PPM in this year')}</h3>
                                        </div>
                                        <div className="card-body p-0 d-flex flex-column d-n">
                                            <Chart
                                                 chartType="BarChart"
                                                 width="100%"
                                                 height="200px"
                                                 data={ppmData}
                                                 options={chartOptions}
                                            />
                                        </div>
                                    </div>
                                </Link>
                                }
                                {modulePermissionSite && modulePermissionSite.preview && modulePermissionSite.preview === 1 ? 
                                    <div className="card card-custom mb-2">
                                        <div className="card-header border-0 pt-0">
                                            <h3 className="card-title font-weight-bolder">
                                                {trans('Site Info')}
                                            </h3>
                                            <div className="card-toolbar">
                                                <span className="font-weight-bolder text-dark py-1 font-size-lg">{data.facilities}</span>
                                            </div>
                                        </div>
                                        <div className="card-body p-0 d-flex flex-column">
                                            <div className="mr-4 ml-4">
                                                <div className="row row-paddingless">
                                                    <div className="d-flex align-items-center mb-2 bg-light-dark rounded p-5  w-100">
                                                        <div className="d-flex flex-column flex-grow-1 mr-2">
                                                        <Link to="/Building" className="font-weight-bold text-dark-75 text-hover-dark font-size-lg mb-1">
                                                                {trans("Total Departments")}</Link>
                                                        </div>
                                                        <span className="font-weight-bolder text-dark py-1 font-size-lg">{data.buildings_hos}</span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-2 bg-light-dark rounded p-5  w-100">
                                                        <div className="d-flex flex-column flex-grow-1 mr-2">
                                                        <Link to="/Floor" className="font-weight-bold text-dark-75 text-hover-dark font-size-lg mb-1">
                                                                {trans("Total Buildings")}</Link>
                                                        </div>
                                                        {/* <span className="font-weight-bolder text-dark py-1 font-size-lg">{data.facilities_mt}</span> */}
                                                        <span className="font-weight-bolder text-dark py-1 font-size-lg">{data.floors_hos}</span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-2 bg-light-dark rounded p-5  w-100">
                                                        <div className="d-flex flex-column flex-grow-1 mr-2">
                                                        <Link to="/RoomSpace" className="font-weight-bold text-dark-75 text-hover-dark font-size-lg mb-1">
                                                                {trans("Total Rooms")}</Link>
                                                        </div>
                                                        <span className="font-weight-bolder text-dark py-1 font-size-lg">{data.rooms_hos}</span>
                                                        {/* <span className="font-weight-bolder text-dark py-1 font-size-lg">{data.buildings_hos}</span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :""}
                            </div>

                    </div>
                    }
                    <>

                        {data && data.is_allowed_to_see_report && data.malfunction_report && data.malfunction_report.hasOwnProperty("site") &&
                            <div className="card card-custom mb-5 mt-10 p-3">
                                <div className="col-md-12 dashboard" ref={componentRef}  id="printTable">
                                    <h3 className="text-center fw-bolder fs-2">{trans("Malfunction indicator")}</h3>
                                    <div className="table-responsive table-facility">
                                        <table className="table table-bordered">
                                            <tbody>
                                            <tr>
                                                <td colSpan="2" rowSpan="2" className="fw-bolder text-dark fs-4">{trans("Total percentages for the area")}</td>
                                                {data.malfunction_report && data.malfunction_report['site'].map((item, index) => {
                                                    return  <td colSpan="2" rowSpan="2" className="fw-bolder text-dark fs-4">{item.name}</td>
                                                })}
                                                <td colSpan="2" className="fw-bolder text-dark fs-4">{trans("Departments")}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" className="fw-bolder text-dark fs-4">{trans("Devices")}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" rowSpan="2" className="bg-dark text-white  ">{data.malfunction_report && data.malfunction_report['A'][data.malfunction_report['A'].length-1].total_percentage}%</td>
                                                {data.malfunction_report && data.malfunction_report['A'].map((item, index) => {
                                                    if(item.malfunction !== undefined){
                                                        return  <><td rowSpan="2" className="bg-light-dark text-dark waves-effect waves-light">{item.percentage}%</td>
                                                            <td className="bg-light-primary text-dark waves-effect waves-light">{item.working }</td></>
                                                    }
                                                })}
                                                <td  className="fw-bolder text-dark fs-4">{trans("Total number of devices")}</td>
                                                <td rowSpan="2"  className="fw-bolder text-dark fs-4">{trans("Class (A)")}</td>
                                            </tr>
                                            <tr>
                                                {data.malfunction_report && data.malfunction_report['A'].map((item, index) => {
                                                    if(item.malfunction  !== undefined){
                                                        return  <td className="bg-light-dark text-dark waves-effect waves-light">{item.malfunction}</td>
                                                    }
                                                })}
                                                <td  className="fw-bolder text-dark fs-4">{trans("Broken devices")}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" rowSpan="2" className="bg-dark text-white  ">{data.malfunction_report && data.malfunction_report['B'][data.malfunction_report['B'].length-1].total_percentage}%</td>
                                                {data.malfunction_report && data.malfunction_report['B'].map((item, index) => {
                                                    if(item.malfunction !== undefined) {
                                                        return <>
                                                            <td rowSpan="2" className="bg-light-dark text-dark waves-effect waves-light">{item.percentage}%</td>
                                                            <td className="bg-light-primary text-dark waves-effect waves-light">{item.working}</td>
                                                        </>
                                                    }
                                                })}
                                                <td  className="fw-bolder text-dark fs-4">{trans("Total number of devices")}</td>
                                                <td rowSpan="2" className="fw-bolder text-dark fs-4">{trans("Class (B)")}</td>
                                            </tr>
                                            <tr>
                                                {data.malfunction_report && data.malfunction_report['B'].map((item, index) => {
                                                    if(item.malfunction !== undefined){
                                                        return  <td className="bg-light-dark text-dark waves-effect waves-light">{item.malfunction}</td>
                                                    }                                })}
                                                <td  className="fw-bolder text-dark fs-4">{trans("Broken devices")}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" rowSpan="2" className="bg-dark text-white  ">{data.malfunction_report && data.malfunction_report['C'][data.malfunction_report['C'].length-1].total_percentage}%</td>
                                                {data.malfunction_report && data.malfunction_report['C'].map((item, index) => {
                                                    if(item.malfunction !== undefined) {
                                                        return <>
                                                            <td rowSpan="2" className="bg-light-dark text-dark waves-effect waves-light">{item.percentage}%</td>
                                                            <td className="bg-light-primary text-dark waves-effect waves-light">{item.working}</td>
                                                        </>
                                                    }
                                                })}
                                                <td  className="fw-bolder text-dark fs-4">{trans("Total number of devices")}</td>
                                                <td rowSpan="2" className="fw-bolder text-dark fs-4">{trans("Class (C)")}</td>
                                            </tr>
                                            <tr>
                                                {data.malfunction_report && data.malfunction_report['C'].map((item, index) => {
                                                    if(item.malfunction !== undefined){
                                                        return  <td className="bg-light-dark text-dark waves-effect waves-light" key={index}>{item.malfunction}</td>
                                                    }
                                                })}
                                                <td  className="fw-bolder text-dark fs-4">{trans("Broken devices")}</td>
                                            </tr>
                                            <tr>
                                                {data.malfunction_report && <td colSpan="2" className="bg-primary text-white">{data.malfunction_report.all_working_percentage}%</td>}
                                                {data.malfunction_report && data.malfunction_report['total_percentage'].map((item, index) => {
                                                    return  <td colSpan="2" className="bg-dark text-white" key={index}>{item}%</td>
                                                })}
                                                <td colSpan="2" className="fw-bolder text-dark fs-4">{trans("Percentage")}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <ReactToPrint
                                        trigger={() => <button className="btn btn-dark text-white print-btn w-30">{trans("Print Report")} </button>}
                                        content={() => componentRef.current}
                                    />

                                    {/*<button className="btn btn-primary" onClick={onDownload}> Export excel </button>*/}
                                    {/*<DownloadTableExcel*/}
                                    {/*    currentTableRef={tableRef.current}*/}
                                    {/*    filename = 'مؤشر الاعطال الخاصة بالأجهزة الطبية بمستشفيات منطقة تبوك'*/}
                                    {/*    sheet ='react-export-table-to-excel'*/}
                                    {/*>*/}
                                    {/*    <button className="btn btn-primary"> Export Excel </button>*/}

                                    {/*</DownloadTableExcel>*/}
                                </div>
                            </div>
                        }
                        {columns && data &&
                            <div className="card mb-5 mb-xl-8">
                                <div className="card-body pt-3">
                                    <h3 className="text-center fw-bold mt-2 mb-2">
                                        {trans("All the pending Work Orders")}
                                    </h3>
                                    <div className="table">
                                        <input type="hidden" name={'data[active_wo]'} value={'1'}/>
                                        <input type="hidden" name={'data[plan_preventive]'} value={'0'}/>
                                        <DataTableComp Params={params}  RoleId={ComponentToRenderData.RoleId} FilterParams={filterParams} SystemUrl={SystemUrl} Columns={columns} CurrentModule="WorkOrder" />
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            data  &&
                            <div className="col-md-12 card mb-5 p-3">
                                <Calendar onClickEvent={onClickTicket} events={data.planned_tickets} onChange={calendarChange}/>
                            </div>
                        }
                    </>
                </div>

            )}
        </div>
    )
}

export default Dashboard
