export default {
   "title_en": "العنوان بالإنجليزية",
   "Title_en": "العنوان بالإنجليزية",
   "title_ar": "العنوان بالعربية",
   "Title_ar":"لعنوان بالعربية",
   "Logout": "تسجيل الخروج",
   "Page": "الصفحات",
   "Role": "الأدوار",
   "Dashboard": "لوحة التحكم",
   "lang": "English",
   "Create": "إنشاء",
   "Submit": "تأكيد",
   "Home": "الرئيسية",
   "Name" : "الإسم",
   "name" : "الإسم",
   "Title En" : "العنوان بالإنجليزية",
   "Title Ar" : "العنوان بالعربية",
   "Title English" : "العنوان بالإنجليزية",
   "Title Arabic" : "العنوان بالعربية",
   "Actions" : "الأفعال",
   "AssetItem": "الأصول / الادوات",
   "Asset/Items": "الأصول / الادوات",
   "Assets / Items": "الأصول / الادوات",
   "Asset/Item": "الأصول / الادوات",
   "AssetItemCategory": "الفئة",
   "AssetItem Ecri": "تعريف الأصول / الادوات",
   "AssetItem Utility": "منافع",
   "utility":"منافع",
   "AssetItem Group": "المجموعة",
   "AssetItem Type": " النوع",
   "type": " النوع",
   "AssetItem UOM": "UOM",
   "AssetItem Risk Class": "فئة المخاطر ",
   "AssetItemCategoryOption": "فئة اختيارية ",
   "Inventory": "المخزون",
   "inventory": "المخزون",
   "Warehouse": "مستودع",
   "warehouse": "مستودع",
   "Supplier Manufacturer": "الموُرد / الشركة المصنعة",
   "Supplier/Manufacturer": "الموُرد / الشركة المصنعة",
   "supplierManufacturer":"الموُرد / الشركة المصنعة",
   "Suppliers/Manufacturer":"الموُرد / الشركة المصنعة",
   "Suppliers / Manufacturers":"الموُرد / الشركة المصنعة",
   "Rank" : "المرتبة",
   "rank":"المرتبة",
   "Supplier":"الموُرد",
   "supplier":"الموُرد",
   "SupplierManufacturerStatus":"الحالة",
   "Customer":"العميل",
   "customer":"العميل",
   "Customer Category":" فئة العميل",
   "CustomerStatus":" حالة العميل",
   "Assign Asset Item":" تعيين الأصول / الادوات",
   "Frequency" : "تردد",
   "AssignAssetItemStatus":"الحالة",
   "Ownership":"الملكية",
   "OwnerShip":"الملكية",
   "ownership":"الملكية",
   "Document Category":"فئة الوثيقة",
   "Document":"وثيقة",
   "Contract":"اتفافية",
   "CoverageType":"نوع التغطية",
   "coverageType":"نوع التغطية",
   "coverage_type":"نوع التغطية",
   "Currency":"العملة",
   "MeasuringPeriod":"فترة القياس",
   "measuringPeriod":"فترة القياس",
   "measuring_period":"فترة القياس",
   "PenaltyUOM":"UOM عقوبة",
   "penaltyUom":"UOM عقوبة",
   "penalty_uom_id":"UOM عقوبة",
   "LaborManagement":"إدارة العمل",
   "Project":"المشروع",
   "project":"المشروع",
   "ProjectPhase":"المرحلة",
   "projectPhase":"المرحلة",
   "Phase":"المرحلة",
   "phase":"المرحلة",
   "ProjectStage":"المنصة",
   "projectStage":"المنصة",
   "Stage":"المنصة",
   "stage":"المنصة",
   "ProjectStatus":"الحالة",
   "Equipment Stats":"الحالة",
   "projectStatu":"الحالة",
   "Building":"البناء",
   "building":"البناء",
   "RoomSpace":" الغرفة",
   "Room Space":" الغرفة",
   "RoomSpaceCategory":"فئة الغرفة",
   "roomSpaceCategory":"فئة الغرفة",
   "RoomSpaceItem":"تخصيص الأصول / الادوات",
   "SparePartManagement":"إدارة قطع الغيار",
   "Ticket":"الطلب",
   "AssignTicket":"تعيين الطلب",
   "Attachment":"مرفق",
   "TicketStatus":"حالة الطلب",
   "TicketsStatus":"حالة الطلب",
   "ticketsStatus":"حالة الطلب",
   "WorkOrder":"أمر العمل",
   "Work Order":"أمر العمل",
   "work_order":"أمر العمل",
   "Workorder":"أمر العمل",
   "workOrder":"أمر العمل",
   "WorkOrderFailureType":"نوع فشل أمر العمل",
   "WorkOrderStatus":"حالة أمر العمل",
   "WorkOrderType":"نوع أمر العمل",
   "WorkOrderCategory":"فئة أمر العمل",
   "PlanPreventiveMaintenance":"خطة الصيانة الدورية",
   "planpreventive":"خطة الصيانة الدورية",
   "Plan Preventive Maintenance":"خطة الصيانة الدورية",
   "User":"مستخدم",
   "Staff":"طاقم عمل",
   "Additional":"إضافي",
   "City":"المدينة",
   "city":"المدينة",
   "Industry":"الصناعة",
   "industry":"الصناعة",
   "Department":"قسم",
   "department":"قسم",
   "Priority":"أولوية",
   "priority_id":"أولوية",
   "priority":"أولوية",
   "Ecri name":"اسم",
   "ECRI Name":"اسم",
   "Ecri code":"كود",
   "Ecri Code":"كود",
   "ecriName":"اسم",
   "ecriCode":"كود",
   "EcriCode":"كود",
   "Name en":"اسم اللغة الإنجليزية",
   "Name ar":"الاسم بالعربي",
   "Route":"عنوان",
   "Controller":"مراقب",
   "Method Type":"نوع الطريقة",
   "Common name":"اسم شائع",
   "CommonName":"اسم شائع",
   "commonName":"اسم شائع",
   "Risk class":"فئة المخاطر",
   "Risk Class":"فئة المخاطر",
   "RiskClass":"فئة المخاطر",
   "Category": "الفئة",
   "category":"الفئة",
   "Ecri": "تعريف المعدة",
   "Utility": "منافع",
   "Group": "المجموعة",
   "Type": " النوع",
   "UOM": "UOM",
   "Category Option": "فئة اختيارية ",
   "Power":"القوة",
   "Connectivity":"الاتصال",
   "Quantity":"الكمية",
   "Medical use ?":"الاستخدام الطبي؟",
   "isMedicalUse":"الاستخدام الطبي؟",
   "MedicalUse":"الاستخدام الطبي",
   "Shelf life":"مدة الصلاحية",
   "Shelf Life":"مدة الصلاحية",
   "ShelfLife":"مدة الصلاحية",
   "Batch number":"رقم الدفعة",
   "BatchNo.":"رقم الدفعة",
   "lot number":"lot number",
   "LOTNO.":"LOT NO.",
   "Intended use":"الاستخدام المقصود",
   "IntendedUse":"الاستخدام المقصود",
   "Spare part":"قطع غيار",
   "SparePart":"قطع غيار",
   "Spare Part":"قطع غيار",
   "Serial number":"الرقم التسلسلي",
   "SerialNumber":"الرقم التسلسلي",
   "serialNumber":"الرقم التسلسلي",
   "Type to search":"اكتب للبحث",
   "Search":"بحث",
   "No choices to choose from":"لا توجد خيارات للاختيار من بينها",
   "AssetDetails":"تفاصيل الأصول",
   "TechnicalDetials":"تفاصيل تقنية",
   "CutSheet":"CutSheet",
   "Issparepart":"قطع غيار؟",
   "isSparePart":"قطع غيار؟",
   "Documentation":"التوثيق",
   "OptionTitleEn":"خيارات العنوان باللغة الإنجليزية",
   "CategoryOptions":"خيارات الفئة",
   "Options":"الخيارات",
   "OptionTitleAr":"عنوان الخيار باللغة العربية",
   "Add Another":"أضف آخر",
   "Address":"العنوان",
   "address":"العنوان",
   "Asset/ItemCategory":"الفئة",
   "Embedlocation":"المواقع",
   "Email":"بريد الالكتروني",
   "email":"بريد الالكتروني",
   "Website":"موقع الكتروني",
   "website":"موقع الكتروني",
   "Status":"الحالة",
   "status":"الحالة",
   "Reference":"المرجع",
   "Password":"كلمة السر",
   "password":"كلمة السر",
   "Contacts":"جهات الاتصال",
   "Phone":"الهاتف",
   "Contact":"البيانات",
   "Sub name":"الاسم الفرعي",
   "subName":"الاسم الفرعي",
   "Facility":"المنشأة",
   "Facility floor":"أرضية المنشأة",
   "Facility building":"مبنى المنشأة",
   "Facility room":"غرفة المنشأة",
   "Facility department":"قسم المنشأة",
   "Facility room_ref":"مرجع الغرفةالمنشأة",
   "facility":"المنشأة",
   "FacilityFloor":"أرضية المنشأة",
   "facilityBuilding":"مبنى المنشأة",
   "facilityRoom":"غرفة المنشأة",
   "facilityDepartment":"قسم المنشأة",
   "facilityRoomRef.":"مرجع الغرفة المنشأة",
   "Sub reference":"مرجع فرعي",
   "subReference":"مرجع فرعي",
   "reference":"مرجع",
   "Logo":"الشعار",
   "CustomerDetails":"تفاصيل العميل",
   "CustomerFacilities":"مرافق العملاء",
   "CustomerSLA":"الاتفاقية بالعمل",
   "customerSLA":"الاتفاقية بالعمل",
   "Manufacturing date": "تاريخ التصنيع",
   "ManufacturingDate":"تاريخ التصنيع",
   "Model":"النموذج",
   "Expire Date":"تاريخ انتهاء الصلاحية",
   "ExpireDate":"تاريخ انتهاء الصلاحية",
   "SW version":"إصدار البرنامج",
   "SoftwareVersion":"إصدار البرنامج",
   "Installation date":"تاريخ التثبيت",
   "InstallationDate":"تاريخ التثبيت",
   "In service date":"تاريخ الخدمة",
   "In Service Date":"تاريخ الخدمة",
   "InServiceDate":"تاريخ الخدمة",
   "PO date":"تاريخ أمر الشراء",
   "PO reference":"مرجع أمر الشراء",
   "PO value":"قيمة أمر الشراء",
   "Warranty status":"حالة الضمان",
   "Warranty start date":"تاريخ بدء الضمان",
   "Warranty end date": "تاريخ انتهاء الضمان",
   "EndDate": "تاريخ انتهاء",
   "Calibration frequency":"تردد المعايرة",
   "GeneralDetails":"تفاصيل عامة",
   "AssetName":"اسم الأصل",
   "Parent":"الأصل",
   "Asset/ItemStatus":"حالة الأصول / الادوات",
   "Installation":"التركيب",
   "PurchaseOrder":"أمر شراء",
   "PODate":"تاريخ أمر شراء",
   "POReference":"مرجع طلب الشراء",
   "POValue":"قيمة أمر الشراء",
   "Warranty": "الضمان",
   "warranty":"الضمان",
   "Maintenance":"اعمال صيانة",
   "Documents":"الوثائق",
   "DocumentParent":"وثيقة الأصل",
   "ChildCategory":"فئة المستند التابعة",
   "file":"الملف",
   "OptionKey":"مفتاح الاختيار",
   "OptionValue":"قيمة الاختيار",
   "Title":"العنوان",
   "title":"العنوان",
   "Contract ref":"مرجع العقد",
   "Contract_ref":"مرجع العقد",
   "manufacturer":"الصانع",
   "Manufacturer":"الصانع",
   "assign_asset_item":" تعيين الأصول / الادوات",
   "Assign Asset / Item ":" تعيين الأصول / الادوات",
   "AssignAssetItem":" تعيين الأصول / الادوات",
   "assignAssetItem":"تعيين الأصول / الادوات",
   "contract_value":"قيمة العقد",
   "currency":"العملة",
   "response_time":"وقت الاستجابة",
   "repair_time":"وقت الإصلاح",
   "uptime":"مدة التشغيل",
   "from_date":"من التاريخ",
   "FromDate":"من التاريخ",
   "to_date":"الي التاريخ",
   "toDate":"الي التاريخ",
   "penalty_rate":"معدل العقوبة",
   "import_grace_period":"فترة سماح الاستيراد",
   "est cost":"التكلفة التقديرية",
   "est_cost":"التكلفة التقديرية",
   "actual_cost":"التكلفة الفعلية",
   "actual cost":"التكلفة الفعلية",
   "purchase_requisition_ref":"مرجع طلب الشراء",
   "supplier_quotation_ref":"المرجع اقتباس الموُرد",
   "purchase_order_ref":"مرجع طلب الشراء",
   "asset_code":"كود الأصول",
   "Version":"إصدار",
   "ProjectDetails":"تفاصيل المشروع",
   "ProjectName":"اسم المشروع",
   "ProjectVersion":"الإصدار",
   "ProjectReference":"مرجع المشروع",
   "Floor":"الدور",
   "floor":"الدور",
   "Room quantity":"كمية الغرفة",
   "RoomSpaceName":"اسم الغرفة",
   "RoomSpaceRef":"مساحة الغرفة",
   "RoomQuantity":"كمية الغرفة",
   "roomQuantity":"كمية الغرفة",
   "manufacturer part no":"رقم جزء الشركة المصنعة",
   "Manufacturer_part_no":"رقم جزء الشركة المصنعة",
   "manufacturer_part_no":"رقم جزء الشركة المصنعة",
   "Description":"الوصف",
   "description":"الوصف",
   "Created by":"انشأ من",
   "Created_by":"انشأ من",
   "created_by":"انشأ من",
   "Date":"التاريخ",
   "date":"التاريخ",
   "Time":"الوقت",
   "time":"الوقت",
   "Scan":"الفحص",
   "scan":"الفحص",
   "Asset location":"موقع الأصول",
   "AssetLocation":"موقع الأصول",
   "assetLocation":"موقع الأصول",
   "wo_reference":"مرجع أمر العمل",
   "created_date":"تاريخ الإنشاء",
   "start_date":"تاريخ البدء",
   "StartDate":"تاريخ البدء",
   "StartDateTo":"تاريخ البدء الي",
   "StartDateFrom":"تاريخ البدء من",
   "finish_date":"تاريخ الانتهاء",
   "closed_date":"تاريخ الإغلاق",
   "Closed_date":"تاريخ الإغلاق",
   "Requested_by":"بتوصية من",
   "requested_by":"بتوصية من",
   "assigned_to":"مخصص الي",
   "request_description":"وصف الطلب",
   "closed_by":"أغلقت من",
   "actions_tasks":"الإجراءات المهام",
   "costing":"التكلفة",
   "failure_type":"نوع الفشل",
   "failureType":"نوع الفشل",
   "contract":"العقد",
   "ticket":"الطلب",
   "Requester_signing_off":"توقيع الطالب",
   "requester_signing_off":"توقيع الطالب",
   "Contract_id":"العقد",
   "contract_id":"العقد",
   "Tagging":"وضع العلامات",
   "Welcome to AEON":"مرحبًا بكم في AEON",
   "Email address":"أدخل عنوان بريدك الالكتروني",
  "Enter Your Password":"ادخل رقمك السري",
   "Login":"تسجيل الدخول",
   "The email field is required.":"حقل البريد الإلكتروني مطلوب.",
   "The password field is required.":"حقل كلمة المرور مطلوب.",
   "AssetItem View":"عرض الأصول / الادوات",
   "Customer View":"تفاصيل العميل",
   "CreateAssetItem":"انشاء الأصول / الادوات",
   "AssetItemDocumentationGroup":"مجموعة توثيق الأصول / الادوات",
   "AssetItemDocumentationGroupType":" نوع مجموعة توثيق انشاء الأصول / الادوات",
   "DownloadDocument":"تنزيل المستند",
   "Asset/ItemCommonName":"كود",
   "Asset/ItemRef.":"تنزيل المستند",
   "ECRIName":"اسم",
   "PMFrequency":"تردد الصيانة الدورية",
   "QAFrequency":"تردد ضمان الجودة",
   "CalibrationFrequency":"تردد المعايرة",
   "PM Frequency":"تردد الصيانة الدورية",
   "QA Frequency":"تردد ضمان الجودة",
   "Calibration Frequency":"تردد المعايرة",
   "LOTNo.":"LOT No.",
   "PMType":"خطة الصيانة الدورية",
   "PM Date":"تاريخ الصيانة الدورية",
   "DepreciationRate":"معدل الاستهلاك",
   "WarrantyStartDate":"تاريخ بدء الضمان",
   "WarrantyEndDate":"تاريخ انتهاء الضمان",
   "Reset":"إعادة تعيين",
   "Upload":"تحميل",
   "Intendeduse":"الاستخدام المقصود",
   "View":"العرض",
   "Suspend":"تعليق",
   "Edit":"تعديل",
   "Trash":"حذف",
   "Track Asset / Item":"تتبع الأصول / الادوات",
   "Print":"طبع",
   "Actions options":"خيارات الإجراءات",
   "Close":"أغلق",
   "Asset/ItemRef.Generation":"إنشاء مرجع الأصول / الادوات ",
   "Utilities":"الخدمات",
   "PurchaseOrderDate":"تاريخ أمر الشراء",
   "Asset/ItemGroup":"مجموعة الأصول / الادوات",
   "Asset/ItemName":"اسم الأصول / الادوات",
   "Asset/Item Name":"اسم الأصول / الادوات",
   "Asset/Item Common Name":"الاسم الشائع الأصول / الادوات",
   "SiteName":"اسم الموقع",
   "Site":"الموقع",
   "CustomerCategory":"فئة العميل",
   "CustomerName":"اسم العميل",
   "SiteRoom":"غرفة الموقع",
   "SiteBuilding":"بناء الموقع",
   "SiteFloor":"أرضية الموقع",
   "SiteDepartment":"قسم الموقع",
   "SiteWarehouse":"مستودع الموقع",
   "Item":" الادوات",
   "Room":"الغرفة",
   "roomspace":"الغرفة",
   "RoomsSpaces":"الغرفة",
   "Maintain":"صيانة",
   "projectStatus":"حالة المشروع",
   "TicketType":"نوع الطلب",
   "TicketSubType":"نوع التذكرة الفرعي",
   "RequestDescription":"وصف الطلب",
   "WorkOrderInformation":"معلومات أمر العمل",
   "WorkOrderDetails":"تفاصيل أمر العمل",
   "Asset Details":"تفاصيل الأصول",
   "Technical Details":"تفاصيل تقنية",
   "Services & Maintenance":"الخدمات والصيانة",
   "Purchase Information":"معلومات الشراء",
   "Create Ticket":"إنشاء الطلب",
   "Create WO":"إنشاء أمر عمل",
   "Common Name":"اسم شائع",
   "Delete":"حذف",
   "File Name":"اسم الملف",
   "Size":"",
   "AssignAsset/ItemRef.":" تعيين الأصول / الادوات المرجع.",
   "PODateFrom":"تاريخ الشراء من",
   "PODateTo":"تاريخ الشراء إلى",
   "InstallationDateFrom":"تاريخ التثبيت من",
   "InstallationDateTo":"تاريخ التثبيت إلى",
   "AlternativeAsset/ItemRef.":"بديل  الأصول / الادوات المرجع",
   "Alternative Asset / Item Ref.":"بديل  الأصول / الادوات المرجع",
   "DocumentationGroupType":"نوع مجموعة التوثيق",
   "DocumentationGroup":"مجموعة التوثيق",
   "Asset/ItemMasterData":"بيانات الأصول / الادوات الرئيسية",
   "Procurement/Installation":"الشراء / التثبيت",
   "SubCustomer":"العميل الفرعي",
   "Sub Customer":"العميل الفرعي",
   "country":"بلد",
   "Country":"بلد",
   "Room/Space":"غرفة / مساحة",
   "Barcode":"الباركود",
   "Facilities":"المنشأت",
    "roomSpaceRef":"غرفة المرجع",
    "TicketCategory":"فئة الطلب",
    "TicketSub-Category":"الفئة الفرعية لللطلب",
    "CreatedBy":"تم إنشاؤه بواسطة",
    "DateFrom":"التاريخ من",
    "DateTo":"التاريخ إلى",
    "AllocateAsset/ItemRef.":"تخصيص الأصول / الادوات البند.",
    "Allocate Asset / Item Ref.":"تخصيص الأصول / الادوات البند",
    "Allocate Asset/Item Ref.":"تخصيص الأصول / الادوات البند",
    "Allocate Asset/ Item Ref.":"تخصيص الأصول / الادوات البند",
    "Plan Preventive Maintenance Date":"تاريخ خطة الصيانة الدورية",
    "AllocateAsset/Item":"تخصيص الأصول / الادوات",
    "workorder":"أمر العمل",
    "WO":"أمر العمل",
    "WOCategory":"فئة أمر العمل",
    "WOType":"نوع أمر العمل",
    "WODateTo":"تاريخ أمر العمل إلى",
    "WODateFrom":"تاريخ أمر العمل من",
    "WOStatus":"حالة أمر العمل",
    "Assignedto":"مخصص ل",
    "ClosedBy":"مغلق بواسطة",
    "RequestedBy":"تم التطلب بواسطة",
    "FailureType":"نوع الفشل",
    "Documentations":"التوثيق",
    "Document Type":"نوع الوثيقة",
    "Document Group":"مجموعة المستندات",
    "Asset / Item Master Data":"بيانات الأصول / الادوات الرئيسية",
    "Manufacturing Date":"تاريخ التصنيع",
    "Serial Number":"رقم متسلسل",
    "Procurement / Installation":"الشراء / التثبيت",
    "Installation Date":"تاريخ التثبيت",
    "Purchase Order Date":"تاريخ أمر الشراء",
    "Asset / Item Documentations":"وثائق الأصول / الادوات",
    "Assign Asset / Item Documentations":"تعيين وثائق الأصول / الادوات",
    "Show Attachment":"إظهار المرفق",
    "Software Version":"إصدار البرنامج",
    "Depreciation Rate":"معدل الاستهلاك",
    "Purchase Order Value":"قيمة أمر الشراء",
    "Purchase Order Reference":"مرجع طلب الشراء",
    "Preview":"معاينة",
    "PreviewDocument":"معاينة الوثيقة",
    "Allocate Asset / Item":"تخصيص الأصول / الادوات",
    "Assign Asset / Item Ref.":"تعيين الأصول / الادوات المرجع.",
    "ECRI Code":"كود",
    "Ticket Information":"معلومات الطلب",
    "Ticket History":"تاريخ الطلب",
    "Asset Location":"موقع الأصول",
    "Created on":"تاريخ الإنشاء",
    "created_on":"تاريخ الإنشاء",
    "Open":"فتح",
    "open":"فتح",
    "Replan":"أعد فتح",
    "planned":"مخطط",
    "Are you sure?":"هل أنت متأكد؟",
    "Convert to WO":"قم بالتحويل إلى أمر العمل",
    "closed":"مغلق",
    "WorkOrder Information":"معلومات أمر العمل",
    "WO Information":"معلومات أمر العمل",
    "Costs":"التكاليف",
    "costs":"التكاليف",
    "Status History":"محفوظات الحالة",
    "Attachments":"المرفقات",
    "Reopened Comments":"تعليقات اعادة الفتح",
    "Re-opening comments ":"تعليقات اعادة الفتح",
    "Total EST Cost":"إجمالي تكلفة EST",
    "Total Actual Cost":"التكلفة الفعلية الإجمالية",
    "Finish Comments":"التعليقات نهائية",
    "Finish":"انتهاء",
    "Failure Type":"نوع الفشل",
    "Delivered to":"ارسال الي",
    "Select an option":"حدد اختيار",
    "Add a new actions":"أضف إجراءات جديدة",
    "Add a new action":"أضف إجراءً جديدًا",
    "Action Options":"خيارات الإجراءات",
    "Add a new cost":"أضف تكلفة جديدة",
    "Add a new Attachments":"أضف مرفقات جديدة",
    "Approve":"موافقة",
    "Re-open":"إعادة الفتح",
    "Assign":"تعيين",
    "Assign to":"تعيين إلى",
    "Notes To Assignee":"الملاحظات للمكلف به",
    "Start":"ابدأ",
    "InventoryTaking":"الجرد",
    "Inventory Taking":"الجرد",
    "ContractRef.":"مرجع العقد",
    "ContractType":"نوع العقد",
    "Users":"المستخدمون",
    "Customers / Projects":"العملاء / المشاريع",
    "approved":"موافق عليه",
    " E-mail":"بريد الالكتروني",
    "AssetItemGroupOption":"خيار مجموعة الأصول / الادوات",
    "AssetItemUtilityInput":"إدخال الأداة المساعدة الأصول / الادوات",
    "Tickets / Work Orders":"الطلب / أوامر العمل",
    "WorkOrderCostType":"نوع تكلفة أمر العمل",
    "Contracts / Procurements":"العقود / المشتريات",
    "Documents / Forms":"المستندات / النماذج",
    "General Settings":"الاعدادات العامة",
    "Settings":"الاعدادات",
    "Plan/Project":"خطة / مشروع",
    "Project Facility":"مرفق المشروع",
    "ProjectFacility":"مرفق المشروع",
    "Project Facility Building":"مبنى مرفق المشروع",
    "ProjectFacilityBuilding":"مبنى مرفق المشروع",
    "Project Facility Building Floor":"طابق مبنى مرفق المشروع",
    "ProjectFacilityBuildingFloor":"طابق مبنى مرفق المشروع",
    "Project Facility Building Floor Space":"غرفة الطابق مبنى مرفق المشروع",
    "ProjectFacilityBuildingFloorSpace":"غرفة الطابق مبنى مرفق المشروع",
    "TypicalSpace":"مساحة نموذجية",
    "Typical Space":"مساحة نموذجية",
    "SpaceRef.":"مرجع الغرفة",
    "Items":"الادوات",
    "Ref.":"المرجع",
    "Profile":"الملف الشخصي",
    "Allow Notifications":"السماح بالإشعارات",
    "Edit Profile":"تعديل الملف الشخصي",
    "Start Taking":"إبدأ الجرد",
    "Asset / Item Found":" تم العثور على الأصول / الادوات",
    "Asset / Item Not Found":"لم يتم العثور على الأصول / الادوات",
    "Asset Misallocated":"مفقود",
    "Add":"يضيف",
    "WorkOrderStatusHistory":"سجل حالة أمر العمل",
    "WorkOrderAttachment":"مرفق أمر العمل",
    "WorkOrderCost":"تكلفة أمر العمل",
    "WorkOrderActionOption":"خيار إجراء أمر العمل",
    "WorkOrderAction":"إجراء أمر العمل",
    "PlanPreventiveMaintenanceType":"نوع خطة الصيانة ",
    "PeriodName":"اسم الفترة",
    "Preview Attachment":"معاينة المرفق",
    "Report problem":"أبلغ عن مشكلة",
    "All the pending Work Orders":"كافة أوامر العمل المعلقة",
    'code':'كود',
    'assigned':"مخصص ل",
    'delegate':"مندوب",
    "fail date":"تاريخ الفشل",
    "Fail Days":"ايام الفشل",
    "Equipment Name":"اسم المعده",
    'Control No': "رقم التحكم",
    'Work Orders':'أمر العمل',
    'View All' :'عرض الكل',
    'There are many actions to do for your work orders now !':'هناك العديد من الإجراءات التي يمكنك القيام بها لأوامر العمل الخاصة بك الآن!',
    'Total Equipments' :'إجمالي تخصيص الأصول / الادوات',
    'Equipment' :'تخصيص الأصول / الادوات',
    'Equ. in class':'فئة',
    'PPM in this year':'خطط للصيانة في هذا العام',
    'Site Info':'معلومات الموقع',
    'Total Departments':'مجموع الأقسام',
    'Total Buildings':'إجمالي المباني',
    'Total Rooms':'إجمالي الغرف',
    'Tickets Status':'حالة الطلب',
    'PPM This Year':'خطط للصيانة هذا العام',
    'PPM Ticket':'خطة الطلب الصيانة ',
    'PPM WO':'تخطيط أمر عمل الصيانة',
    'Print Report': 'اطبع تقرير',
    'Account settings':'إعدادت الحساب',
    'EquipmentAllocation':'تخصيص المعدات',
    'EquipmentType':'النوع',
    'ModelNo.':'نموذج رقم.',
    'ControlNo.':'رقم التحكم',
    'Classification':'تصنيف',
    'Vendor':'بائع',
    'Price':'سعر',
    'In Warranty':'في الضمان',
    'Working Time':'وقت العمل',
    'there is no installation dates':'لا توجد مواعيد التثبيت',
    'Forgot Password ?':'هل نسيت كلمة السر ؟',
    "Malfunction indicator":"مؤشر الاعطال",
    "Total percentages for the area":"النسب الاجمالية للمنطقة",
    "Departments":"الادارات",
    "Devices":"الأجهزة",
    "Total number of devices":"العدد الكلي للأجهزة",
    "Class (A)":"فئة ( ا )",
    "Class (B)":"فئة ( ب )",
    "Broken devices":"الأجهزة المتعطلة",
    "Class (C)":"فئة ( ج ) ",
    "Percentage":"النسبة المئوية",
    "Incorrect Username or Password.":"اسم المستخدم أو كلمة المرور غير صحيحة.",
    "Mark all as read":"اشر عليها بانها قرات",
    "Confirm Password":"تأكيد كلمة المرور",
    "InReport":" ",
    'code':'كود',
    'Code':'كود',
    "SupervisorDelegate":"مندوب المشرف",
    "PrimarySupervisor":"المشرف الأساسي",
    "InReport":" ? في التقرير",
    "IsPrimaryActive?":"هل الأساسي نشط؟",
    "ResponseTime/Day":"وقت الاستجابة / يوم",
    "PaymentTerms":"شروط الدفع",
    "VisitValue":"قيمة الزيارة",
    "CallCenterContact":"اتصل بمركز الاتصال",
    "CallCenterEmail":"ايميل مركز الاتصال",
    "LastPPM":"آخر PPM",
    "NextPPM":"التالي PPM",
    "PPMWO":"PPM WO",
    "CorrectiveWO":"أمر عمل تصحيحي",
    "AllPendingStatus":"جميع الحالات المعلقة",
    "Mapurl":"رابط الخريطة",
    "Map":"رابط الخريطة",
    "ecriCode":"كود",
    "Site Type":"نوع الموقع",
    "ECRI":"تعريف المعدة",
    "EquipmentData":"بيانات المعدة",
    "ControlNo.":"رقم التحكم",
    "CL":"تصنيف",
    "In Report":"في التقرير",
    "In Report ":"في التقرير",
    "Technican":"الفني",
    "TechnicanDelegate":"مندوب الفني",
    "PrimaryTechnican":"الفني الأساسي",
    "quantity":"كمية",
    "Assignto":"تعيين إلى",
    "Controlnumber":"رقم التحكم",
    "Comments":"تعليقات",
    "Spare Parts":"قطع الغيار",
    "Reports":"التقارير",
    "Print Report":"اطبع التقرير",
    "PM Report":"تقرير الصيانة الدورية",
    "Service Report":"تقرير الخدمة",
    "You have to start this work order to create a report":"يجب أن تبدأ هذا الأمر لإنشاء تقرير",
    "You have to start this work order to create a report":"يجب أن تبدأ هذا الأمر لإنشاء تقرير",
    "Causer Name":"اسم المسبب",
    "Causer Email":"البريد الإلكتروني للمسبب",
    "Action":"إجراء",
    "Date":"تاريخ",
    "Failure Type":"نوع الفشل",
    "Causer":"المسبب",
    "Comments":"التعليقات",
    "Comment":"تعليق",
    "Created By":"انشأ من",
    "Documentation Group":"مجموعة التوثيق",
    "Documentation Type":"نوع التوثيق",
    "File":"الملف",
    "Notifications":"الإشعارات",
    "User Profile":"الملف الشخصي",
    "Signature":"التوقيع",
    "Admin":"المسؤل",
    "Supervisor":"المشرف",
    "Department Head":"رئيس القسم",
    "Maintenance department supervisor":"رئيس القسم",
    "Warehouse Gaurd":"امين المستودع",
    "Contract Type":"نوع العقد",
    "Payment Terms":"شروط الدفع",
    "Created":"انشأ",
    "Updated":"تم التحديث",
    "Deleted":"تم الحذف",
    "AutogeneratePPM" : "توليد تلقائي للخطة الوقائية للصيانة",
    "AutomaticallyGeneratePlanPreventiveMaintenanceforequipmentofthedepartmentbythesystem" : "توليد خطة صيانة وقائية تلقائياً لمعدات القسم من قبل النظام",
    "Chooseanoption" : "اختر خياراً",
    "thisfieldisrequiredwhenprimaryinactive" : "هذا الحقل مطلوب عندما يكون الأساسي غير نشط",
    "at row number": "في رقم الصف",    

    

}
