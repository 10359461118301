import React, { useEffect } from 'react';
import $ from 'jquery';
import config from '../../config.js';
import 'datatables.net';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import JSZip from 'jszip';
import 'datatables.net-bs4';
import 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-responsive';

const CustomDataTable = ({
  onFileSelect,
  columns,
  url,
  table_id,
  columnDefs = [],
  order = [[0, 'desc']],
  FilterParams = [],
}) => {
  useEffect(() => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    window.JSZip = JSZip;
  }, []);

  const transfiles = {
    en: '../../DataTables/English.json',
    ar: '../../DataTables/Arabic.json',
  };

  useEffect(() => {
    // begin first table
    $.fn.dataTable.ext.errMode = 'none';
    $('#' + table_id).DataTable({
      responsive: true,
      searchDelay: 50,
      processing: true,
      serverSide: true,
      searching: false,
      ajax: {
        url: config.SystemUrl + url,
        data: function (data) {
          FilterParams.forEach((el) => {
            data[el] =
              $(`select[name="data[${el}]"]`).val() ||
              $(`input[name="data[${el}]"]`).val();
          });
        },
        xhrFields: {
          withCredentials: true,
        },
      },
      columns: columns,
      order: order,
      columnDefs: [
        { orderable: false},
        ...columnDefs,
      ],
      ordering: false,
      select: {
        style: 'multi',
      },
      dom:
        '<"top"<"left-col"B><"center-col"l><"right-col"f>>rtip',
      buttons: [
        'csv',
        {
          extend: 'print',
          exportOptions: {
            columns: ':visible',
          },
        },
        {
          extend: 'copy',
          exportOptions: {
            columns: [0, ':visible'],
          },
        },
        {
          extend: 'excel',
          exportOptions: {
            columns: ':visible',
          },
        },
        {
            extend: 'print', 
            text:'PDF',
            exportOptions:
                { columns: ':visible' }
        },
        {
          extend: 'colvis',
          postfixButtons: ['colvisRestore'],
        },
      ],
      lengthMenu: [
        [10, 25, 50, 100, 1000, -1],
        [10, 25, 50, 100, 1000, 'All'],
      ],
      language: {
        url: transfiles[localStorage.getItem('lang') || 'en'],
      },
    });
  }, []);

  return (
    <table
      id={table_id}
      className="table table-striped table-hover"
      width="100%"
      cellSpacing="0"
    />
  );
};

export default CustomDataTable;
