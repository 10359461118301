import React, {useEffect,useState} from 'react'
import axios from "axios";
import config from "../../../config.js";
import {useHistory, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {trans} from "../../utils";
import user from "../../../assets/img/user.png";



const Index = (ComponentToRenderData) => {

    let history = useHistory();
    const params = useParams();
    const SystemUrl = ComponentToRenderData.SystemUrl;
    const token = localStorage.getItem('token')
    const [data, setData ] = useState([]);

    useEffect(() => {
        axios(config.SystemUrl + `Profile/0`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => {
                setData(oldData=>({...oldData,email:result.data.data.email,name:result.data.data.name,signature:result.data.data.signature}))
                // setData(oldData=>({...oldData,allow_notification:result.data.data.allow_notification}))
                // if(result.data.data.allow_notification == 1){
                //     document.getElementById('allow_notification').checked = true;
                // }
            });

    }, [])

    const handleSubmitChange = event => {
        event.preventDefault();

        var formData = new FormData();
        for ( let key in data ) {
            formData.append(key, data[key]);
        }
        axios.post(config.SystemUrl + `Profile`, formData,
            { headers:{
                    'content-type': 'multipart/form-data'
                }})
            .then(result => {

                toast.success('Success');
            })
            .catch(async (error) => {
                if (error.response.status == 422) {
                    alert(Object.values(error.response.data.errors)[0])
                }else{
                    alert(error.response.data.message);
                }
            });
        document.getElementById("signature").value = null
    };

    // const handleAllowNotification = (e) =>{
    //     if(e.target.checked){
    //         setData(oldData=>({...oldData,allow_notification:1}))
    //     }else {
    //         setData(oldData=>({...oldData,allow_notification:0}))
    //     }
    // }

    const handleEmailChange = event => {
        setData({ ...data, email: event.target.value });
    };

    const handleNameChange = event => {
        setData({ ...data, name: event.target.value });
    };

    const handlePasswordChange = event => {
        setData({ ...data, password: event.target.value });
    };

    const handleSignatureChange = event => {
        let files = event.target.files || event.dataTransfer.files;
        setData({...data,signature: files[0]} );
    };

    return (
        <>
            <div className="card card-custom">
                <div className="card-body pt-3">
                    <form onSubmit={handleSubmitChange} encType="multipart/form-data">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="message-text"
                                           className="col-form-label">{trans('Name')} :</label>
                                    <input
                                        className="form-control"
                                        type="text" name="name"
                                        onChange={handleNameChange} autoFocus
                                        value={data && data.name}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="message-text"
                                           className="col-form-label">{trans('Email')} :</label>
                                    <input
                                        className="form-control"
                                        type="email" name="email"
                                        onChange={handleEmailChange} autoFocus
                                        value={data && data.email}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="message-text"
                                           className="col-form-label">{trans('Password')} :</label>
                                    <input
                                        className="form-control"
                                        type="password" name="password"
                                        onChange={handlePasswordChange} autoFocus
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="message-text"
                                           className="col-form-label">{trans('Signature')} :</label>
                                    <input
                                        className="form-control"
                                        type="file" name="signature"
                                        id="signature"
                                        onChange={handleSignatureChange} autoFocus

                                    />
                                    <img src={config.SystemUrl+data.signature} alt="image"  height={100} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                {/*<div className="form-check">*/}
                                {/*    <input className="form-check-input" type="checkbox"*/}
                                {/*           onChange={handleAllowNotification} id="allow_notification" />*/}
                                {/*        <label className="form-check-label mt-2" htmlFor="flexCheckDefault">*/}
                                {/*            {trans('Allow Notifications')}*/}
                                {/*        </label>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="text-center">
                            <button type="submit" className="btn btn btn-light-primary" style={{padding: "10px 29px"}}>
                                {trans('Submit')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default Index;
