import React, {useEffect, useState} from 'react';
import axios from "axios";
import config from "../../config.js";
import {NavLink, useHistory, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {trans} from "../utils";
import {SystemUrl} from "../../App";

const Permissions = (ComponentToRenderData ) => {
    let history = useHistory();
    const params = useParams();
    const SystemUrl = ComponentToRenderData.SystemUrl;
    const token = localStorage.getItem('token')
    const [data, setData ] = useState([]);
    const [permission, setpermission] = useState({});

    const [permissionValues, setPermissionValues] = useState([]);
    const handlePermissionChange = (event,page_id) => {
        let permission2 = {};
        let addSetter = {};
        permission2[page_id] = {};
        permission2[page_id][event.target.value] = event.target.checked;
        if(typeof permission[page_id] !== 'undefined'){
             addSetter[page_id] = {...permission[page_id] ,...permission2[page_id]}
        }else{
            addSetter[page_id] = permission2[page_id]

        }
        setpermission( {...permission,...addSetter} );
    };
    const handleSubmitChange = event => {
        event.preventDefault();
        let formData = {
            isLoading: false,
            _method : 'put'
        }
        formData["pages"] = permission
        axios.post(config.SystemUrl + `Permission/${params.id}`,formData)
            .then(result => {
                toast.success('Success');

            })
            .catch(async (error) => {
                if (error.response.status == 422) {
                    alert(Object.values(error.response.data.errors)[0])
                }else{
                    alert(error.response.data.message);
                }
            });
    };
    useEffect(() => {
        axios(config.SystemUrl + `Page?bool=true`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => {
                setData(result.data.data)
            });

    }, [])
    useEffect(() => {
        axios(config.SystemUrl + `getPermissions?page_role_id=${params.id}`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => {
                let allData = [];
                for(const datum of result.data.data){
                    allData[datum.page_id] = datum
                }
                setPermissionValues(allData)
            });

    }, [])

    return(
        <>
            <div className="card card-custom mb-5">
                <div className="card-body pt-3">
                    <form onSubmit={handleSubmitChange} >
                        <div className="modal-body">
                            {data.map(page => {
                                let suspend = permissionValues[page.id] && !!permissionValues[page.id].suspend
                                let preview = permissionValues[page.id] && !!permissionValues[page.id].preview
                                let add = permissionValues[page.id] && !!permissionValues[page.id].add
                                let edit = permissionValues[page.id] && !!permissionValues[page.id].edit
                                let del = permissionValues[page.id] && !!permissionValues[page.id].del

                                return (
                                    ![2, 15, 16].includes(page.id) && <div key={page.id}>
                                        {page.role_editable == 1 ?
                                            <>
                                                <h5><i className={page.icon}></i> {trans(page.name_en)}</h5>
                                                <div className="row">
                                                    {page.preview == 1 ?
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <div className="form-check check-form">
                                                                    <input type="checkbox"  onChange={e => handlePermissionChange(e,page.id)} className="form-check-input" id={"exampleCheck2"+page.id} value={"preview"} defaultChecked={preview}/>
                                                                    <label className="form-check-label" htmlFor={"exampleCheck2"+page.id}> {trans('Preview')}</label>
                                                                </div>
                                                            </div>
                                                        </div> : '' }
                                                    {page.add == 1 ?
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <div className="form-check check-form">
                                                                    <input type="checkbox" className="form-check-input" onChange={e => handlePermissionChange(e,page.id)} id={"exampleCheck3"+page.id} value={"add"} defaultChecked={add} />
                                                                    <label className="form-check-label" htmlFor={"exampleCheck3"+page.id} >  {trans('Add')}</label>
                                                                </div>
                                                            </div>
                                                        </div>: '' }
                                                    {page.edit == 1 ?
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <div className="form-check check-form">
                                                                    <input type="checkbox" className="form-check-input" onChange={e => handlePermissionChange(e,page.id)}  id={"exampleCheck4"+page.id} value={"edit"} defaultChecked={edit}/>
                                                                    <label className="form-check-label" htmlFor={"exampleCheck4"+page.id}>{trans('Edit')}</label>
                                                                </div>
                                                            </div>
                                                        </div>: '' }
                                                    {page.suspend == 1 ?
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <div className="form-check check-form">

                                                                    <input type="checkbox" className="form-check-input" id={"exampleCheck5"+page.id} onChange={e => handlePermissionChange(e,page.id)}  value={"suspend"} defaultChecked={suspend}/>
                                                                    <label className="form-check-label" htmlFor={"exampleCheck5"+page.id}>{trans('Suspend')}</label>
                                                                </div>
                                                            </div>
                                                        </div>: '' }
                                                    {page.del == 1 ?
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <div className="form-check check-form">
                                                                    <input type="checkbox" className="form-check-input"  id={"exampleCheck6"+page.id} value={"del"} onChange={e => handlePermissionChange(e,page.id)}  defaultChecked={del}/>
                                                                    <label className="form-check-label" htmlFor={"exampleCheck6"+page.id}>{trans('Delete')}</label>
                                                                </div>
                                                            </div>
                                                        </div>: '' }
                                                </div>
                                                <hr />
                                            </>: ''
                                        }
                                    </div>
                                )})}
                        </div>
                        <div className="text-center">
                            <button className="btn btn-primary"  type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )

}
export default Permissions
