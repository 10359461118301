import React, {useEffect, useState} from 'react'
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../config.js";
import {trans} from "../../utils";


const Index = (ComponentToRenderData) => {
    let history = useHistory();
    const params = useParams();
    const SystemUrl = ComponentToRenderData.SystemUrl;
    const token = localStorage.getItem('token')
    const [data, setData ] = useState([]);

    useEffect(() => {
        axios(config.SystemUrl + `Project/${params.id}`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => setData(result.data.data));

    }, [])
    return (
        <div className="card">
            <div className="card-body pt-9 pb-0">
                <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
                    <div className="flex-grow-1">
                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center mb-1">
                                    <h3 className="text-gray-800 text-hover-primary fs-2 fw-bolder me-3">
                                        {data.name}
                                    </h3>
                                    <span className="badge badge-lg badge-light-primary d-inline right">{data.status}</span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap justify-content-start">
                            <div className="d-flex flex-wrap">

                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                    <a href="#" className="text-dark">
                                        <div className="d-flex align-items-center">
                                            <span className="svg-icon-danger me-2">
                                                <i className="bi bi-people fs-3"></i>
                                            </span>

                                            { data.customer &&  <div className="fs-4 fw-bolder">
                                                {data.customer.name}</div>}
                                        </div>
                                        <div className="fw-bold fs-6 text-gray-400">Customer</div>
                                    </a>
                                </div>

                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                    <div className="d-flex align-items-center">
                                        <span className="svg-icon-danger me-2">
                                            <i className="bi bi-bar-chart-steps fs-3"></i>
                                        </span>

                                        { data.project_stage &&  <div className="fs-4 fw-bolder">
                                            {data.project_stage.title}</div>}
                                    </div>
                                    <div className="fw-bold fs-6 text-gray-400">Stage</div>
                                </div>

                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                    <div className="d-flex align-items-center">
                                        <span className="svg-icon-danger me-2">
                                            <i className="bi bi-stars fs-3"></i>
                                        </span>
                                        {data.project_stage &&
                                        <div className="fs-4 fw-bolder counted">
                                            {data.project_status.title}
                                        </div>
                                        }
                                    </div>
                                    <div className="fw-bold fs-6 text-gray-400">Status</div>
                                </div>

                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                    <div className="d-flex align-items-center">
                                        <span className="svg-icon-danger me-2">
                                            <i className="bi bi-aspect-ratio fs-3"></i>
                                        </span>
                                        {data.project_phase &&
                                        <div className="fs-4 fw-bolder counted">
                                            {data.project_phase.title}
                                        </div>
                                        }
                                    </div>
                                    <div className="fw-bold fs-6 text-gray-400">Phase</div>
                                </div>

                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                    <div className="d-flex align-items-center">
                                        <span className="svg-icon-danger me-2">
                                            <i className="bi bi-collection fs-3"></i>
                                        </span>
                                        <div className="fs-4 fw-bolder counted">{data.reference}</div>
                                    </div>
                                    <div className="fw-bold fs-6 text-gray-400">Reference</div>
                                </div>

                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                    <div className="d-flex align-items-center">
                                        <span className="svg-icon-danger me-2">
                                            <i className="bi bi-gear fs-3"></i>
                                        </span>
                                        <div className="fs-4 fw-bolder counted">{data.version}</div>
                                    </div>
                                    <div className="fw-bold fs-6 text-gray-400">Version</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className="separator"></div>*/}
                {/*<div className="d-flex overflow-auto h-55px">*/}
                {/*    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">*/}
                {/*        <li className="nav-item">*/}
                {/*            <a className="nav-link text-active-primary me-6"*/}
                {/*               href="/metronic8/demo13/../demo13/pages/projects/project.html">Overview</a>*/}
                {/*        </li>*/}
                {/*        <li className="nav-item">*/}
                {/*            <a className="nav-link text-active-primary me-6 active"*/}
                {/*               href="/metronic8/demo13/../demo13/pages/projects/targets.html">Targets</a>*/}
                {/*        </li>*/}
                {/*        <li className="nav-item">*/}
                {/*            <a className="nav-link text-active-primary me-6"*/}
                {/*               href="/metronic8/demo13/../demo13/pages/projects/budget.html">Budget</a>*/}
                {/*        </li>*/}
                {/*        <li>*/}
                {/*            <a className="nav-link text-active-primary me-6"*/}
                {/*               href="/metronic8/demo13/../demo13/pages/projects/users.html">Users</a>*/}
                {/*        </li>*/}
                {/*        <li className="nav-item">*/}
                {/*            <a className="nav-link text-active-primary me-6"*/}
                {/*               href="/metronic8/demo13/../demo13/pages/projects/files.html">Files</a>*/}
                {/*        </li>*/}
                {/*        <li className="nav-item">*/}
                {/*            <a className="nav-link text-active-primary me-6"*/}
                {/*               href="/metronic8/demo13/../demo13/pages/projects/activity.html">Activity</a>*/}
                {/*        </li>*/}
                {/*        <li className="nav-item">*/}
                {/*            <a className="nav-link text-active-primary me-6"*/}
                {/*               href="/metronic8/demo13/../demo13/pages/projects/settings.html">Settings</a>*/}
                {/*        </li>*/}
                {/*    </ul>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}
export default Index;
