import axios from 'axios';

//Set Dynamic Routes Generate at app.js Page
var Navigation = [];

//Get Data Render
export let GetPagesData = (RecievedRoutes) => {
   // console.log(RecievedRoutes);
    for (let Module of RecievedRoutes) {

        HandlingModuleRoutes(Module);
    }
    //Set Navigation routes to the local sorage
    localStorage.setItem('Navigation',JSON.stringify(Navigation));
    localStorage.setItem('SystemModules',JSON.stringify(RecievedRoutes));

}


///////////////////////////////////////////////////////////
///////////////////Function to Set Routes/////////////////
///////////////////////////////////////////////////////////
function HandlingModuleRoutes(Module){
    
    var route=Module.route;

    var preview=Module.preview;
    var add=Module.add;
    var edit=Module.edit;
    var del=Module.del;
    var suspend=Module.suspend;

    var page_add=Module.page_add;
    var page_edit=Module.page_edit;
    var page_del=Module.page_del;
    var page_suspend=Module.page_suspend;
    var page_preview=Module.page_preview;

    var menu=Module.menu;
    var is_select=Module.is_select;
    var name_en=Module.name_en;
    var name_ar=Module.name_ar;
    var ModuleFolder="CrudModule";

    if((del==1  && page_del==1 )|| (suspend==1  && page_suspend==1)){
        preview = 1
    }
    if(add==1 && page_add==1){
        preview = 1
        //Route Print at app main component
        Navigation.push(['/'+route+'/Add/:parent_id?', route, 'Add', ModuleFolder , false]);
    }
    if(edit==1  && page_edit==1){
        preview = 1
        //Route Print at app main component
        Navigation.push(['/'+route+'/Edit/:id', route, 'Edit', ModuleFolder, false]);
    }

    //Handling Pages
    if(preview==1){
        Module.preview = 1;
        //Route Print at app main component
        Navigation.push(['/'+route, route, 'View', ModuleFolder, false]);
    }

    //Set System Modules Variable
    localStorage.setItem(route,JSON.stringify(Module));

    //Handling Child
    var HasChild=Module.child;
    if(Array.isArray(HasChild)){
        //For each Child as Module
        for (let ChildModule of HasChild) {
            //Call Function to Handle App.js Routes, Template.js Dynamic Modules
            HandlingModuleRoutes(ChildModule);
        }
    }
}
///END Function
export default GetPagesData;




