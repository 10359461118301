import React from "react";

const Footer = () => {

return (
    <div className="footer py-2 py-lg-0 my-5 d-flex flex-lg-column" id="kt_footer">
        <div
            className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
            <div className="text-dark order-2 order-md-1">
                <span className="text-muted font-weight-bold mr-2">2023 ©</span>
                <a href="https://www.intrazero.com" target="_blank"
                   className="text-dark-75 text-hover-primary">Intrazero</a>
            </div>
        </div>
    </div>
)
}
export default Footer;
