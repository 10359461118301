 import React, {useEffect, useState , useRef} from 'react'
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../config.js";
import {trans} from "../../utils";
import QRCode from "react-qr-code";
import ReactToPrint from "react-to-print";
import Spinner from 'react-bootstrap/Spinner';



const Index = (ComponentToRenderdata) => {
    let history = useHistory();
    const params = useParams();
    const SystemUrl = ComponentToRenderdata.SystemUrl;
    const token = localStorage.getItem('token')
    const [data, setdata] = useState([]);
    const search = window.location.search;
    let URLParams = new URLSearchParams(search);
    const [ticketData, setTicketData ] = useState([]);
    const routeChange = path => {
        history.push(path);
    }
    const CurrentModuleObj = JSON.parse(localStorage.getItem("RoomSpaceItem"));

    useEffect(() => {
        axios(config.SystemUrl + `RoomSpaceItem/${params.id}?include=next_ppm,last_ppm,ppm_wo_signed_off,work_orders,sub_contract_relation,vendor,one_tickets,facility.customer,room.department,facility.sub_customer,facility,building,floor,room,department,warehouse,supplier,documentations,manufacturer,ecri.category,ecri.group,related_pm_frequency,risk_class`, {
          headers: {
            "Accept": "application/json",
            "lang": "en",
            'Authorization': "Bearer " + token
          }
        })
        .then(result => {
          setdata(result.data.data);
          setIsLoading(false); 
        })
        .catch(error => {
          console.error(error);
          setIsLoading(false);
        });
      }, []);
      
    const componentRef = useRef();
    const componentQR = useRef();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      const modulePermission = JSON.parse(localStorage.getItem("Ticket"));

    return (
        <>
        <div>
        {isLoading ? (
                <div className="d-flex flex-column-fluid" style={{height: "600px"}}>
                    <div className="container-fluid">
                        <div className="pos-center">
                            <Spinner animation="border" variant="primary" style={{
                                    width:"50px",
                                    height:"50px",
                                    borderWidth: "4px",
                                    borderBottomColor: "rgb(243, 243, 243)",
                                    borderRightColor: "rgb(243, 243, 243)",
                                    borderTopColor: "rgb(243, 243, 243)"
                                }} />
                        </div>
                    </div>
                 </div>
          )
          : (

            <div className="card card-custom mb-5 p-6 bg-gray-100">
            <div className="row">
                <div className="col-md-3">
                    <div className="text-center">
                        <div className="symbol symbol-100px mb-7 symbol-square text-center mt-3" ref={componentQR}>
                            <div className="print-qr justify-content-center text-center">
                                <QRCode value={config.FrontUrl  + "Ticket" + "/Add?hiddenInput[asset_item_id]="+params.id+"&parent_id="+params.id+"&swal=1"}/>
                                   {data &&
                                    <h4 className=" mt-3 text-center">{trans(data.control_no)}</h4>
                                    }
                            </div>
                        </div>
                    </div>
                    <div className="card card-custom card-shadowless bg-white gutter-b">
                        <div className="card-body pt-8">
                            { data.ecri && data.ecri.category &&
                                <div className="d-flex align-items-center mb-10">
                                    <div className="symbol symbol-40 symbol-light-primary mr-5">
                                    <span className="symbol-label">
                                       <span className="svg-icon svg-icon-xl svg-icon-primary">
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
                                             <g stroke="none" fill="none">
                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                <path
                                                    d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z"
                                                    fill="#000000"></path>
                                                <rect fill="#000000" opacity="0.3"
                                                      transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519) "
                                                      x="16.3255682" y="2.94551858" width="3" height="18" rx="1"></rect>
                                             </g>
                                          </svg>
                                       </span>
                                    </span>
                                    </div>
                                    <div className="d-flex flex-column font-weight-bold">
                                        <h2 className="text-dark mb-1 font-size-lg">{trans('Category')}</h2>
                                        <span className="text-muted">{trans(data.ecri.category.title)}</span>
                                    </div>
                                </div>
                            }
    
                            { data.ecri && data.ecri.group &&
                                <div className="d-flex align-items-center mb-10">
                                    <div className="symbol symbol-40 symbol-light-warning mr-5">
                                    <span className="symbol-label">
                                       <span className="svg-icon svg-icon-lg svg-icon-warning">
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
                                             <g stroke="none" fill="none">
                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                <path
                                                    d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                                    fill="#000000" 
                                                    transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "></path>
                                                <path
                                                    d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                                    fill="#000000"  opacity="0.3"></path>
                                             </g>
                                          </svg>
                                       </span>
                                    </span>
                                    </div>
                                    <div className="d-flex flex-column font-weight-bold">
                                        <h2 className="text-dark-75 mb-1 font-size-lg">{trans('Group')}</h2>
                                        <span className="text-muted">{trans(data.ecri.group.title)}</span>
                                    </div>
                                </div>
                            }
    
                            {data.ecri && data.ecri.hasOwnProperty("ecri_name") &&
                                <div className="d-flex align-items-center mb-10">
                                    <div className="symbol symbol-40 symbol-light-success mr-5">
                                    <span className="symbol-label">
                                       <span className="svg-icon svg-icon-lg svg-icon-success">
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
                                             <g stroke="none" fill="none">
                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                <path
                                                    d="M16,15.6315789 L16,12 C16,10.3431458 14.6568542,9 13,9 L6.16183229,9 L6.16183229,5.52631579 C6.16183229,4.13107011 7.29290239,3 8.68814808,3 L20.4776218,3 C21.8728674,3 23.0039375,4.13107011 23.0039375,5.52631579 L23.0039375,13.1052632 L23.0206157,17.786793 C23.0215995,18.0629336 22.7985408,18.2875874 22.5224001,18.2885711 C22.3891754,18.2890457 22.2612702,18.2363324 22.1670655,18.1421277 L19.6565168,15.6315789 L16,15.6315789 Z"
                                                    fill="#000000"></path>
                                                <path
                                                    d="M1.98505595,18 L1.98505595,13 C1.98505595,11.8954305 2.88048645,11 3.98505595,11 L11.9850559,11 C13.0896254,11 13.9850559,11.8954305 13.9850559,13 L13.9850559,18 C13.9850559,19.1045695 13.0896254,20 11.9850559,20 L4.10078614,20 L2.85693427,21.1905292 C2.65744295,21.3814685 2.34093638,21.3745358 2.14999706,21.1750444 C2.06092565,21.0819836 2.01120804,20.958136 2.01120804,20.8293182 L2.01120804,18.32426 C1.99400175,18.2187196 1.98505595,18.1104045 1.98505595,18 Z M6.5,14 C6.22385763,14 6,14.2238576 6,14.5 C6,14.7761424 6.22385763,15 6.5,15 L11.5,15 C11.7761424,15 12,14.7761424 12,14.5 C12,14.2238576 11.7761424,14 11.5,14 L6.5,14 Z M9.5,16 C9.22385763,16 9,16.2238576 9,16.5 C9,16.7761424 9.22385763,17 9.5,17 L11.5,17 C11.7761424,17 12,16.7761424 12,16.5 C12,16.2238576 11.7761424,16 11.5,16 L9.5,16 Z"
                                                    fill="#000000" opacity="0.3"></path>
                                             </g>
                                          </svg>
                                       </span>
                                    </span>
                                    </div>
                                    <div className="d-flex flex-column font-weight-bold">
                                        <h2 className="text-dark mb-1 font-size-lg">{trans('ECRI Name')}</h2>
                                        <span className="text-muted">{trans('ECRI Code')}</span>
                                        <span className="text-muted">{trans(data.ecri.ecri_code)}</span>
                                    </div>
                                </div>
                            }
    
                            {data.risk_class &&
                                <div className="d-flex align-items-center mb-10">
                                    <div className="symbol symbol-40 symbol-light-danger mr-5">
                                    <span className="symbol-label">
                                       <span className="svg-icon svg-icon-lg svg-icon-danger">
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
                                             <g stroke="none" fill="none">
                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                <path
                                                    d="M11.7573593,15.2426407 L8.75735931,15.2426407 C8.20507456,15.2426407 7.75735931,15.6903559 7.75735931,16.2426407 C7.75735931,16.7949254 8.20507456,17.2426407 8.75735931,17.2426407 L11.7573593,17.2426407 L11.7573593,18.2426407 C11.7573593,19.3472102 10.8619288,20.2426407 9.75735931,20.2426407 L5.75735931,20.2426407 C4.65278981,20.2426407 3.75735931,19.3472102 3.75735931,18.2426407 L3.75735931,14.2426407 C3.75735931,13.1380712 4.65278981,12.2426407 5.75735931,12.2426407 L9.75735931,12.2426407 C10.8619288,12.2426407 11.7573593,13.1380712 11.7573593,14.2426407 L11.7573593,15.2426407 Z"
                                                    fill="#000000" opacity="0.3"
                                                    transform="translate(7.757359, 16.242641) rotate(-45.000000) translate(-7.757359, -16.242641) "></path>
                                                <path
                                                    d="M12.2426407,8.75735931 L15.2426407,8.75735931 C15.7949254,8.75735931 16.2426407,8.30964406 16.2426407,7.75735931 C16.2426407,7.20507456 15.7949254,6.75735931 15.2426407,6.75735931 L12.2426407,6.75735931 L12.2426407,5.75735931 C12.2426407,4.65278981 13.1380712,3.75735931 14.2426407,3.75735931 L18.2426407,3.75735931 C19.3472102,3.75735931 20.2426407,4.65278981 20.2426407,5.75735931 L20.2426407,9.75735931 C20.2426407,10.8619288 19.3472102,11.7573593 18.2426407,11.7573593 L14.2426407,11.7573593 C13.1380712,11.7573593 12.2426407,10.8619288 12.2426407,9.75735931 L12.2426407,8.75735931 Z"
                                                    fill="#000000"
                                                    transform="translate(16.242641, 7.757359) rotate(-45.000000) translate(-16.242641, -7.757359) "></path>
                                                <path
                                                    d="M5.89339828,3.42893219 C6.44568303,3.42893219 6.89339828,3.87664744 6.89339828,4.42893219 L6.89339828,6.42893219 C6.89339828,6.98121694 6.44568303,7.42893219 5.89339828,7.42893219 C5.34111353,7.42893219 4.89339828,6.98121694 4.89339828,6.42893219 L4.89339828,4.42893219 C4.89339828,3.87664744 5.34111353,3.42893219 5.89339828,3.42893219 Z M11.4289322,5.13603897 C11.8194565,5.52656326 11.8194565,6.15972824 11.4289322,6.55025253 L10.0147186,7.96446609 C9.62419433,8.35499039 8.99102936,8.35499039 8.60050506,7.96446609 C8.20998077,7.5739418 8.20998077,6.94077682 8.60050506,6.55025253 L10.0147186,5.13603897 C10.4052429,4.74551468 11.0384079,4.74551468 11.4289322,5.13603897 Z M0.600505063,5.13603897 C0.991029355,4.74551468 1.62419433,4.74551468 2.01471863,5.13603897 L3.42893219,6.55025253 C3.81945648,6.94077682 3.81945648,7.5739418 3.42893219,7.96446609 C3.0384079,8.35499039 2.40524292,8.35499039 2.01471863,7.96446609 L0.600505063,6.55025253 C0.209980772,6.15972824 0.209980772,5.52656326 0.600505063,5.13603897 Z"
                                                    fill="#000000" opacity="0.3"
                                                    transform="translate(6.014719, 5.843146) rotate(-45.000000) translate(-6.014719, -5.843146) "></path>
                                                <path
                                                    d="M17.9142136,15.4497475 C18.4664983,15.4497475 18.9142136,15.8974627 18.9142136,16.4497475 L18.9142136,18.4497475 C18.9142136,19.0020322 18.4664983,19.4497475 17.9142136,19.4497475 C17.3619288,19.4497475 16.9142136,19.0020322 16.9142136,18.4497475 L16.9142136,16.4497475 C16.9142136,15.8974627 17.3619288,15.4497475 17.9142136,15.4497475 Z M23.4497475,17.1568542 C23.8402718,17.5473785 23.8402718,18.1805435 23.4497475,18.5710678 L22.0355339,19.9852814 C21.6450096,20.3758057 21.0118446,20.3758057 20.6213203,19.9852814 C20.2307961,19.5947571 20.2307961,18.9615921 20.6213203,18.5710678 L22.0355339,17.1568542 C22.4260582,16.76633 23.0592232,16.76633 23.4497475,17.1568542 Z M12.6213203,17.1568542 C13.0118446,16.76633 13.6450096,16.76633 14.0355339,17.1568542 L15.4497475,18.5710678 C15.8402718,18.9615921 15.8402718,19.5947571 15.4497475,19.9852814 C15.0592232,20.3758057 14.4260582,20.3758057 14.0355339,19.9852814 L12.6213203,18.5710678 C12.2307961,18.1805435 12.2307961,17.5473785 12.6213203,17.1568542 Z"
                                                    fill="#000000" opacity="0.3"
                                                    transform="translate(18.035534, 17.863961) scale(1, -1) rotate(45.000000) translate(-18.035534, -17.863961) "></path>
                                             </g>
                                          </svg>
                                       </span>
                                    </span>
                                    </div>
                                    <div className="d-flex flex-column font-weight-bold">
                                        <h2 className="text-dark mb-1 font-size-lg">{trans('Risk Class')}</h2>
                                        <span className="text-muted">{trans(data.risk_class.title)}</span>
                                    </div>
                                </div>
                            }
    
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <ul className="nav nav-tabs nav-tabs-line">
                        <li className="nav-item">
                            <a className="nav-link active" data-toggle="tab"
                               href="#kt_allocate_tab">
                                {trans('Equipment')}
                            </a>
                        </li>
    
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="tab" href="#kt_service">
                                {trans('Procurement / Installation')}
                            </a>
                        </li>
    
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="tab" href="#kt_stats">
                                {trans('Equipment Stats')}
                            </a>
                        </li>
    
                        {data.documentations && data.documentations.length > 0 ?
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab"
                                   href="#kt_docs_tab">
                                    {trans('Documentations')}
                                </a>
                            </li> : ""
                        }
                        { data.status && data.status == "suspended" ?
                            "" : <li className="nav-item" style={{marginLeft: "auto"}}>
                                { data.one_tickets && data.one_tickets.hasOwnProperty("id") ?
                                    '' : modulePermission.add === 1 ?  <button onClick={() => {
                                            let url = "/" + "Ticket" + "/Add?hiddenInput[asset_item_id]="+params.id+"&parent_id="+params.id;
                                            routeChange(url);
                                        }}
                                                 className="btn btn-light-primary font-weight-bold mr-2 waves-effect waves-light">
                                            <i className="bi bi-plus fs-3"></i> {trans('Report problem')}</button> :""
                                }
                            </li>
                        }
                    </ul>
                    <div className="tab-content mt-5" id="myTabContent">
                        <div className="tab-pane fade active show" id="kt_allocate_tab" role="tabpanel">
                            <div className="row g-5 g-xl-8">
                                {data &&
                                    <div className="col-xl-3 mb-4">
                                        <div className="col bg-white px-6 py-8 rounded-xl mr-7">
                                        <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" fill="none">
                                                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                    <path
                                                        d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z"
                                                        fill="#000000" ></path>
                                                    <path
                                                        d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z"
                                                        fill="#000000" opacity="0.3"></path>
                                                </g>
                                            </svg>
                                            </span>
                                            <h2 className="text-primary font-weight-bold font-size-h6 mt-2"> {trans('Classification')}</h2>
                                            <div className="font-size-h4 font-weight-bolder text-uppercase">{data.classification}</div>
                                        </div>
                                    </div>
                                }
    
                                <div className="col-xl-3 mb-4">
                                    <div className="col bg-white px-6 py-8 rounded-xl mr-7">
                                        <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" fill="none">
                                                    <rect x="0" y="0" width="24" height="24"/>
                                                    <path d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z" fill="#000000" opacity="0.3" transform="translate(11.500000, 12.000000) rotate(-345.000000) translate(-11.500000, -12.000000) "/>
                                                    <path d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z M11.5,14 C12.6045695,14 13.5,13.1045695 13.5,12 C13.5,10.8954305 12.6045695,10 11.5,10 C10.3954305,10 9.5,10.8954305 9.5,12 C9.5,13.1045695 10.3954305,14 11.5,14 Z" fill="#000000"/>
                                                </g>
                                            </svg>
                                            </span>
                                            <h2 className="text-success font-weight-bold font-size-h6 mt-2">{trans('Price')}</h2>
                                            <div className="font-size-h4 font-weight-bolder text-uppercase">{trans(data.price)}</div>
                                        </div>
                                </div>
    
                                <div className="col-xl-3 mb-4">
                                        <div className="col bg-white px-6 py-8 rounded-xl mr-7">
                                        <span className="svg-icon svg-icon-3x svg-icon-info d-block my-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" fill="none">
                                                    <rect x="0" y="0" width="24" height="24"/>
                                                    <rect fill="#000000" opacity="0.3" x="5" y="8" width="2" height="8" rx="1"/>
                                                    <path d="M6,21 C7.1045695,21 8,20.1045695 8,19 C8,17.8954305 7.1045695,17 6,17 C4.8954305,17 4,17.8954305 4,19 C4,20.1045695 4.8954305,21 6,21 Z M6,23 C3.790861,23 2,21.209139 2,19 C2,16.790861 3.790861,15 6,15 C8.209139,15 10,16.790861 10,19 C10,21.209139 8.209139,23 6,23 Z" fill="#000000" fillRule="nonzero"/>
                                                    <rect fill="#000000" opacity="0.3" x="17" y="8" width="2" height="8" rx="1"/>
                                                    <path d="M18,21 C19.1045695,21 20,20.1045695 20,19 C20,17.8954305 19.1045695,17 18,17 C16.8954305,17 16,17.8954305 16,19 C16,20.1045695 16.8954305,21 18,21 Z M18,23 C15.790861,23 14,21.209139 14,19 C14,16.790861 15.790861,15 18,15 C20.209139,15 22,16.790861 22,19 C22,21.209139 20.209139,23 18,23 Z" fill="#000000" fillRule="nonzero"/>
                                                    <path d="M6,7 C7.1045695,7 8,6.1045695 8,5 C8,3.8954305 7.1045695,3 6,3 C4.8954305,3 4,3.8954305 4,5 C4,6.1045695 4.8954305,7 6,7 Z M6,9 C3.790861,9 2,7.209139 2,5 C2,2.790861 3.790861,1 6,1 C8.209139,1 10,2.790861 10,5 C10,7.209139 8.209139,9 6,9 Z" fill="#000000" fillRule="nonzero"/>
                                                    <path d="M18,7 C19.1045695,7 20,6.1045695 20,5 C20,3.8954305 19.1045695,3 18,3 C16.8954305,3 16,3.8954305 16,5 C16,6.1045695 16.8954305,7 18,7 Z M18,9 C15.790861,9 14,7.209139 14,5 C14,2.790861 15.790861,1 18,1 C20.209139,1 22,2.790861 22,5 C22,7.209139 20.209139,9 18,9 Z" fill="#000000" fillRule="nonzero"/>
                                                </g>
                                            </svg>
                                            </span>
                                            <h2 className="text-info font-weight-bold font-size-h6 mt-2">{trans('Model')}</h2>
                                            <div className="font-size-h4 font-weight-bolder text-uppercase">{trans(data.model)}</div>
                                        </div>
                                    </div>
    
                                <div className="col-xl-3 mb-4">
                                        <div className="col bg-white px-6 py-8 rounded-xl mr-7">
                                        <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" fill="none">
                                                    <rect x="0" y="0" width="24" height="24"/>
                                                    <rect fill="#000000" opacity="0.3" x="4" y="4" width="4" height="4" rx="2"/>
                                                    <rect fill="#000000" x="4" y="10" width="4" height="4" rx="2"/>
                                                    <rect fill="#000000" x="10" y="4" width="4" height="4" rx="2"/>
                                                    <rect fill="#000000" x="10" y="10" width="4" height="4" rx="2"/>
                                                    <rect fill="#000000" x="16" y="4" width="4" height="4" rx="2"/>
                                                    <rect fill="#000000" x="16" y="10" width="4" height="4" rx="2"/>
                                                    <rect fill="#000000" x="4" y="16" width="4" height="4" rx="2"/>
                                                    <rect fill="#000000" x="10" y="16" width="4" height="4" rx="2"/>
                                                    <rect fill="#000000" x="16" y="16" width="4" height="4" rx="2"/>
                                                </g>
                                            </svg>
                                            </span>
                                            <h2 className="text-warning font-weight-bold font-size-h6 mt-2">{trans('Serial Number')}</h2>
                                            <div className="font-size-h4 font-weight-bolder text-uppercase">{data.serial_number}</div>
                                        </div>
                                    </div>
    
                                {data.facility && data.facility.hasOwnProperty("name") &&
                                    <div className="col-xl-3 mb-4">
                                        <div className="col bg-white px-6 py-8 rounded-xl mr-7">
                                            <span className="svg-icon svg-icon-3x svg-icon-info d-block my-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" fill="none">
                                                        <rect x="0" y="0" width="24" height="24"/>
                                                        <path d="M14,13.381038 L14,3.47213595 L7.99460483,15.4829263 L14,13.381038 Z M4.88230018,17.2353996 L13.2844582,0.431083506 C13.4820496,0.0359007077 13.9625881,-0.12427877 14.3577709,0.0733126292 C14.5125928,0.15072359 14.6381308,0.276261584 14.7155418,0.431083506 L23.1176998,17.2353996 C23.3152912,17.6305824 23.1551117,18.1111209 22.7599289,18.3087123 C22.5664522,18.4054506 22.3420471,18.4197165 22.1378777,18.3482572 L14,15.5 L5.86212227,18.3482572 C5.44509941,18.4942152 4.98871325,18.2744737 4.84275525,17.8574509 C4.77129597,17.6532815 4.78556182,17.4288764 4.88230018,17.2353996 Z" fill="#000000" fillRule="nonzero" transform="translate(14.000087, 9.191034) rotate(-315.000000) translate(-14.000087, -9.191034) "/>
                                                    </g>
                                                </svg>
                                            </span>
                                            <h2 className="text-info font-weight-bold font-size-h6 mt-2"> {trans('Facility')}</h2>
                                            <div className="font-size-h4 font-weight-bolder">{data.facility.name}</div>
                                        </div>
                                    </div>
                                }
    
                                {data.building && data.building.hasOwnProperty("title") &&
                                    <div className="col-xl-3 mb-4">
                                        <div className="col bg-white px-6 py-8 rounded-xl mr-7">
                                            <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" fill="none">
                                                        <rect x="0" y="0" width="24" height="24"/>
                                                        <path d="M6,9 L6,15 C6,16.6568542 7.34314575,18 9,18 L15,18 L15,18.8181818 C15,20.2324881 14.2324881,21 12.8181818,21 L5.18181818,21 C3.76751186,21 3,20.2324881 3,18.8181818 L3,11.1818182 C3,9.76751186 3.76751186,9 5.18181818,9 L6,9 Z M17,16 L17,10 C17,8.34314575 15.6568542,7 14,7 L8,7 L8,6.18181818 C8,4.76751186 8.76751186,4 10.1818182,4 L17.8181818,4 C19.2324881,4 20,4.76751186 20,6.18181818 L20,13.8181818 C20,15.2324881 19.2324881,16 17.8181818,16 L17,16 Z" fill="#000000" fillRule="nonzero"/>
                                                        <path d="M9.27272727,9 L13.7272727,9 C14.5522847,9 15,9.44771525 15,10.2727273 L15,14.7272727 C15,15.5522847 14.5522847,16 13.7272727,16 L9.27272727,16 C8.44771525,16 8,15.5522847 8,14.7272727 L8,10.2727273 C8,9.44771525 8.44771525,9 9.27272727,9 Z" fill="#000000" opacity="0.3"/>
                                                    </g>
                                                </svg>
                                            </span>
                                            <h2 className="text-warning font-weight-bold font-size-h6 mt-2"> {trans('Department')}</h2>
                                            <div className="font-size-h4 font-weight-bolder">{data.building.title}</div>
                                        </div>
                                    </div>
                                }
    
                                {data.floor && data.floor.hasOwnProperty("name") &&
                                    <div className="col-xl-3 mb-4">
                                        <div className="col bg-white px-6 py-8 rounded-xl mr-7">
                                        <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                                           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" fill="none">
                                                    <rect x="0" y="0" width="24" height="24"/>
                                                    <path d="M13.5,21 L13.5,18 C13.5,17.4477153 13.0522847,17 12.5,17 L11.5,17 C10.9477153,17 10.5,17.4477153 10.5,18 L10.5,21 L5,21 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,21 L13.5,21 Z M9,4 C8.44771525,4 8,4.44771525 8,5 L8,6 C8,6.55228475 8.44771525,7 9,7 L10,7 C10.5522847,7 11,6.55228475 11,6 L11,5 C11,4.44771525 10.5522847,4 10,4 L9,4 Z M14,4 C13.4477153,4 13,4.44771525 13,5 L13,6 C13,6.55228475 13.4477153,7 14,7 L15,7 C15.5522847,7 16,6.55228475 16,6 L16,5 C16,4.44771525 15.5522847,4 15,4 L14,4 Z M9,8 C8.44771525,8 8,8.44771525 8,9 L8,10 C8,10.5522847 8.44771525,11 9,11 L10,11 C10.5522847,11 11,10.5522847 11,10 L11,9 C11,8.44771525 10.5522847,8 10,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 L8,14 C8,14.5522847 8.44771525,15 9,15 L10,15 C10.5522847,15 11,14.5522847 11,14 L11,13 C11,12.4477153 10.5522847,12 10,12 L9,12 Z M14,12 C13.4477153,12 13,12.4477153 13,13 L13,14 C13,14.5522847 13.4477153,15 14,15 L15,15 C15.5522847,15 16,14.5522847 16,14 L16,13 C16,12.4477153 15.5522847,12 15,12 L14,12 Z" fill="#000000"/>
                                                    <rect fill="#FFFFFF" x="13" y="8" width="3" height="3" rx="1"/>
                                                    <path d="M4,21 L20,21 C20.5522847,21 21,21.4477153 21,22 L21,22.4 C21,22.7313708 20.7313708,23 20.4,23 L3.6,23 C3.26862915,23 3,22.7313708 3,22.4 L3,22 C3,21.4477153 3.44771525,21 4,21 Z" fill="#000000" opacity="0.3"/>
                                                </g>
                                            </svg>
                                            </span>
                                            <h2 className="text-danger font-weight-bold font-size-h6 mt-2">  {trans('Building')}</h2>
                                            <div className="font-size-h4 font-weight-bolder">{data.floor.name}</div>
                                        </div>
                                    </div>
                                }
    
                                {data.room &&
                                    <div className="col-xl-3 mb-4">
                                        <div className="col bg-white px-6 py-8 rounded-xl mr-7">
                                            <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" fill="none">
                                                        <rect x="0" y="0" width="24" height="24"/>
                                                        <path d="M3.5,3 L9.5,3 C10.3284271,3 11,3.67157288 11,4.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L3.5,20 C2.67157288,20 2,19.3284271 2,18.5 L2,4.5 C2,3.67157288 2.67157288,3 3.5,3 Z M9,9 C8.44771525,9 8,9.44771525 8,10 L8,12 C8,12.5522847 8.44771525,13 9,13 C9.55228475,13 10,12.5522847 10,12 L10,10 C10,9.44771525 9.55228475,9 9,9 Z" fill="#000000" opacity="0.3"/>
                                                        <path d="M14.5,3 L20.5,3 C21.3284271,3 22,3.67157288 22,4.5 L22,18.5 C22,19.3284271 21.3284271,20 20.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,4.5 C13,3.67157288 13.6715729,3 14.5,3 Z M20,9 C19.4477153,9 19,9.44771525 19,10 L19,12 C19,12.5522847 19.4477153,13 20,13 C20.5522847,13 21,12.5522847 21,12 L21,10 C21,9.44771525 20.5522847,9 20,9 Z" fill="#000000" transform="translate(17.500000, 11.500000) scale(-1, 1) translate(-17.500000, -11.500000) "/>
                                                    </g>
                                                </svg>
                                            </span>
                                            <h2 className="text-success font-weight-bold font-size-h6 mt-2">  {trans('Room Space')}</h2>
                                            <div className="font-size-h4 font-weight-bolder">{data.room.room_space_name}</div>
                                        </div>
                                    </div>
                                }
                                
                            </div>
                        </div>
    
                        <div className="tab-pane fade" id="kt_service" role="tabpanel">
                            <div className="card card-custom">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h4>{trans('Procurement / Installation')}</h4>
                                    </div>
                                </div>
                                <div className="card-body pt-0">
                                    <div className="fs-6">
                                        {data.manufacturer &&
                                            <>
    
                                                <div className="fw-bolder mt-3">{trans('Manufacturer')}</div>
                                                <div
                                                    className="text-gray-600">{trans(data.manufacturer.name)}</div>
                                                
                                            </>
                                        }
                                        {data.vendor &&
                                        <>
    
                                            <div className="fw-bolder mt-3">{trans('Vendor')}</div>
                                            <div
                                                className="text-gray-600">{trans(data.vendor.name)}</div>
                                            
                                        </>
                                        }
                                        {data.installation_date &&
                                            <>
                                                <div className="fw-bolder mt-3">{trans('Installation Date')}</div>
                                                <div
                                                    className="text-gray-600">{trans(data.installation_date)}</div>
                                                
                                            </>
                                        }
                                        {data.related_pm_frequency &&
                                        <>
    
                                            <div className="fw-bolder mt-3">{trans('PM Frequency')}</div>
                                            <div
                                                className="text-gray-600">{trans(data.related_pm_frequency.title)}</div>
                                            
                                        </>
                                        }
                                        {data.sub_contract_relation &&
                                            <>
    
                                                <div className="fw-bolder mt-3">{trans('Contract')}</div>
                                                <div
                                                    className="text-gray-600">{trans(data.sub_contract_relation.contract_ref)}</div>
                                                
                                            </>
                                        }
    
                                        {data.warranty_period &&
                                        <>
    
                                            <div className="fw-bolder mt-3">{trans('Warranty Period')}</div>
                                            <div
                                                className="text-gray-600">{trans(data.warranty_period)}</div>
                                        </>
                                        }
    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="kt_stats" role="tabpanel">
                            <div className="row g-5 g-xl-8">
                                {/*<div className="col-xl-4">*/}
                                {/*    <div className="">*/}
                                {/*        <div className="card card-custom pt-1 pb-0">*/}
                                {/*            <div className="d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2">*/}
                                {/*                <>*/}
                                {/*                    <span className="fw-bolder text-dark fs-4 mb-2">*/}
                                {/*                        {trans('Fail Count')}*/}
                                {/*                    </span>*/}
                                {/*                    <span className="fw-bold text-muted fs-5"></span>*/}
                                {/*                </>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {data.ppm_wo_signed_off && data.ppm_wo_signed_off.length > 0 ?
                                <div className="col-xl-4">
                                    <div className="">
                                        <div className="card card-custom pt-1 pb-0">
                                            <div className="d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2">
                                                <>
                                                    <span className="fw-bolder text-dark fs-4 mb-2">
                                                        {trans('Number of PPM Done')}
                                                    </span>
                                                    <span className="fw-bold text-muted fs-5">
                                                        {data.ppm_wo_signed_off.length}
                                                    </span>
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                </div> : ""
                                }
                                {data &&
                                <div class="col-xl-3 mb-4">
                                    <div class="col bg-white px-6 py-8 rounded-xl mr-7">
                                        <div className="d-flex align-items-center justify-content-center ">
                                            <div className="d-flex flex-column font-weight-bold justify-content-center text-center">
                                                <h2 className="text-dark mb-1 font-size-lg">{trans('Working Time')}</h2>
                                                <span className="text-muted">{data.working_time}</span>
                                            </div>
                                        </div>
                                    </div>
                                 </div>
                                
                                }
                                {data &&
                                <div class="col-xl-3 mb-4">
                                <div class="col bg-white px-6 py-8 rounded-xl mr-7">
                                    <div className="d-flex align-items-center justify-content-center ">
                                        <div className="d-flex flex-column font-weight-bold justify-content-center text-center">
                                            <h2 className="text-dark mb-1 font-size-lg"> {trans('In Warranty')}</h2>
                                            {data.in_warranty == 1  ?
                                                        <span className="fw-bold text-muted fs-5">
                                                                 <>Yes</>
                                                        </span> :
                                                            <span className="fw-bold text-muted fs-5">
                                                                 <>No</>
                                                        </span>
                                                        }
                                        </div>
                                    </div>
                                </div>
                             </div>
                                }
                                {data.last_ppm && data.last_ppm.length > 0 ?
                                     <div class="col-xl-3 mb-4">
                                     <div class="col bg-white px-6 py-8 rounded-xl mr-7">
                                         <div className="d-flex align-items-center justify-content-center ">
                                             <div className="d-flex flex-column font-weight-bold justify-content-center text-center">
                                                 <h2 className="text-dark mb-1 font-size-lg">  {trans('Last PPM Date')}</h2>
                                                 <span className="text-muted"> {data.last_ppm.pm_date}</span>

                                             </div>
                                         </div>
                                     </div>
                                  </div> : ""
                                }
                                {data.next_ppm && data.next_ppm.length > 0 ?
                                    <div className="col-xl-4">
                                        <div className="">
                                            <div className="card card-custom pt-1 pb-0">
                                                <div className="d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2">
                                                    <>
                                                    <span className="fw-bolder text-dark fs-4 mb-2">
                                                        {trans('Next PPM Date')}
                                                    </span>
                                                        <span className="fw-bold text-muted fs-5">
                                                            {data.next_ppm.pm_date}
                                                        </span>
                                                    </>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ""
                                }
                            </div>
                        </div>
                        <div className="tab-pane fade" id="kt_docs_tab" role="tabpanel">
                            <div className="card mb-5">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h4>{trans('Documentations')}</h4>
                                    </div>
                                </div>
                                <div className="card-body pt-0">
                                    <table className="table mt-10">
                                        <thead>
                                        <tr>
                                            <th scope="col">{trans('Document Group')}</th>
                                            <th scope="col">{trans('Document Type')}</th>
                                            <th scope="col">{trans('Document')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data.documentations && data.documentations.map(documentation => (
                                            <tr>
                                                <td>{trans(documentation.parent_category)}</td>
                                                <td>{trans(documentation.child_category)}</td>
                                                <td>
                                                    <a href={documentation.file} target="_blank">{trans('Show Attachment')}</a>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="print-container p-1" ref={componentRef}>
                        <div className="row bg-white print-eqp">
                            <div className='col-md-12'>
                            <div class="fw-bolder mt-3">Equipment Details</div>
                            </div>
                            <div className="col-md-6">
                            
                                <div className="fs-6">
                                    { data &&
                                    <>
                                        <div className="fw-bolder mt-3">{trans('Name')}</div>
                                        <div className="text-gray-600">{trans(data.common_name)}</div>
                                        
                                    </>
                                    }
                                    {data && data.status_data &&
                                    <>
                                        <div className="fw-bolder mt-3">{trans('Status Data')}</div>
                                        <div className="text-gray-600">{trans(data.status_data.title)}</div>
                                        
                                    </>
                                    }
    
                                    {data && data.ecri && data.ecri.hasOwnProperty("ecri_name") &&
                                    <>
                                        <div className="fw-bolder mt-3">{trans('ECRI Name')}</div>
                                        <div
                                            className="text-gray-600">{trans(data.ecri.ecri_name)}</div>
                                        
                                        <div className="fw-bolder mt-3">{trans('ECRI Code')}</div>
                                        <div
                                            className="text-gray-600">{trans(data.ecri.ecri_code)}</div>
                                        
                                    </>
                                    }
                                    {data.risk_class &&
                                    <>
                                        <div className="fw-bolder mt-3">{trans('Risk Class')}</div>
                                        <div
                                            className="text-gray-600">{trans(data.risk_class.title)}</div>
                                    </>
                                    }
    
    
                                    {data.related_pm_frequency && data.related_pm_frequency.hasOwnProperty("title") &&
                                    <>
                                        
                                        <div className="fw-bolder mt-3">{trans('PM Frequency')}</div>
                                        <div
                                            className="text-gray-600">{trans(data.related_pm_frequency.title)}</div>
                                    </>
                                    }
    
    
    
    
                                            {data.manufacturer &&
                                            <>
    
                                                <div className="fw-bolder mt-3">{trans('Manufacturer')}</div>
                                                <div
                                                    className="text-gray-600">{trans(data.manufacturer.name)}</div>
                                            </>
                                            }
    
                                            {data.model &&
                                            <>
                                                
                                                <div className="fw-bolder mt-3">{trans('Model')}</div>
                                                <div className="text-gray-600">{trans(data.model)}</div>
                                            </>
                                            }
                                            {data &&
                                            <>
                                                
                                                <div className="fw-bolder mt-3">{trans('Serial Number')}</div>
                                                <div
                                                    className="text-gray-600">{data.serial_number}</div>
                                            </>
                                            }
                                        </div>
    
                            </div>
                            <div className="col-md-6">
    
                                            {data.facility && data.facility.hasOwnProperty("name") &&
                                            <>
    
                                                <div className="fw-bolder mt-3"> {trans('Facility')}</div>
                                                <div
                                                    className="text-gray-600">{data.facility.name}</div>
                                                                                            

                                            </>
                                            }
                                            {data.building && data.building.hasOwnProperty("title") &&
                                            <>
    
                                                <div className="fw-bolder mt-3">  {trans('Building')}</div>
                                                <div
                                                    className="text-gray-600">{data.building.title}</div>
                                                                                            

                                            </>
                                            }
                                            {data.room && data.room.department && data.room.department.hasOwnProperty("title") &&
                                            <>
    
                                                <div className="fw-bolder mt-3"> {trans('Department')}</div>
                                                <div
                                                    className="text-gray-600">{data.room.department.title}</div>
                                                                                            

                                            </>
                                            }
                                             {data.room &&
                                            <>
    
                                                <div className="fw-bolder mt-3">   {trans('Room Space')}</div>
                                                <div
                                                    className="text-gray-600">{data.room.room_space_name}</div>
                                                                                            

                                            </>
                                            }
                                            <div className="fs-6">
                                            {data.vendor &&
                                            <>
    
                                                <div className="fw-bolder mt-3">{trans('Vendor')}</div>
                                                <div
                                                    className="text-gray-600">{trans(data.vendor.name)}</div>
                                                                                            

                                            </>
                                            }
                                            {data.installation_date &&
                                            <>
                                                <div className="fw-bolder mt-3">{trans('Installation Date')}</div>
                                                <div className="text-gray-600">{trans(data.installation_date)}</div>
                                                

                                            </>
                                            }
    
                                            {data && data.contract &&
                                                <>
    
                                                    <div className="fw-bolder mt-3">{trans('Contract')}</div>
                                                    <div
                                                        className="text-gray-600">{trans(data.contract_ref)}</div>
                                                </>
                                            }
    
    
                                        </div>
                            </div>
                        </div>
                    </div>
                    <ReactToPrint
                    trigger={() => <button className="btn btn-success mr-4"> Print Equipment</button>}
                    content={() => componentRef.current}
                    />
    
                    <ReactToPrint
                    trigger={() => <button className="btn btn-primary"> Print QR Code </button>}
                    content={() => componentQR.current}
                    />
                </div>
            </div>
        </div>
            )}
        </div>
       

        </>
    )
}
export default Index;
