import React from 'react';
import Header from "../Layout/Header";
import LeftSideBar from "../Layout/LeftSideBar";


const userEdit = () => {
        return (
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed toolbar-tablet-and-mobile-fixed aside-enabled aside-fixed">
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LeftSideBar></LeftSideBar>
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <Header />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="toolbar" id="kt_toolbar">

                                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">

                                        <div data-kt-place="true" data-kt-place-mode="prepend"
                                             data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                                             className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">

                                            <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Tables</h1>

                                            <span className="h-20px border-gray-200 border-start mx-4"></span>

                                            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">

                                                <li className="breadcrumb-item text-muted">
                                                    <a href="index.html"
                                                       className="text-muted text-hover-primary">Home</a>
                                                </li>

                                                <li className="breadcrumb-item">
                                                    <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                                </li>

                                                <li className="breadcrumb-item text-dark">Tables</li>

                                            </ul>

                                        </div>

                                        <div className="d-flex align-items-center py-1">

                                            <div className="me-4">

                                                <a href="#"
                                                   className="btn btn-sm btn-flex btn-light btn-active-primary fw-bolder"
                                                   data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                                                   data-kt-menu-flip="top-end">

                                                    <span className="svg-icon svg-icon-5 svg-icon-gray-500 me-1">
											<svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px"
                                                 viewBox="0 0 24 24" version="1.1">
												<g stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
													<rect x="0" y="0" width="24" height="24"/>
													<path
                                                        d="M5,4 L19,4 C19.2761424,4 19.5,4.22385763 19.5,4.5 C19.5,4.60818511 19.4649111,4.71345191 19.4,4.8 L14,12 L14,20.190983 C14,20.4671254 13.7761424,20.690983 13.5,20.690983 C13.4223775,20.690983 13.3458209,20.6729105 13.2763932,20.6381966 L10,19 L10,12 L4.6,4.8 C4.43431458,4.5790861 4.4790861,4.26568542 4.7,4.1 C4.78654809,4.03508894 4.89181489,4 5,4 Z"
                                                        fill="#000000"/>
												</g>
											</svg>
										</span>
                                                    Filter</a>


                                            </div>

                                        </div>

                                    </div>

                                </div>
                                <div className="post d-flex flex-column-fluid m-100" id="kt_post">
                                    <div id="kt_content_container" className="container">
                                        <div className="card mb-5 mb-xl-8">
                                            <form>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Email address</label>
                                                    <input type="email" className="form-control" id="exampleInputEmail1"
                                                           aria-describedby="emailHelp" placeholder="Enter email" />
                                                    <small id="emailHelp" className="form-text text-muted">We'll
                                                        never share your email with anyone else.</small>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Password</label>
                                                    <input type="password" className="form-control"
                                                           id="exampleInputPassword1" placeholder="Password" />
                                                </div>
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input"
                                                           id="exampleCheck1" />
                                                    <label className="form-check-label" htmlFor="exampleCheck1">Check
                                                        me out</label>
                                                </div>
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

}

export default userEdit
