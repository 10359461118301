import React, {Component,useState,useEffect} from 'react';
import axios from 'axios';
import {Redirect, Link, useHistory} from 'react-router-dom';
import  logo from '../../assets/img/logo.png';
import {SystemUrl } from '../../App';
import GetPagesData from "../Routes";
import { trans } from "../utils";
import Swal from "sweetalert2";
import background from "../../assets/img/bg.png";
import logoWhite from "../../assets/img/iAssets-white.png";
import backgroundLeft from "../../assets/img/background-left.png";

const RegisterAsStaff = (assetItemId) => {
    const [userRequest, setUserRequest] = useState({
        email: '',
        password: '',
        phone: '',
        name: '',
        isLoading: false,
        errorEmail : '',
        errorName : '',
        errorPassword: '',
        location: {},
        invalidEmail : false
    });
    let ClientLogo=localStorage.getItem('ClientLogo');
    let SystemTitle=localStorage.getItem('SystemTitle')?localStorage.getItem('SystemTitle') : "MOHSA";

    useEffect(() => {
        document.title = SystemTitle;
    },[userRequest]);
    let history = useHistory();
    const renderRedirect = () => {
        if (userRequest.redirect==true) {
            history.push('/')
        }
    };

    const isLoading = userRequest.isLoading;

    const handleEmailChange = event => {
        setUserRequest({...userRequest,email: event.target.value} );
    };

    const handleNameChange = event => {
        setUserRequest({...userRequest,name: event.target.value} );
    };

    const handlePhoneChange = event => {
        setUserRequest({...userRequest,phone: event.target.value} );
    };

    const handlePwdChange = event => {
        setUserRequest({...userRequest,password: event.target.value} );
    };

    const handleSubmit = event => {
        event.preventDefault();
        const Sanctumurl = SystemUrl+'sanctum/csrf-cookie';
        axios.get(Sanctumurl).then(response => {
            setUserRequest({...userRequest,isLoading: true} );
            const url = SystemUrl+'register';
            const email = userRequest.email;
            const password = userRequest.password;
            const name = userRequest.name;
            const phone = userRequest.phone;
            const asset_item_id = assetItemId.location.state.assetItemId;
            let bodyFormData = new FormData();
            bodyFormData.set('email', email);
            bodyFormData.set('password', password);
            bodyFormData.set('name', name);
            bodyFormData.set('phone', phone);
            bodyFormData.set('asset_item_id', asset_item_id);
            axios.post(url, bodyFormData)
                .then(result => {
                    Swal.fire({
                        title: "Thank You !",
                        icon:"success",
                        text: `Waiting for admin approval`,
                        showCancelButton: false,
                        confirmButtonColor: '#3f91ec',
                        confirmButtonText: `Return`
                    })
                    history.push("/");
                    var lang = localStorage.getItem('lang') || "en";
                    var styleUrl = localStorage.getItem('styleUrl') || "/assets/css/style.bundle.css"
                    localStorage.clear();
                    localStorage.setItem('lang',lang)
                    localStorage.setItem('styleUrl',styleUrl)
                    var pages=result.data.data.pages;
                    localStorage.setItem('isLoggedIn', true)
                    //Refresh Routes
                    GetPagesData(pages);
                    setUserRequest({...userRequest,redirect: true, isLoading: false} );
                })
                .catch(async (error) => {
                    if( error.response ){
                        setUserRequest({...userRequest,
                            errorEmail :error.response.data.errors.hasOwnProperty('email')? error.response.data.errors.email[0]:"",
                            errorName :error.response.data.errors.hasOwnProperty('name')? error.response.data.errors.name[0]:"",
                            errorPhone :error.response.data.errors.hasOwnProperty('phone')? error.response.data.errors.phone[0]:"",
                            errorPassword : error.response.data.errors.hasOwnProperty('password')?error.response.data.errors.password[0]:"",
                            isLoading: false});
                    }
                });
        });
    };


    return (

        <div className="d-flex flex-column flex-root">

            <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
                 id="kt_login">

                <div className="login-aside d-flex flex-column flex-row-auto" style={{backgroundColor: "#63a5ef"}}>
                    <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-5 align-items-center">

                        <img src={logoWhite} style={{width:'100px'}} className="mb-5" alt=""/>

                        <h3 className="font-weight-bolder text-white text-center font-size-h4 font-size-h1-lg mb-5 mt-3">Securely manage your assets
                            <br/>with IASSETS</h3>
                    </div>

                    <div
                        className="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
                        style={{ backgroundImage: `url(${backgroundLeft})`,
                            backgroundPosition: "center",
                            backgroundSize: "contain"}}>
                    </div>
                </div>

                <div
                    className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">

                    <div className="d-flex flex-column-fluid flex-center">

                        <div className="login-form login-signin">

                            <form
                                className="form"
                                noValidate="novalidate"
                                id="kt_login_signin_form" action="#"
                                onSubmit={handleSubmit}
                            >

                                <div className="pb-13 pt-lg-0 pt-5">
                                    <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Welcome to
                                        IAssets</h3>
                                    <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h2-lg">Register as staff</h3>
                                </div>

                                <div className="form-group">
                                    <label className="font-size-h6 font-weight-bolder text-dark">{trans('Name')}</label>
                                    <input
                                        className="form-control form-control-solid h-auto py-6 px-6 rounded-lg"
                                        id="inputEmail" placeholder={trans('Name')} type="text" name="name"
                                        onChange={handleNameChange} autoFocus required />
                                    { userRequest.errorName &&
                                        <div className="fv-plugins-message-container text-danger">
                                            <div data-field="name" data-validator="name">{ userRequest.errorName } </div>
                                        </div> }
                                </div>

                                <div className="form-group">
                                    <label className="font-size-h6 font-weight-bolder text-dark">{trans('Email')}</label>
                                    <input
                                        className="form-control form-control-solid h-auto py-6 px-6 rounded-lg"
                                        id="inputEmail" placeholder={trans('Email address')} type="text" name="email"
                                        onChange={handleEmailChange} autoFocus required />

                                    {userRequest.errorEmail &&
                                        <div className="fv-plugins-message-container text-danger">
                                            <div data-field="email" data-validator="emailAddress">{ userRequest.errorEmail } </div>
                                        </div> }
                                </div>

                                <div className="form-group">
                                    <label className="font-size-h6 font-weight-bolder text-dark">{trans('Password')}</label>
                                    <input
                                        className="form-control form-control-solid h-auto py-6 px-6 rounded-lg"
                                        id="inputPassword" placeholder={trans('Enter Your Password')} name="password"
                                        onChange={handlePwdChange} required/>
                                    { userRequest.errorPassword &&
                                        <div className="fv-plugins-message-container text-danger">
                                            <div data-field="email" data-validator="emailAddress">{ userRequest.errorPassword } </div>
                                        </div> }
                                </div>

                                <div className="form-group">
                                    <label className="font-size-h6 font-weight-bolder text-dark">{trans('Mobile Number')}</label>
                                    <input
                                        className="form-control form-control-solid h-auto py-6 px-6 rounded-lg"
                                        id="inputEmail" placeholder={trans('Mobile Number')} type="tel" name="phone"
                                        onChange={handlePhoneChange} autoFocus required />

                                    { userRequest.errorPhone &&
                                        <div className="fv-plugins-message-container text-danger">
                                            <div data-field="name" data-validator="name">{ userRequest.errorPhone } </div>
                                        </div> }
                                </div>

                                <div className="pb-lg-0 pb-5">
                                    <button
                                        className="btn-blue-new btn w-100"
                                        type="submit" disabled={userRequest.isLoading ? true : false}>
                                        <span className="indicator-label">{trans('Submit')}</span>
                                        {isLoading ? (
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        ) : (
                                            <span></span>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div
                        className="text-center">
                        <div className="text-dark-50 font-size-lg font-weight-bolder">
                            <span className="mr-1">2023©</span>
                            <a href="https://www.intrazero.com" target="_blank"
                               className="text-dark-75 text-hover-primary">intrazero</a>
                        </div>
                    </div>
                </div>
            </div>
            {renderRedirect()}
        </div>
    );


}
export default  RegisterAsStaff;



