import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useHistory,Link } from 'react-router-dom';
import Swal from "sweetalert2";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileContract, faPrint,faCamera, faArrowCircleRight, faCheckCircle, faEdit, faEye, faPauseCircle, faTrash, faLock, faHistory } from '@fortawesome/free-solid-svg-icons'
import { resetPermissions, trans } from './Components/utils';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import  "datatables.net";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import JSZip from 'jszip';
import  "datatables.net-bs4";
import  "datatables.net-buttons";
import  "datatables.net-buttons/js/buttons.colVis.js";
import  "datatables.net-buttons/js/buttons.html5.js";
import  "datatables.net-buttons/js/buttons.print.js";
import  "datatables.net-responsive";
import 'datatables.net-buttons/js/buttons.html5.js';



var DataTableComp = ({ SystemUrl, CurrentModule, Columns, Params, FilterParams,RoleId }) => {
    
    const transfiles = { en: "DataTables/English.json", ar: 'DataTables/Arabic.json' }
      
    let ajaxUrl = SystemUrl + CurrentModule
    useEffect(() => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        window.JSZip = JSZip;

    },[])
    const $ = require("jquery");    // const links = { "AssetItem": "AssignAssetItem", 'AssignAssetItem': "RoomSpaceItem" };
    const links = {"AssetItem" : "AssignAssetItem" , 'AssignAssetItem' : "RoomSpaceItem" , "RoomSpaceItem" : "PlanPreventiveMaintenance"};

    const CurrentModuleObj = JSON.parse(localStorage.getItem(CurrentModule));
    const PermissionModuleObj = JSON.parse(localStorage.getItem("Permission"));
    let WorkorderModuleObj = null
    if(CurrentModule=="Ticket"){
         WorkorderModuleObj = JSON.parse(localStorage.getItem("WorkOrder"));
    }
    let deleteRecord = (url, type = "Delete",reason ="") => {
        let text = reason?"textarea":""
        //Show Sweetalert for confirmation first before submit
        Swal.fire({
            title: 'Are you sure?',
            text:"You won't be able to revert this!" ,
            icon: 'warsetColumns(res.data)ning',
            showCancelButton: true,
            confirmButtonColor: '#3f91ec',
            cancelButtonColor: '#d33',
            confirmButtonText: `Yes, ${type} it!`,
            inputPlaceholder: text ? "Close reason":"",
            input: text,
        }).then((result) => {
            if (result.isConfirmed) {
                if(reason){
                    reason = "&"+reason+"="+result.value
                }
                //Submit to delete
                axios.delete(url+reason)
                    .then(jsonRes => {
                        if (CurrentModule == "Page" || CurrentModule == "Role") {
                            resetPermissions(CurrentModule)
                        } else {
                            $("#datatable").DataTable().ajax.reload()
                        }
                    }).catch((res) => {
                        Swal.fire(
                            `You cannot ${type} this item because it has related:`,
                            `(${res.response.data.msg})`,
                            'error'
                        );
                    })

            }
        });
    }

    const history = useHistory();

    const routeChange = path => {
        history.push(path);
    }

    const [modal, setModal] = useState(null);
    
    const columnsDefs = [
        { 
            targets: 0,
            orderable: true // Enable sorting on the first column
        },
        { 
            targets: "_all",
            orderable: false // Disables sorting on all columns
        }, 

        {

            targets: -1,
            orderable: false,
            className: "all",
            width: "285px",
            createdCell: (td, cellData, full) => ReactDOM.render(

                // <NavLink  to={} className="btn btn-light-primary btn-light-sm">
                //     <i className="fas fa-edit"></i>
                // </NavLink>
                <>
                    <div style={{ width: 285 }}>
                        {
                            !!CurrentModuleObj.edit &&
                            !!CurrentModuleObj.page_edit &&
                            <Tippy content="Edit" placement="bottom" arrow={false} animation="scale" theme="custom">
                                    <button
                                        onClick={() => {
                                            let url = "/" + CurrentModule + "/Edit/" + full.id;
                                            routeChange(url);
                                        }}
                                        className="btn btn-sm btn-clean btn-icon mr-2 text-primary">
                                        <span className="svg-icon svg-icon-primary">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none"  fill="none" fillRule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"/>
                                                    <path
                                                        d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                                                        fill="#000000" fillRule="nonzero"
                                                        transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "/>
                                                    <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"/>
                                                </g>
                                            </svg>
                                        </span>
                                    </button>
                            </Tippy>

                        }

                        {
                            !!CurrentModuleObj.del &&
                            !!CurrentModuleObj.page_del &&
                            <Tippy content="Delete" placement="bottom" arrow={false} animation="scale" theme="custom">
                                <button
                                    className="btn btn-sm btn-clean btn-icon mr-2 text-danger"
                                    onClick={() => {
                                        let url = ajaxUrl + "/" + full.id;
                                        deleteRecord(url);
                                    }}>
                                    <div className="svg-icon svg-icon-danger">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" fill="none">
                                                <rect x="0" y="0" width="24" height="24"/>
                                                <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fillRule="nonzero"/>
                                                <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                                            </g>
                                        </svg>
                                    </div>
                                </button>
                            </Tippy>
                        }
                            {
                                !!CurrentModuleObj.suspend &&
                                !!CurrentModuleObj.page_suspend &&
                                (CurrentModule != "WorkOrder" && CurrentModule != "Ticket") &&
                                (full.status == "approved"  ?
                                <Tippy content="Suspend" placement="bottom" arrow={false} animation="scale" theme="custom">
                                        <button
                                        
                                            className="btn btn-sm btn-clean btn-icon mr-2 text-warning"
                                            onClick={() => {
                                                let url = ajaxUrl + "/" + full.id + '?status=suspend';
                                                deleteRecord(url, 'Suspend');
                                            }}>
                                            <FontAwesomeIcon icon={faPauseCircle}/>
                                        </button>
                                        </Tippy>
                                         : 
                                         <Tippy content="Approve" placement="bottom" arrow={false} animation="scale" theme="custom">
                                            <button
                                                
                                                className="btn btn-sm btn-clean btn-icon mr-2 text-success"
                                                onClick={() => {
                                                    let url = ajaxUrl + "/" + full.id + '?status=approve';
                                                    deleteRecord(url, 'Approve');
                                                }}>
                                                <FontAwesomeIcon icon={faCheckCircle}/>
                                            </button>
                                        </Tippy>)

                        }
                        {

                            !!CurrentModuleObj.has_view ?
                                (CurrentModule != 'WorkOrder' ? 
                                <Tippy content="View" placement="bottom" arrow={false} animation="scale" theme="custom">
                                    <button
                                    
                                        className="btn btn-sm btn-clean btn-icon mr-2 text-info"
                                        onClick={() => {
                                            let url = "/" + CurrentModule + "/View/" + full.id;
                                            routeChange(url);
                                        }}>
                                        <FontAwesomeIcon icon={faEye} />
                                    </button> 
                                </Tippy>
                                : <Tippy content="View" placement="bottom" arrow={false} animation="scale" theme="custom">
                                    <a
                                        className="btn btn-sm btn-clean btn-icon mr-2 text-info"
                                        href={ "/" + CurrentModule + "/View/" + full.id}
                                        target={"_blank"}
                                    >
                                        <FontAwesomeIcon icon={faEye} />
                                    </a></Tippy>):""
                        }

                        {typeof links[CurrentModule] !== "undefined" && ((CurrentModule == "AssignAssetItem" && full.has_allocation == false) || CurrentModule != "AssignAssetItem" && ((CurrentModule == "RoomSpaceItem" && full.has_ppm == true) || CurrentModule != "RoomSpaceItem")) ?
                            localStorage.getItem(links[CurrentModule]) &&
                            <Tippy content={trans(JSON.parse(localStorage.getItem(links[CurrentModule])).name_en)} placement="bottom" arrow={false} animation="scale" theme="custom">
                            <button
                                data-container="body"
                                className="btn btn-sm btn-clean btn-icon mr-2 text-dark"
                                onClick={() => {
                                    let url = "/" + links[CurrentModule] + "/Add/" + full.id;
                                    routeChange(url);
                                }}>
                                <FontAwesomeIcon icon={faArrowCircleRight} />
                            </button></Tippy> : ""
                        }


                        {/*{ CurrentModule== "RoomSpaceItem" ?*/}
                        {/*    <button*/}
                        {/*        data-container="body" data-toggle="tooltip" data-placement="top" title={trans('Track Asset / Item')}*/}
                        {/*        className="btn btn-light-info btn-light-sm"*/}
                        {/*        onClick={() => {*/}
                        {/*            let url = "/AssetItem/View/" + full.assign_asset_item.asset_item.id+"?AllocateID="+full.id;*/}
                        {/*            routeChange(url);*/}

                        {/*        }}>*/}
                        {/*        <FontAwesomeIcon icon={faFileContract} />*/}
                        {/*    </button> : ""*/}
                        {/*    }*/}

                        {CurrentModule == "Role" &&
                        !!PermissionModuleObj.edit &&
                        !!PermissionModuleObj.page_edit ?
                        <Tippy content="Permissions" placement="bottom" arrow={false} animation="scale" theme="custom">
                            <button
                                data-container="body" onClick={() => {
                                    let url = "/Permissions/" + full.id;
                                    routeChange(url);
                                }} className="btn btn-sm btn-clean btn-icon mr-2 text-success">
                                <FontAwesomeIcon icon={faLock} />
                            </button>
                            </Tippy>
                            : ""
                        }
                        {(CurrentModule == "InventoryTaking" && full.can_start == true) ?
                        <Tippy content="Start Taking" placement="bottom" arrow={false} animation="scale" theme="custom">
                            <button
                                data-container="body" onClick={() => {
                                let url = "/InventoryTaking/Scan/" + full.id;
                                routeChange(url);
                            }} className="btn btn-sm btn-clean btn-icon mr-2 text-success">
                                <FontAwesomeIcon icon={faCamera} />
                            </button></Tippy>
                            : ""
                        }
                        {CurrentModule == "Building"?
                        <Tippy content="Print Equipments" placement="bottom" arrow={false} animation="scale" theme="custom">
                            <button
                                data-container="body" onClick={() => {
                                let url = "/QrCodes/" + full.id;
                                routeChange(url);
                            }} className="btn btn-sm btn-clean btn-icon mr-2 text-secondary">
                                <FontAwesomeIcon icon={faPrint} />
                            </button>
                            </Tippy>
                            : ""
                        }

                        {CurrentModule == "Ticket" && full.status == "open" && full.actions && full.actions.can_convert_ticket_to_wo == true ?
                            <>
                                {WorkorderModuleObj && !!WorkorderModuleObj.add &&
                                    !!WorkorderModuleObj.page_add &&
                                    <Tippy content="Convert to WO" placement="bottom" arrow={false} animation="scale" theme="custom">
                                    <button
                                        data-container="body"
                                        className="btn btn-sm btn-clean btn-icon mr-2 text-primary"
                                        onClick={() => {
                                            let url = "/WorkOrder/Add/" + full.id;
                                            routeChange(url);
                                        }}>
                                        <FontAwesomeIcon icon={faArrowCircleRight}/>
                                    </button>
                                    </Tippy>
                                }

                                {!!CurrentModuleObj.del &&
                                !!CurrentModuleObj.page_del &&
                                <Tippy content="Close Ticket" placement="bottom" arrow={false} animation="scale" theme="custom">
                                <button
                                    data-container="body"
                                    className="btn btn-sm btn-clean btn-icon mr-2 text-info"
                                    onClick={() => {
                                        let url = ajaxUrl + "/" + full.id + '?status=closed';
                                          deleteRecord(url, 'Close',"close_reason");

                                    }}>
                                    <FontAwesomeIcon icon={faLock} />
                                </button></Tippy>
                                }
                            </>
                            : ""}
                       <Tippy content="Activity Log" placement="bottom" arrow={false} animation="scale" theme="custom">
                            <button
                                data-container="body"
                                className="btn btn-sm btn-clean btn-icon mr-2 text-info"
                                onClick={() => {
                                    let url = "/ActivityLog/"+CurrentModule+"/" + full.id;
                                    routeChange(url);
                                }}>
                                <FontAwesomeIcon icon={faHistory} />
                            </button>
                        </Tippy>
                        {RoleId > 0 &&
                        <Tippy content="User Activity Log" placement="bottom" arrow={false} animation="scale" theme="custom">
                        <button data-container="body"
                            className="btn btn-sm btn-clean btn-icon mr-2 text-warning"
                            onClick={() => {
                                let url = "/ActivityLogPerUser/"+ full.id;
                                routeChange(url);
                            }}>
                            <FontAwesomeIcon icon={faHistory} />
                        </button></Tippy>  }
                    </div>
                </>
                , td)

        },
        {
            orderable: false,
            className: 'select-checkbox',

            // createdCell: (td, cellData, full) => ReactDOM.render(
            //     <>
            //             <p>sa</p>
            //     </>, td)
        }
    ];
 
    
    useEffect(() => {
        // begin first table
        $.fn.dataTable.ext.errMode = 'none';
        $('#datatable').DataTable({
            responsive: true,
            searchDelay: 50,
            processing: true,
            searching: false,
            serverSide: true,
            ajax: {
                "url": ajaxUrl + Params,
                "data": function (data) {

                    FilterParams.forEach(el => {
                        // if (el != "role_id" && !!Params) {
                        if (el != "role_id") {
                            data[el] = function () {
                                return $(`select[name="data[${el}]"]`).val() || $(`input[name="data[${el}]"]`).val();
                            }
                        }
                        // }
                    })
                },
                xhrFields: {
                    withCredentials: true
                }
            },
            columns: Columns,
            "order": [[0, "desc"]],
            columnDefs: columnsDefs,
            'select': {
                'style': 'multi'
            },
            lengthMenu:[ [10, 25, 50, 100,1000, -1], [10, 25, 50, 100,1000, "All"] ],
            dom: '<"top"<"left-col"B><"center-col"l><"right-col"f>>rtip',
            buttons: [
                'csv',
                'excelHtml5',
                {
                    extend: 'print', exportOptions:
                        { columns: ':visible' }
                },
                {
                    extend: 'print', 
                    text:'PDF',
                    exportOptions:
                        { columns: ':visible' }
                },
                {
                    extend: 'colvis', postfixButtons: ['colvisRestore']
                },
                {
                    extend: 'copy', exportOptions:
                    { columns: [0, ':visible'] }
                },
            ],

            language: {
                url: transfiles[localStorage.getItem('lang') || 'en']
            }
        });

    }, []);
    return (
        <>
            <div className="table-responsive">
                <table
                    id="datatable"
                    className="table table-striped table-hover  dt-responsive responsive"
                    width="100%"
                    cellSpacing="0"
                />
            </div>
        </>
    );
}
export default DataTableComp;
