import React, {useState} from 'react';
import CustomDataTable from "../CustomDataTable";
import {trans} from "../../utils";
import Multiselect from "multiselect-react-dropdown";
import $ from "jquery";


const StatusHistory = ({params,data,sendRequest,dataStatus}) => {
    const columns = [
        {data:"created_at",title:trans("Date")},
        {data:"causer.name",title:trans("Causer")},
        {data:function (full){
            return full.properties.attributes["status_data.title_en"]
            },title:trans("Status")},
        // {data:function (full){
        //     return full.properties.attributes["secondary_status_data.title_en"]
        //     },title:"Secondary Status"},
    ]
    const [status, setStatus] = useState({
        secondary_status_id: '-1',
    });

    const handleStatusChange = event => {
        setStatus({...status,secondary_status_id: event.target.value} );
    };
    const handleStatusFormChange = event =>
    {
        event.preventDefault();
        sendRequest(status,"WorkOrderSecondaryStatus",function (){
            $("#WorkOrderStatusHistory").DataTable().ajax.reload()
            setStatus({
                secondary_status_id: '',
            })
        })
    }
    return(
       <div className="card card-custom card-stretch gutter-b">
            <div className="card-header">
                <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">{trans('Status History')}</h3>
                </div>
                {/*<div className="d-flex my-4">*/}
                {/*    { data.status_data  &&*/}
                {/*        data.status_data.title_en == "Active" && (data.is_assignee || data.is_admin) &&*/}
                {/*    <button type="button" className="btn btn-light-primary waves-effect waves-light" data-toggle="modal" data-target="#StatusModal">*/}
                {/*        {trans('Change Status')}</button>*/}
                {/*    }*/}
                {/*</div>*/}
            </div>
            <div className="card-body p-9">
                <CustomDataTable columns={columns} url={"WorkOrderStatusHistory?wo_id="+params.id} table_id="WorkOrderStatusHistory" order={ [[0, "asc"]]} />
            </div>
           <div className="modal fade" id="StatusModal" tabIndex="-1" role="dialog" aria-labelledby="StatusModalLabel" aria-hidden="true">
               <div className="modal-dialog" role="document">
                   <div className="modal-content">
                       {/*<div className="modal-header">*/}
                       {/*    <h5 className="modal-title" id="exampleModalLabel">{trans('Change Status')}</h5>*/}
                       {/*    <button type="button" className="close" data-dismiss="modal"*/}
                       {/*            aria-label="Close">*/}
                       {/*        <span aria-hidden="true">&times;</span>*/}
                       {/*    </button>*/}
                       {/*</div>*/}
                       <form onSubmit={handleStatusFormChange}>
                           <div className="modal-body">
                               <div className="form-group">
                                   <label htmlFor="recipient-name"
                                          className="col-form-label">{trans('Select Status')} * :</label>
                                   <select className="form-select form-select-sm"
                                           aria-label=".form-select-sm example"
                                           id="inputAssign" type="text" name="assign"
                                           onChange={handleStatusChange}
                                           value={status.secondary_status_id}
                                   >
                                       <option value="-1"  disabled>{trans('Select an option')}</option>
                                       {dataStatus.map(status => (
                                           <option key={status.id} value={status.id} >{trans(status.title)}</option>
                                       ))}
                                   </select>
                               </div>
                           </div>
                           <div className="modal-footer">
                               <button type="button" className="btn btn-secondary"
                                       data-dismiss="modal">{trans('Close')}
                               </button>
                               <button className="btn btn-primary"  type="submit">{trans('Submit')}</button>
                           </div>
                       </form>
                   </div>
               </div>
           </div>

       </div>
    )

}
export default StatusHistory
