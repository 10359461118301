import React, {useEffect, useState} from 'react'
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../config.js";
import {trans} from "../../utils";
import customer from "../../../assets/img/add-user.png";
import name from "../../../assets/img/control-room.png";
import ref from "../../../assets/img/reference.png";
import subCustomer from "../../../assets/img/add-user.png";
import maintenance from "../../../assets/img/maintenance.png";
import Facility from "../../../assets/img/building.png";
import Building from "../../../assets/img/B-1.png";
import Floor from "../../../assets/img/stairs.png";
import Department from "../../../assets/img/department.png";

const Index = (ComponentToRenderdata) => {
    const params = useParams();
    const SystemUrl = ComponentToRenderdata.SystemUrl;
    const token = localStorage.getItem('token')
    const [data, setdata] = useState([]);
    const search = window.location.search;
    let URLParams = new URLSearchParams(search);
    const columns = [
        {data:"items.data.asset_item.name",title:"Name"},
        {data:"items.common_name",title:"Common Name"},
        {data:"items.reference_code",title:"Ref."},
        {data:"items.utility.title",title:"Utility"},
    ];
    useEffect(() => {
        axios(config.SystemUrl + `Space/${params.id}?include=project,project.customer,project.sub_customer,project_facility,project_facility_building,project_facility_building_floor,department,items.asset_item,items.asset_item.utility,typical_space,typical_space.items,typical_space.items.asset_item.utility`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization': "Bearer " + token
            }
        })
            .then(result => setdata(result.data.data));

    }, [])


    return (
        <>
            <div className="d-flex flex-column flex-xl-row">
                <div className="flex-column flex-lg-row-auto w-100 w-xl-350px row">
                    <div className="col-md-12">
                        <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">

                            <li className="nav-item">
                                <a className="nav-link text-active-primary pb-4 active" data-toggle="tab"
                                   href="#kt_allocate_tab">
                                    {trans('Space Details')}
                                </a>
                            </li>
                            { data.items  && data.items.length > 0 &&
                                <li className="nav-item">
                                <a className="nav-link text-active-primary pb-4" data-toggle="tab"
                                   href="#kt_techincal_tab">
                                    {trans('Items')}
                                </a>
                            </li>
                            }
                            { data.typical_space && data.typical_space.items &&
                                <li className="nav-item">
                                    <a className="nav-link text-active-primary pb-4" data-toggle="tab"
                                       href="#kt_typical_tab">
                                        {trans('Typical Space Items')}
                                    </a>
                                </li>
                            }

                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade active show" id="kt_allocate_tab" role="tabpanel">
                                <div className="row g-5 g-xl-8">
                                    <div className="col-xl-3">
                                        <div className="card card-xl-stretch mb-xl-8">
                                            <div className="card-body d-flex align-items-center pt-1 pb-0">
                                                <div className="d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2">
                                                    {data.name && data.name &&
                                                    <>
                                                            <span className="fw-bolder text-dark fs-4 mb-2">
                                                                Name
                                                            </span>
                                                        <span className="fw-bold text-muted fs-5">{data.name}</span>
                                                    </>
                                                    }
                                                </div>
                                                <img src={name}
                                                     alt="" className="align-self-end h-80px" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3">
                                        <div className="card card-xl-stretch mb-xl-8">
                                            <div className="card-body d-flex align-items-center pt-1 pb-0">
                                                <div className="d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2">
                                                    {data.space_ref && data.space_ref &&
                                                    <>
                                                            <span className="fw-bolder text-dark fs-4 mb-2">
                                                                Space Ref.
                                                            </span>
                                                        <span className="fw-bold text-muted fs-5">{data.space_ref}</span>
                                                    </>
                                                    }
                                                </div>
                                                <img src={ref}
                                                     alt="" className="align-self-end h-80px" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3">
                                        <div className="card card-xl-stretch mb-xl-8">
                                            <div className="card-body d-flex align-items-center pt-1 pb-0">
                                                <div className="d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2">
                                                    {data.project && data.project.customer &&
                                                    <>
                                                            <span className="fw-bolder text-dark fs-4 mb-2">
                                                                Customer
                                                            </span>
                                                        <span className="fw-bold text-muted fs-5">{data.project.customer.name}</span>
                                                    </>
                                                    }
                                                </div>
                                                <img src={customer}
                                                     alt="" className="align-self-end h-80px" />
                                            </div>
                                        </div>
                                    </div>
                                    {data.project && data.project.sub_customer && data.project.sub_customer.hasOwnProperty("name") &&
                                    <div className="col-xl-3">
                                        <div className="card card-xl-stretch mb-xl-8">
                                            <div className="card-body d-flex align-items-center pt-1 pb-0">
                                                <div className="d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2">

                                                    <>
                                                            <span className="fw-bolder text-dark fs-4 mb-2">
                                                                Sub Customer
                                                            </span>
                                                        <span className="fw-bold text-muted fs-5">{data.facility.sub_customer.name}</span>
                                                    </>

                                                </div>
                                                <img src={subCustomer}
                                                     alt="" className="align-self-end h-80px" />
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {data.project && data.project.hasOwnProperty("name") &&
                                    <div className="col-xl-3">
                                        <div className="card card-xl-stretch mb-5 mb-xl-8">
                                            <div className="card-body d-flex align-items-center pt-1 pb-0">
                                                <div className="d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2">
                                                    <>
                                                            <span className="fw-bolder text-dark fs-4 mb-2">
                                                                Project
                                                            </span>
                                                        <span className="fw-bold text-muted fs-5">{data.project.name}</span>
                                                    </>

                                                </div>
                                                <img src={Facility}
                                                     alt="" className="align-self-end h-80px" />
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {data.project_facility && data.project_facility.hasOwnProperty("title") &&
                                    <div className="col-xl-3">
                                        <div className="card card-xl-stretch mb-5 mb-xl-8">
                                            <div className="card-body d-flex align-items-center pt-1 pb-0">
                                                <div className="d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2">
                                                    <>
                                                            <span className="fw-bolder text-dark fs-4 mb-2">
                                                                Facility
                                                            </span>
                                                        <span className="fw-bold text-muted fs-5">{data.project_facility.title}</span>
                                                    </>

                                                </div>
                                                <img src={maintenance}
                                                     alt="" className="align-self-end h-80px" />
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {data.project_facility_building && data.project_facility_building.hasOwnProperty("title") &&
                                    <div className="col-xl-3">
                                        <div className="card card-xl-stretch mb-5 mb-xl-8">
                                            <div className="card-body d-flex align-items-center pt-1 pb-0">
                                                <div className="d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2">
                                                    <>
                                                            <span className="fw-bolder text-dark fs-4 mb-2">
                                                                Building
                                                            </span>
                                                        <span className="fw-bold text-muted fs-5">{data.project_facility_building.title}</span>
                                                    </>

                                                </div>
                                                <img src={Building}
                                                     alt="" className="align-self-end h-80px" />
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {data.project_facility_building_floor && data.project_facility_building_floor.hasOwnProperty("title") &&
                                    <div className="col-xl-3">
                                        <div className="card card-xl-stretch mb-5 mb-xl-8">
                                            <div className="card-body d-flex align-items-center pt-1 pb-0">
                                                <div className="d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2">
                                                    <>
                                                            <span className="fw-bolder text-dark fs-4 mb-2">
                                                                Floor
                                                            </span>
                                                        <span className="fw-bold text-muted fs-5">{data.project_facility_building_floor.title}</span>
                                                    </>

                                                </div>
                                                <img src={Floor}
                                                     alt="" className="align-self-end h-80px" />
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {data.department && data.department && data.department.hasOwnProperty("title") &&
                                    <div className="col-xl-3">
                                        <div className="card card-xl-stretch mb-5 mb-xl-8">
                                            <div className="card-body d-flex align-items-center pt-1 pb-0">
                                                <div className="d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2">
                                                    <>
                                                            <span className="fw-bolder text-dark fs-4 mb-2">
                                                                Department
                                                            </span>
                                                        <span className="fw-bold text-muted fs-5">{data.department.title}</span>
                                                    </>

                                                </div>
                                                <img src={Department}
                                                     alt="" className="align-self-end h-80px" />
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                            <div className="tab-pane fade" id="kt_techincal_tab" role="tabpanel">
                                <div className="card">
                                    <div className="card-header card-header-stretch">
                                        <div className="card-title d-flex align-items-center">
                                            <h4 className="mb-1">{trans('Items')}</h4>
                                        </div>
                                    </div>
                                    <div className="card-body p-9">
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Common Name</th>
                                                <th scope="col">Ref.</th>
                                                <th scope="col">Utility</th>
                                                <th scope="col">Quantity</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                { data.items && data.items && data.items.map(item => (
                                                    <tr>
                                                        <td>{item.asset_item.name}</td>
                                                        <td>{item.asset_item.common_name}</td>
                                                        <td>{item.asset_item.reference_code}</td>
                                                        <td>{item.asset_item.utility.title}</td>
                                                        <td>{item.quantity}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="kt_typical_tab" role="tabpanel">
                                <div className="card">
                                    <div className="card-header card-header-stretch">
                                        <div className="card-title d-flex align-items-center">
                                            {data.typical_space && <h4 className="mb-1">{data.typical_space.title}</h4>}
                                        </div>
                                    </div>
                                    <div className="card-body p-9">
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Common Name</th>
                                                <th scope="col">Ref.</th>
                                                <th scope="col">Utility</th>
                                                <th scope="col">Quantity</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                { data.typical_space && data.typical_space.items && data.typical_space.items.map(item => (
                                                    <tr>
                                                        <td>{item.asset_item.name}</td>
                                                        <td>{item.asset_item.common_name}</td>
                                                        <td>{item.asset_item.reference_code}</td>
                                                        <td>{item.asset_item.utility.title}</td>
                                                        <td>{item.quantity}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Index;
