import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import config from './config.js'

const NotSubscribed = () => {

  const handleSubscribe = () => {
    window.location.href = config.SaasUrl;
  };

  return (
    <div className="modern-page" style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <div style={{ textAlign: 'center', color: 'darkred' }}>
        <FontAwesomeIcon icon={faExclamationTriangle} size="3x" />
        <h1>Welcome, To Iassets!</h1>
        <p>To be able to use our product, an administrator needs to subscribe through the following link.</p>
        <button onClick={handleSubscribe} style={{ fontSize: '16px', padding: '10px 20px', color: '#fff', backgroundColor: 'green', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
          Subscribe
        </button>
      </div>
    </div>
  );
};

export default NotSubscribed;
