import React, {useEffect, useState} from 'react';
import CustomDataTable from "../CustomDataTable";
import {trans} from "../../utils";
import axios from "axios";
import config from "../../../config.js";
import {toast} from "react-toastify";

const SigningReport = ({data,data5,resetData,setResetData}) =>{
    const signUsers = ['department_head','supervisor','admin']

    const HandleSign = (id,type) =>
    {
        let postData = {}
        postData['type']= type;
        postData["work_order_id"]= id;
        postData["_method"]= "post";
        axios.post(config.SystemUrl + `WorkOrderServiceReportSign` , postData)
            .then(response => {
                toast.success('Success');
                setResetData(!resetData);
            }).catch(err => {

            toast.error(err.response.data.msg[0], { position: toast.POSITION.UPPER_RIGHT });
        });

    }
    let checkBy = data5 ;
    if(data.is_plan_preventive){
        checkBy = data.pm_instructions
    }
    return(
        <>


                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th scope="col" className="pl-4 text-center fw-bolder">
                                            <h5>
                                                #
                                            </h5>
                                        </th>
                                        <th scope="col">
                                            <h5>
                                                Dep. Head
                                                <br />
                                                رئيس القسم
                                            </h5>
                                        </th>
                                        <th scope="col">
                                            <h5>
                                                Site Supervisor
                                                <br />
                                                المهندس المسؤول
                                            </h5>
                                        </th>
                                        <th scope="col">
                                            <h5>
                                                Site Admin
                                                <br />
                                                مشرف الصيانة لطبية
                                            </h5>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th scope="row" className="pl-4 text-center fw-bolder">Name:</th>
                                        {signUsers.map((user,index) =>(<td>{data && data[user] &&data[user].name}</td>))}


                                    </tr>
                                    <tr>
                                        <th scope="row" className="pl-4 text-center fw-bolder">SIG:</th>
                                        {signUsers.map((user,index) =>(<td>{data && data[user] && data[user].signature &&
                                            <img src={config.BackendUrl+data[user].signature} width={"100px"}/>}</td>))}


                                    </tr>
                                    <tr>

                                        <th scope="row" className="pl-4 text-center fw-bolder">Date:</th>
                                        {signUsers.map((user,index) =>(<td>{data && data[user+"_date"]}</td>))}

                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

        </>


    )

}
export default SigningReport
