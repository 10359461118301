import React, {useState , useRef} from 'react';
import Multiselect from 'multiselect-react-dropdown';
import 'react-toastify/dist/ReactToastify.css';
import {trans} from "../../utils";
import $ from "jquery";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";

const Assignee = ({data,sendRequest,data2,data5}) => {
    const [assign, setAssign] = useState({
        staff_id: '-1',
        notes_to_assignee: '',
        action_option:'',
        _method : 'put',
        isLoading: false,
        custom_function : true
    });
    const handleNotesChange = event => {
        setAssign({...assign,notes_to_assignee: event.target.value} );
    };

    const handleAssigneeChange = event => {
        setAssign({...assign,staff_id: event.target.value} );
    };
    const handleAssignForm = event =>
    {
        event.preventDefault();
        sendRequest(assign)
    }
    const handleProgress = () =>
    {
        const data =  {
            _method : 'put',
            custom_function : true
        }
        sendRequest(data)
    }

    const [finish, setFinish] = useState({
        failure_type_id: [],
        delivered_to: '',
        _method : 'put',
        isLoading: false,
        custom_function : true,
        req_type:'finish'
    });

    const handleFinishChange = event =>
    {
        event.preventDefault();
        sendRequest(finish)
    }

    const handleDeliveredToChange = event => {
        setFinish({...finish,delivered_to: event.target.value} );
    };
    const handleClose = () =>
    {
        const data =  {
            _method : 'put',
            custom_function : true,
            req_type:'close'
        }
        sendRequest(data)
    }

    const handleSignOff = () =>
    {
        const data =  {
            _method : 'put',
            custom_function : true,
            req_type:'signoff'
        }
        sendRequest(data)
    }
    const handleApprove = () =>
    {
        const data =  {
            _method : 'put',
            custom_function : true,
            req_type:'approve'
        }
        sendRequest(data)
    }
    const [reopen, setReopen] = useState({
        comment:'',
        _method : 'put',
        custom_function : true,
        req_type:'reopen'
    });
    const handleReopenChange = event => {
        setReopen({...reopen,comment: event.target.value} );
    };

    const [reopenreport, setReopenReport] = useState({
        comment:'',
        _method : 'put',
        custom_function : true,
        req_type:'reopen_report'
    });
    const handleReopenReportChange = event => {
        setReopenReport({...reopenreport,comment: event.target.value} );
    };
    const handleReOpenForm = event =>
    {
        event.preventDefault();
        sendRequest(reopen)
    }

    const handleReOpenReportForm = event =>
    {
        event.preventDefault();
        sendRequest(reopenreport)
    }
    window.$('[data-toggle="tooltip"]').tooltip();

    const options = data5;
    const [selectedValues,setSelectedValues] = useState([]);
    const onSelect = (selectedList, selectedItem) => {
        setSelectedValues(selectedList)
        var failure_ids = selectedList.map(el=>el.id);
        setFinish({...finish,failure_type_id: failure_ids} );
    }
    const onRemove = (selectedList, selectedItem) => {
        setSelectedValues(selectedList)
        var failure_ids = selectedList.map(el=>el.id);
        setFinish({...finish,failure_type_id: failure_ids} );
    }

    const componentRef = useRef();

    return(
        <div  className="card card-custom card-stretch gutter-b">
            <div className="card-header">
                <div className="card-title m-0">
                    <div className="card-label d-flex">
                        <h3 className="fw-bolder m-0">{trans('WO Information')}</h3>
                        { data.priority &&
                            <span className="badge badge-success me-auto ml-2"
                                  data-container="body" data-toggle="tooltip" data-placement="top" title={trans('Priority')}>{trans(data.priority.title)}</span>
                        }
                    </div>
                </div>
                <div className="card-toolbar">
                    { data.status_data  &&
                    data.status_data.title_en == "Assigned" &&  data.wo_actions && data.wo_actions.can_start_wo == true ?
                    <button type="button" className="btn btn-light-warning mr-4" onClick={handleProgress}>
                        {trans('Start')}</button> : ""
                    }
                    { data.status_data  &&
                    (data.status_data.title_en == "Active" || data.status_data.title_en == "Report Reopened" || data.status_data.title_en == "Pending for Spare Part") &&  data.wo_actions && data.wo_actions.can_finish_wo == true ?
                    <button type="button" className="btn btn-light-danger mr-4" data-toggle="modal" data-target="#FinisheModal">
                            {trans('Finish')}</button> : ""
                    }
                    { data.status_data  &&
                    data.status_data.title_en == "Skipped"   && data.wo_actions && data.wo_actions.can_start_wo == true ?
                    <button type="button" className="btn btn-light-warning mr-4" onClick={handleProgress}>
                        {trans('Start')}</button> : ""
                    }
                    { data.status_data  &&
                    data.status_data.title_en == "Pending" &&  data.wo_actions && data.wo_actions.can_start_wo == true ?
                    <button type="button" className="btn btn-light-warning mr-4" onClick={handleProgress}>
                        {trans('Start')}</button> : ""
                    }
                    { data.status_data  &&
                    data.status_data.title_en == "Approved" && data.wo_actions && data.wo_actions.can_close_wo == true ?
                    <button type="button" className="btn btn-light-success mr-4"  onClick={handleClose}>
                        {trans('Close')}</button> : ""
                    }
                    { data.status_data  &&
                    data.status_data.title_en == "Closed" && data.wo_actions && data.wo_actions.can_sign_off_wo == true ?
                    <button type="button" className="btn btn-light-success mr-4"  onClick={handleSignOff}>
                        {trans('Sign Off')}</button> : ""
                    }
                    { data.status_data  &&
                    data.status_data.title_en == "Pending for Approval" && data.wo_actions && data.wo_actions.can_approve_wo == true ?
                        <button type="button" className="btn btn-light-success mr-4" onClick={handleApprove}>
                            {trans('Approve')}</button> : ""
                    }
                    { data.status_data  &&
                    (data.status_data.title_en == "Pending for Approval" || data.status_data.title_en == "Approved" || data.status_data.title_en == "Closed" ||(data.status_data.title_en == "Signed Off" && data.is_admin ) ) &&  data.wo_actions && data.wo_actions.can_reopen_wo == true ?
                        <button type="button" className="btn btn-light-info mr-4" data-toggle="modal" data-target="#ReopenModal" >
                            {trans('Re-open')}</button>
                    :""}
                    { data.status_data  &&
                    (data.status_data.title_en == "Pending for Approval" || data.status_data.title_en == "Approved" || data.status_data.title_en == "Closed" )  &&  data.wo_actions && data.wo_actions.can_reopen_report == true ?
                        <button type="button" className="btn  btn-light-warning mr-4" data-toggle="modal" data-target="#ReopenReportModal" >
                            {trans('Re-open Report')}</button>
                        :""}
                </div>

            </div>
            <div className="modal fade" id="AssigneeModal" tabIndex="-1" role="dialog" aria-labelledby="AssigneeModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{trans('Assign')}</h5>
                            <button type="button" className="close" data-dismiss="modal"
                                    aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form onSubmit={handleAssignForm}>

                            <div className="modal-body">
                                {data.status_data  &&
                                    data.status_data.title_en == "Reopened" &&
                                <div className="form-group">
                                    <label htmlFor="recipient-name"
                                           className="col-form-label">{trans('Assign to')} * :</label>
                                    <select className="form-select form-select-sm"
                                            aria-label=".form-select-sm example"
                                            id="inputAssign" type="text" name="assign"
                                            onChange={handleAssigneeChange}
                                            value={assign.staff_id}
                                    >
                                        <option value="-1"  disabled>{trans('Select an option')}</option>
                                        {data2.map(name => (
                                            <option key={name.id} value={name.id} >{trans(name.name)}</option>
                                        ))}
                                    </select>
                                </div>
                                    }
                                <div className="form-group">
                                    <label htmlFor="message-text"
                                           className="col-form-label">{trans('Notes To Assignee')}:</label>
                                    <input
                                        className="form-control"
                                        type="text" name="notes"
                                        value={assign.notes_to_assignee}
                                        onChange={handleNotesChange}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                        data-dismiss="modal">{trans('Close')}
                                </button>
                                <button className="btn btn-primary"  type="submit">{trans('Submit')}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="ReopenModal" tabIndex="-1" role="dialog" aria-labelledby="ReopenModallabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{trans('Re-opening comments')}</h5>
                            <button type="button" className="close" data-dismiss="modal"
                                    aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form onSubmit={handleReOpenForm}>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="message-text"
                                           className="col-form-label">{trans('Re-opening comments')} *:</label>
                                    <input
                                        className="form-control"
                                        type="text" name="notes"
                                        value={reopen.comment}
                                        onChange={handleReopenChange}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                        data-dismiss="modal">{trans('Close')}
                                </button>
                                <button className="btn btn-primary"  type="submit">{trans('Submit')}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="ReopenReportModal" tabIndex="-1" role="dialog" aria-labelledby="ReopenReportModallabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{trans('Reopen Report')}</h5>
                            <button type="button" className="close" data-dismiss="modal"
                                    aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form onSubmit={handleReOpenReportForm}>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="message-text"
                                           className="col-form-label">{trans('Re-opening report comments')} *:</label>
                                    <input
                                        className="form-control"
                                        type="text" name="notes"
                                        value={reopenreport.comment}
                                        onChange={handleReopenReportChange}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                        data-dismiss="modal">{trans('Close')}
                                </button>
                                <button className="btn btn-primary"  type="submit">{trans('Submit')}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="FinisheModal" tabIndex="-1" role="dialog" aria-labelledby="FinishModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{trans('Finish')}</h5>
                            <button type="button" className="close" data-dismiss="modal"
                                    aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form onSubmit={handleFinishChange}>

                            <div className="modal-body">

                                <div className="form-group">
                                    <label htmlFor="recipient-name"
                                           className="col-form-label">{trans('Failure Type')} * :</label>
                                    <Multiselect
                                        options={options} // Options to display in the dropdown
                                        selectedValues={selectedValues} // Preselected value to persist in dropdown
                                        onSelect={onSelect} // Function will trigger on select event
                                        onRemove={onRemove} // Function will trigger on remove event
                                        displayValue="title" // Property name to display in the dropdown options
                                    />
                                </div>

                                {/*<div className="form-group">*/}
                                {/*    <label htmlFor="recipient-name"*/}
                                {/*           className="col-form-label">{trans('Delivered to')} :</label>*/}
                                {/*    <select className="form-select form-select-sm"*/}
                                {/*            aria-label=".form-select-sm example"*/}
                                {/*            id="inputAssign" type="text" name="assign"*/}
                                {/*            onChange={handleDeliveredToChange}*/}
                                {/*            value={finish.delivered_to}*/}
                                {/*    >*/}
                                {/*        <option value="" >{trans('Select an option')}</option>*/}
                                {/*        {data2.map(name => (*/}
                                {/*            <option key={name.id} value={name.id} >{trans(name.name)}</option>*/}
                                {/*        ))}*/}
                                {/*    </select>*/}
                                {/*</div>*/}

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                        data-dismiss="modal">{trans('Close')}
                                </button>
                                <button className="btn btn-primary"  type="submit">{trans('Submit')}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="card-body p-4"  ref={componentRef}>
                <>
                    <div className="print-2 text-center">
                        { data.status_data &&
                        <h3 className="mb-3 text-center">{trans('WO')} #{data.id}</h3>
                    }
                    </div>
                </>
                <>
                    <div className="print-2 text-center">
                        {data.ticket &&
                        <h3 className="text-center">{trans('Ticket')} #{data.ticket.id} </h3>}
                    </div>
                </>

                <>
                    <div className="fw-bolder mt-3">{trans('Equipment Name')} :</div>
                    <div className="text-gray-600">
                        {   data.room_space_item &&
                            data.room_space_item.ecri &&
                           <>{trans(data.room_space_item.ecri.ecri_name)}</>
                        }
                    </div>
                </>
                {   data.room_space_item &&
                    data.room_space_item.ecri &&
                    <>
                        <>
                            <div className="separator mb-2 mt-2"></div>
                            <div className="fw-bolder mt-3">{trans('ECRI Code')} :</div>
                            <div className="text-gray-600">{trans(data.room_space_item.ecri.ecri_code)}</div>
                        </>
                    </>
                }

                { data.ticket && data.ticket.requested_by_user &&
                <>
                    <div className="separator mb-2 mt-2"></div>
                    <div className="fw-bolder mt-3">{trans('Requested by')}  :</div>
                    <div className="text-gray-600">
                        {trans(data.ticket.requested_by_user.name)}
                    </div>
                </>
                }
                { data.room_space_item && data.room_space_item.facility &&
                <>
                    <div className="separator mb-2 mt-2"></div>
                    <div className="fw-bolder mt-3">{trans('Facility')}  :</div>
                    <div className="text-gray-600">
                        {trans(data.room_space_item.facility.name)}
                    </div>
                    {data.room_space_item && data.room_space_item.facility.map_url &&
                        <>
                        <div className="separator mb-2 mt-2"></div>
                        <div className="fw-bolder mt-3">{trans('Site Map')}  :</div>
                        <a className={"btn btn-sm btn-primary"}  href={data.room_space_item.facility.map_url} target="_blank" >{trans('View On Map')}</a>
                        </>
                    }
                </>
                }

                { data.room_space_item && data.room_space_item.building &&
                <>
                    <div className="separator mb-2 mt-2"></div>
                    <div className="fw-bolder mt-3">{trans('Department')}  :</div>
                    <div className="text-gray-600">
                        {trans(data.room_space_item.building.title)}
                    </div>
                </>
                }
                { data.room_space_item && data.room_space_item.floor.hasOwnProperty("name") &&
                <>
                    <div className="separator mb-2 mt-2"></div>
                    <div className="fw-bolder mt-3">{trans('Building')}  :</div>
                    <div className="text-gray-600">
                        {trans(data.room_space_item.floor.name)}
                    </div>
                </>
                }
                { data.room_space_item && data.room_space_item.room &&
                <>
                    <div className="separator mb-2 mt-2"></div>
                    <div className="fw-bolder mt-3">{trans('Room')}  :</div>
                    <div className="text-gray-600">
                        {trans(data.room_space_item.room.room_space_name)}
                    </div>
                </>
                }

                {data.room_space_item && data.room_space_item.ecri > 0 ?
                    <>
                        <div className="separator mb-2 mt-2"></div>
                        <div className="fw-bolder mt-3">{trans('ECRI Name')} :</div>
                        <div className="text-gray-600">{trans(data.room_space_item.ecri.ecri_name)}</div>
                    </>
                    : ''
                }

                {data.ticket && data.ticket.description &&
                <>
                    <div className="separator mb-2 mt-2"></div>
                    <div className="fw-bolder mt-3">{trans('Ticket Description')} :</div>
                    <div className="text-gray-600">{trans(data.ticket.description)}</div>
                </>
                }

                {data.assigned_to_user && data.assigned_to_user &&
                <>
                    <div className="separator mb-2 mt-2"></div>
                    <div className="fw-bolder mt-3">{trans('Assigned to')} :</div>
                    <div className="text-gray-600">{trans(data.assigned_to_user.name)}</div>
                </>
                }

            </div>
        </div>
    )

}
export default Assignee;
