import React, {useState} from 'react';
import {trans} from "../../utils";
import axios from "axios";
import config from "../../../config.js";
import {toast} from "react-toastify";
import Select from 'react-select';
import {faCheckCircle,faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const SpareParts = ({data,sendRequest,params,data3,newSpare, setNewSpare,dataSpare}) => {
    const [inputBox, setInputBox] = useState(false);
    const [approvedQuantity, setApprovedQuantity] = useState(0);
    const [spareId, setSpareId] = useState(0);
    const [sparePart, setSparePart] = useState({
        spare_part_id:'',
        quantity:'',
        is_exist:inputBox ? 0 : 1,
        name:'',
        description:'',
    });
    const [approveSparePart, setApproveSparePart] = useState({
        approved_quantity:'',
    });

    const [selectedValues, setSelectedValues] = useState('');

    const handleApproveQuantityChange = event => {
        setApprovedQuantity(event.target.value);
    };
    const handleSparePartChange = (selectedValues) => {
        setSparePart({...sparePart,spare_part_id: selectedValues.value} );
    };

    const handleIsExistChange = event => {
        setInputBox(!inputBox);
        setSparePart({...sparePart,is_exist: !inputBox ? 0 : 1} );
    };

    const handleQuantityChange  = event => {
        setSparePart({...sparePart,quantity: event.target.value} );
    };

    const handleNameChange = event => {
        setSparePart({...sparePart,name: event.target.value} );
    };

    const handleDescriptionChange = event => {
        setSparePart({...sparePart,description: event.target.value} );
    };
    const handleSparePartForm = event =>
    {
        event.preventDefault();
        setSparePart({...sparePart,is_not_exist: inputBox } );
        sendRequest(sparePart,"WOSparePart",function (response){
            setNewSpare(!newSpare);
            setSparePart({
                spare_part_id:'',
                quantity:'',
                is_exist:1,
                name:'',
                description:'',
            })
        })
    }
    const CurrentModuleObj = JSON.parse(localStorage.getItem("WOSparePart"));
    const approveRequest = (id,status="approved") =>
    {
        axios.delete(config.SystemUrl + `WOSparePart/${id}` ,
            { headers:{
                    _method : 'delete',
                    custom_function : true,
                },
                data :{
                    status : status,
                    approved_quantity : approvedQuantity,
                }
            })
            .then(response => {
                toast.success('Success');
                setNewSpare(!newSpare);
            }).catch(err => {
        if(err.response.data.msg)
            toast.error(err.response.data.msg, { position: toast.POSITION.UPPER_RIGHT });
        else
            toast.error(err.response.data.message, { position: toast.POSITION.UPPER_RIGHT });

        });
    }
    const handleApprove = (id,status="approved") =>
    {
        if(status != "approved"){
            approveRequest(id,status);
        }
    }
    window.$('[data-toggle="tooltip"]').tooltip();

    const SpareParts = data3.map(name => ({ label: name.name , value: name.id }));

    return(
        <div className="card card-custom card-stretch gutter-b">
        <div className="card-header">
            <div className="card-title m-0">
                <h3 className="fw-bolder m-0">{trans('Spare Parts')}</h3>
            </div>
            { data.status_data  &&
                (data.status_data.title_en == "Active" || data.status_data.title_en == "Pending for Spare Part")  && data.wo_actions && data.wo_actions.can_request_spare_part == true ?
                <div className="card-toolbar d-flex my-4">
                    <button type="button" className="btn btn btn-light-primary waves-effect waves-light" data-toggle="modal" data-target="#CostModal">
                        <i className="bi bi-plus fs-3"></i> Add a Spare Part
                    </button>
                </div> : ""
            }
        </div>
        <div className="modal fade" id="CostModal" tabIndex="-1" role="dialog" aria-labelledby="CostModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{trans('Add a Spare Part')}</h5>
                        <button type="button" className="close" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form onSubmit={handleSparePartForm}>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>Is Exist ?</label>
                                <select className="form-select form-select-sm"
                                            aria-label=".form-select-sm example"
                                            id="inputAction" type="text" name="is_exist"
                                            onChange={handleIsExistChange}
                                            value={sparePart.is_exist}>
                                    <option value="1">Yes</option>
                                    <option value="0" >No</option>
                                </select>
                            </div>
                            {inputBox ?
                                <>
                                    <h5>Add a new spare part</h5>
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="text" name="name"
                                            value={sparePart.name}
                                            onChange={handleNameChange}
                                            placeholder="Name"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="text" name="description"
                                            value={sparePart.description}
                                            onChange={handleDescriptionChange}
                                            placeholder="Description"
                                        />
                                    </div>
                                </>
                                :
                                <div className="form-group">
                                    {/*<select className="form-select form-select-sm"*/}
                                    {/*        aria-label=".form-select-sm example"*/}
                                    {/*        id="inputAction" type="text" name="assign"*/}
                                    {/*        onChange={handleSparePartChange}*/}
                                    {/*        value={sparePart.spare_part_id}>*/}
                                    {/*    <option value="" >{trans('Choose Spare Part')}</option>*/}
                                    {/*    {data3.map(name => (*/}
                                    {/*        <option key={name.id} value={name.id} >{trans(name.name)}</option>*/}
                                    {/*    ))}*/}
                                    {/*</select>*/}
                                    <Select
                                        defaultValue={selectedValues}
                                        onChange={handleSparePartChange}
                                        options={SpareParts}
                                    />
                                </div>
                            }
                            <div className="form-group">
                                <input
                                    className="form-control"
                                    type="number" name="quantity"
                                    value={sparePart.quantity}
                                    onChange={handleQuantityChange}
                                    placeholder="Quantity"
                                />
                            </div>
                            <div className="modal-footer">
                                <button
                                    className="btn btn-primary"
                                    onClick={handleSparePartForm}
                                    type="submit"
                                    data-dismiss="modal">
                                    {trans('Submit')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
            <div className="modal fade" id="ApproveModal" tabIndex="-1" role="dialog" aria-labelledby="ApproveModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{trans("Approve Spare Part's Quantity")}</h5>
                            <button type="button" className="close" data-dismiss="modal"
                                    aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form >
                            <div className="modal-body">
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="number" name="quantity"
                                        value={approvedQuantity}
                                        onChange={handleApproveQuantityChange}
                                        placeholder="Quantity"
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button
                                        className="btn btn-primary"
                                        onClick={function (){
                                            approveRequest(spareId)
                                        }}

                                        type="submit"
                                        data-dismiss="modal">
                                        {trans('Submit')}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12">
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th scope="col" className="text-center fw-bolder">
                                    رقم القطعة<br/>
                                    Part No.
                                </th>
                                <th scope="col" className="text-center fw-bolder">
                                    وصف القطعة<br/>
                                    Part Description
                                </th>
                                <th scope="col" className="text-center fw-bolder">
                                    الكمية المطلوبة <br/>
                                    Requested Quantity
                                </th>

                                <th scope="col" className="text-center fw-bolder">
                                    الكمية المعتمدة<br/>
                                    Approved Quantity
                                </th>
                                <th scope="col" className="text-center fw-bolder">
                                    الحالة<br/>
                                    Status
                                </th>
                                {!!CurrentModuleObj.page_suspend && !!CurrentModuleObj.suspend && data.status_data && (data.status_data.title_en == "Active" || data.status_data.title_en == "Pending for Spare Part" ) &&
                                    <th scope="col" className="text-center fw-bolder">
                                        Action
                                    </th>
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {dataSpare.length > 0 ?
                                dataSpare.map(part => (
                                    <tr key={part.spare_part.id}>
                                        <th scope="row" className="text-center fw-bolder">{part.spare_part.id}</th>
                                        <td className="text-center fw-bolder">{part.spare_part.name}</td>
                                        <td className="text-center fw-bolder">{part.quantity}</td>
                                        <td className="text-center fw-bolder">{part.approved_quantity}</td>
                                        <td className="text-center fw-bolder">{part.status}</td>
                                        {data.status_data && (data.status_data.title_en == "Active" || data.status_data.title_en == "Pending for Spare Part") &&

                                            <td className="text-center fw-bolder">
                                                <>
                                                    {part.spare_part.status == "approved" &&
                                                        <>

                                                            {!!CurrentModuleObj.page_suspend && !!CurrentModuleObj.suspend && part.status == "pending"
                                                            && data.wo_actions && data.wo_actions.can_take_action_on_spare_part == true ?
                                                                <>
                                                                    <button type="button" className="btn btn-light-success mr-2"
                                                                            data-toggle="modal" data-target="#ApproveModal"
                                                                            onClick={() => {
                                                                                let id = part.id;
                                                                                setSpareId(id);
                                                                                setApprovedQuantity(part.quantity);
                                                                                handleApprove(id,"approved");
                                                                            }}
                                                                    >
                                                                        <span data-container="body" data-toggle="tooltip" data-placement="top" title={trans('Approve')}>
                                                                            <FontAwesomeIcon icon={faCheckCircle}/>
                                                                        </span>
                                                                    </button>

                                                                    <button type="button" className="btn btn-light-warning mr-2"
                                                                            onClick={() => {
                                                                                let id = part.id;
                                                                                handleApprove(id, "refused");
                                                                            }}
                                                                    >
                                                                        <span data-container="body" data-toggle="tooltip" data-placement="top" title={trans('Refuse')}>
                                                                            &#10005;
                                                                        </span></button>
                                                                </> : ""

                                                            }
                                                        </>
                                                    }
                                                    {part.status == "pending" && part.spare_part.status == "suspended" &&
                                                        <>
                                                            Action cannot be taken on this spare part until it is approved
                                                            <br/>
                                                        </>
                                                    }

                                                    {!!CurrentModuleObj.page_suspend && !!CurrentModuleObj.suspend &&
                                                    data.wo_actions && data.wo_actions.can_take_action_on_spare_part == true ?
                                                        <button type="button" className="btn btn-light-danger"
                                                                onClick={() => {
                                                                    let id = part.id;
                                                                    handleApprove(id, "delete");
                                                                }}
                                                        >

                                                            <span data-container="body" data-toggle="tooltip" data-placement="top" title=  {trans('Delete')}>
                                                                            <FontAwesomeIcon icon={faTrash}/>
                                                                        </span></button> : ""
                                                    }
                                                </>
                                            </td>
                                        }
                                    </tr>
                                )):<h5 className="text-center">No Spare Parts Yet</h5>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default SpareParts
