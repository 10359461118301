import React, { useState, useEffect, useRef } from 'react';
import Header from "./Layout/Header";
import {Link } from 'react-router-dom';
import LeftSideBar from "./Layout/LeftSideBar";
import UserProfile from "./Layout/UserProfile";
import Notifications from "./Layout/Notifications";
import Footer from "./Layout/Footer";
import { NavLink } from "react-router-dom";
import { SystemUrl } from '../App';
import { trans } from "./utils";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux'
import  logo_iassets from '../assets/img/iAssets.png';


const Template = (data) => {
    const minimize = useSelector((state) => state.menuToggle.minimize)

    const $ = require("jquery");
    const [asideVisible, setAsideVisible] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const headerRef = useRef(null);
    const toggleAside = () => {
        setAsideVisible(!asideVisible);
    };
    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };
    const [modal, setModal] = useState(false)

    //Render Component
    var ComponentToRender = require('./' + data.getfolder + '/' + data.getaction).default;
    //Get Module Variables FROM API
    let GetModuleData = JSON.parse(localStorage.getItem(data.getmodule)) || data.getmodule;
    let module_name = data.getmodule;
    if (GetModuleData && GetModuleData.role_id != 0) {
        module_name = GetModuleData.module_name
    }
    const [file, setFile] = useState(null);

    const [isDisabled, setDisabled] = useState(false);

    const UploadExcel = () => {
        // TODO: Upload Excel https://intrazero.atlassian.net/browse/AST-974
        setDisabled(true);
        let formData = new FormData();
        formData.append("file", file);
        
        axios.post(SystemUrl + data.getmodule + '/import', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then(res => {
            $("#datatable").DataTable().ajax.reload();
            toast.success('Success');
            setDisabled(false);
    
            // Clear the input field
            inputFileRef.current.value = '';
            setModal(false)
            // Close the modal
            const modal = document.getElementById('AssignModal')
            if (modal) {
            modal.classList.remove('show')
            modal.style.display = 'none'
            const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0]
            if (modalBackdrop) {
                modalBackdrop.parentNode?.removeChild(modalBackdrop)
            }
            }
        }).catch((err) => {
            setDisabled(false);
            setModal(true)
            var error = "";
            err.response.data.message.forEach(el => {
                error += (el + "\n\r\t");
            });
            toast.error(error, { position: toast.POSITION.UPPER_RIGHT });
        });
    }
    const inputFileRef = useRef(null);

    //Dashboard Has no Variables
    if (!GetModuleData) {
        GetModuleData = {}
        GetModuleData.name_en = 'Dashboard';
    }
    let SystemTitle = localStorage.getItem('SystemTitle') ? localStorage.getItem('SystemTitle') : "iAssets";
    let PageModuleTitle = trans(GetModuleData.name_en) ? trans(GetModuleData.name_en) : "Dashboard";

    const folder = data.getfolder
    useEffect(() => {
        document.title = SystemTitle + " | " + PageModuleTitle + " - " + data.getaction
        window.$('.tooltip').tooltip('hide');
        window.$('[data-toggle="tooltip"]').tooltip();
    })
    const handleScrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // You can use 'auto' here for an immediate jump instead of smooth scroll
        });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (headerRef.current && !headerRef.current.contains(event.target)) {
            setShowMenu(false);
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

      const [form, setForm] = useState({
        isValid: false, // Set the initial validity state to false
      });

      const handleFormValidation = () => {
        // Your form validation logic here
        // For example, you might check if the file input is filled
        const isFormValid = !!file;
        setForm({ isValid: isFormValid });
      };
      
    return (
        <>
            {folder != "FormBuilder" ? (
            <>
                <div id="kt_header_mobile" className="header-mobile header-mobile-fixed">
                    <Link to="/">
                        <img alt="Logo" src={logo_iassets} className="logo-default max-h-30px" />
                    </Link>
                    <div className="d-flex align-items-center">
                        <button className="btn p-0 burger-icon burger-icon-left rounded-0" onClick={toggleMenu}>
                            <span></span>
                        </button>
                        <button className="btn btn-hover-icon-primary p-0 ml-5" id="kt_sidebar_mobile_toggle">
                        <span className="svg-icon svg-icon-xl">

                        </span>
                        </button>
                        <button className="btn btn-hover-icon-primary p-0 ml-2" onClick={toggleAside}>

                            <span className="svg-icon svg-icon-xl">
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                                    <g stroke="none" fill="none">
                                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                        <path
                                            d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                                            fill="#000000" fillRule="nonzero" opacity="0.3"></path>
                                        <path
                                            d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                                            fill="#000000" fillRule="nonzero"></path>
                                    </g>
                                </svg>
                            </span>
                        </button>
                    </div>
                </div>
                <div className="d-flex flex-column flex-root">
                    <div className="d-flex flex-row flex-column-fluid page">
                        <LeftSideBar  asideVisible={asideVisible}></LeftSideBar>
                        <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                            <Header headerRef={headerRef} showMenu={showMenu}></Header>
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="subheader py-2 py-lg-6  subheader-transparent " id="kt_subheader">
                                    <div className=" container-fluid  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                                        <div className="d-flex align-items-center flex-wrap mr-1">
                                            <div className="d-flex align-items-baseline flex-wrap mr-5">
                                                <h5 className="text-dark font-weight-bold my-1 mr-5">{trans(GetModuleData.name_en)}</h5>
                                                <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
                                                    <li className="breadcrumb-item text-muted">
                                                        <NavLink to='/dashboard' className="text-muted">
                                                            {trans('Dashboard')}
                                                        </NavLink>
                                                    </li>
                                                    <li className="breadcrumb-item text-muted">
                                                        {trans(GetModuleData.name_en)}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            {!!GetModuleData.add && !!GetModuleData.page_add && data.getaction != 'Add' ?
                                                <div className="d-flex align-items-center py-1">
                                                    <div className="me-4">
                                                        {data.getmodule  == "Ticket" || data.getmodule  == "Extract"  ? "" :
                                                            <NavLink to={`/${data.getmodule}/Add`} className="btn btn-primary font-weight-bolder btn-sm mr-5 p-4">
                                                                <i className="bi bi-plus fs-3"></i> {trans('Create')} {trans(GetModuleData.name_en)}
                                                            </NavLink>
                                                        }
                                                    </div>
                                                    <>
                                                        {data.getmodule == "Ticket" || data.getmodule  == "Extract"? "" :
                                                            <button type="button"
                                                                    className="btn btn-light-primary font-weight-bolder btn-sm p-4"
                                                                    data-toggle="modal"
                                                                    data-target="#AssignModal">
                                                                {trans('Upload')} {trans(GetModuleData.name_en)}
                                                            </button>
                                                        }
                                                    </>

                                                </div>
                                                : <br />
                                            }
                                        </div>
                                        <div className="modal fade" id="AssignModal" tabIndex="-1" role="dialog"
                                             aria-labelledby="AssignModalLabel" aria-hidden="true">
                                            <div className="modal-dialog" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">{trans('Upload')} {trans(GetModuleData.name_en)} <a href={SystemUrl + data.getmodule + '?export=1'} download>{trans('Download Sample')}</a></h5>
                                                        <button type="button" className="close" data-dismiss="modal"
                                                                aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <form>

                                                            <div className="form-group">
                                                                <label htmlFor="message-text"
                                                                       className="col-form-label">{trans('Actions options')} :</label>
                                                                <input ref={inputFileRef} onChange={e => setFile(e.target.files[0])} type="file" className="form-control" required />
                                                            </div>
                                                            <div className="modal-footer">
                                                                <div className="row">

                                                                    <div className="col-md-6">
                                                                        <button type="button" className="btn btn-secondary"
                                                                                data-dismiss="modal">{trans('Close')}
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <button type="button" onClick={UploadExcel} className="btn btn-primary" disabled={isDisabled}
                                                                                                 data-bs-dismiss={form.isValid ? (modal ? 'modal' : '') : ''}
                                                                                                >{trans('Submit')}</button>

                                                                    </div>


                                                                </div>

                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column-fluid">
                                    <div className="container">
                                        <ComponentToRender RoleId={GetModuleData.role_id} CurrentModule={data.getmodule}
                                                           SystemUrl={SystemUrl}/>
                                    </div>
                                </div>
                            </div>

                            <Footer></Footer>
                        </div>
                    </div>
                </div>

                {/*<div id="kt_quick_actions1" className="offcanvas offcanvas-left p-10">*/}
                {/*    <div className="offcanvas-header d-flex align-items-center justify-content-between pb-10">*/}
                {/*        <h3 className="font-weight-bold m-0">Quick Actions*/}
                {/*            <small className="text-muted font-size-sm ml-2">finance &amp; reports</small></h3>*/}
                {/*        <a href="#" className="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_actions_close">*/}
                {/*            <i className="ki ki-close icon-xs text-muted"></i>*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*    <div className="offcanvas-content pr-5 mr-n5">*/}
                {/*        <div className="row gutter-b">*/}
                {/*            <div className="col-6">*/}
                {/*                <a href="#" className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5">*/}
                {/*                <span className="svg-icon svg-icon-3x svg-icon-primary m-0">*/}

                {/*                </span>*/}
                {/*                    <span className="d-block font-weight-bold font-size-h6 mt-2">Accounting</span>*/}
                {/*                </a>*/}
                {/*            </div>*/}
                {/*            <div className="col-6">*/}
                {/*                <a href="#" className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5">*/}
                {/*                <span className="svg-icon svg-icon-3x svg-icon-primary m-0">*/}

                {/*                </span>*/}
                {/*                    <span className="d-block font-weight-bold font-size-h6 mt-2">Members</span>*/}
                {/*                </a>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="row gutter-b">*/}
                {/*            <div className="col-6">*/}
                {/*                <a href="#" className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5">*/}
                {/*                <span className="svg-icon svg-icon-3x svg-icon-primary m-0">*/}

                {/*                </span>*/}
                {/*                    <span className="d-block font-weight-bold font-size-h6 mt-2">Projects</span>*/}
                {/*                </a>*/}
                {/*            </div>*/}
                {/*            <div className="col-6">*/}
                {/*                <a href="#" className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5">*/}
                {/*                <span className="svg-icon svg-icon-3x svg-icon-primary m-0">*/}

                {/*                </span>*/}
                {/*                    <span className="d-block font-weight-bold font-size-h6 mt-2">Customers</span>*/}
                {/*                </a>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="row gutter-b">*/}
                {/*            <div className="col-6">*/}
                {/*                <a href="#" className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5">*/}
                {/*                <span className="svg-icon svg-icon-3x svg-icon-primary m-0">*/}

                {/*                </span>*/}
                {/*                    <span className="d-block font-weight-bold font-size-h6 mt-2">Email</span>*/}
                {/*                </a>*/}
                {/*            </div>*/}
                {/*            <div className="col-6">*/}
                {/*                <a href="#" className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5">*/}
                {/*                <span className="svg-icon svg-icon-3x svg-icon-primary m-0">*/}

                {/*                </span>*/}
                {/*                    <span className="d-block font-weight-bold font-size-h6 mt-2">Settings</span>*/}
                {/*                </a>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-6">*/}
                {/*                <a href="#" className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5">*/}
                {/*                <span className="svg-icon svg-icon-3x svg-icon-primary m-0">*/}

                {/*                </span>*/}
                {/*                    <span className="d-block font-weight-bold font-size-h6 mt-2">Orders</span>*/}
                {/*                </a>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div id="kt_quick_actions2" className="offcanvas offcanvas-left p-10">*/}
                {/*    <div className="offcanvas-header d-flex align-items-center justify-content-between pb-10">*/}
                {/*        <h3 className="font-weight-bold m-0">Quick Actions*/}
                {/*            <small className="text-muted font-size-sm ml-2">finance &amp; reports</small></h3>*/}
                {/*        <a href="#" className="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_actions_close">*/}
                {/*            <i className="ki ki-close icon-xs text-muted"></i>*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*    <div className="offcanvas-content pr-5 mr-n5">*/}
                {/*        <div className="row gutter-b">*/}
                {/*            <div className="col-6">*/}
                {/*                <a href="#" className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5">*/}
                {/*                <span className="svg-icon svg-icon-3x svg-icon-primary m-0">*/}

                {/*                </span>*/}
                {/*                    <span className="d-block font-weight-bold font-size-h6 mt-2">Accounting</span>*/}
                {/*                </a>*/}
                {/*            </div>*/}
                {/*            <div className="col-6">*/}
                {/*                <a href="#" className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5">*/}
                {/*                <span className="svg-icon svg-icon-3x svg-icon-primary m-0">*/}

                {/*                </span>*/}
                {/*                    <span className="d-block font-weight-bold font-size-h6 mt-2">Members</span>*/}
                {/*                </a>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="row gutter-b">*/}
                {/*            <div className="col-6">*/}
                {/*                <a href="#" className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5">*/}
                {/*                <span className="svg-icon svg-icon-3x svg-icon-primary m-0">*/}

                {/*                </span>*/}
                {/*                    <span className="d-block font-weight-bold font-size-h6 mt-2">Projects</span>*/}
                {/*                </a>*/}
                {/*            </div>*/}
                {/*            <div className="col-6">*/}
                {/*                <a href="#" className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5">*/}
                {/*                <span className="svg-icon svg-icon-3x svg-icon-primary m-0">*/}

                {/*                </span>*/}
                {/*                    <span className="d-block font-weight-bold font-size-h6 mt-2">Customers</span>*/}
                {/*                </a>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="row gutter-b">*/}
                {/*            <div className="col-6">*/}
                {/*                <a href="#" className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5">*/}
                {/*                <span className="svg-icon svg-icon-3x svg-icon-primary m-0">*/}

                {/*                </span>*/}
                {/*                    <span className="d-block font-weight-bold font-size-h6 mt-2">Email</span>*/}
                {/*                </a>*/}
                {/*            </div>*/}
                {/*            <div className="col-6">*/}
                {/*                <a href="#" className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5">*/}
                {/*                <span className="svg-icon svg-icon-3x svg-icon-primary m-0">*/}

                {/*                </span>*/}
                {/*                    <span className="d-block font-weight-bold font-size-h6 mt-2">Settings</span>*/}
                {/*                </a>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-6">*/}
                {/*                <a href="#" className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5">*/}
                {/*                <span className="svg-icon svg-icon-3x svg-icon-primary m-0">*/}

                {/*                </span>*/}
                {/*                    <span className="d-block font-weight-bold font-size-h6 mt-2">Orders</span>*/}
                {/*                </a>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div id="kt_quick_panel" className="offcanvas offcanvas-left pt-5 pb-10">*/}
                {/*    <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">*/}
                {/*        <ul className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10" role="tablist">*/}
                {/*            <li className="nav-item">*/}
                {/*                <a className="nav-link active" data-toggle="tab" href="#kt_quick_panel_logs">Audit Logs</a>*/}
                {/*            </li>*/}
                {/*            <li className="nav-item">*/}
                {/*                <a className="nav-link" data-toggle="tab" href="#kt_quick_panel_notifications">Notifications</a>*/}
                {/*            </li>*/}
                {/*            <li className="nav-item">*/}
                {/*                <a className="nav-link" data-toggle="tab" href="#kt_quick_panel_settings">Settings</a>*/}
                {/*            </li>*/}
                {/*        </ul>*/}
                {/*        <div className="offcanvas-close mt-n1 pr-5">*/}
                {/*            <a href="#" className="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close">*/}
                {/*                <i className="ki ki-close icon-xs text-muted"></i>*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="offcanvas-content px-10">*/}
                {/*        <div className="tab-content">*/}
                {/*            <div className="tab-pane fade show pt-3 pr-5 mr-n5 active" id="kt_quick_panel_logs" role="tabpanel">*/}
                {/*                <div className="mb-15">*/}
                {/*                    <h5 className="font-weight-bold mb-5">System Messages</h5>*/}
                {/*                    <div className="d-flex align-items-center flex-wrap mb-5">*/}
                {/*                        <div className="symbol symbol-50 symbol-light mr-5">*/}
                {/*                        <span className="symbol-label">*/}
                {/*                            <img src="assets/media/svg/misc/006-plurk.svg" className="h-50 align-self-center" alt="" />*/}
                {/*                        </span>*/}
                {/*                        </div>*/}
                {/*                        <div className="d-flex flex-column flex-grow-1 mr-2">*/}
                {/*                            <a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Top Authors</a>*/}
                {/*                            <span className="text-muted font-weight-bold">Most Successful Fellas</span>*/}
                {/*                        </div>*/}
                {/*                        <span className="btn btn-sm btn-light font-weight-bolder py-1 my-lg-0 my-2 text-dark-50">+82$</span>*/}
                {/*                    </div>*/}
                {/*                    <div className="d-flex align-items-center flex-wrap mb-5">*/}
                {/*                        <div className="symbol symbol-50 symbol-light mr-5">*/}
                {/*                        <span className="symbol-label">*/}
                {/*                            <img src="assets/media/svg/misc/015-telegram.svg" className="h-50 align-self-center" alt="" />*/}
                {/*                        </span>*/}
                {/*                        </div>*/}
                {/*                        <div className="d-flex flex-column flex-grow-1 mr-2">*/}
                {/*                            <a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Popular Authors</a>*/}
                {/*                            <span className="text-muted font-weight-bold">Most Successful Fellas</span>*/}
                {/*                        </div>*/}
                {/*                        <span className="btn btn-sm btn-light font-weight-bolder my-lg-0 my-2 py-1 text-dark-50">+280$</span>*/}
                {/*                    </div>*/}
                {/*                    <div className="d-flex align-items-center flex-wrap mb-5">*/}
                {/*                        <div className="symbol symbol-50 symbol-light mr-5">*/}
                {/*                        <span className="symbol-label">*/}
                {/*                            <img src="assets/media/svg/misc/003-puzzle.svg" className="h-50 align-self-center" alt="" />*/}
                {/*                        </span>*/}
                {/*                        </div>*/}
                {/*                        <div className="d-flex flex-column flex-grow-1 mr-2">*/}
                {/*                            <a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">New Users</a>*/}
                {/*                            <span className="text-muted font-weight-bold">Most Successful Fellas</span>*/}
                {/*                        </div>*/}
                {/*                        <span className="btn btn-sm btn-light font-weight-bolder my-lg-0 my-2 py-1 text-dark-50">+4500$</span>*/}
                {/*                    </div>*/}
                {/*                    <div className="d-flex align-items-center flex-wrap mb-5">*/}
                {/*                        <div className="symbol symbol-50 symbol-light mr-5">*/}
                {/*                        <span className="symbol-label">*/}
                {/*                            <img src="assets/media/svg/misc/005-bebo.svg" className="h-50 align-self-center" alt="" />*/}
                {/*                        </span>*/}
                {/*                        </div>*/}
                {/*                        <div className="d-flex flex-column flex-grow-1 mr-2">*/}
                {/*                            <a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Active Customers</a>*/}
                {/*                            <span className="text-muted font-weight-bold">Most Successful Fellas</span>*/}
                {/*                        </div>*/}
                {/*                        <span className="btn btn-sm btn-light font-weight-bolder my-lg-0 my-2 py-1 text-dark-50">+4500$</span>*/}
                {/*                    </div>*/}
                {/*                    <div className="d-flex align-items-center flex-wrap">*/}
                {/*                        <div className="symbol symbol-50 symbol-light mr-5">*/}
                {/*                        <span className="symbol-label">*/}
                {/*                            <img src="assets/media/svg/misc/014-kickstarter.svg" className="h-50 align-self-center" alt="" />*/}
                {/*                        </span>*/}
                {/*                        </div>*/}
                {/*                        <div className="d-flex flex-column flex-grow-1 mr-2">*/}
                {/*                            <a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1">Bestseller Theme</a>*/}
                {/*                            <span className="text-muted font-weight-bold">Most Successful Fellas</span>*/}
                {/*                        </div>*/}
                {/*                        <span className="btn btn-sm btn-light font-weight-bolder my-lg-0 my-2 py-1 text-dark-50">+4500$</span>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*                <div className="mb-5">*/}
                {/*                    <h5 className="font-weight-bold mb-5">Notifications</h5>*/}

                {/*                    <div className="d-flex align-items-center bg-light-warning rounded p-5 mb-5">*/}
                {/*                    <span className="svg-icon svg-icon-warning mr-5">*/}
                {/*                        <span className="svg-icon svg-icon-lg">*/}

                {/*                        </span>*/}
                {/*                    </span>*/}
                {/*                        <div className="d-flex flex-column flex-grow-1 mr-2">*/}
                {/*                            <a href="#" className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1">Another purpose persuade</a>*/}
                {/*                            <span className="text-muted font-size-sm">Due in 2 Days</span>*/}
                {/*                        </div>*/}
                {/*                        <span className="font-weight-bolder text-warning py-1 font-size-lg">+28%</span>*/}
                {/*                    </div>*/}
                {/*                    <div className="d-flex align-items-center bg-light-success rounded p-5 mb-5">*/}
                {/*                    <span className="svg-icon svg-icon-success mr-5">*/}
                {/*                        <span className="svg-icon svg-icon-lg">*/}

                {/*                        </span>*/}
                {/*                    </span>*/}
                {/*                        <div className="d-flex flex-column flex-grow-1 mr-2">*/}
                {/*                            <a href="#" className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1">Would be to people</a>*/}
                {/*                            <span className="text-muted font-size-sm">Due in 2 Days</span>*/}
                {/*                        </div>*/}
                {/*                        <span className="font-weight-bolder text-success py-1 font-size-lg">+50%</span>*/}
                {/*                    </div>*/}
                {/*                    <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">*/}
                {/*                    <span className="svg-icon svg-icon-danger mr-5">*/}
                {/*                        <span className="svg-icon svg-icon-lg">*/}

                {/*                        </span>*/}
                {/*                    </span>*/}
                {/*                        <div className="d-flex flex-column flex-grow-1 mr-2">*/}
                {/*                            <a href="#" className="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1">Purpose would be to persuade</a>*/}
                {/*                            <span className="text-muted font-size-sm">Due in 2 Days</span>*/}
                {/*                        </div>*/}
                {/*                        <span className="font-weight-bolder text-danger py-1 font-size-lg">-27%</span>*/}
                {/*                    </div>*/}
                {/*                    <div className="d-flex align-items-center bg-light-info rounded p-5">*/}
                {/*                    <span className="svg-icon svg-icon-info mr-5">*/}
                {/*                        <span className="svg-icon svg-icon-lg">*/}

                {/*                        </span>*/}
                {/*                    </span>*/}
                {/*                        <div className="d-flex flex-column flex-grow-1 mr-2">*/}
                {/*                            <a href="#" className="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1">The best product</a>*/}
                {/*                            <span className="text-muted font-size-sm">Due in 2 Days</span>*/}
                {/*                        </div>*/}
                {/*                        <span className="font-weight-bolder text-info py-1 font-size-lg">+8%</span>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="tab-pane fade pt-2 pr-5 mr-n5" id="kt_quick_panel_notifications" role="tabpanel">*/}
                {/*                <div className="navi navi-icon-circle navi-spacer-x-0">*/}
                {/*                    <a href="#" className="navi-item">*/}
                {/*                        <div className="navi-link rounded">*/}
                {/*                            <div className="symbol symbol-50 mr-3">*/}
                {/*                                <div className="symbol-label">*/}
                {/*                                    <i className="flaticon-bell text-success icon-lg"></i>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                            <div className="navi-text">*/}
                {/*                                <div className="font-weight-bold font-size-lg">5 new user generated report</div>*/}
                {/*                                <div className="text-muted">Reports based on sales</div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </a>*/}
                {/*                    <a href="#" className="navi-item">*/}
                {/*                        <div className="navi-link rounded">*/}
                {/*                            <div className="symbol symbol-50 mr-3">*/}
                {/*                                <div className="symbol-label">*/}
                {/*                                    <i className="flaticon2-box text-danger icon-lg"></i>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                            <div className="navi-text">*/}
                {/*                                <div className="font-weight-bold font-size-lg">2 new items submited</div>*/}
                {/*                                <div className="text-muted">by Grog John</div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </a>*/}
                {/*                    <a href="#" className="navi-item">*/}
                {/*                        <div className="navi-link rounded">*/}
                {/*                            <div className="symbol symbol-50 mr-3">*/}
                {/*                                <div className="symbol-label">*/}
                {/*                                    <i className="flaticon-psd text-primary icon-lg"></i>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                            <div className="navi-text">*/}
                {/*                                <div className="font-weight-bold font-size-lg">79 PSD files generated</div>*/}
                {/*                                <div className="text-muted">Reports based on sales</div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </a>*/}
                {/*                    <a href="#" className="navi-item">*/}
                {/*                        <div className="navi-link rounded">*/}
                {/*                            <div className="symbol symbol-50 mr-3">*/}
                {/*                                <div className="symbol-label">*/}
                {/*                                    <i className="flaticon2-supermarket text-warning icon-lg"></i>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                            <div className="navi-text">*/}
                {/*                                <div className="font-weight-bold font-size-lg">$2900 worth producucts sold</div>*/}
                {/*                                <div className="text-muted">Total 234 items</div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </a>*/}
                {/*                    <a href="#" className="navi-item">*/}
                {/*                        <div className="navi-link rounded">*/}
                {/*                            <div className="symbol symbol-50 mr-3">*/}
                {/*                                <div className="symbol-label">*/}
                {/*                                    <i className="flaticon-paper-plane-1 text-success icon-lg"></i>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                            <div className="navi-text">*/}
                {/*                                <div className="font-weight-bold font-size-lg">4.5h-avarage response time</div>*/}
                {/*                                <div className="text-muted">Fostest is Barry</div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </a>*/}
                {/*                    <a href="#" className="navi-item">*/}
                {/*                        <div className="navi-link rounded">*/}
                {/*                            <div className="symbol symbol-50 mr-3">*/}
                {/*                                <div className="symbol-label">*/}
                {/*                                    <i className="flaticon-safe-shield-protection text-danger icon-lg"></i>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                            <div className="navi-text">*/}
                {/*                                <div className="font-weight-bold font-size-lg">3 Defence alerts</div>*/}
                {/*                                <div className="text-muted">40% less alerts thar last week</div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </a>*/}
                {/*                    <a href="#" className="navi-item">*/}
                {/*                        <div className="navi-link rounded">*/}
                {/*                            <div className="symbol symbol-50 mr-3">*/}
                {/*                                <div className="symbol-label">*/}
                {/*                                    <i className="flaticon-notepad text-primary icon-lg"></i>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                            <div className="navi-text">*/}
                {/*                                <div className="font-weight-bold font-size-lg">Avarage 4 blog posts per author</div>*/}
                {/*                                <div className="text-muted">Most posted 12 time</div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </a>*/}
                {/*                    <a href="#" className="navi-item">*/}
                {/*                        <div className="navi-link rounded">*/}
                {/*                            <div className="symbol symbol-50 mr-3">*/}
                {/*                                <div className="symbol-label">*/}
                {/*                                    <i className="flaticon-users-1 text-warning icon-lg"></i>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                            <div className="navi-text">*/}
                {/*                                <div className="font-weight-bold font-size-lg">16 authors joined last week</div>*/}
                {/*                                <div className="text-muted">9 photodrapehrs, 7 designer</div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </a>*/}
                {/*                    <a href="#" className="navi-item">*/}
                {/*                        <div className="navi-link rounded">*/}
                {/*                            <div className="symbol symbol-50 mr-3">*/}
                {/*                                <div className="symbol-label">*/}
                {/*                                    <i className="flaticon2-box text-info icon-lg"></i>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                            <div className="navi-text">*/}
                {/*                                <div className="font-weight-bold font-size-lg">2 new items have been submited</div>*/}
                {/*                                <div className="text-muted">by Grog John</div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </a>*/}
                {/*                    <a href="#" className="navi-item">*/}
                {/*                        <div className="navi-link rounded">*/}
                {/*                            <div className="symbol symbol-50 mr-3">*/}
                {/*                                <div className="symbol-label">*/}
                {/*                                    <i className="flaticon2-download text-success icon-lg"></i>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                            <div className="navi-text">*/}
                {/*                                <div className="font-weight-bold font-size-lg">2.8 GB-total downloads size</div>*/}
                {/*                                <div className="text-muted">Mostly PSD end AL concepts</div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </a>*/}
                {/*                    <a href="#" className="navi-item">*/}
                {/*                        <div className="navi-link rounded">*/}
                {/*                            <div className="symbol symbol-50 mr-3">*/}
                {/*                                <div className="symbol-label">*/}
                {/*                                    <i className="flaticon2-supermarket text-danger icon-lg"></i>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                            <div className="navi-text">*/}
                {/*                                <div className="font-weight-bold font-size-lg">$2900 worth producucts sold</div>*/}
                {/*                                <div className="text-muted">Total 234 items</div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </a>*/}
                {/*                    <a href="#" className="navi-item">*/}
                {/*                        <div className="navi-link rounded">*/}
                {/*                            <div className="symbol symbol-50 mr-3">*/}
                {/*                                <div className="symbol-label">*/}
                {/*                                    <i className="flaticon-bell text-primary icon-lg"></i>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                            <div className="navi-text">*/}
                {/*                                <div className="font-weight-bold font-size-lg">7 new user generated report</div>*/}
                {/*                                <div className="text-muted">Reports based on sales</div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </a>*/}
                {/*                    <a href="#" className="navi-item">*/}
                {/*                        <div className="navi-link rounded">*/}
                {/*                            <div className="symbol symbol-50 mr-3">*/}
                {/*                                <div className="symbol-label">*/}
                {/*                                    <i className="flaticon-paper-plane-1 text-success icon-lg"></i>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                            <div className="navi-text">*/}
                {/*                                <div className="font-weight-bold font-size-lg">4.5h-avarage response time</div>*/}
                {/*                                <div className="text-muted">Fostest is Barry</div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </a>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="tab-pane fade pt-3 pr-5 mr-n5" id="kt_quick_panel_settings" role="tabpanel">*/}
                {/*                <form className="form">*/}
                {/*                    <div>*/}
                {/*                        <h5 className="font-weight-bold mb-3">Customer Care</h5>*/}
                {/*                        <div className="form-group mb-0 row align-items-center">*/}
                {/*                            <label className="col-8 col-form-label">Enable Notifications:</label>*/}
                {/*                            <div className="col-4 d-flex justify-content-end">*/}
                {/*                            <span className="switch switch-success switch-sm">*/}
                {/*                                <label>*/}
                {/*                                    <input type="checkbox" checked="checked" name="select" />*/}
                {/*                                    <span></span>*/}
                {/*                                </label>*/}
                {/*                            </span>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        <div className="form-group mb-0 row align-items-center">*/}
                {/*                            <label className="col-8 col-form-label">Enable Case Tracking:</label>*/}
                {/*                            <div className="col-4 d-flex justify-content-end">*/}
                {/*                            <span className="switch switch-success switch-sm">*/}
                {/*                                <label>*/}
                {/*                                    <input type="checkbox" name="quick_panel_notifications_2" />*/}
                {/*                                    <span></span>*/}
                {/*                                </label>*/}
                {/*                            </span>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        <div className="form-group mb-0 row align-items-center">*/}
                {/*                            <label className="col-8 col-form-label">Support Portal:</label>*/}
                {/*                            <div className="col-4 d-flex justify-content-end">*/}
                {/*                            <span className="switch switch-success switch-sm">*/}
                {/*                                <label>*/}
                {/*                                    <input type="checkbox" checked="checked" name="select" />*/}
                {/*                                    <span></span>*/}
                {/*                                </label>*/}
                {/*                            </span>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div className="separator separator-dashed my-6"></div>*/}
                {/*                    <div className="pt-2">*/}
                {/*                        <h5 className="font-weight-bold mb-3">Reports</h5>*/}
                {/*                        <div className="form-group mb-0 row align-items-center">*/}
                {/*                            <label className="col-8 col-form-label">Generate Reports:</label>*/}
                {/*                            <div className="col-4 d-flex justify-content-end">*/}
                {/*                            <span className="switch switch-sm switch-danger">*/}
                {/*                                <label>*/}
                {/*                                    <input type="checkbox" checked="checked" name="select" />*/}
                {/*                                    <span></span>*/}
                {/*                                </label>*/}
                {/*                            </span>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        <div className="form-group mb-0 row align-items-center">*/}
                {/*                            <label className="col-8 col-form-label">Enable Report Export:</label>*/}
                {/*                            <div className="col-4 d-flex justify-content-end">*/}
                {/*                            <span className="switch switch-sm switch-danger">*/}
                {/*                                <label>*/}
                {/*                                    <input type="checkbox" name="select" />*/}
                {/*                                    <span></span>*/}
                {/*                                </label>*/}
                {/*                            </span>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        <div className="form-group mb-0 row align-items-center">*/}
                {/*                            <label className="col-8 col-form-label">Allow Data Collection:</label>*/}
                {/*                            <div className="col-4 d-flex justify-content-end">*/}
                {/*                            <span className="switch switch-sm switch-danger">*/}
                {/*                                <label>*/}
                {/*                                    <input type="checkbox" checked="checked" name="select" />*/}
                {/*                                    <span></span>*/}
                {/*                                </label>*/}
                {/*                            </span>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div className="separator separator-dashed my-6"></div>*/}

                {/*                    <div className="pt-2">*/}
                {/*                        <h5 className="font-weight-bold mb-3">Memebers</h5>*/}
                {/*                        <div className="form-group mb-0 row align-items-center">*/}
                {/*                            <label className="col-8 col-form-label">Enable Member singup:</label>*/}
                {/*                            <div className="col-4 d-flex justify-content-end">*/}
                {/*                            <span className="switch switch-sm switch-primary">*/}
                {/*                                <label>*/}
                {/*                                    <input type="checkbox" checked="checked" name="select" />*/}
                {/*                                    <span></span>*/}
                {/*                                </label>*/}
                {/*                            </span>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        <div className="form-group mb-0 row align-items-center">*/}
                {/*                            <label className="col-8 col-form-label">Allow User Feedbacks:</label>*/}
                {/*                            <div className="col-4 d-flex justify-content-end">*/}
                {/*                            <span className="switch switch-sm switch-primary">*/}
                {/*                                <label>*/}
                {/*                                    <input type="checkbox" name="select" />*/}
                {/*                                    <span></span>*/}
                {/*                                </label>*/}
                {/*                            </span>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        <div className="form-group mb-0 row align-items-center">*/}
                {/*                            <label className="col-8 col-form-label">Enable Customer Portal:</label>*/}
                {/*                            <div className="col-4 d-flex justify-content-end">*/}
                {/*                            <span className="switch switch-sm switch-primary">*/}
                {/*                                <label>*/}
                {/*                                    <input type="checkbox" checked="checked" name="select" />*/}
                {/*                                    <span></span>*/}
                {/*                                </label>*/}
                {/*                            </span>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </form>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="modal modal-sticky modal-sticky-bottom-right" id="kt_chat_modal" role="dialog" data-backdrop="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">

                            <div className="card card-custom">

                                <div className="card-header align-items-center px-4 py-3">
                                    <div className="text-left flex-grow-1">

                                        <div className="dropdown dropdown-inline">
                                            <button type="button" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span className="svg-icon svg-icon-lg">

                                            </span>
                                            </button>
                                            <div className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-md">
                                                <ul className="navi navi-hover py-5">
                                                    <li className="navi-item">
                                                        <a href="#" className="navi-link">
                                                        <span className="navi-icon">
                                                            <i className="flaticon2-drop"></i>
                                                        </span>
                                                            <span className="navi-text">New Group</span>
                                                        </a>
                                                    </li>
                                                    <li className="navi-item">
                                                        <a href="#" className="navi-link">
                                                        <span className="navi-icon">
                                                            <i className="flaticon2-list-3"></i>
                                                        </span>
                                                            <span className="navi-text">Contacts</span>
                                                        </a>
                                                    </li>
                                                    <li className="navi-item">
                                                        <a href="#" className="navi-link">
                                                        <span className="navi-icon">
                                                            <i className="flaticon2-rocket-1"></i>
                                                        </span>
                                                            <span className="navi-text">Groups</span>
                                                            <span className="navi-link-badge">
                                                            <span className="label label-light-primary label-inline font-weight-bold">new</span>
                                                        </span>
                                                        </a>
                                                    </li>
                                                    <li className="navi-item">
                                                        <a href="#" className="navi-link">
                                                        <span className="navi-icon">
                                                            <i className="flaticon2-bell-2"></i>
                                                        </span>
                                                            <span className="navi-text">Calls</span>
                                                        </a>
                                                    </li>
                                                    <li className="navi-item">
                                                        <a href="#" className="navi-link">
                                                        <span className="navi-icon">
                                                            <i className="flaticon2-gear"></i>
                                                        </span>
                                                            <span className="navi-text">Settings</span>
                                                        </a>
                                                    </li>
                                                    <li className="navi-separator my-3"></li>
                                                    <li className="navi-item">
                                                        <a href="#" className="navi-link">
                                                        <span className="navi-icon">
                                                            <i className="flaticon2-magnifier-tool"></i>
                                                        </span>
                                                            <span className="navi-text">Help</span>
                                                        </a>
                                                    </li>
                                                    <li className="navi-item">
                                                        <a href="#" className="navi-link">
                                                        <span className="navi-icon">
                                                            <i className="flaticon2-bell-2"></i>
                                                        </span>
                                                            <span className="navi-text">Privacy</span>
                                                            <span className="navi-link-badge">
                                                            <span className="label label-light-danger label-rounded font-weight-bold">5</span>
                                                        </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center flex-grow-1">
                                        <div className="text-dark-75 font-weight-bold font-size-h5">Matt Pears</div>
                                        <div>
                                            <span className="label label-dot label-success"></span>
                                            <span className="font-weight-bold text-muted font-size-sm">Active</span>
                                        </div>
                                    </div>
                                    <div className="text-right flex-grow-1">
                                        <button type="button" className="btn btn-clean btn-sm btn-icon btn-icon-md" data-dismiss="modal">
                                            <i className="ki ki-close icon-1x"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="scroll scroll-pull" data-height="375" data-mobile-height="300">
                                        <div className="messages">
                                            <div className="d-flex flex-column mb-5 align-items-start">
                                                <div className="d-flex align-items-center">
                                                    <div className="symbol symbol-circle symbol-40 mr-3">
                                                        <img alt="Pic" src="assets/media/users/300_12.jpg" />
                                                    </div>
                                                    <div>
                                                        <a href="#" className="text-dark-75 text-hover-primary font-weight-bold font-size-h6">Matt Pears</a>
                                                        <span className="text-muted font-size-sm">2 Hours</span>
                                                    </div>
                                                </div>
                                                <div className="mt-2 rounded p-5 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left max-w-400px">How likely are you to recommend our company to your friends and family?</div>
                                            </div>
                                            <div className="d-flex flex-column mb-5 align-items-end">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <span className="text-muted font-size-sm">3 minutes</span>
                                                        <a href="#" className="text-dark-75 text-hover-primary font-weight-bold font-size-h6">You</a>
                                                    </div>
                                                    <div className="symbol symbol-circle symbol-40 ml-3">
                                                        <img alt="Pic" src="assets/media/users/300_21.jpg" />
                                                    </div>
                                                </div>
                                                <div className="mt-2 rounded p-5 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-right max-w-400px">Hey there, we’re just writing to let you know that you’ve been subscribed to a repository on GitHub.</div>
                                            </div>
                                            <div className="d-flex flex-column mb-5 align-items-start">
                                                <div className="d-flex align-items-center">
                                                    <div className="symbol symbol-circle symbol-40 mr-3">
                                                        <img alt="Pic" src="assets/media/users/300_21.jpg" />
                                                    </div>
                                                    <div>
                                                        <a href="#" className="text-dark-75 text-hover-primary font-weight-bold font-size-h6">Matt Pears</a>
                                                        <span className="text-muted font-size-sm">40 seconds</span>
                                                    </div>
                                                </div>
                                                <div className="mt-2 rounded p-5 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left max-w-400px">Ok, Understood!</div>
                                            </div>
                                            <div className="d-flex flex-column mb-5 align-items-end">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <span className="text-muted font-size-sm">Just now</span>
                                                        <a href="#" className="text-dark-75 text-hover-primary font-weight-bold font-size-h6">You</a>
                                                    </div>
                                                    <div className="symbol symbol-circle symbol-40 ml-3">
                                                        <img alt="Pic" src="assets/media/users/300_21.jpg" />
                                                    </div>
                                                </div>
                                                <div className="mt-2 rounded p-5 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-right max-w-400px">You’ll receive notifications for all issues, pull requests!</div>
                                            </div>
                                            <div className="d-flex flex-column mb-5 align-items-start">
                                                <div className="d-flex align-items-center">
                                                    <div className="symbol symbol-circle symbol-40 mr-3">
                                                        <img alt="Pic" src="assets/media/users/300_12.jpg" />
                                                    </div>
                                                    <div>
                                                        <a href="#" className="text-dark-75 text-hover-primary font-weight-bold font-size-h6">Matt Pears</a>
                                                        <span className="text-muted font-size-sm">40 seconds</span>
                                                    </div>
                                                </div>
                                                <div className="mt-2 rounded p-5 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left max-w-400px">You can unwatch this repository immediately by clicking here:
                                                    <a href="#">https://github.com</a></div>
                                            </div>
                                            <div className="d-flex flex-column mb-5 align-items-end">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <span className="text-muted font-size-sm">Just now</span>
                                                        <a href="#" className="text-dark-75 text-hover-primary font-weight-bold font-size-h6">You</a>
                                                    </div>
                                                    <div className="symbol symbol-circle symbol-40 ml-3">
                                                        <img alt="Pic" src="assets/media/users/300_21.jpg" />
                                                    </div>
                                                </div>
                                                <div className="mt-2 rounded p-5 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-right max-w-400px">Discover what students who viewed Learn Figma - UI/UX Design. Essential Training also viewed</div>
                                            </div>
                                            <div className="d-flex flex-column mb-5 align-items-start">
                                                <div className="d-flex align-items-center">
                                                    <div className="symbol symbol-circle symbol-40 mr-3">
                                                        <img alt="Pic" src="assets/media/users/300_12.jpg" />
                                                    </div>
                                                    <div>
                                                        <a href="#" className="text-dark-75 text-hover-primary font-weight-bold font-size-h6">Matt Pears</a>
                                                        <span className="text-muted font-size-sm">40 seconds</span>
                                                    </div>
                                                </div>
                                                <div className="mt-2 rounded p-5 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left max-w-400px">Most purchased Business courses during this sale!</div>
                                            </div>
                                            <div className="d-flex flex-column mb-5 align-items-end">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <span className="text-muted font-size-sm">Just now</span>
                                                        <a href="#" className="text-dark-75 text-hover-primary font-weight-bold font-size-h6">You</a>
                                                    </div>
                                                    <div className="symbol symbol-circle symbol-40 ml-3">
                                                        <img alt="Pic" src="assets/media/users/300_21.jpg" />
                                                    </div>
                                                </div>
                                                <div className="mt-2 rounded p-5 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-right max-w-400px">Company BBQ to celebrate the last quater achievements and goals. Food and drinks provided</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer align-items-center">
                                    <textarea className="form-control border-0 p-0" rows="2" placeholder="Type a message"></textarea>
                                    <div className="d-flex align-items-center justify-content-between mt-5">
                                        <div className="mr-3">
                                            <a href="#" className="btn btn-clean btn-icon btn-md mr-1">
                                                <i className="flaticon2-photograph icon-lg"></i>
                                            </a>
                                            <a href="#" className="btn btn-clean btn-icon btn-md">
                                                <i className="flaticon2-photo-camera icon-lg"></i>
                                            </a>
                                        </div>
                                        <div>
                                            <button type="button" className="btn btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6">Send</button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <div className="scrolltop" onClick={handleScrollTop}>
                    <span className="svg-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
                        <g fill="none">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <rect fill="#000000" opacity="0.3" x="11" y="10" width="2" height="10" rx="1" />
                            <path d="M6.70710678,12.7071068 C6.31658249,13.0976311 5.68341751,13.0976311 5.29289322,12.7071068 C4.90236893,12.3165825 4.90236893,11.6834175 5.29289322,11.2928932 L11.2928932,5.29289322 C11.6714722,4.91431428 12.2810586,4.90106866 12.6757246,5.26284586 L18.6757246,10.7628459 C19.0828436,11.1360383 19.1103465,11.7686056 18.7371541,12.1757246 C18.3639617,12.5828436 17.7313944,12.6103465 17.3242754,12.2371541 L12.0300757,7.38413782 L6.70710678,12.7071068 Z" fill="#000000" />
                        </g>
                    </svg>
                    </span>
                </div>
            </>) :
                <ComponentToRender CurrentModule={data.getmodule} SystemUrl={SystemUrl} />
            }
            <ToastContainer />
        </>
    )
}
export default Template
