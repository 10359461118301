import React, {useEffect,useState} from 'react'
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../config.js";
import {toast, ToastContainer} from "react-toastify";
import {Chart} from "react-google-charts";
import {trans} from "../../utils";
import CustomDataTable from "../CustomDataTable";
import ReactDOM from "react-dom";
import $ from "jquery";


const InventoryTakingReports = ({room_id,it_id,changesCount}) => {
    let history = useHistory();
    const params = useParams();
    const token = localStorage.getItem('token')

    const [data, setData] = useState([])
    const routeChange = path => {
        history.push(path);
    }
    useEffect(() => {
        axios.get(config.SystemUrl + `getCharsReport?room_space_id=${room_id}&inventory_taking_id=${it_id}`)
            .then(result => {
                setData(result.data.data)
            })
    },[changesCount])

    const AddAction = (taking_id,action) =>{
        var formData = new FormData();
        formData.append('taking_id',taking_id);
        formData.append('action',action);
        axios.post(config.SystemUrl +`TakingAddAction` , formData).then(response => {
            toast.success('Success');
            $("#missing,#mislocated").DataTable().ajax.reload()
        })
    }
    const columns = [
        {data:"id",title:"#"},
        {data:"room_space_item.assign_asset_item.reference",title:"Allocate Asset / Item ref."},
        {data:"room_space.room_space_name",title:"Current Room"},
        {data:"taking_by.name",title:"Person"},
        {data:"created_at",title:"Date"}
    ];
    const columns2 = [
        {data:"id",title:"#"},
        {data:"room_space_item.assign_asset_item.reference",title:"Allocate Asset / Item ref."},
        {data:"room_space.room_space_name",title:"Current Room"},
        {data:"taking_by.name",title:"Person"},
        {data:"created_at",title:"Date"},
        {data:"action",title:"Action"},
        {title:"Action"}
    ];
    const columns3 = [
        {data:"id",title:"#"},
        {data:"room_space_item.assign_asset_item.reference",title:"Allocate Asset / Item ref."},
        {data:"room_space_item.room.room_space_name",title:"Last Room"},
        {data:"room_space.room_space_name",title:"Current Room"},
        {data:"taking_by.name",title:"Person"},
        {data:"created_at",title:"Date"},
        {data:"action",title:"Action"},
        {title:"Action"}
    ];
    const columnsDefs = [
        {

            targets: -1,
            orderable: false,
            className: "all",
            width: "300px",
            createdCell: (td, cellData, full) => ReactDOM.render(
                full.action == null &&
               <>
                   <button
                    className="btn btn-info btn-sm"
                    onClick={() => AddAction(full.id,'reallocate')}
                    style={{width: "94%"}}>
                    {trans('Reallocate to current room')}
                    </button>
                   <button
                       className="btn btn-warning btn-sm"
                       onClick={() => AddAction(full.id,'restore')}
                       style={{width: "94%"}}>
                       {trans('Return to old room')}
                   </button>
               </>, td)

        },
        {
            targets: 2,
            createdCell: (td, cellData, full) => ReactDOM.render(

                <>
                    {
                        full.old_room_space == null  ?
                        <span>{cellData}</span>
                        : <span>{full.old_room_space.room_space_name}</span>
                    }
                </>, td)

        },
        ];
    const columnsDefs2 = [
        {

            targets: -1,
            orderable: false,
            className: "all",
            width: "300px",
            createdCell: (td, cellData, full) => ReactDOM.render(
                full.action == null &&
                <>
                    <button
                        className="btn btn-success btn-sm"
                        onClick={() => AddAction(full.id,'found')}
                        style={{width: "94%"}}>
                        {trans('Found')}
                    </button>
                </>, td)

        },

    ];

    return (
        <>
            <div className="card mb-5 mb-xl-8">
                <div className="card-body pt-3">
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <Chart
                                    chartType="BarChart"
                                    loader={<div>Loading Chart</div>}
                                    data={data.status_charts}
                                    options={{
                                        title: 'Inventory Taking Status',
                                        legend: { position: 'none' },
                                    }}
                                    rootProps={{ 'data-testid': '1' }}
                                />
                        </div>
                        <div className="col-md-6">
                            <Chart
                                height={'270px'}
                                chartType="PieChart"
                                loader={<div>Loading Chart</div>}
                                data={data.total_scanned_charts}
                                options={{
                                    title: 'Total Asset/Item Scanned',
                                    legend: { position: 'none' },
                                }}
                                rootProps={{ 'data-testid': '1' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">

                <li className="nav-item">
                    <a className="nav-link text-active-primary pb-4 active" data-toggle="tab"
                       href="#kt_allocate_tab">
                        {trans('Asset / Item Found')}
                    </a>
                </li>

                <li className="nav-item">
                    <a className="nav-link text-active-primary pb-4" data-toggle="tab"
                       href="#kt_techincal_tab">
                        {trans('Asset / Item Not Found')}
                    </a>
                </li>

                <li className="nav-item">
                    <a className="nav-link text-active-primary pb-4" data-toggle="tab"
                       href="#kt_misallocated_tab">
                        {trans('Asset Misallocated')}
                    </a>
                </li>

            </ul>
            <div className="tab-content  mb-5" id="myTabContent">
                <div className="tab-pane fade active show" id="kt_allocate_tab" role="tabpanel">
                    <div className="card">
                        <div className="card-header card-header-stretch">
                            <div className="card-title d-flex align-items-center">
                                <h4 className="mb-1">{trans('Asset / Item Found')}</h4>
                            </div>
                        </div>
                        <div className="card-body pt-0">
                            <div className="card-body p-9">
                                <CustomDataTable columns={columns} url={"getReportDatatable?inventory_taking_id="+it_id+"&status=exists"} table_id="exists" FilterParams={['room_space_id']}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="kt_techincal_tab" role="tabpanel">
                    <div className="card">
                        <div className="card-header card-header-stretch">
                            <div className="card-title d-flex align-items-center">
                                <h4 className="mb-1">{trans('Asset / Item Not Found')}</h4>
                            </div>
                        </div>
                        <div className="card-body pt-0">
                            <div className="card-body p-9">
                                <CustomDataTable columns={columns2} columnDefs={columnsDefs2} url={"getReportDatatable?inventory_taking_id="+it_id+"&status=missing"} table_id="missing" FilterParams={['room_space_id']}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="kt_misallocated_tab" role="tabpanel">
                    <div className="card">
                        <div className="card-header card-header-stretch">
                            <div className="card-title d-flex align-items-center">
                                <h4 className="mb-1">{trans('Asset Misallocated')}</h4>
                            </div>
                        </div>
                        <div className="card-body pt-0">
                            <div className="card-body p-9">
                                <CustomDataTable columns={columns3} columnDefs={columnsDefs} url={"getReportDatatable?inventory_taking_id="+it_id+"&status=mislocated"} table_id="mislocated" FilterParams={['room_space_id']}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
export default InventoryTakingReports;
