import React from 'react';
import ReactDOM from 'react-dom';
import App, {SystemUrl} from './App';
import * as serviceWorker from './serviceWorker';
import axios from "axios";
import './index.css'
import config from './config.js'


import LogoutRequestHandler from "./Components/Auth/LogoutRequestHandler";
import NotSubscribed from './NotSubscribed';
import ExpiredSubscription from './ExpiredSubscription';


axios.defaults.withCredentials = true;
    if(config.hasOwnProperty("allowRequestIntercepts")){
    if(config.allowRequestIntercepts)
    axios.interceptors.response.use(
        function(response) {
            // Call was successful, don't do anything special.
            return response;
        },
        function (error) {
            if(error.config.url.includes(config.BackendUrl) && typeof error.response !== "undefined"){
            switch (error.response.status) {
                case 401: // Not logged in
                case 419: // Session expired
                case 503: // Down for maintenance
                    // Bounce the user to the login screen with a redirect back
                    LogoutRequestHandler();
                    return Promise.reject(error);
                case 500:
                    // alert('Oops, something went wrong!  The team have been notified.');
                    return Promise.reject(error);
                default:
                    // Allow individual requests to handle other errors
                    return Promise.reject(error);
            }
            }else {
                return Promise.reject(error);

            }
        });
}

async function fetchData() {
    const saasCheckUrl = `${config.SaasUrl}/v1/tenant_data/${config.extractedSubdomain}`;  // Unique key for the data
  // Fetch data from server if not cached
  try {
      const response = await fetch(saasCheckUrl);
      const data = await response.json();

      // console.log("Data from server:", data);
    // Cache the fetched data
    return data;
  } catch (error) {
    console.error("Error fetching data from server:", error);
    throw error; // Re-throw error for handling at calling code
  }
}
if(config.saas){
// Usage example
 fetchData()
  .then(data => {
    if (data.isTenantSubscribed) {
        ReactDOM.render(<App/>, document.getElementById('root'));
    } else if (data.wasTenantSubscribed) {
        ReactDOM.render(<ExpiredSubscription/>, document.getElementById('root'));
    } else {
        ReactDOM.render(<NotSubscribed/>, document.getElementById('root'));
    }
  })
  .catch(error => {
    // problem occurred on server
    ReactDOM.render(<NotSubscribed/>, document.getElementById('root'));
  });
}else{
        ReactDOM.render(<App/>, document.getElementById('root'));

}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
