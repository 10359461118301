import React, {useEffect, useState} from 'react'
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../config.js";
import {trans} from "../../utils";

const Index = (ComponentToRenderData) => {
    let history = useHistory();
    const params = useParams();
    const SystemUrl = ComponentToRenderData.SystemUrl;
    const token = localStorage.getItem('token')
    const [data, setData ] = useState([]);
    const search = window.location.search;
    let URLParams = new URLSearchParams(search);
    const routeChange = path => {
        history.push(path);
    }

    useEffect(() => {
        axios(config.SystemUrl + `SupplierManufacturer/${params.id}?include=status_data,rank,contacts,documentations`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => setData(result.data.data));
    }, [])


    return (
            <div className="card card-custom mb-5 p-6 bg-gray-100">
                <div className="row">
                    <div className="col-md-4">
                        <div className="card card-custom bg-light-primary mb-5">
                            <span  className="p-20 text-center text-primary">
                                <h4> {trans(data.name)}</h4>
                            </span>
                            {data.type &&
                                <span className="p-2 bg-light text-center">
                                    <h4>{trans(data.type)}</h4>
                                </span>
                            }
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade active show" id="kt_techincal_tab" role="tabpanel">
                                <div className="card card-custom">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h4 className="mb-1">{trans('Supplier/Manufacturer/Vendor Details')}</h4>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <div className="fs-6">
                                            { data.status_data &&
                                            <>
                                                <div className="fw-bolder mt-3">{trans('Status')}</div>
                                                <div className="text-gray-600">{trans(data.status_data.title)}</div>
                                            </>
                                            }
                                            { data.rank &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Rank')}</div>
                                                <div className="text-gray-600">{trans(data.rank.title)}</div>
                                            </>
                                            }
                                            { data.address &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Address')}</div>
                                                <div className="text-gray-600">{trans(data.address)}</div>
                                            </>
                                            }
                                            { data.website &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Website')}</div>
                                                <div className="text-gray-600">{trans(data.website)}</div>
                                            </>
                                            }
                                            { data.reference &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Code')}</div>
                                                <div className="text-gray-600">{trans(data.reference)}</div>
                                            </>
                                            }
                                            { data.deliverables &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Deliverables')}</div>
                                                <div className="text-gray-600">{trans(data.deliverables)}</div>
                                            </>
                                            }
                                            { data.call_center_contact &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Call Center Contact')}</div>
                                                <div className="text-gray-600">{trans(data.call_center_contact)}</div>
                                            </>
                                            }
                                            { data.call_center_email &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Call Center Email')}</div>
                                                <div className="text-gray-600">{trans(data.call_center_email)}</div>
                                            </>
                                            }
                                            { data.contacts && data.contacts.length > 0 ?
                                                <>
                                                    <table className="table mt-5">
                                                        <thead>
                                                        <tr>
                                                            <th scope="col">Contact</th>
                                                            <th scope="col">Phone</th>
                                                            <th scope="col">Email</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <>
                                                            {data.contacts.map((contact) =>
                                                                <tr>
                                                                    <th>{contact.contact}</th>
                                                                    <td>{contact.phone}</td>
                                                                    <td>{contact.email}</td>
                                                                </tr>
                                                            )}
                                                        </>
                                                        </tbody>
                                                    </table>
                                                </>  : ""
                                            }
                                            { data.documentations && data.documentations.length > 0 ?
                                                <>
                                                    <table className="table mt-5">
                                                        <thead>
                                                        <tr>
                                                            <th scope="col">Document Group</th>
                                                            <th scope="col">Document Type</th>
                                                            <th scope="col">Documentation</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <>
                                                            {data.documentations.map((documentation) =>
                                                                <tr>
                                                                    <th>{documentation.parent_category}</th>
                                                                    <td>{documentation.child_category}</td>
                                                                    <td>
                                                                       <a
                                                                           target="_blank"
                                                                           href={documentation.file}
                                                                       > Preview Document </a>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </>
                                                        </tbody>
                                                    </table>
                                                </> : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}
export default Index;
