import React, { useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Template from "./Components/Template";
import config from './config.js'
import { getCustomModules } from './Components/utils';
import { Provider } from "react-redux";
import store from "./store";
import Login from "./Components/Auth/Login";
import ForgetPassword from './Components/Auth/ForgetPassword'
import ResetPassword from './Components/Auth/ResetPassword'
import RegisterAsStaff from './Components/Auth/RegisterAsStaff'
import {Scan , AssetItemList, Charts} from "./Components/CustomModules";
// import firebase, { requestForToken} from './firebase';
//Set System URL
// export let SystemUrl="https://hic.intrazero.org/";
export let SystemUrl = config.SystemUrl;
export let FrontUrl = config.FrontUrl;
export let BackendUrl = config.BackendUrl;

//Dynamic Routes Mapping to create routes from the Navigation Array
let AppDynamicRoutes = () => {
    //Get Data from Storage
        let NavData = JSON.parse(localStorage.getItem('Navigation'));

    return NavData.map(navItem => {
        return (
            <Route exact path={navItem[0]} key={navItem[0]} >
                <Template getmodule={navItem[1]} getaction={navItem[2]} getfolder={navItem[3]} />
            </Route>
        );
    });
};
let AppCustomRoutes = () => {
    //Get Data from Storage
    return getCustomModules().map((module, index) => {
        return (
            <Route path={'/' + module.route } key={index}>
                {module.route == 'InventoryTaking/Scan/:it_id'  ?
                    <Scan ComponentToRenderData={{CurrentModule : module,SystemUrl:SystemUrl}} />
                    :  module.route == 'InventoryTaking/AssetItemList/:it_id/:room_id' ?
                        <AssetItemList ComponentToRenderData={{CurrentModule : module,SystemUrl:SystemUrl}} />
                    :  module.route == 'InventoryTaking/Charts/:it_id/:room_id' ?
                        <Charts ComponentToRenderData={{CurrentModule : module,SystemUrl:SystemUrl}} />
                    :
                    <Template getmodule={module} getaction='index' getfolder={'CustomModules/' + module.module} />
                }
            </Route>
        )
    })

};

let DynamicNavigationSwitcher = () => {
    // Get Token
    var isLoggedIn = localStorage.getItem('isLoggedIn')
    if (isLoggedIn) {
        // requestForToken();

        return (
            <Provider store={store}>

                <Router>
                <Switch>
                    <Route exact path='/' key='/dashboard' >
                        <Template getmodule='Dashboard' getaction='index' getfolder='Dashboard' />
                    </Route>

                    <Route exact path='/dashboard' key='/dashboard' >
                        <Template getmodule='Dashboard' getaction='index' getfolder='Dashboard' />
                    </Route>
                    <Route path='/formbuilder' key='/formbuilder'>
                        <Template getmodule='FormBuilder' getaction='index' getfolder='FormBuilder' />
                    </Route>
                    <Route path='/Permissions/:id' key='/Permissions'>
                        <Template getmodule='Permissions' getaction='index' getfolder='Permissions' />
                    </Route>
                    <Route path='/QrCodes/:id' key='/QrCodes'>
                        <Template getmodule='QrCodes' getaction='QrCodes' getfolder='QrCodes' />
                    </Route>
                    {AppCustomRoutes()}
                    {AppDynamicRoutes()}
                </Switch>
            </Router>
            </Provider>
        );
    } else {
        return (
            <Router>
                <Switch>
                    <Route exact path='/reset-password/:token' component={ResetPassword} />
                    <Route exact path='/forget-password' component={ForgetPassword} />
                    <Route exact path='/register-as-staff' component={RegisterAsStaff} />
                    <Route path='/' component={Login} />
                </Switch>
            </Router>
        );
    }

};

var App = () => {

    useEffect(() => {
        if (!localStorage.getItem('lang')) {
            localStorage.setItem('lang', 'en')
        }
        if (!localStorage.getItem('styleUrl')) {
            localStorage.setItem('styleUrl', "/assets/css/style.bundle.css")
        }
        if ((!localStorage.getItem('SystemTitle'))||(!localStorage.getItem('ClientLogo'))) {
            axios.get(BackendUrl+'Setting/logo,title')
                .then(result => {
                    var Setting=result.data.data;
                    for (let item of Setting) {
                        if(item.key=="title"){
                            localStorage.setItem('SystemTitle', item.value);
                        }else if(item.key=="logo"){
                            localStorage.setItem('ClientLogo', item.value);

                        }
                    }

                })
                .catch(async (error) => {
                });
        }
    })
    return (
        <div className="App">
            <div id="kt_body" className="header-fixed header-mobile-fixed sidebar-enabled" style={{background: "#f2f3f7"}}>
                <DynamicNavigationSwitcher />
            </div>
        </div>
    );
}
export default App;
