import React, {useEffect, useState , useRef} from 'react'
import axios from "axios";
import config from "../../config.js";
import {useHistory, useParams,Link} from "react-router-dom";
import QRCode from "react-qr-code";
import {trans} from "../utils";
import ReactToPrint from "react-to-print";

const QrCodes = (ComponentToRenderdata) => {
    let history = useHistory();
    const params = useParams();
    const SystemUrl = ComponentToRenderdata.SystemUrl;
    const token = localStorage.getItem('token')
    const [data, setdata] = useState([]);
    const [page, setPage] = useState(0);

    useEffect(() => {
        axios(config.SystemUrl + `select/RoomSpaceItem?page=${page}&building_id=${params.id}`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization': "Bearer " + token
            }
        })
            .then(result => setdata(result.data));
    }, [page])

    const NextPage = () => {
        setPage(page + 1);
    }

    const componentQR = useRef();

    return(
        <>
            <ReactToPrint
                trigger={() => <button className="btn btn-primary"> Print QR Code </button>}
                content={() => componentQR.current}
            />
            {data.meta && data.meta.pagination && data.meta.pagination.total_pages > page+1 ?
            <button className="btn btn-success"
                    onClick={NextPage}>
                Next
            </button> : ""
            }
            <div className='qr-prints' ref={componentQR}>
                {data.data && data.data.map((item) =>
                <>
                    <div className="justify-content-center text-center qr-code-br" key={item.id}>
                        <QRCode value={config.FrontUrl  + "Ticket" + "/Add?hiddenInput[asset_item_id]="+item.id+"&parent_id="+item.id+"&swal=1"}/>
                        <h4 className=" mt-3 text-center">{item.control_no}</h4>
                    </div>
                </>
                )
                }
            </div>
        </>
    )
}
export default QrCodes;
