import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import axios from "axios";
import { Form } from '@formio/react';
import json_trans from '../translation';
import {resetPermissions} from '../utils';
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";

const Edit = ( ComponentToRenderData) => {
    //Set History Variable for redirect after submit
    let history = useHistory();
    // Define CURRENT Module
    const CurrentModule = ComponentToRenderData.CurrentModule;
    const CurrentModuleObj = JSON.parse(localStorage.getItem(CurrentModule));
    const [isLoading, setIsLoading] = useState(true);

    //Get System URL
    const SystemUrl= ComponentToRenderData.SystemUrl;

    //Get Param id
    const params = useParams();
    const options = {
        language: localStorage.getItem('lang') || "en",
        i18n: json_trans,
        template: 'bootstrap'
    }

    //Update the data
    const handleSubmitEdit = (res)=>{
        console.log('handleSubmitEdit',res)
        if(res.status == true){
            if(CurrentModule == "Page"|| CurrentModule == "Role"){
                resetPermissions(CurrentModule)
            }else{
                Swal.fire({
                    title: "Thank You !",
                    icon:"success",
                    text: `Record successfully updated`,
                    showCancelButton: false,
                    confirmButtonColor: '#3f91ec',
                    confirmButtonText: `Return`
                })
                history.push("/" + CurrentModule);

            }
        }
    }

    let posCenter = {
        "position": "absolute",
        "top": "calc(50% - 40px)",
        "left": "calc(50% - 40px)",
        "height": "50rem"
    }
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return (
        <>
            {isLoading &&
                <div className="d-flex flex-column-fluid" style={{height: "600px"}}>
                    <div className="container-fluid">
                        <div style={posCenter}>
                        <Spinner
                            animation="border"
                            variant="primary"
                            style={{
                                width: "50px",  // Adjust the width
                                height: "50px", // Adjust the height
                                borderWidth: "4px",
                                borderBottomColor: "rgb(243, 243, 243)",
                                borderRightColor: "rgb(243, 243, 243)",
                                borderTopColor: "rgb(243, 243, 243)"
                            }}
                            />
                        </div>
                    </div>
                </div>

            }
                {CurrentModuleObj.edit && CurrentModuleObj.page_edit &&<div className="card mb-5 mb-xl-8">
                    <div className="card-body pt-3">
                <Form src={SystemUrl+CurrentModule+'?get_edit_form=true&edit_id='+params.id}
                      onRender={function (){
                    setIsLoading(false);

                }}
                      options={options}  onSubmit={handleSubmitEdit} />
                {/* <button className="btn btn-primary">Submit</button> */}
                    </div>
                </div>
                }
            </>

    );
}

export default Edit
