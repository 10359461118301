import React, { Component, useState, useEffect } from 'react';
import axios from 'axios';
import logo from '../../assets/img/logo.png';
import { BackendUrl } from '../../App';
import { trans } from "../utils";
import { useHistory, useParams } from "react-router-dom";

const Login = () => {
    const [userRequest, setUserRequest] = useState({
        email: '',
        password: '',
        confirmation_password: '',
    });
    useEffect(() => {

    }, [userRequest]);
    let history = useHistory();

    const params = useParams();

    const renderRedirect = () => {
        if (userRequest.redirect == true) {
            window.location.reload(true);
        }
    };
    const isLoading = userRequest.isLoading;

    const handleEmailChange = event => {

        setUserRequest({ ...userRequest, email: event.target.value });
    };
    const handlePwdChange = event => {

        setUserRequest({ ...userRequest, password: event.target.value });
    };

    const handleConfirmationPwdChange = event => {

        setUserRequest({ ...userRequest, confirmation_password: event.target.value });
    };
    const handleSubmit = event => {
        event.preventDefault();

        setUserRequest({ ...userRequest, isLoading: true });
        const url = BackendUrl + 'reset';
        const email = userRequest.email;
        const password = userRequest.password;
        const confirmation_password = userRequest.confirmation_password;
        const token = params.token;
        let bodyFormData = new FormData();
        bodyFormData.set('email', email);
        bodyFormData.set('password', password);
        bodyFormData.set('password_confirmation', confirmation_password);
        bodyFormData.set('token', token);
        console.log(bodyFormData);
        axios.post(url, bodyFormData)
            .then(result => {
                alert('your password reset successfully')
                setUserRequest({ ...userRequest, isLoading: false });
                window.location.href = "/"
            })
            .catch(async (error) => {
                if (error.response.status == 422) {
                    alert(Object.values(error.response.data.errors)[0])
                    console.log(error.response.data);
                }else{
                    alert(error.response.data.message);
                }
                setUserRequest({ ...userRequest, isLoading: false });
                // alert(Array.values(error.data.errors));

            });


    };


    return (

        <div className="d-flex flex-column flex-root" style={{height:'100vh'}}>

            <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed bg-login">

                <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 bg-dark-login">

                    <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
                        <div className="text-center">
                            <img alt="Logo" src={logo} className="h-60px m-2" />
                        </div>

                        <form className="form w-100 mt-10" noValidate="novalidate" id="kt_sign_in_form" action="#"
                            onSubmit={handleSubmit}>

                            {/*<div className="text-center mb-10">*/}

                            {/*    <h1 className="text-dark mb-3">{trans('Welcome to AEON')}</h1>*/}
                            {/*</div>*/}

                            <div className="fv-row mb-10">

                                <label className="form-label fs-6 fw-bolder text-dark">{trans('Email')}</label>
                                <input
                                    className="form-control form-control-lg form-control-solid"
                                    id="inputEmail" placeholder={trans('Email address')} type="text" name="email"
                                    onChange={handleEmailChange} autoFocus required />
                            </div>

                            <div className="fv-row mb-10">

                                <div className="d-flex flex-stack mb-2">

                                    <label className="form-label fw-bolder text-dark fs-6 mb-0">{trans('Password')}</label>


                                </div>
                                <input type="password"
                                    className="form-control form-control-lg form-control-solid"
                                    id="inputPassword" placeholder={trans('Enter Your Password')} name="password"
                                    onChange={handlePwdChange} required />
                            </div>


                            <div className="fv-row mb-10">

                                <div className="d-flex flex-stack mb-2">

                                    <label className="form-label fw-bolder text-dark fs-6 mb-0">{trans('Confirm Password')}</label>


                                </div>
                                <input type="password"
                                    className="form-control form-control-lg form-control-solid"
                                    id="inputPassword" placeholder={trans('Confirm Password')} name="confirmation_password"
                                    onChange={handleConfirmationPwdChange} required />
                            </div>

                            <div className="text-center">
                                <button className="btn btn-lg btn-blue-new w-100 mb-5" type="submit" disabled={userRequest.isLoading ? true : false}><span className="indicator-label">{trans('Reset')}</span>
                                    {isLoading ? (
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    ) : (
                                        <span></span>
                                    )}
                                </button>

                            </div>

                        </form>


                    </div>

                </div>

            </div>
            {renderRedirect()}
        </div>
    );


}
export default Login;



