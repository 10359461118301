import React, {useEffect,useState} from 'react'
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../config.js";
import {toast, ToastContainer} from "react-toastify";
import QrReader from 'react-qr-reader'

const AssetItemList = (ComponentToRenderData) => {
    let history = useHistory();
    const params = useParams();
    const token = localStorage.getItem('token')
    const SystemUrl = ComponentToRenderData.SystemUrl;
    const [data, setData] = useState([])
    const [scanning, setScanning] = useState(false)
    useEffect(() => {
            getList()
    }, [])
    const routeChange = path => {
        history.push(path);
    }
    const getList = () =>{
        axios.get(config.SystemUrl + `getRoomSpaceItemsTaking?room_space_id=${params.room_id}&inventory_taking_id=${params.it_id}`)
            .then(result => {
                setData(result.data)
            })
    }
    const  finishScan  = () => {
        axios.post(config.SystemUrl + `finishTaking`,{
            'inventory_taking_id' : params.it_id,
            'room_space_id' : params.room_id,
        }).then(result=>{

        })
    }
    const statusColor = {
        "exists" : "text-success",
        "not_scanned":"text-dark",
        "mislocated":"text-danger",
        "missing":"text-dark",
    }
    const statusBGColor = {
        "exists" : "bg-success",
        "not_scanned":"bg-dark",
        "mislocated":"bg-danger",
        "missing":"bg-dark",
    }
    const statusIcons = {
        "exists" : 'fa fa-check-circle ',
        "not_scanned":'fa fa-minus-circle ',
        "mislocated":'fa fa-times-circle ',
        "missing":'fa fa-question-circle ',
    }
    const startScan = ()=>{
        setScanning(true);
    }
    const closeScan = ()=>{
        setScanning(false);
    }
    const onFind = (result) => {
        if(result != null){
            var result_arr = result.split('/');
            if(result_arr[3] == "RoomSpaceItem"){
                axios.post(config.SystemUrl + `addTaking`,{
                    'inventory_taking_id' : params.it_id,
                    'room_space_id' : params.room_id,
                    'room_space_item_id' : result_arr[5],
                }).then(result=>{
                    getList()
                }).catch(err=>{
                    toast.error(err.response.data.message);
                })
                setScanning(false);
            }else{
                toast.error('Please scan Asset/Item QR code');
            }
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12 p-0">
                    <div>
                        <div className=" align-items-center bg-template p-3">
                            <h3 className="card-title fw-bolder text-white text-center mb-0">Stock Taking</h3>
                        </div>
                        <div className="card-body pt-2 p-10">
                            {data.counts &&
                                <div className="row mb-3">
                                    <div className="col mx-2 mb-2">
                                        <div className="d-flex align-items-center me-2">

                                            <div className="symbol symbol-50px me-3">
                                                <div className="symbol-label bg-light-warning">
                                                    <span className="svg-icon svg-icon-1 svg-icon-warning">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                             fill="currentColor" className="bi bi-hourglass-top"
                                                             viewBox="0 0 16 16">
                                                          <path
                                                              d="M2 14.5a.5.5 0 0 0 .5.5h11a.5.5 0 1 0 0-1h-1v-1a4.5 4.5 0 0 0-2.557-4.06c-.29-.139-.443-.377-.443-.59v-.7c0-.213.154-.451.443-.59A4.5 4.5 0 0 0 12.5 3V2h1a.5.5 0 0 0 0-1h-11a.5.5 0 0 0 0 1h1v1a4.5 4.5 0 0 0 2.557 4.06c.29.139.443.377.443.59v.7c0 .213-.154.451-.443.59A4.5 4.5 0 0 0 3.5 13v1h-1a.5.5 0 0 0-.5.5zm2.5-.5v-1a3.5 3.5 0 0 1 1.989-3.158c.533-.256 1.011-.79 1.011-1.491v-.702s.18.101.5.101.5-.1.5-.1v.7c0 .701.478 1.236 1.011 1.492A3.5 3.5 0 0 1 11.5 13v1h-7z"/>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="fs-4 text-dark fw-bolder">{data.counts.remaining}</div>
                                                <div className="fs-7 text-muted fw-bold">Remaining</div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col mx-2 mb-2">
                                        <div className="d-flex align-items-center me-2">
                                            <div className="symbol symbol-50px me-3">
                                                <div className="symbol-label bg-light-info">
                                                    <span className="svg-icon svg-icon-1 svg-icon-info">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                             fill="currentColor" className="bi bi-journal-check"
                                                             viewBox="0 0 16 16">
                                                            <path fillRule="evenodd"
                                                                d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                                            <path
                                                              d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"/>
                                                            <path
                                                              d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"/>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="fs-4 text-dark fw-bolder">{data.counts.scanned}</div>
                                                <div className="fs-7 text-muted fw-bold">Scanned</div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col mx-2 mb-2">
                                        <div className="d-flex align-items-center me-2">
                                            <div className="symbol symbol-50px me-3">
                                                <div className="symbol-label bg-light-danger">
                                                    <span className="svg-icon svg-icon-1 svg-icon-info">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                             fill="currentColor" className="bi bi-plus-square"
                                                             viewBox="0 0 16 16">
                                                            <path
                                                              d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="fs-4 text-dark fw-bolder">{data.counts.mislocated}</div>
                                                <div className="fs-7 text-muted fw-bold">Added</div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col mx-2 mb-2">
                                        <div className="d-flex align-items-center me-2">
                                            <div className="symbol symbol-50px me-3">
                                                <div className="symbol-label bg-light-success">
                                                    <span className="svg-icon svg-icon-1 svg-icon-info">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                             fill="currentColor" className="bi bi-check2-all"
                                                             viewBox="0 0 16 16">
                                                          <path
                                                              d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z"/>
                                                          <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z"/>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="fs-4 text-dark fw-bolder">{data.counts.all}</div>
                                                <div className="fs-7 text-muted fw-bold">All</div>
                                            </div>
                                        </div>
                                    </div>
                                    {data.counts.missing > 0 &&
                                    <div className="col mx-2 mb-2">
                                        <div className="d-flex align-items-center me-2">
                                            <div className="symbol symbol-50px me-3">
                                                <div className="symbol-label bg-light-dark">
                                                    <span className="svg-icon svg-icon-1 svg-icon-info">
                                                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                            fill="currentColor" className="bi bi-question-circle"
                                                            viewBox="0 0 16 16">
                                                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                          <path
                                                              d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="fs-4 text-dark fw-bolder">{data.counts.missing}</div>
                                                <div className="fs-7 text-muted fw-bold">Missing</div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                </div>
                            }
                            <div className="height-f">
                                {data.data && data.data.map(item => (
                                    <>
                                        <div className='d-flex align-items-center'>
                                            <span className={"bullet bullet-vertical h-40px "+ statusBGColor[item.status]}></span>
                                            <div className="flex-grow-1 m-2">
                                                <h3 className="text-gray-800 fw-bolder fs-3">
                                                    {item.reference}
                                                </h3>
                                            </div>
                                            <span className="fs-3x fw-bolder" key={item.id}><i className={statusIcons[item.status] + statusColor[item.status]} ></i></span>
                                        </div>
                                        <div className="separator mb-2 mt-2"></div>
                                    </>
                                ))}
                            </div>
                        </div>
                        <div className="btn_fixed">
                            {
                                scanning ? (
                                    <>
                                        <QrReader
                                            delay={300}
                                            onScan={onFind}
                                            style={{ width: '100%',height:'91vh' }}
                                        />
                                        <button onClick={closeScan} className="w-100 btn btn-danger fw-bold m-0 pt-4 pb-4 border-0 rounded-0">
                                            Close Scan
                                        </button>
                                    </>
                                ) :
                                    <button onClick={startScan} className="w-100 btn btn-info fw-bold m-0 pt-4 pb-4 border-0 rounded-0">
                                        Start Scan
                                    </button>
                            }

                            <button className="w-100 btn btn-light-danger fw-bold m-0 pt-4 pb-4 border-0 rounded-0"
                                    onClick={() => {
                                        let url = "/InventoryTaking/Charts/" + params.it_id +'/' + params.room_id;
                                        routeChange(url);
                                    }}>
                                Finish Scan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
export default AssetItemList;
