import React, {useEffect, useRef, useState} from 'react';
import CustomDataTable from "../CustomDataTable";
import $ from "jquery";
import {trans} from "../../utils";
import logo from "../../../assets/img/logo.png";
import ReactToPrint from "react-to-print";
import axios from "axios";
import config from "../../../config.js";
import {toast} from "react-toastify";
import SparePartReport from "./SparePartReport";
import SigningReport from "./SigningReport";

const ServiceReport = ({data, resetData,setResetData ,sendRequest,params,data5,data2,dataSpare}) => {
    const [service, setService] = useState({
        mis_used:0,
        missing:0,
        un_repairable:0,
        agent_repair:0,
        partially_working:0,
        incident:0,
        user_errors:0,
        service_notes:'',
    });
    useEffect(function (){
        setService({...service,...data5[0]} );
    },[data5])

    const componentServiceRef = useRef();
    const handleMisUsedChange = event => {
        setService({...service,mis_used: service.mis_used?0:1} );
    };
    const handleMissingChange = event => {
        setService({...service,missing: service.missing?0:1} );
    };
    const handleUnRepairableChange = event => {
        setService({...service,un_repairable: service.un_repairable?0:1} );
    };

    const handleAgentRepairChange = event => {
        setService({...service,agent_repair: service.agent_repair?0:1} );
    };
    const handlePartiallyWorkingChange = event => {
        setService({...service,partially_working: service.partially_working?0:1} );
    };
    const handleIncidentChange = event => {
        setService({...service,incident: service.incident?0:1} );
    };
    const handleUserErrorsChange = event => {
        setService({...service,user_errors: service.user_errors?0:1} );
    };
    const handleServiceReportForm = event =>
    {
        event.preventDefault();
        sendRequest(service,"WorkOrderServiceReport",function (){
            window.location.reload();
        })
    }

    const handleServiceNotesChange = event =>{
        setService({...service,service_notes: event.target.value} );
    }


    return(
       <>
           <div className="modal fade" id="ActionModal" tabIndex="-1" role="dialog" aria-labelledby="ActionModalLabel" aria-hidden="true" >
               <div className="modal-dialog modal-lg" role="document">
                   <div className="modal-content container">
                       <div className="modal-header no-border">
                           <h5 className="modal-title container" id="exampleModalLabel">
                               <div className="row">
                                   <div className="col-md-4">
                                       <h5>Kingdom of Saudi Arabia</h5>
                                       <h5>Contractor : First Gulf Company</h5>
                                   </div>
                                   <div className="col-md-4 text-center">
                                       <img alt="Logo" src={logo} className="h-100px m-2"/>
                                       <h4>
                                           Job Order Report
                                       </h4>
                                   </div>
                                   <div className="col-md-4">
                                       <h5 className="text-right">المملكة العربية السعودية</h5>
                                       <h5 className="text-right">المقاول : الشركة الخليجية الأولى</h5>
                                   </div>

                               </div>
                           </h5>
                       </div>
                       <div className="row">
                           <div className="col-md-4">
                               <div className="row">
                                   <div className="col-md-6 text-center">
                                       <h5>
                                           رقم أمر العمل <br/>
                                           Job No:
                                       </h5>
                                   </div>
                                   <div className="col-md-6 text-left">
                                       <h4 className="n-border text-center">
                                           {data.id}
                                       </h4>
                                   </div>
                               </div>
                           </div>
                           {data.ticket &&
                               <div className="col-md-4">
                                   <div className="row">
                                       <div className="col-md-6">
                                           <h5>
                                               رقم التقرير<br/>
                                               Report ID:
                                           </h5>
                                       </div>
                                       <div className="col-md-6 text-left">
                                           <h4 className="n-border text-center">
                                               {data.ticket.id}
                                           </h4>
                                       </div>
                                   </div>
                               </div>
                           }
                           <div className="col-md-4">
                               <div className="row">
                                   <div className="col-md-5 text-center">
                                       <h5>
                                           رقم تحكم الجهاز<br/>
                                           Control No:
                                       </h5>
                                   </div>
                                   <div className="col-md-7 text-left">
                                       {data.room_space_item && data.room_space_item.control_no &&
                                           <h4 className="n-border text-center">
                                               {data.room_space_item.control_no}
                                           </h4>
                                       }
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className="">
                           <form onSubmit={handleServiceReportForm} className="row">
                               <div className="col-md-4">
                                   <div className="table-responsive">
                                       <table className="table table-bordered">
                                           <tbody>
                                           <tr>
                                               <th scope="row" className="pl-4">
                                                   <h5 className="text-center">
                                                       اسم الجهاز<br/>
                                                       Equip Name:
                                                   </h5>
                                               </th>
                                               {   data.room_space_item &&
                                                   data.room_space_item.ecri &&
                                                   <td colSpan="3" className="fw-bolder">{trans(data.room_space_item.ecri.ecri_name)}</td>
                                               }
                                           </tr>
                                           {data.room_space_item && data.room_space_item.facility &&
                                               <tr>
                                                   <th scope="row" className="pl-4">
                                                       <h5 className="text-center">
                                                           الموقع<br/>
                                                           Site:
                                                       </h5>
                                                   </th>
                                                   <td colSpan="3" className="fw-bolder">{data.room_space_item.facility.name}</td>
                                               </tr>
                                           }
                                           <tr>
                                               <th scope="row" className="pl-4">
                                                   <h5 className="text-center">
                                                       القسم الرئيسي<br/>
                                                       Department:
                                                   </h5>
                                               </th>

                                               {data.room_space_item && data.room_space_item.building &&
                                                   <td className="fw-bolder">{trans(data.room_space_item.building.title)}</td>
                                               }
                                           </tr>
                                           { data.room_space_item && data.room_space_item.floor &&
                                               <tr>
                                                   <th scope="row" className="pl-4">
                                                       <h5 className="text-center">
                                                           المبنى<br/>
                                                           Building:
                                                       </h5>
                                                   </th>
                                                   <td colSpan="3" className="fw-bolder">{data.room_space_item.floor.name}</td>
                                               </tr>
                                           }
                                           {data.room_space_item && data.room_space_item.room &&
                                               <tr>
                                                   <th scope="row" className="pl-4">
                                                       <h5 className="text-center">
                                                           الغرفة<br/>
                                                           Room:
                                                       </h5>
                                                   </th>
                                                   <td className="fw-bolder">{trans(data.room_space_item.room.room_space_name)}</td>
                                               </tr>
                                           }
                                           </tbody>
                                       </table>
                                   </div>
                               </div>
                               <div className="col-md-4">
                                   <div className="table-responsive">
                                       <table className="table table-bordered">
                                           <tbody>

                                           <tr>
                                               <th scope="row" className="pl-4 text-center">
                                                   <h5 className="text-center">
                                                       رقم الموديل<br/>
                                                       Model No:
                                                   </h5>
                                               </th>
                                               {data.room_space_item  &&
                                                   <td className="fw-bolder">{trans(data.room_space_item.model)}</td>
                                               }
                                           </tr>
                                           <tr>
                                               <th scope="row" className="pl-4">
                                                   <h5 className="text-center">
                                                       الرقم التسلسلي<br/>
                                                       Serial No:
                                                   </h5>
                                               </th>
                                               {data.room_space_item &&
                                                   <td className="fw-bolder">{trans(data.room_space_item.serial_number)}</td>
                                               }
                                           </tr>
                                           {data.room_space_item && data.room_space_item.manufacturer &&
                                               <tr>
                                                   <th scope="row" className="pl-4">
                                                       <h5 className="text-center">
                                                           الشركة الصانعة<br/>
                                                           Manufacturer:
                                                       </h5>
                                                   </th>
                                                   <td colSpan="3" className="fw-bolder">{data.room_space_item.manufacturer.name}</td>
                                               </tr>
                                           }
                                           <tr>

                                               <th scope="row" className="pl-4">
                                                   <h5 className="text-center">
                                                       الأولوية<br/>
                                                       Priority:
                                                   </h5>
                                               </th>
                                               {data.priority &&
                                                   <td className="fw-bolder">{trans(data.priority.title)}</td>
                                               }
                                           </tr>
                                           {data.room_space_item && data.room_space_item.risk_class &&
                                               <tr>
                                                   <th scope="row" className="pl-4">
                                                       <h5 className="text-center">
                                                           مستوى الخطورة<br/>
                                                           Risk Factor:
                                                       </h5>
                                                   </th>
                                                   <td className="fw-bolder">{trans(data.room_space_item.risk_class.title)}</td>

                                               </tr>
                                           }
                                           <tr>
                                               {data.assigned_to_user &&
                                                   <>
                                                       <th scope="row" className="pl-4">
                                                           <h5 className="text-center">
                                                               منفذ العمل<br/>
                                                               Technician:
                                                           </h5>
                                                       </th>

                                                       <td className="fw-bolder">{data.assigned_to_user.name}</td>
                                                   </>
                                               }
                                           </tr>
                                           </tbody>
                                       </table>
                                   </div>
                               </div>
                               <div className="col-md-4">
                                   <div className="">
                                       <div className="form-group">
                                           <div className="table-responsive">
                                               <table className="table table-bordered">
                                                   <thead>
                                                   <tr>
                                                       <th scope="col" colSpan="2" className="text-center">
                                                           <h5>
                                                               Quality Contorl Analysis
                                                           </h5>
                                                       </th>
                                                   </tr>
                                                   </thead>
                                                    
                                                    {   data && data.status_data && data.wo_actions &&
                                                        (data.status_data.title_en == "Active" || data.status_data.title_en == "Report Reopened" || data.status_data.title_en == "Pending for Spare Part") &&
                                                        data.wo_actions.can_submit_report == true ? 
                                                        
                                                        <tbody>
                                                        <tr>
                                                            <th className="pl-4">
                                                                <h5>User Errors</h5>
                                                            </th>
                                                            <th>
                                                                <div className="form-check check-form">
                                                                    <input type="checkbox" value="1"
                                                                           className="form-check-input"
                                                                           id="user_errors" name="user_errors"
                                                                           onChange={handleUserErrorsChange}
                                                                           checked={!!service.user_errors}
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="user_errors"> </label>
                                                                </div>
 
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th className="pl-4">
                                                                <h5>Need S/P</h5>
                                                            </th>
                                                            <th>
                                                                <div className="form-check check-form">
                                                                    <input type="checkbox" value="1" className="form-check-input"
                                                                           id="mis_used" name="mis_used" onChange={handleMisUsedChange}
                                                                           checked={!!service.mis_used}/>
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="mis_used"> </label>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                        <tr className="pl-4">
                                                            <th className="pl-4">
                                                                <h5>Need Calibration</h5>
                                                            </th>
                                                            {data5 &&
                                                                <td>
                                                                    <div className="form-check check-form">
                                                                        <input type="checkbox" value="1"
                                                                               className="form-check-input"
                                                                               id="missing" name="missing"
                                                                               onChange={handleMissingChange}
                                                                               checked={!!service.missing}
                                                                            
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="missing"> </label>
                                                                    </div>
                                                                </td>
                                                            }
                                                        </tr>
                                                        <tr className="pl-4">
                                                            <th className="pl-4">
                                                                <h5>UnRepairable</h5>
                                                            </th>
                                                            <td>
                                                                <div className="form-check check-form">
                                                                    <input type="checkbox" value="1" className="form-check-input"
                                                                           id="un_repairable" name="un_repairable" onChange={handleUnRepairableChange}
                                                                           checked={!!service.un_repairable}
                                                                        
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="un_repairable"> </label>
                                                                </div>
                                                            </td>
                                                        </tr>
 
                                                        <tr className="pl-4">
                                                            <th className="pl-4">
                                                                <h5>Agent Repair</h5>
                                                            </th>
                                                            <td>
                                                                <div className="form-check check-form">
                                                                    <input type="checkbox" value="1"  onChange={handleAgentRepairChange} className="form-check-input"
                                                                           id="agent_repair" name="agent_repair"
                                                                           checked={!!service.agent_repair}
                                                                           
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="agent_repair"> </label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="pl-4">
                                                            <th className="pl-4">
                                                                <h5>Partially Working</h5>
                                                            </th>
                                                            <td>
                                                                <div className="form-check check-form">
                                                                    <input type="checkbox" value="1" className="form-check-input"
                                                                           id="partially_working" name="partially_working" onChange={handlePartiallyWorkingChange}
                                                                           checked={!!service.partially_working}
                                                                           
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="partially_working"> </label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="pl-4">
                                                            <th className="pl-4">
                                                                <h5>Incident</h5>
                                                            </th>
                                                            <td>
                                                                <div className="form-check check-form">
                                                                    <input type="checkbox" value="1" onChange={handleIncidentChange} className="form-check-input"
                                                                           id="incident" name="incident"
                                                                           checked={!!service.incident}
                                                                           
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="incident"> </label>
                                                                </div>
                                                            </td>
                                                        </tr>
 
                                                        </tbody>                
                                                        :
                                                        <tbody>
                                                        <tr>
                                                            <th className="pl-4">
                                                                <h5>User Errors</h5>
                                                            </th>
                                                            <th>
                                                                <div className="form-check check-form">
                                                                    <input type="checkbox" value="1"
                                                                           className="form-check-input"
                                                                           id="user_errors" name="user_errors"
                                                                           onChange={handleUserErrorsChange}
                                                                           checked={!!service.user_errors}
                                                                           disabled
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="user_errors"> </label>
                                                                </div>
 
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th className="pl-4">
                                                                <h5>Need S/P</h5>
                                                            </th>
                                                            <th>
                                                                <div className="form-check check-form">
                                                                    <input type="checkbox" value="1" className="form-check-input"
                                                                           id="mis_used" name="mis_used" onChange={handleMisUsedChange}
                                                                           checked={!!service.mis_used} disabled
                                                                           />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="mis_used"> </label>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                        <tr className="pl-4">
                                                            <th className="pl-4">
                                                                <h5>Need Calibration</h5>
                                                            </th>
                                                            {data5 &&
                                                                <td>
                                                                    <div className="form-check check-form">
                                                                        <input type="checkbox" value="1"
                                                                               className="form-check-input"
                                                                               id="missing" name="missing"
                                                                               onChange={handleMissingChange}
                                                                               checked={!!service.missing}
                                                                               disabled
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="missing"> </label>
                                                                    </div>
                                                                </td>
                                                            }
                                                        </tr>
                                                        <tr className="pl-4">
                                                            <th className="pl-4">
                                                                <h5>UnRepairable</h5>
                                                            </th>
                                                            <td>
                                                                <div className="form-check check-form">
                                                                    <input type="checkbox" value="1" className="form-check-input"
                                                                           id="un_repairable" name="un_repairable" onChange={handleUnRepairableChange}
                                                                           checked={!!service.un_repairable}
                                                                           disabled
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="un_repairable"> </label>
                                                                </div>
                                                            </td>
                                                        </tr>
 
                                                        <tr className="pl-4">
                                                            <th className="pl-4">
                                                                <h5>Agent Repair</h5>
                                                            </th>
                                                            <td>
                                                                <div className="form-check check-form">
                                                                    <input type="checkbox" value="1"  onChange={handleAgentRepairChange} className="form-check-input"
                                                                           id="agent_repair" name="agent_repair"
                                                                           checked={!!service.agent_repair}
                                                                           disabled
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="agent_repair"> </label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="pl-4">
                                                            <th className="pl-4">
                                                                <h5>Partially Working</h5>
                                                            </th>
                                                            <td>
                                                                <div className="form-check check-form">
                                                                    <input type="checkbox" value="1" className="form-check-input"
                                                                           id="partially_working" name="partially_working" onChange={handlePartiallyWorkingChange}
                                                                           checked={!!service.partially_working}
                                                                           disabled
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="partially_working"> </label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="pl-4">
                                                            <th className="pl-4">
                                                                <h5>Incident</h5>
                                                            </th>
                                                            <td>
                                                                <div className="form-check check-form">
                                                                    <input type="checkbox" value="1" onChange={handleIncidentChange} className="form-check-input"
                                                                           id="incident" name="incident"
                                                                           checked={!!service.incident}
                                                                           disabled
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="incident"> </label>
                                                                </div>
                                                            </td>
                                                        </tr>
 
                                                        </tbody> 
                                                   }
                                               </table>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                               <div className="col-md-12">
                                   <div className="table-responsive">
                                       <table className="table table-bordered">
                                           <thead>
                                           <tr>
                                               <th scope="col" className="pl-4 w-33">
                                                   <h5>
                                                       تاريخ التعطل<br/>
                                                       Failed Date: {data.ticket &&
                                                       <span>{trans(data.ticket.created_at)}</span>
                                                   }
                                                   </h5>
                                               </th>

                                               <th scope="col" className="pl-4">
                                                   <h5>
                                                       تاريخ الإصلاح<br/>
                                                       Repair Date:
                                                       {data.wo_finished_date &&
                                                           <span> {trans(data.wo_finished_date)}</span>
                                                       }
                                                   </h5>
                                               </th>
                                               <th scope="col" className="pl-4">
                                                   <h5>
                                                       بيانات المبلغ<br/>
                                                       Caller details:
                                                       {data.ticket && data.ticket.created_by_user &&
                                                           <span> {trans(data.ticket.created_by_user.name)} <br />
                                                               {trans(data.ticket.created_by_user.phone_number)}
                                                           </span>
                                                       }
                                                   </h5>
                                               </th>
                                           </tr>
                                           </thead>
                                       </table>
                                   </div>
                               </div>
                               <div className="col-md-12">
                                   <div className="row">
                                       <div className="col-md-2">
                                           <h4 className="fw-bolder">
                                               وصف العطل<br/>
                                               Fault Description
                                           </h4>
                                       </div>
                                       <div className="col-md-4">
                                           {data.ticket && data.ticket.description &&
                                               <div><h5 className="">{data.ticket.description}</h5></div>
                                           }
                                       </div>
                                       <div className="col-md-6">
                                           <h4 className="left">REMEDY / WORK DONE</h4>
                                           <h4 className="right">العمل المنجز/ ملاحظات</h4>
                                           <br />
                                               
                                               {   data && data.status_data && data.wo_actions &&
                                                        (data.status_data.title_en == "Active" || data.status_data.title_en == "Report Reopened" || data.status_data.title_en == "Pending for Spare Part") &&
                                                        data.wo_actions.can_submit_report == true ? <textarea
                                                        className="form-control"
                                                        name="service_notes"
                                                        value={service.service_notes}
                                                        onChange={handleServiceNotesChange}
                                                        autoFocus required
                                                        style={{"height" : "100px"}}
                                                    />
                                                    :
                                                    <textarea
                                                   className="form-control"
                                                   name="service_notes"
                                                   value={service.service_notes}
                                                   onChange={handleServiceNotesChange}
                                                   autoFocus required
                                                   style={{"height" : "100px"}}
                                                   disabled
                                               /> }
                                       </div>
                                   </div>
                                   <hr />
                               </div>
                               { data.is_internal_wo && <SparePartReport dataSpare={dataSpare}  data5={data5} />}
                                <SigningReport resetData={resetData} setResetData={setResetData}  data={data} data5={data5} />
                               <div className="modal-footer">
                                   <button type="button" className="btn btn-secondary"
                                           data-dismiss="modal">{trans('Close')}
                                   </button>
                                   {   data && data.status_data && data.wo_actions &&
                                       (data.status_data.title_en == "Active" || data.status_data.title_en == "Report Reopened" || data.status_data.title_en == "Pending for Spare Part") &&
                                       data.wo_actions.can_submit_report == true ?
                                           <button className="btn btn-primary" onClick={handleServiceReportForm} type="submit"
                                               data-dismiss="modal">{trans('Submit')}</button> :
                                           <ReactToPrint
                                               trigger={() => <button className="btn btn-success print-btn" type={"button"}> Print Report </button>}
                                               content={() => componentServiceRef.current}
                                           />
                                   }
                               </div>
                           </form>
                       </div>

                   </div>
               </div>
           </div>
           <div className="print"  ref={componentServiceRef}>
               <div className="container pt-4">
                   <div className="no-border">
                       <h5 className="container">
                           <div className="row">
                               <div className="col-md-4">
                                   <h5>Kingdom of Saudi Arabia</h5>
                                   <h5>Contractor : First Gulf Company</h5>
                               </div>
                               <div className="col-md-4 text-center">
                                   <img alt="Logo" src={logo} className="h-100px m-2"/>
                                   <h4>
                                       Job Order Report
                                   </h4>
                               </div>
                               <div className="col-md-4">
                                   <h5 className="text-right">المملكة العربية السعودية</h5>
                                   <h5 className="text-right">المقاول : الشركة الخليجية الأولى</h5>
                               </div>

                           </div>
                       </h5>
                   </div>
                   <div className="row">
                       <div className="col-md-4">
                           <div className="row">
                               <div className="col-md-6 text-center">
                                   <h5>
                                       رقم أمر العمل <br/>
                                       Job No:
                                   </h5>
                               </div>
                               <div className="col-md-6 text-left">
                                   <h4 className="n-border text-center">
                                       {data.id}
                                   </h4>
                               </div>
                           </div>
                       </div>
                       {data.ticket &&
                           <div className="col-md-4">
                               <div className="row">
                                   <div className="col-md-6 text-center">
                                       <h5>
                                           رقم التقرير<br/>
                                           Report ID:
                                       </h5>
                                   </div>
                                   <div className="col-md-6 text-left">
                                       <h4 className="n-border text-center">
                                           {data.ticket.id}
                                       </h4>
                                   </div>
                               </div>
                           </div>
                       }
                       <div className="col-md-4">
                           <div className="row">
                               <div className="col-md-5 text-center">
                                   <h5>
                                       رقم تحكم الجهاز<br/>
                                       Control No:
                                   </h5>
                               </div>
                               <div className="col-md-7 text-left">
                                   {data.room_space_item && data.room_space_item.control_no &&
                                       <h4 className="n-border text-center">
                                           {data.room_space_item.control_no}
                                       </h4>
                                   }
                               </div>
                           </div>
                       </div>
                   </div>
                   <div className="">
                       <form onSubmit={handleServiceReportForm} className="row">
                           <div className="col-md-4">
                               <div className="table-responsive">
                                   <table className="table table-bordered">
                                       <tbody>
                                       <tr>
                                           <th scope="row" className="pl-4">
                                               <h5 className="text-center">
                                                   اسم الجهاز<br/>
                                                   Equip Name:
                                               </h5>
                                           </th>
                                           {   data.room_space_item &&
                                               data.room_space_item.ecri &&
                                               <td colSpan="3" className="fw-bolder">{trans(data.room_space_item.ecri.ecri_name)}</td>
                                           }
                                       </tr>
                                       {data.room_space_item && data.room_space_item.facility &&
                                           <tr>
                                               <th scope="row" className="pl-4">
                                                   <h5 className="text-center">
                                                       الموقع<br/>
                                                       Site:
                                                   </h5>
                                               </th>
                                               <td colSpan="3" className="fw-bolder">{data.room_space_item.facility.name}</td>
                                           </tr>
                                       }
                                       <tr>
                                           <th scope="row" className="pl-4">
                                               <h5 className="text-center">
                                                   القسم الرئيسي<br/>
                                                   Department:
                                               </h5>
                                           </th>

                                           {data.room_space_item && data.room_space_item.building &&
                                               <td className="fw-bolder">{trans(data.room_space_item.building.title)}</td>
                                           }
                                       </tr>
                                       { data.room_space_item && data.room_space_item.floor &&
                                           <tr>
                                               <th scope="row" className="pl-4">
                                                   <h5 className="text-center">
                                                       المبنى<br/>
                                                       Building:
                                                   </h5>
                                               </th>
                                               <td colSpan="3" className="fw-bolder">{data.room_space_item.floor.name}</td>
                                           </tr>
                                       }
                                       {data.room_space_item && data.room_space_item.room &&
                                           <tr>
                                               <th scope="row" className="pl-4">
                                                   <h5 className="text-center">
                                                       الغرفة<br/>
                                                       Room:
                                                   </h5>
                                               </th>
                                               <td className="fw-bolder">{trans(data.room_space_item.room.room_space_name)}</td>
                                           </tr>
                                       }
                                       </tbody>
                                   </table>
                               </div>
                           </div>
                           <div className="col-md-4">
                               <div className="table-responsive">
                                   <table className="table table-bordered">
                                       <tbody>

                                       <tr>
                                           <th scope="row" className="pl-4 text-center">
                                               <h5 className="text-center">
                                                   رقم الموديل<br/>
                                                   Model No:
                                               </h5>
                                           </th>
                                           {data.room_space_item &&
                                               <td className="fw-bolder">{trans(data.room_space_item.model)}</td>
                                           }
                                       </tr>
                                       <tr>
                                           <th scope="row" className="pl-4">
                                               <h5 className="text-center">
                                                   الرقم التسلسلي<br/>
                                                   Serial No:
                                               </h5>
                                           </th>
                                           {data.room_space_item &&
                                               <td className="fw-bolder">{trans(data.room_space_item.serial_number)}</td>
                                           }
                                       </tr>
                                       {data.room_space_item  && data.room_space_item.manufacturer &&
                                           <tr>
                                               <th scope="row" className="pl-4">
                                                   <h5 className="text-center">
                                                       الشركة الصانعة<br/>
                                                       Manufacturer:
                                                   </h5>
                                               </th>
                                               <td colSpan="3" className="fw-bolder">{data.room_space_item.manufacturer.name}</td>
                                           </tr>
                                       }
                                       <tr>

                                           <th scope="row" className="pl-4">
                                               <h5 className="text-center">
                                                   الأولوية<br/>
                                                   Priority:
                                               </h5>
                                           </th>
                                           {data.priority &&
                                               <td className="fw-bolder">{trans(data.priority.title)}</td>
                                           }
                                       </tr>
                                       {data.room_space_item  && data.room_space_item.risk_class &&
                                           <tr>
                                               <th scope="row" className="pl-4">
                                                   <h5 className="text-center">
                                                       مستوى الخطورة<br/>
                                                       Risk Factor:
                                                   </h5>
                                               </th>
                                               <td className="fw-bolder">{trans(data.room_space_item.risk_class.title)}</td>

                                           </tr>
                                       }
                                       <tr>
                                           {data.assigned_to_user &&
                                               <>
                                                   <th scope="row" className="pl-4">
                                                       <h5 className="text-center">
                                                           منفذ العمل<br/>
                                                           Technician:
                                                       </h5>
                                                   </th>

                                                   <td className="fw-bolder">{data.assigned_to_user.name}</td>
                                               </>
                                           }
                                       </tr>
                                       </tbody>
                                   </table>
                               </div>
                           </div>
                           <div className="col-md-4">
                               <div className="">
                                   <div className="form-group">
                                       <div className="table-responsive">
                                           <table className="table table-bordered">
                                               <thead>
                                               <tr>
                                                   <th scope="col" colSpan="2" className="text-center">
                                                       <h5>
                                                           Quality Contorl Analysis
                                                       </h5>
                                                   </th>
                                               </tr>
                                               </thead>
                                               {data5 && data5.length > 0 ?
                                                   <tbody>
                                                   <tr>
                                                       <th className="pl-4">
                                                           <h5>User Errors</h5>
                                                       </th>
                                                       <th>
                                                           <div className="form-check check-form">
                                                               <input type="checkbox" value="1"
                                                                      className="form-check-input"
                                                                      id="user_errors" name="user_errors"
                                                                      onChange={handleUserErrorsChange}
                                                                      checked={data5[0] && data5[0].user_errors ? true : false}
                                                               />
                                                               <label
                                                                   className="form-check-label"
                                                                   htmlFor="user_errors"> </label>
                                                           </div>

                                                       </th>
                                                   </tr>
                                                   <tr>
                                                       <th className="pl-4">
                                                           <h5>Need S/P</h5>
                                                       </th>
                                                       <th>
                                                           <div className="form-check check-form">
                                                               <input type="checkbox" value="1" className="form-check-input"
                                                                      id="mis_used" name="mis_used" onChange={handleMisUsedChange}
                                                                      checked={data5[0] && data5[0].mis_used ? true : false}/>
                                                               <label
                                                                   className="form-check-label"
                                                                   htmlFor="mis_used"> </label>
                                                           </div>
                                                       </th>
                                                   </tr>
                                                   <tr className="pl-4">
                                                       <th className="pl-4">
                                                           <h5>Need Calibration</h5>
                                                       </th>
                                                       {data5 &&
                                                           <td>
                                                               <div className="form-check check-form">
                                                                   <input type="checkbox" value="1"
                                                                          className="form-check-input"
                                                                          id="missing" name="missing"
                                                                          onChange={handleMissingChange}
                                                                          checked={data5[0] && data5[0].missing ? true : false}
                                                                   />
                                                                   <label
                                                                       className="form-check-label"
                                                                       htmlFor="missing"> </label>
                                                               </div>
                                                           </td>
                                                       }
                                                   </tr>
                                                   <tr className="pl-4">
                                                       <th className="pl-4">
                                                           <h5>UnRepairable</h5>
                                                       </th>
                                                       <td>
                                                           <div className="form-check check-form">
                                                               <input type="checkbox" value="1" className="form-check-input"
                                                                      id="un_repairable" name="un_repairable" onChange={handleUnRepairableChange}
                                                                      checked={data5[0] && data5[0].un_repairable ? true : false}
                                                               />
                                                               <label
                                                                   className="form-check-label"
                                                                   htmlFor="un_repairable"> </label>
                                                           </div>
                                                       </td>
                                                   </tr>

                                                   <tr className="pl-4">
                                                       <th className="pl-4">
                                                           <h5>Agent Repair</h5>
                                                       </th>
                                                       <td>
                                                           <div className="form-check check-form">
                                                               <input type="checkbox" value="1"  onChange={handleAgentRepairChange} className="form-check-input"
                                                                      id="agent_repair" name="agent_repair"
                                                                      checked={data5[0] && data5[0].agent_repair ? true : false}
                                                               />
                                                               <label
                                                                   className="form-check-label"
                                                                   htmlFor="agent_repair"> </label>
                                                           </div>
                                                       </td>
                                                   </tr>
                                                   <tr className="pl-4">
                                                       <th className="pl-4">
                                                           <h5>Partially Working</h5>
                                                       </th>
                                                       <td>
                                                           <div className="form-check check-form">
                                                               <input type="checkbox" value="1" className="form-check-input"
                                                                      id="partially_working" name="partially_working" onChange={handlePartiallyWorkingChange}
                                                                      checked={data5[0] && data5[0].partially_working ? true : false}
                                                               />
                                                               <label
                                                                   className="form-check-label"
                                                                   htmlFor="partially_working"> </label>
                                                           </div>
                                                       </td>
                                                   </tr>
                                                   <tr className="pl-4">
                                                       <th className="pl-4">
                                                           <h5>Incident</h5>
                                                       </th>
                                                       <td>
                                                           <div className="form-check check-form">
                                                               <input type="checkbox" value="1" onChange={handleIncidentChange} className="form-check-input"
                                                                      id="incident" name="incident"
                                                                      checked={data5[0] && data5[0].incident ? true : false}
                                                               />
                                                               <label
                                                                   className="form-check-label"
                                                                   htmlFor="incident"> </label>
                                                           </div>
                                                       </td>
                                                   </tr>

                                                   </tbody> :
                                                   <tbody>
                                                   <tr>
                                                       <th className="pl-4">
                                                           <h5>User Errors</h5>
                                                       </th>
                                                       <th>
                                                           <div className="form-check check-form">
                                                               <input type="checkbox" value="1"
                                                                      className="form-check-input"
                                                                      id="user_errors" name="user_errors"
                                                                      onChange={handleUserErrorsChange}
                                                               />
                                                               <label
                                                                   className="form-check-label"
                                                                   htmlFor="user_errors"> </label>
                                                           </div>

                                                       </th>
                                                   </tr>
                                                   <tr>
                                                       <th className="pl-4">
                                                           <h5>Need S/P</h5>
                                                       </th>
                                                       <th>
                                                           <div className="form-check check-form">
                                                               <input type="checkbox" value="1" className="form-check-input"
                                                                      id="mis_used" name="mis_used" onChange={handleMisUsedChange}
                                                               />
                                                               <label
                                                                   className="form-check-label"
                                                                   htmlFor="mis_used"> </label>
                                                           </div>
                                                       </th>
                                                   </tr>
                                                   <tr className="pl-4">
                                                       <th className="pl-4">
                                                           <h5>Need Calibration</h5>
                                                       </th>
                                                       {data5 &&
                                                           <td>
                                                               <div className="form-check check-form">
                                                                   <input type="checkbox" value="1"
                                                                          className="form-check-input"
                                                                          id="missing" name="missing"
                                                                          onChange={handleMissingChange}
                                                                   />
                                                                   <label
                                                                       className="form-check-label"
                                                                       htmlFor="missing"> </label>
                                                               </div>
                                                           </td>
                                                       }
                                                   </tr>
                                                   <tr className="pl-4">
                                                       <th className="pl-4">
                                                           <h5>UnRepairable</h5>
                                                       </th>
                                                       <td>
                                                           <div className="form-check check-form">
                                                               <input type="checkbox" value="1" className="form-check-input"
                                                                      id="un_repairable" name="un_repairable" onChange={handleUnRepairableChange}
                                                               />
                                                               <label
                                                                   className="form-check-label"
                                                                   htmlFor="un_repairable"> </label>
                                                           </div>
                                                       </td>
                                                   </tr>
                                                   <tr className="pl-4">
                                                       <th className="pl-4">
                                                           <h5>Agent Repair</h5>
                                                       </th>
                                                       <td>
                                                           <div className="form-check check-form">
                                                               <input type="checkbox" value="1"  onChange={handleAgentRepairChange} className="form-check-input"
                                                                      id="agent_repair" name="agent_repair"
                                                               />
                                                               <label
                                                                   className="form-check-label"
                                                                   htmlFor="agent_repair"> </label>
                                                           </div>
                                                       </td>
                                                   </tr>
                                                   <tr className="pl-4">
                                                       <th className="pl-4">
                                                           <h5>Partially Working</h5>
                                                       </th>
                                                       <td>
                                                           <div className="form-check check-form">
                                                               <input type="checkbox" value="1" className="form-check-input"
                                                                      id="partially_working" name="partially_working" onChange={handlePartiallyWorkingChange}
                                                               />
                                                               <label
                                                                   className="form-check-label"
                                                                   htmlFor="partially_working"> </label>
                                                           </div>
                                                       </td>
                                                   </tr>
                                                   <tr className="pl-4">
                                                       <th className="pl-4">
                                                           <h5>Incident</h5>
                                                       </th>
                                                       <td>
                                                           <div className="form-check check-form">
                                                               <input type="checkbox" value="1" onChange={handleIncidentChange} className="form-check-input"
                                                                      id="incident" name="incident"
                                                               />
                                                               <label
                                                                   className="form-check-label"
                                                                   htmlFor="incident"> </label>
                                                           </div>
                                                       </td>
                                                   </tr>
                                                   </tbody>
                                               }
                                           </table>
                                       </div>
                                   </div>
                               </div>
                           </div>
                           <div className="col-md-12">
                               <div className="table-responsive">
                                   <table className="table table-bordered">
                                       <thead>
                                       <tr>
                                           <th scope="col" className="pl-4 w-33">
                                               <h5>
                                                   تاريخ التعطل<br/>
                                                   Failed Date: {data.ticket &&
                                                   <span>{trans(data.ticket.created_at)}</span>
                                               }
                                               </h5>
                                           </th>

                                           <th scope="col" className="pl-4">
                                               <h5>
                                                   تاريخ الإصلاح<br/>
                                                   Repair Date:
                                                   {data.wo_finished_date &&
                                                           <span> {trans(data.wo_finished_date)}</span>
                                                   }
                                               </h5>
                                           </th>
                                           <th scope="col" className="pl-4">
                                               <h5>
                                                   بيانات المبلغ<br/>
                                                   Caller details:
                                                   {data.ticket && data.ticket.created_by_user &&
                                                       <span> {trans(data.ticket.created_by_user.name)} <br />
                                                           {trans(data.ticket.created_by_user.phone_number)}
                                                           </span>
                                                   }
                                               </h5>
                                           </th>
                                       </tr>
                                       </thead>
                                   </table>
                               </div>
                           </div>
                           <div className="col-md-12">
                               <div className="row">
                                   <div className="col-md-2">
                                       <h4 className="fw-bolder">
                                           وصف العطل<br/>
                                           Fault Description
                                       </h4>
                                   </div>
                                   <div className="col-md-4">
                                       {data.ticket && data.ticket.description &&
                                           <div><h5 className="">{data.ticket.description}</h5></div>
                                       }
                                   </div>
                                   <div className="col-md-6">
                                       <h4 className="left">REMEDY / WORK DONE</h4>
                                       <h4 className="right">العمل المنجز/ ملاحظات</h4>
                                       <br />
                                       {data5 && data5.length > 0 ?
                                           <div> <br /> <h5 className="n-border">{data5[0].service_notes}</h5></div>
                                           :
                                           <textarea
                                               className="form-control"
                                               name="service_notes"
                                               value={service.service_notes}
                                               onChange={handleServiceNotesChange}
                                               autoFocus required
                                               style={{"height" : "100px"}}
                                           />
                                       }
                                   </div>
                               </div>
                               <hr />
                           </div>
                           { data.is_internal_wo && <SparePartReport dataSpare={dataSpare} data5={data5} />}
                           <SigningReport resetData={resetData} setResetData={setResetData}  data={data} data5={data5} />
                           <div className="modal-footer">
                               <button type="button" className="btn btn-secondary"
                                       data-dismiss="modal">{trans('Close')}
                               </button>
                               {data5 && data5.length > 0 ?
                                   <ReactToPrint
                                       trigger={() => <button className="btn btn-success print-btn"  type={"button"}> Print Report </button>}
                                       content={() => componentServiceRef.current}
                                   />
                                   :
                                   <button className="btn btn-primary" onClick={handleServiceReportForm} type="submit"
                                           data-dismiss="modal">{trans('Submit')}</button>}
                           </div>
                       </form>
                   </div>

               </div>
           </div>
       </>
    )

}
export default ServiceReport
