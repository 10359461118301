import axios from "axios";
import {SystemUrl} from "../../App";

const LogoutRequestHandler = () => {

    axios.post(SystemUrl + "logout").then(function (){
        var lang = localStorage.getItem('lang') || "en";
        var styleUrl = localStorage.getItem('styleUrl') || "/assets/css/style.bundle.css"

        localStorage.clear();
        localStorage.setItem('lang',lang)
        localStorage.setItem('styleUrl',styleUrl)
        window.location.href = "/";
    });


}
export default LogoutRequestHandler;