import React, {useEffect,useState} from 'react'
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../config.js";
import {toast, ToastContainer} from "react-toastify";


const Scan = (ComponentToRenderData) => {
    let history = useHistory();
    const params = useParams();
    const token = localStorage.getItem('token')
    //Get System URL
    const SystemUrl = ComponentToRenderData.SystemUrl;
    const routeChange = path => {
        history.push(path);
    }

    const [dataFacility, setDataFacility ] = useState([]);
    const [dataBuilding, setDataBuilding ] = useState([]);
    const [dataFloor, setDataFloor ] = useState([]);
    const [dataRoom, setDataRoom ] = useState([]);
    const [chainedBuilding , setChainedBuilding] = useState([]);
    const [chainedFloor , setChainedFloor] = useState([]);
    const [chainedRoom , setChainedRoom] = useState([]);

    useEffect(() => {
        axios(config.SystemUrl + `Facility`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => setDataFacility(result.data.data));
    }, [])

    useEffect(() => {
        axios(config.SystemUrl + `Building`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => setDataBuilding(result.data.data));
    }, [])

    useEffect(() => {
        axios(config.SystemUrl + `Floor`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => setDataFloor(result.data.data));
    }, [])

    useEffect(() => {
        axios(config.SystemUrl + `RoomSpace`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => setDataRoom(result.data.data));
    }, [])

    const [data, setData] = useState({
        facility_id: '-1',
        building_id: '-1',
        floor_id:'-1',
        room_id:'-1',
    });

    const handleFacilityChange = event => {
        setData({...data,facility_id: event.target.value} );
        let buildings = dataBuilding.filter(el=>el.facility_id == event.target.value);
        setChainedBuilding(buildings);
    };
    const handleBuildingChange = event => {
        setData({...data,building_id: event.target.value} );
        let floors = dataFloor.filter(el=>el.building_id == event.target.value);
        setChainedFloor(floors)
    };
    const handleFloorChange = event => {
        setData({...data,floor_id: event.target.value} );
        let rooms = dataRoom.filter(el=>el.floor_id == event.target.value);
        setChainedRoom(rooms)

    };
    const handleRoomChange = event => {
        setData({...data,room_id: event.target.value} );
    };
    const handleSubmitChange = event => {
        event.preventDefault();
        if(data.room_id == -1){
            toast.error('Please select the room', { position: toast.POSITION.UPPER_RIGHT });
            return false;
        }
        let url = "/InventoryTaking/AssetItemList/" + params.it_id +'/' + data.room_id;
        routeChange(url)
    };
        return (
            <>
                <div className="row">
                    <div className="col-md-12 p-0">
                        <div>
                            <div className=" align-items-center bg-template p-3">
                                <h3 className="card-title fw-bolder text-white text-center mb-0">Choose</h3>
                            </div>
                            <div className="card-body pt-5 p-10">
                                <form onSubmit={handleSubmitChange}>
                                    <div className="form-group">
                                        <label htmlFor="recipient-name"
                                               className="col-form-label">Facility :</label>
                                        <select className="form-select form-select-sm"
                                                aria-label=".form-select-sm example"
                                                type="text"
                                                onChange={handleFacilityChange}
                                                value={data.facility_id}
                                                required >
                                            <option value="-1"  disabled>Select an option</option>
                                            {dataFacility.map(name => (
                                                <option key={name.id} value={name.id} >{name.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="recipient-name"
                                               className="col-form-label">Building :</label>
                                        <select className="form-select form-select-sm"
                                                aria-label=".form-select-sm example"
                                                type="text"
                                                onChange={handleBuildingChange}
                                                value={data.building_id}
                                                required >
                                            <option value="-1"  disabled>Select an option</option>
                                            {chainedBuilding.map(name => (
                                                <option key={name.id} value={name.id} >{name.title}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="recipient-name"
                                               className="col-form-label">Floor :</label>
                                        <select className="form-select form-select-sm"
                                                aria-label=".form-select-sm example"
                                                type="text"
                                                onChange={handleFloorChange}
                                                value={data.floor_id}
                                                required >
                                            <option value="-1"  disabled>Select an option</option>
                                            {chainedFloor.map(name => (
                                                <option key={name.id} value={name.id} >{name.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="recipient-name"
                                               className="col-form-label">Room :</label>
                                        <select className="form-select form-select-sm"
                                                aria-label=".form-select-sm example"
                                                 type="text"
                                                onChange={handleRoomChange}
                                                value={data.room_id}
                                                required >
                                            <option value="-1"  disabled>Select an option</option>
                                            {chainedRoom.map(name => (
                                                <option key={name.id} value={name.id} >{name.room_space_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="text-center">
                                        <button className="bg-template btn text-white"  type="submit">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </>
        )
}
export default Scan;
