import React, {useState,useEffect} from 'react';
import {trans } from '../utils';
import {NavLink} from "react-router-dom";

const TopBarParentItem = ({Toggle,setNavigate,MenuItem,activeSideBar,MenuItemKey,active,activeParent}) => {
    let ChildArray = MenuItem.child;
    const [isHovered, setIsHovered] = useState(false);
    const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null);
    const handleMouseEnter = (index) => {
        setIsHovered(true);
        setOpenSubMenuIndex(index);
    };
    const handleMouseLeave = () => {
        const isMouseInsideSubMenu = ChildArray[openSubMenuIndex]?.child?.length > 0 && isHovered;
        if (!isMouseInsideSubMenu) {
            setIsHovered(false);
            setOpenSubMenuIndex(null);
        }
    };
    return (
        <>
            <li className={`menu-item menu-item-submenu menu-item-rel ${isHovered ? 'hovered' : ''}  ${activeParent === MenuItem.id ? 'active-parent' : ''}`} key={MenuItem.id}
                 onMouseEnter={() => setIsHovered(true)}
                 onMouseLeave={() => setIsHovered(false)}
            >
                <a href="javascript:;" className="menu-link menu-toggle">
                    <span className="menu-text">
                        {trans(MenuItem.parent_name_en)}
                    </span>
                </a>
                <div className="menu-submenu menu-sub menu-submenu-classic menu-submenu-left">
                    <ul className="menu-subnav">
                    {MenuItem.menu == "show" && MenuItem.preview == 1 &&
                    
                                    <li className="menu-item">
                                        <NavLink to={'/' + MenuItem.route} className="menu-link ">
                                            <span className="svg-icon menu-icon">
                                               <i className={MenuItem.icon}></i> {}
                                            </span>
                                            <span className="menu-text">{trans(MenuItem.name_en)}</span>
                                        </NavLink>
                                    </li>}
                        {ChildArray.map(function (SubMenuItem, SubMenuItemKey) {
                            if (Array.isArray(SubMenuItem.child)) {
                                return  <li
                                    className={`menu-item menu-item-submenu menu-item-sub menu-item-rel ${
                                        openSubMenuIndex === SubMenuItemKey ? 'hovered' : ''
                                    }`}
                                    onMouseEnter={() => handleMouseEnter(SubMenuItemKey)}
                                    onMouseLeave={handleMouseLeave}
                                    key={SubMenuItemKey}
                                >
                                    <NavLink to={'/' + SubMenuItem.route} className="menu-link ">
                                        <span className="svg-icon menu-icon">
                                               <i className={SubMenuItem.icon}></i> {}
                                        </span>
                                        <span className="menu-text">{trans(SubMenuItem.name_en)}</span>
                                        <i className="bi bi-chevron-right fs-2"></i>
                                    </NavLink>
                                    <div className="menu-submenu menu-sub-menu menu-submenu-classic menu-submenu-right">
                                        <ul className="menu-subnav">
                                            {SubMenuItem.child?.map(function (SubSubMenuItem, SubSubMenuItemKey) {
                                               return  SubSubMenuItem.menu == "show" &&
                                                   <li className="menu-item" aria-haspopup="true" key={SubSubMenuItemKey}>
                                                        <NavLink to={'/' + SubSubMenuItem.route} className="menu-link ">
                                                            <span className="svg-icon menu-icon">
                                                               <i className={SubSubMenuItem.icon}></i> {}
                                                            </span>
                                                            <span className="menu-text">{trans(SubSubMenuItem.name_en)}</span>
                                                        </NavLink>
                                                    </li>
                                            })}
                                        </ul>
                                    </div>
                                </li>
                            }else{
                                return SubMenuItem.menu == "show" &&
                                    <li className="menu-item" key={SubMenuItemKey}>
                                        <NavLink to={'/' + SubMenuItem.route} className="menu-link ">
                                            <span className="svg-icon menu-icon">
                                               <i className={SubMenuItem.icon}></i> {}
                                            </span>
                                            <span className="menu-text">{trans(SubMenuItem.name_en)}</span>
                                        </NavLink>
                                    </li>

                            }

                        })}

                    </ul>
                </div>
            </li>

        </>
        );
}
export default TopBarParentItem;