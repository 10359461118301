import React, {useEffect, useState} from 'react';
import $ from "jquery";
import CustomDataTable from "../CustomDataTable";
import {trans} from "../../utils";
import { useHistory, useParams } from "react-router-dom";
import ReactDOM from "react-dom";
import { Markup } from 'interweave';

const Index = (ComponentToRenderData) => {
    let history = useHistory();
    const params = useParams();
    const columns = [
        {data: 'id', title: "#"},
        {data: 'causer.name',title:  trans('Causer Name')},
        {data: 'causer.email',title:  trans('Causer Email')},
        {data: 'description',title:  trans('Action')},
        {data: 'created_at', title: trans('Date')},
        {data: 'id', title: trans('Action'), "className": "all"},
    ];

    const dataTableDiscription = (full)=>{
       var tableData = "<table><tr><th>Attribute</th><th>New</th><th>Old</th></tr>"
        $.each(full.properties.attributes, function (key, value) {
            var oldValue = '';
            var classOfTd = '';
            var classOfTdNew = '';
            var oldValue = '';

            if(full.properties.hasOwnProperty('old')){
                oldValue =  full.properties.old[key];
                if (value != full.properties.old[key]) {
                    classOfTd = 'className="text-danger font-weight-bold"'
                    classOfTdNew = 'className="text-success font-weight-bold"'
                } else {
                    classOfTd = ''
                    classOfTdNew = ''

                }
            }else{
                oldValue =  '';
                    classOfTd = ''
                    classOfTdNew = ''

            }
            tableData += "<tr><th>" + key + "</th><td " + classOfTdNew + ">" + value + "</td><td " + classOfTd + ">" +oldValue + "</td></tr>"
        });

        tableData += "</table>";
        return <Markup content={tableData} />
    }
   
    const columnDefs = [
        {
            targets: -1,
            createdCell: (td, cellData, full) => ReactDOM.render(
                dataTableDiscription(full)
                , td)
        }
    ];
    const [search, setSearch] = useState({
        'action': '',
    });
    const submitFilter = () => {
        $("#activityLog").DataTable().ajax.reload()
    }

    const resetFilter = () => {
        setSearch({ ...search, 'action': '' });
        $("#activityLog").DataTable().ajax.reload()
    }
    return(
       <>
           
            <h3>{params.page}</h3>
            <div className="card mb-5 mb-xl-8">
                <div className="card-body pt-3">
                    <div className='row form-group'>
                        <div className='col-md-6'>
                            <label className="col-form-label">{trans('Action')}</label>
                                <select type="text" className="form-control" name='data[action]' value={search.action} onChange={(e) => {
                                    setSearch({ ...search, 'action': e.target.value })
                                }} placeholder="Search" >
                                    <option value=''>Select Action</option>
                                    <option value='created'>{trans('Created')}</option>
                                    <option value='updated'>{trans('Updated')}</option>
                                    <option value='deleted'>{trans('Deleted')}</option>
                                </select>
                        </div>
                    </div>
                    <div className="text-center">
                        <button className="btn btn-primary" onClick={submitFilter}>{trans('Search')}</button>
                        <button className="btn btn-warning" onClick={resetFilter}>{trans('Reset')}</button>

                    </div>
                </div>
            </div>
            <div className="card-body p-9">
                <div className='table-responsive'>
                <CustomDataTable columnDefs={columnDefs} columns={columns} url={"ActivityLog?page="+params.page+"&row_id="+params.id} table_id="activityLog" FilterParams={['action']}  />
                </div>
            </div>
        </>
    )

}
export default Index
