import { createSlice } from '@reduxjs/toolkit'
let initialState;


const menuToggleDefaultValue  = JSON.parse(localStorage.getItem("redux_menu")) ||{toggle:'',minimize:'on'}
const menuToggleSlice = createSlice({
    name: 'menuToggle',
    initialState: menuToggleDefaultValue,
    reducers: {
        menuToggle: (state) => {
            if(state.toggle =='' ){
                state.toggle ='active';
                state.minimize ='off';
            }else{
                state.toggle ='';
                state.minimize ='on';
            }
            localStorage.setItem("redux_menu",  JSON.stringify(state));

        }
    },
});

const activeDefaultValue  = JSON.parse(localStorage.getItem("redux_menu_active")) ? {active:JSON.parse(localStorage.getItem("redux_menu_active"))} :{active:{}}

const  menuActiveSlice = createSlice({
    name: 'active',
    initialState:  activeDefaultValue,
    reducers: {
        activated: (state,newVal) => {
            state.active = newVal.payload;
            localStorage.setItem("redux_menu_active",  JSON.stringify(state.active));

        }
    },
});



const  menuToggles = menuToggleSlice.reducer ;
const  menuActive = menuActiveSlice.reducer ;

export const { menuToggle } = menuToggleSlice.actions
export const { activated } = menuActiveSlice.actions


export {menuToggles ,menuActive};
