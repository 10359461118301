import React, {Component,useState,useEffect} from 'react';
import axios from 'axios';
import { Redirect,Link } from 'react-router-dom';
import  logo from '../../assets/img/logo.png';
import  logoWhite from '../../assets/img/iAssets-white.png';
import  logo_iassets from '../../assets/img/iAssets.png';
import {SystemUrl } from '../../App';
import GetPagesData from "../Routes";
import { trans } from "../utils";
import background from "../../assets/img/bg.png";
import backgroundLeft from "../../assets/img/background-left.png";
import backgroundForm from "../../assets/img/bg-form.png";
import '../../assets/css/login.css';


const Login = () => {

    const query = new URLSearchParams(window.location.search);
    const assetItemId = query.get('parent_id')

    const [userRequest, setUserRequest] = useState({
        email: '',
        password: '',
        isLoading: false,
        errorEmail : '',
        errorPassword: '',
        location: {},
        invalidEmail : false
    });
    let ClientLogo=localStorage.getItem('ClientLogo');
    let SystemTitle=localStorage.getItem('SystemTitle')?localStorage.getItem('SystemTitle') : "IAssets";

    useEffect(() => {
        document.title = SystemTitle;

    },[userRequest]);

    const renderRedirect = () => {
        if (userRequest.redirect==true) {
            window.location.reload(true);
        }
    };
    const isLoading = userRequest.isLoading;

    const handleEmailChange = event => {
        setUserRequest({...userRequest,email: event.target.value} );
    };
    const handlePwdChange = event => {
        setUserRequest({...userRequest,password: event.target.value} );
    };
    const handleSubmit = event => {
        event.preventDefault();

        const Sanctumurl = SystemUrl+'sanctum/csrf-cookie';
        axios.get(Sanctumurl).then(response => {
            setUserRequest({...userRequest,isLoading: true} );
            const url = SystemUrl+'login';
            const email = userRequest.email;
            const password = userRequest.password;
            let bodyFormData = new FormData();
            bodyFormData.set('email', email);
            bodyFormData.set('password', password);
            axios.post(url, bodyFormData)
                .then(result => {
                    var pages=result.data.data.pages;
                    localStorage.setItem('isLoggedIn', true)
                    //Refresh Routes
                    GetPagesData(pages);

                    setUserRequest({...userRequest,redirect: true, isLoading: false} );
                    renderRedirect();
                })
                .catch(async (error) => {
                    if( error.response ){
                        setUserRequest({...userRequest,errorEmail :error.response.data.errors.hasOwnProperty('email')? error.response.data.errors.email[0]:"",errorPassword : error.response.data.errors.hasOwnProperty('password')?error.response.data.errors.password[0]:"",isLoading: false});
                    }

                });
        });


    };


    return (
        <div className="d-flex flex-column flex-root" style={{height:'100vh'}}>

            <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
                 id="kt_login">

                <div className="login-aside d-flex flex-column flex-row-auto" style={{backgroundColor: "#63a5ef"}}>
                    <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-5 align-items-center">

                        <img src={logoWhite} style={{width:'100px'}} className="mb-5" alt=""/>

                        <h3 className="font-weight-bolder text-white text-center font-size-h4 font-size-h1-lg mb-5 mt-1">Securely manage your assets
                            <br/>with IASSETS</h3>
                    </div>

                    <div
                        className="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
                        style={{ backgroundImage: `url(${backgroundLeft})`,
                                backgroundPosition: "center",
                                backgroundSize: "contain"}}>
                    </div>
                </div>

                <div
                    className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">

                    <div className="d-flex flex-column-fluid flex-center">

                        <div className="login-form login-signin">

                            <form
                                  className="form"
                                  noValidate="novalidate"
                                  id="kt_login_signin_form" action="#"
                                  onSubmit={handleSubmit}
                            >

                                <div className="pb-13 pt-lg-0 pt-5">
                                    <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Welcome to
                                        IAssets</h3>
                                </div>

                                <div className="form-group">
                                    <label className="font-size-h6 font-weight-bolder text-dark">{trans('Email')}</label>
                                    <input
                                        className="form-control form-control-solid h-auto py-6 px-6 rounded-lg"
                                        id="inputEmail" placeholder={trans('Email address')} type="email" name="email"
                                        onChange={handleEmailChange} autoFocus required />

                                    { userRequest.errorEmail &&
                                        <div className="fv-plugins-message-container text-danger" id='email-error-message'>
                                            <div data-field="email" data-validator="emailAddress">{ userRequest.errorEmail } </div>
                                        </div> }
                                </div>

                                <div className="form-group">
                                    <div className="d-flex justify-content-between mt-n5">
                                        <label
                                            className="font-size-h6 font-weight-bolder text-dark pt-5">{trans('Password')}</label>
                                    </div>
                                    <input type="password"
                                           className="form-control form-control-solid h-auto py-6 px-6 rounded-lg"
                                           id="inputPassword" placeholder={trans('Enter Your Password')} name="password"
                                           onChange={handlePwdChange} required/>
                                    { userRequest.errorPassword &&
                                        <div className="fv-plugins-message-container text-danger">
                                            <div data-field="email" data-validator="emailAddress">{ userRequest.errorPassword } </div>
                                        </div> }
                                        <div className="d-flex justify-content-between">
                                        <label
                                            className="font-size-h6 font-weight-bolder text-dark pt-5"></label>
                                            <a href="/forget-password"
                                           className="text-primary font-size-h6 text-hover-primary pt-5"
                                           id="kt_login_forgot">{trans('Forgot Password ?')}</a>
                                    </div>
                                        
                                </div>

                                <div className="pb-lg-0 pb-5">
                                    <button
                                            className="btn-blue-new btn w-100"
                                            type="submit" disabled={userRequest.isLoading ? true : false}>
                                        <span className="indicator-label">{trans('Login')} </span>

                                        {isLoading ? (
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        ) : (
                                            <span></span>
                                        )}
                                    </button>
                                </div>
                            </form>
                            {window.location.href.includes("parent_id") ?
                                <div className="text-center">
                                    <Link
                                        to={{
                                            pathname: "/register-as-staff",
                                            state: {
                                                assetItemId: assetItemId
                                            }
                                        }}
                                        className="btn-blue-o-new btn w-100">
                                        {trans('Register as staff')}
                                    </Link>
                                </div> : ""
                            }
                        </div>
                    </div>

                    <div
                        className="text-center">
                        <div className="text-dark-50 font-size-lg font-weight-bolder">
                            <span className="mr-1">2023©</span>
                            <a href="https://www.intrazero.com" target="_blank"
                               className="text-dark-75 text-hover-primary">intrazero</a>
                        </div>
                    </div>
                </div>
            </div>
            {renderRedirect()}
        </div>
    );


}
export default  Login;



