import React, { useEffect, useState , useRef } from 'react';
import { Link, useHistory, useParams , useLocation} from "react-router-dom";
import axios from "axios";
import config from "../../config.js";
import {trans} from "../utils";

const Notifications = (ComponentToRenderData) => {
    let history = useHistory();
    const params = useParams();
    const SystemUrl = ComponentToRenderData.SystemUrl;
    const token = localStorage.getItem('token')
    const search = window.location.search;
    let URLParams = new URLSearchParams(search);
    const [data, setData] = useState([]);
    const [countsData, setCountsData] = useState({});
    const [iconsData, setIconsData] = useState({});
    const [TotalData, setTotalData] = useState({});
    const routeChange = path => {
        history.push(path);
    }
    const $ = require("jquery");    

    const markAllAsRead = () => {
        axios.get(config.SystemUrl + 'MarkAsRead?id=&all=1', {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
        .then(result => {
            getNotification();
        })
        .catch(error => {
        });
    };

    $(document).on('click', "a[data-dropdown='notificationMenu']", function (e) {
        e.preventDefault();
        var el = $(e.currentTarget);
        $('body').prepend('<div id="dropdownOverlay" style="background: transparent; height:100%;width:100%;position:fixed;"></div>')
        var container = $(e.currentTarget).parent();
        var dropdown = container.find('.dropdown');
        var containerWidth = container.width();
        dropdown.css({
            'right': containerWidth / 2 + 'px'
        })
    });


    $(document).on('click', '#dropdownOverlay', function (e) {
        var el = $(e.currentTarget)[0].activeElement;

        if (typeof $(el).attr('data-dropdown') === 'undefined') {
            $('#dropdownOverlay').remove();
            $('.dropdown-container.expanded').removeClass('expanded');
        }
    })

    $('.notification-tab').click(function (e) {
        if ($(e.currentTarget).parent().hasClass('expanded')) {
            $('.notification-group').removeClass('expanded');
        }
        else {
            $('.notification-group').removeClass('expanded');
            $(e.currentTarget).parent().toggleClass('expanded');
        }
    })
    useEffect(() => {
        getNotification()
    }, [])


    const getNotification = () => {
        axios(config.SystemUrl + `Notification`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization': "Bearer " + token
            }
        })
            .then(result => {
                if (result.data.hasOwnProperty('data')) {
                    setData(Object.entries(result.data.data))
                    setCountsData(result.data['counts']);
                    setIconsData(result.data['icons']);
                    setTotalData(result.data['total']);
                }
            });
    }

    const [activeTab, setActiveTab] = useState(1);
    const location = useLocation();
    useEffect(() => {
        const hash = location.hash.replace("#", "");
        if (hash) {
            setActiveTab(hash);
        }
    }, [location]);
    useEffect(() => {
        const element = document.getElementById(activeTab);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }, [activeTab]);

    useEffect(() => {
        setActiveTab('Registeration');
    }, []);

    const [isClassAdded, setIsClassAdded] = useState(false);
    const [isOverlayAdded, setIsOverlayAdded] = useState(false);

    const notificationClassName = isClassAdded
        ? "offcanvas offcanvas-on offcanvas-left p-10"
        : "offcanvas offcanvas-left p-10";

    const overlayClassName = isOverlayAdded
        ? "offcanvas-overlay"
        : "";

    const handleClick = () => {
        setIsClassAdded(!isClassAdded);
        setIsOverlayAdded(!isOverlayAdded);
    };


    const offcanvasRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (offcanvasRef.current && !offcanvasRef.current.contains(event.target)) {
                setIsClassAdded(false);
                setIsOverlayAdded(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [offcanvasRef]);


      
    return (
        <>
            <button
                onClick={handleClick}
                className="btn btn-icon btn-lg btn-borderless mb-1 position-relative"
                data-toggle="tooltip"
                data-placement="right" data-container="body"
                data-boundary="window"
                title={trans('Notifications')}
            >
                    <span className="svg-icon svg-icon-xxl">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" fill="none">
                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" fill="#000000" fillRule="nonzero"></path>
                                <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" fill="#000000" opacity="0.3"></path>
                            </g>
                        </svg>
                    </span>
                {TotalData && TotalData > 0 ?
                    <span className="label-notification label label-sm label-light-danger font-weight-bolder position-absolute top-0 right-0 mt-1 mr-1">{TotalData}</span>: ''
                }
            </button>
            <div className={notificationClassName} ref={offcanvasRef}>
                <div className="offcanvas-header d-flex align-items-center justify-content-between mb-10">
                    <h3 className="font-weight-bold m-0">{trans('Notifications')}
                        {TotalData && TotalData > 0 ?
                            <small className="text-muted font-size-sm ml-2">{TotalData}</small> : ''
                        }
                    </h3>
                    <div className="btn btn-xs btn-icon btn-light btn-hover-primary" onClick={handleClick}>
                        &times;
                    </div>

                </div>
                <div className='text-left mb-4'>
                    <button onClick={markAllAsRead} className='mark_btn'>{trans('Mark all as read')}</button>
                </div>
                <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
                    <ul className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1" role="tablist">
                        {data.map((notifications, index) => (
                            <li key={index} className="nav-item mr-3">
                                <Link className="nav-link m-0"  data-toggle="tab"  to={`#${notifications[0]}`}>
                                    {trans(notifications[0])}
                                    <span className="btn btn-sm btn-light p-1 my-lg-0 my-2 text-dark-50 ml-1">
                                    {countsData[notifications[0]]}
                                </span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="offcanvas-content">
                    <div className="tab-content">
                        {data.map((notifications, index) => (
                            notifications[1].map((notification, id) => (
                                <div  key={id}
                                      className={`tab-pane fade pt-2 pr-5 mr-n5 ${activeTab == notifications[0] ? "show active" : ""}`}
                                      id={notifications[0]}
                                      role="tabpanel">
                                    { notification.module_name == "Registeration" ?
                                        <a href={'/Staff'}
                                              className={`text-hover-primary d-flex mb-1 p-3 ${notification.is_read == 0 ? 'bg-light-primary text-primary' : 'text-dark'} ` }
                                              onClick={() => {
                                                  if (notification.is_read == 0) {
                                                      axios.get(config.SystemUrl + `MarkAsRead?id=${notification.id}`).then(result => {
                                                          getNotification()
                                                      })
                                                  }
                                              }}
                                        >
                                            <div className="symbol symbol-50 mr-3 text-hover-primary">
                                                <div className="symbol-label">
                                                            <span className={`svg-icon svg-icon-2x ${notification.is_read == 0 ? 'svg-icon-primary' : ''} `}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
                                                                    <g stroke="none" fill="none">
                                                                        <polygon points="0 0 24 0 24 24 0 24"/>
                                                                        <path
                                                                            d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                                                                            fill="#000000" fillRule="nonzero" opacity="0.3"/>
                                                                        <path
                                                                            d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                                                                            fill="#000000" fillRule="nonzero"/>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                </div>
                                            </div>
                                            <div className="navi-text">
                                                <div className="font-weight-bold  font-size-lg text-hover-primary text-capitalize">{notification.text}</div>
                                                <div className="text-muted">{notification.date}</div>
                                            </div>
                                        </a> :
                                        <a href={'/' + notification.url}
                                              className={`text-hover-primary d-flex mb-1 p-3 ${notification.is_read == 0 ? 'bg-light-primary text-primary' : 'text-dark'} ` }
                                              onClick={() => {
                                                  if (notification.is_read == 0) {
                                                      axios.get(config.SystemUrl + `MarkAsRead?id=${notification.id}`).then(result => {
                                                          getNotification()
                                                      })
                                                  }
                                              }}
                                        >
                                            <div className="symbol symbol-50 mr-3 text-hover-primary">
                                                <div className="symbol-label">
                                                            <span className={`svg-icon svg-icon-2x ${notification.is_read == 0 ? 'svg-icon-primary' : ''} `}>
                                                                { notification.module_name == "Ticket" ?
                                                                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" version="1.1">
                                                                        <g stroke="none"  fill="none">
                                                                            <rect x="0" y="0" width="24" height="24"/>
                                                                            <path d="M3,10.0500091 L3,8 C3,7.44771525 3.44771525,7 4,7 L9,7 L9,9 C9,9.55228475 9.44771525,10 10,10 C10.5522847,10 11,9.55228475 11,9 L11,7 L21,7 C21.5522847,7 22,7.44771525 22,8 L22,10.0500091 C20.8588798,10.2816442 20,11.290521 20,12.5 C20,13.709479 20.8588798,14.7183558 22,14.9499909 L22,17 C22,17.5522847 21.5522847,18 21,18 L11,18 L11,16 C11,15.4477153 10.5522847,15 10,15 C9.44771525,15 9,15.4477153 9,16 L9,18 L4,18 C3.44771525,18 3,17.5522847 3,17 L3,14.9499909 C4.14112016,14.7183558 5,13.709479 5,12.5 C5,11.290521 4.14112016,10.2816442 3,10.0500091 Z M10,11 C9.44771525,11 9,11.4477153 9,12 L9,13 C9,13.5522847 9.44771525,14 10,14 C10.5522847,14 11,13.5522847 11,13 L11,12 C11,11.4477153 10.5522847,11 10,11 Z" fill="#000000" opacity="0.3" transform="translate(12.500000, 12.500000) rotate(-45.000000) translate(-12.500000, -12.500000) "/>
                                                                        </g>
                                                                    </svg> :
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
                                                                        <g stroke="none" fill="none">
                                                                            <rect x="0" y="0" width="24" height="24"/>
                                                                            <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>
                                                                            <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>
                                                                            <rect fill="#000000" opacity="0.3" x="10" y="9" width="7" height="2" rx="1"/>
                                                                            <rect fill="#000000" opacity="0.3" x="7" y="9" width="2" height="2" rx="1"/>
                                                                            <rect fill="#000000" opacity="0.3" x="7" y="13" width="2" height="2" rx="1"/>
                                                                            <rect fill="#000000" opacity="0.3" x="10" y="13" width="7" height="2" rx="1"/>
                                                                            <rect fill="#000000" opacity="0.3" x="7" y="17" width="2" height="2" rx="1"/>
                                                                            <rect fill="#000000" opacity="0.3" x="10" y="17" width="7" height="2" rx="1"/>
                                                                        </g>
                                                                    </svg>
                                                                }
                                                            </span>
                                                </div>
                                            </div>
                                            <div className="navi-text">
                                                <div className="font-weight-bold  font-size-lg text-hover-primary text-capitalize">{notification.text}</div>
                                                <div className="text-muted">{notification.date}</div>
                                            </div>
                                        </a>

                                    }

                                </div>
                            ))
                        ))}
                    </div>
                </div>
            </div>
            <div className={overlayClassName}></div>
        </>
    );
}
export default Notifications;
