import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Form } from '@formio/react';
import json_trans from '../translation';
import { resetPermissions } from '../utils';
import Swal from "sweetalert2";
import axios from 'axios';

import config from '../../config.js'
import Spinner from "react-bootstrap/Spinner";


const Add = (ComponentToRenderData) => {
    const url = new URL(window.location.href);
    let URLParams = new URLSearchParams(url.search);
    const [isLoading, setIsLoading] = useState(true);

    let HiddenParams = URLParams.toString().replace("%5B","[").replace("%5D","]")
    //Set History Variable for redirect after submit
    let history = useHistory();
    // Define CURRENT Module
    const CurrentModule = ComponentToRenderData.CurrentModule;
    const CurrentModuleObj = JSON.parse(localStorage.getItem(CurrentModule));

    //Get System URL
    const SystemUrl = ComponentToRenderData.SystemUrl;
    const params = useParams();
    params.parent_id =  typeof params.parent_id == "undefined" ? "":params.parent_id;

    const options = {
        language: localStorage.getItem('lang') || "en",
        i18n: json_trans,
        template: 'bootstrap'
    }
    const token = localStorage.getItem('token');
    const [data, setdata] = useState([]);
    useEffect(() => {
        //Get Current Module Dataco
        if(CurrentModule == "Ticket" && URLParams.get('swal')){
            axios(config.SystemUrl + `RoomSpaceItem/${URLParams.get('parent_id')}?include=one_tickets`).then(result => {
            if(result.data.data.one_tickets.hasOwnProperty("id")){
            Swal.fire({
                title: "There is already opened ticket for this Equipment",
                icon:"question",
                text: `There is already submitted ticket with the same issue, we will contact you soon`,
                reverseButtons: true,
                reverseButtonsColor: '#3f91ec',
            })
           history.push(`/RoomSpaceItem/View/${URLParams.get('parent_id')}`)
        }})}
    }, [])
    const handleSubmitAdd = (res) => {

        if (res.status == true) {
            if (res.data.redirect) {
                return history.push("/" + res.data.redirect + "/Add/" + res.data.data.id);
            }
            if(CurrentModule == "Ticket"){
                Swal.fire({
                    title: "Thank You !",
                    icon:"success",
                    text: `You've successfully created a ticket, and our team will contact you shortly,
                             Ticket Reference No. #${res.data.id}`,
                    showCancelButton: false,
                    confirmButtonColor: '#3f91ec',
                    confirmButtonText: `Return`
                })
            }else if(CurrentModule == "RoomSpaceItem"){
                Swal.fire({
                    title: "Thank You !",
                    icon:"success",
                    text: `You've successfully created an Equipment, Equipment's ID #${res.data.id}`,
                    showCancelButton: false,
                    confirmButtonColor: '#3f91ec',
                    confirmButtonText: `Return`
                })
            }else{
                let orderId = ''
                if(res?.data?.id){
                    orderId = `, with id No. #${res?.data?.id}`;
                }
                Swal.fire({
                    title: "Thank You !",
                    icon:"success",
                    text: `Record successfully created${orderId}`,
                    showCancelButton: false,
                    confirmButtonColor: '#3f91ec',
                    confirmButtonText: `Return`
                })
            }

            if (CurrentModule == "Page" || CurrentModule == "Role") {
                resetPermissions(CurrentModule)
            } else {
                history.push("/" + CurrentModule);
            }
        }
    }

    let posCenter = {
        "position": "absolute",
        "top": "calc(50% - 40px)",
        "left": "calc(50% - 40px)",
        "height": "50rem"
    }
    return (
        <>
            {isLoading &&
                <div className="d-flex flex-column-fluid" style={{height: "600px"}}>
                    <div className="container-fluid">
                        <div style={posCenter}>
                            <Spinner animation="border" variant="primary" style={{
                                width:"50px",
                                height:"50px",
                                borderWidth: "4px",
                                borderBottomColor: "rgb(243, 243, 243)",
                                borderRightColor: "rgb(243, 243, 243)",
                                borderTopColor: "rgb(243, 243, 243)"
                            }} />
                        </div>
                    </div>
                </div>

            }
            {CurrentModuleObj.add && CurrentModuleObj.page_add &&
                <div className="card card-custom mb-5 p-6">
                    <Form onRender={function () {
                        setIsLoading(false);
                    }}
                          src={SystemUrl + CurrentModule + '?get_create_form=true&parent_id=' + params.parent_id+"&"+HiddenParams} options={options} onSubmit={handleSubmitAdd} />
                </div>
            }
        </>

    );
}

export default Add
