import React, {useEffect, useState , useRef } from 'react';
import {useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../config.js";
import {trans} from "../../utils";
import CustomDataTable from "../CustomDataTable";
import { ToastContainer} from 'react-toastify';
import ReactToPrint from "react-to-print";
import QRCode from "react-qr-code";



const Index = () => {

    const params = useParams();
    const token = localStorage.getItem('token')
    const [data, setData ] = useState([]);
    const [qrCodes, setQRCodes] = useState([]);

    const columns = [
        { data: 'id', title: '#' },
        {
          data: 'control_no',
          title: trans('Control number'),
          render: function (data, type, row, meta) {
            if (type === 'display') {
              return `<a href='${config.FrontUrl}RoomSpaceItem/View/${row.id}'>${data}</a>`;
            } else {
              return data; // for other data types, return the data as is
            }
          },
        },
        { data: 'model', title: trans('Model') },
        { data: 'serial_number', title: trans('Serial Number') },
        { data: 'status', title: trans('Status') },
      ];
      

    useEffect(() => {
        axios(config.SystemUrl + `RoomSpace/${params.id}?include=facility,building,floor,room_space_category,industry`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
        .then(result => setData(result.data.data));

    }, [])

    useEffect(() => {
        axios(config.SystemUrl + `RoomSpaceItem?room_space_id=${params.id}`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
        .then(result => setQRCodes(result.data.data));

    }, [])

    const componentQR = useRef();
    const componentsQR = useRef();

    return (
        <div className="card card-custom mb-5 p-6 bg-gray-100">
            <div className="row">
                <div className="col-md-3">
                    <div className="text-center">
                        <div className="symbol symbol-100px mb-7 symbol-square text-center mt-3" ref={componentsQR}>
                            <div className="print-qr justify-content-center text-center">
                                <QRCode value={config.FrontUrl  + "RoomSpace" + "/View/"+params.id }/>
                                   {data &&
                                        <h4 className=" mt-3 text-center">{trans(data.room_space_name)}</h4>
                                    }
                            </div>
                            <ReactToPrint
                                trigger={() => <button className="btn btn-primary"> Print QR Code </button>}
                                content={() => componentsQR.current}
                            />
                        </div>
                    </div>
                </div>
            <div className="col-md-9">
                    <div className="card card-custom mb-5">
                        <div className="card-header">
                            <div className="card-title m-0">
                                <h3 className="fw-bolder m-0">{trans('Room Space Information')}</h3>
                            </div>
                        </div>
                        <div className="card-body p-9">
                            <div className="row">
                                <div className="col-lg-6 col-xxl-4">
                                    <div className="d-flex flex-column justify-content-center flex-row-fluid pe-11 mb-5">
                                            <>
                                                <div className="fw-bolder mt-3">{trans('Room Space Name ')} :</div>
                                                <div className="text-gray-600">
                                                {trans(data.room_space_name)}
                                                </div>
                                            </>

                                            {data.facility &&
                                            <>
                                                <div className="fw-bolder mt-3">{trans('Site')} :</div>
                                                <div className="text-gray-600">
                                                {trans(data.facility.name)}
                                                </div>
                                            </>
                                            }

                                            

                                            {data.industry &&
                                            <>
                                                <div className="fw-bolder mt-3">{trans('Industry')} :</div>
                                                <div className="text-gray-600">
                                                {trans(data.industry.title)}
                                                </div>
                                            </>
                                            }

                                    </div>
                                </div>
                                <div className="col-lg-6 col-xxl-4">
                                    <div className="d-flex flex-column justify-content-center flex-row-fluid pe-11 mb-5">
                                        {data.building && 
                                            <>
                                                
                                                <div className="fw-bolder mt-3">{trans('Department')} :</div>
                                                <div className="text-gray-600">{data.building.title}</div>
                                            </>
                                        }
                                        {data.room_space_category && 
                                        <>
                                            
                                            <div className="fw-bolder mt-3">{trans('Room Space Category')} :</div>
                                            <div className="text-gray-600">{data.room_space_category.title}</div>
                                        </>
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xxl-4">
                                    <div className="d-flex flex-column justify-content-center flex-row-fluid pe-11 mb-5">
                                        {data.floor &&
                                            <>
                                                <div className="fw-bolder mt-3">{trans('Building')} :</div>
                                                <div className="text-gray-600">
                                                {trans(data.floor.name)}
                                                </div>
                                            </>
                                        }
                                        {data.room_space_ref &&
                                            <>
                                                <div className="fw-bolder mt-3">{trans('Room Space Ref')} :</div>
                                                <div className="text-gray-600">
                                                {trans(data.room_space_ref)}
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className='col-md-12'>
                    <div className="card card-custom mb-5">
                            <div className="card-header cursor-pointer">
                                <div className="card-title m-0">
                                    <h3 className="fw-bolder m-0">{trans('Equipments')}</h3>
                                </div>
                                <div className="card-toolbar">
                                    <div ref={componentQR} className='print-2'>
                                        <div className='d-block text-center justify-content-center mt-10'>
                                            {qrCodes.map((code, index) => (
                                                <div key={index} className='print-qrs mb-10 text-center justify-content-center'>
                                                    <>
                                                        <h3 className="mt-3">{trans('Room Space Name ')}</h3>
                                                        <div className="text-gray-600 mb-10">
                                                            <h3 className='mt-10'> {trans(data.room_space_name)}</h3>
                                                        </div>
                                                    </>
                                                    <QRCode value={code.qr_code} size={350} />
                                                    <h3 className='mt-10'>{code.control_no}</h3>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <ReactToPrint
                                        trigger={() => <button className="btn btn-primary">{trans('Print Equipments QR Codes')}  </button>}
                                        content={() => componentQR.current}
                                    />
                                </div>
                            </div>
                            <div className="card-body p-9">
                                <CustomDataTable columns={columns} url={"RoomSpaceItem?room_space_id="+params.id} table_id="Equipments" order={ [[0, "asc"]]} />
                            </div>
                        </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    )
}
export default Index;
