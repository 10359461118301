import React, { Component, useState, useEffect } from 'react';
import axios from 'axios';
import logo from '../../assets/img/logo.png';
import { BackendUrl } from '../../App';
import { trans } from "../utils";
import background from "../../assets/img/bg.png";
import logoWhite from "../../assets/img/iAssets-white.png";
import backgroundLeft from "../../assets/img/background-left.png";
import {Link} from "react-router-dom";

const Login = () => {
    const [userRequest, setUserRequest] = useState({
        email: '',
        isLoading: false,
    });
    useEffect(() => {

    }, [userRequest]);


    const renderRedirect = () => {
        if (userRequest.redirect == true) {
            window.location.reload(true);
        }
    };
    const isLoading = userRequest.isLoading;

    const handleEmailChange = event => {

        setUserRequest({ ...userRequest, email: event.target.value });
    };
    const handleSubmit = event => {
        event.preventDefault();

        console.log("handleSubmit")
        setUserRequest({ ...userRequest, isLoading: true });
        const url = BackendUrl + 'forgot-password';
        const email = userRequest.email;
        let bodyFormData = new FormData();
        bodyFormData.set('email', email);
        axios.post(url, bodyFormData)
            .then(result => {
                if(result.data.status == true){
                    alert('mail sent successfully to your mail')
                    setUserRequest({ ...userRequest, isLoading: false });
                }
                else{
                    setUserRequest({ ...userRequest, isLoading: false });
                    alert('mail already sent, check your inbox!')
                }

            })
            .catch(async (error) => {
                console.log(error.response);
            });


    };


    return (

        <div className="d-flex flex-column flex-root"  style={{height:'100vh'}}>

            <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
                 id="kt_login">

                <div className="login-aside d-flex flex-column flex-row-auto" style={{backgroundColor: "#63a5ef"}}>
                    <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-5 align-items-center">

                        <img src={logoWhite} style={{width:'100px'}} className="mb-5" alt=""/>

                        <h3 className="font-weight-bolder text-white text-center font-size-h4 font-size-h1-lg mb-5 mt-3">Securely manage your assets
                            <br/>with IASSETS</h3>
                    </div>

                    <div
                        className="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
                        style={{ backgroundImage: `url(${backgroundLeft})`,
                            backgroundPosition: "center",
                            backgroundSize: "contain"}}>
                    </div>
                </div>

                <div
                    className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">

                    <div className="d-flex flex-column-fluid flex-center">

                        <div className="login-form login-signin">

                            <form
                                className="form"
                                noValidate="novalidate"
                                id="kt_login_signin_form" action="#"
                                onSubmit={handleSubmit}
                            >

                                <div className="pb-13 pt-lg-0 pt-5">
                                    <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Welcome to
                                        IAssets</h3>
                                </div>

                                <div className="form-group">
                                    <label className="font-size-h6 font-weight-bolder text-dark">{trans('Email')}</label>
                                    <input
                                        className="form-control form-control-solid h-auto py-6 px-6 rounded-lg"
                                        id="inputEmail" placeholder={trans('Email address')} type="text" name="email"
                                        onChange={handleEmailChange} autoFocus required />
                                </div>

                                <div className="pb-lg-0 pb-5">
                                    <button
                                        className="btn-blue-new btn w-100"
                                        type="submit" disabled={userRequest.isLoading ? true : false}>
                                        <span className="indicator-label">{trans('Reset')}</span>
                                        {isLoading ? (
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        ) : (
                                            <span></span>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div
                        className="text-center">
                        <div className="text-dark-50 font-size-lg font-weight-bolder">
                            <span className="mr-1">2023©</span>
                            <a href="https://www.intrazero.com" target="_blank"
                               className="text-dark-75 text-hover-primary">intrazero</a>
                        </div>
                    </div>
                </div>
            </div>
            {renderRedirect()}
        </div>
    );


}
export default Login;



