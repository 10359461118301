import React, {useEffect,useState} from 'react'
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../config.js";
import {trans} from "../../utils";
import user from '../../../assets/img/user.png';

const Index = (ComponentToRenderData) => {
    let history = useHistory();
    const params = useParams();
    const token = localStorage.getItem('token')
    //Get System URL
    const SystemUrl = ComponentToRenderData.SystemUrl;
    const [data, setData ] = useState([]);

    useEffect(() => {
        axios(config.SystemUrl + `Customer/${params.id}?include=customer_sla,contacts,facility,category,industry,city,country`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => setData(result.data.data));

    }, [])


        return (
            <>
               <div className="d-flex flex-column flex-xl-row">
                   <div className="flex-column flex-lg-row-auto w-100 w-xl-350px row">
                        <div className="col-md-3">
                            <div className="card">
                                <div className="card-body">
                                <div className="d-flex flex-center flex-column py-5">
                                    <div className="symbol symbol-100px symbol-circle mb-7">
                                        {data.logo ="url" ?
                                            <img src={ config.BackendUrl + data.logo} alt="image" /> :
                                            <img src={user} alt="image"  />
                                        }
                                    </div>
                                    <h3 className="fs-3 text-gray-800 fw-bolder mb-3">{data.name}</h3>
                                    <div className="badge badge-lg badge-light-primary d-inline"> {trans(data.status)}</div>
                                </div>
                                <div className="separator"></div>
                                <div id="kt_user_view_details" className="collapse show">
                                    <div className="pb-2 fs-6">
                                        <div className="fw-bolder mt-3"><i className="bi bi-envelope"></i>  {trans('Email')}</div>
                                        <div className="text-gray-600">
                                            <a href={'mailto://'+data.email} className="text-gray-600 text-hover-primary">{data.email}</a>
                                        </div>

                                        <div className="fw-bolder mt-3"><i className="bi bi-grid"></i>  {trans('Category')}</div>
                                        {data.category && <div className="text-gray-600"> {trans(data.category.title)}</div>}

                                        <div className="fw-bolder mt-3"><i className="bi bi-map"></i>  {trans('Country')}</div>
                                        {data.city &&<div className="text-gray-600"> {trans(data.country.title)}</div> }

                                        <div className="fw-bolder mt-3"><i className="bi bi-map"></i>  {trans('City')}</div>
                                        {data.city &&<div className="text-gray-600"> {trans(data.city.title)}</div> }

                                        <div className="fw-bolder mt-3"><i className="bi bi-geo-alt"></i>  {trans('Address')}</div>
                                        <div className="text-gray-600"> {trans(data.address)}</div>

                                        <div className="fw-bolder mt-3"><i className="bi bi-globe"></i>  {trans('Website')}</div>
                                        <div className="text-gray-600">{data.website}</div>

                                        <div className="fw-bolder mt-3"><i className="bi bi-signpost"></i>  {trans('Reference')}</div>
                                        <div className="text-gray-600">{data.reference}</div>

                                        <div className="fw-bolder mt-3"><i className="bi bi-building"></i>  {trans('Industry')}</div>
                                        {data.industry && <div className="text-gray-600"> {trans(data.industry.title)}</div>}
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-9">
                           {(data.contacts &&  data.contacts.length >0 &&
                               <div className="card pt-4 mb-6 mb-xl-9">
                                   <div className="card-header border-0">
                                       <div className="card-title">
                                           <h2 className="fw-bolder mb-0"> {trans('Contacts')}</h2>
                                       </div>
                                   </div>

                                   <div id="kt_customer_view_payment_method" className="card-body pt-0">
                                       <div className="separator separator-dashed"></div>
                                       <div className="separator separator-dashed"></div>
                                       {
                                           data.contacts.map((contacts) => (
                                               <div className="py-0" data-kt-customer-payment-method="row"  key={contacts.id}>

                                                   <div className="py-3 d-flex flex-stack flex-wrap">
                                                       <div className="d-flex align-items-center collapsible rotate collapsed"
                                                            data-bs-toggle="collapse" href="#kt_customer_view_payment_method_3" role="button"
                                                            aria-expanded="false" aria-controls="kt_customer_view_payment_method_3">
                                                           <div className="me-3">
                                                               <div className="d-flex align-items-center">
                                                                   <div className="text-gray-800 fw-bolder"> {contacts.contact}</div>
                                                               </div>
                                                           </div>

                                                       </div>
                                                   </div>

                                                   <div id="kt_customer_view_payment_method_3"
                                                        data-bs-parent="#kt_customer_view_payment_method">
                                                       <div className="d-flex flex-wrap">
                                                           <div className="flex-equal  me-5">
                                                               <table className="table table-flush fw-bold gy-1">
                                                                   <tbody>
                                                                   <tr>
                                                                       <td className="text-muted min-w-125px w-125px p-2"><i
                                                                           className="bi bi-phone"></i>  {trans('Phone')}</td>
                                                                       <td className="text-gray-800 p-2">{contacts.phone}</td>
                                                                   </tr>
                                                                   </tbody>
                                                               </table>
                                                           </div>
                                                           <div className="flex-equal">
                                                               <table className="table table-flush fw-bold gy-1">
                                                                   <tbody>
                                                                   <tr>
                                                                       <td className="text-muted min-w-125px w-125px p-2"><i
                                                                           className="bi bi-envelope"></i>  {trans('E-mail')}</td>
                                                                       <td className="text-gray-800 p-2">{contacts.email}</td>
                                                                   </tr>
                                                                   </tbody>
                                                               </table>
                                                           </div>
                                                       </div>
                                                   </div>
                                               </div>
                                           ))}
                                   </div>
                               </div>
                           )}
                           {(data.customer_sla &&  data.customer_sla.length >0  &&
                               <div className="card pt-4 mb-6 mb-xl-9">
                                   <div className="card-header border-0">
                                       <div className="card-title">
                                           <h2 className="fw-bolder mb-0"> {trans('Customer SLA')}</h2>
                                       </div>
                                   </div>

                                   <div id="kt_customer_view_payment_method" className="card-body pt-0">
                                       <div className="separator separator-dashed"></div>
                                       <div className="separator separator-dashed"></div>
                                       { data.customer_sla.map((customer_sla) => (
                                           <div className="py-0" data-kt-customer-payment-method="row" key={customer_sla.id}>
                                               <div className="py-3 d-flex flex-stack flex-wrap">
                                                   <div className="d-flex align-items-center collapsible rotate collapsed"
                                                        data-bs-toggle="collapse" href="#kt_customer_view_payment_method_3" role="button"
                                                        aria-expanded="false" aria-controls="kt_customer_view_payment_method_3">
                                                       <div className="me-3">
                                                           <div className="d-flex align-items-center">
                                                               <div className="text-gray-800 fw-bolder">{customer_sla.contact}</div>
                                                           </div>
                                                       </div>

                                                   </div>
                                               </div>

                                               <div id="kt_customer_view_payment_method_3"
                                                    data-bs-parent="#kt_customer_view_payment_method">
                                                   <div className="d-flex flex-wrap">
                                                       <div className="flex-equal me-5">
                                                           <table className="table table-flush fw-bold gy-1">
                                                               <tbody>
                                                               <tr>
                                                                   <td className="text-muted min-w-125px w-125px p-2"><i
                                                                       className="bi bi-person-lines-fill"></i>  {trans('Contact')}</td>
                                                                   <td className="text-gray-800 p-2">{customer_sla.contact}</td>
                                                               </tr>
                                                               <tr>
                                                                   <td className="text-muted min-w-125px w-125px  p-2"><i
                                                                       className="bi bi-person"></i>  {trans('Name')}</td>
                                                                   <td className="text-gray-800  p-2">{customer_sla.name}</td>
                                                               </tr>
                                                               </tbody>
                                                           </table>
                                                       </div>
                                                       <div className="flex-equal">
                                                           <table className="table table-flush fw-bold gy-1">
                                                               <tbody>
                                                               <tr>
                                                                   <td className="text-muted min-w-125px w-125px  p-2"><i
                                                                       className="bi bi-phone"></i>  {trans('Phone')}</td>
                                                                   <td className="text-gray-800  p-2">{customer_sla.phone}</td>
                                                               </tr>
                                                               <tr>
                                                                   <td className="text-muted min-w-125px w-125px  p-2"><i
                                                                       className="bi bi-envelope"></i>  {trans('E-mail')}</td>
                                                                   <td className="text-gray-800  p-2">{customer_sla.email}</td>
                                                               </tr>
                                                               </tbody>
                                                           </table>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       ))}
                                   </div>
                               </div>
                           )}
                       </div>
                   </div>
               </div>
            </>
        )
}
export default Index;
