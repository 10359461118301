import React, {Component, useEffect, useState} from 'react';
import {Link, NavLink, useHistory,useLocation, useParams} from 'react-router-dom';
import { trans } from '../utils';
import axios from 'axios';
import { SystemUrl } from '../../App';
import Template from "../Template";
import config from "../../config.js";
import { useSelector, useDispatch } from 'react-redux'
import { menuToggle ,activated} from "../../reducer"
import TopBarParentItem from "./TopBarParentItem"


const Header = ({showMenu,headerRef},ComponentToRenderData) => {

    let history = useHistory();
    const routeChange = path => {
        history.push(path);
    }
    const params = useParams();
    const SystemUrl = ComponentToRenderData.SystemUrl;
    const token = localStorage.getItem('token')
    const [data, setData ] = useState([]);

    const location = useLocation();
    const [activeParent, setActiveParent] = useState(null);

    useEffect(() => {
        axios(config.SystemUrl + `Profile/0`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => setData(result.data.data));
    }, [])

    useEffect(() => {
        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();

        Tawk_API.visitor = {name: data.name , email: data.email};
        (function(){
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.async=true;
            s1.src='https://embed.tawk.to/638f3e5eb0d6371309d2e39e/1gjjon966';
            s1.charset='UTF-8';
            s1.setAttribute('crossorigin','*');
            s0.parentNode.insertBefore(s1,s0);
        })();
    })


    const state = useSelector((state) =>state)
    const toggle = state.menuToggle.toggle
    const active = state.active.active
    // console.log(active);

    const dispatch = useDispatch()
    let ClientLogo=localStorage.getItem('ClientLogo');
    let SystemModules = JSON.parse(localStorage.getItem('SystemModules'));
    let all = {};

    useEffect(() => {
        if(!JSON.parse(localStorage.getItem("redux_menu_active")) ){
            SystemModules.forEach((MenuItem, index) => {
                defaultActive(MenuItem,all);
            });
            dispatch(activated(all))
        }
    }, [])

    const defaultActive =(MenuItem,all)=>{
        if (Array.isArray(MenuItem.child)) {
            all[MenuItem.id] =""
            MenuItem.child.forEach((MenuItemNew) => {
                defaultActive(MenuItemNew,all);
            })
        }
    }

    useEffect(() => {
        // Determine the active parent based on the current location
        const activeParentItem = SystemModules.find((MenuItem) => {
            if (Array.isArray(MenuItem.child)) {
                return MenuItem.child.some((child) => location.pathname.startsWith('/' + child.route));
            } else {
                return location.pathname.startsWith('/' + MenuItem.route);
            }
        });
        setActiveParent(activeParentItem?.id || null);
    }, [location.pathname]);


    
    return (
         <div id="kt_header" className="header header-fixed">
            <div className="header-wrapper rounded-top-xl d-flex flex-grow-1 align-items-center">
                <div className="container-fluid d-flex align-items-center justify-content-end justify-content-lg-between flex-wrap">
                <div ref={headerRef} className={showMenu ? 'header-menu-wrapper header-menu-wrapper-left header-menu-wrapper-on' : 'header-menu-wrapper header-menu-wrapper-left'}>
                        <div id="kt_header_menu" className="header-menu header-menu-mobile header-menu-layout-default">
                            <ul className="menu-nav">
                                <li className={`menu-item menu-item-rel`}>
                                <NavLink to="/" exact className="menu-link" activeClassName="active">
                                    <span className="menu-text">{trans("Dashboard")}</span>
                                    <i className="menu-arrow"></i>
                                </NavLink>
                                </li>

                                {SystemModules.map(function (MenuItem, MenuItemKey) {
                                    if (Array.isArray(MenuItem.child)) {
                                        var count = 0;
                                        return (
                                            <TopBarParentItem MenuItem={MenuItem} count={count} MenuItemKey={MenuItemKey} active={active} key={MenuItemKey} activeParent={activeParent}/>
                                        );
                                    } else {
                                        return MenuItem.menu === "show" && (
                                            <li className={`menu-item menu-item-rel ${activeParent === MenuItem.id ? 'active-parent' : ''}`} key={MenuItem.id}>
                                                <NavLink to={'/' + MenuItem.route} className="menu-link" activeClassName="active">
                                                    <span className="menu-text">{trans(MenuItem.name_en)}</span>
                                                </NavLink>
                                            </li>
                                        );
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default Header
