export default {
    "title_en": "Title English",
    "title_ar": "Title Arabic",
    "Logout" : "Logout",
    "lang" : "عربي",
    "Create" : "Create",
    "Title En" : "English Title",
    "Title Ar" : "Arabic Title",
    "title english" : "English Title",
    "title arabic" : "Arabic Title",
    "AssetItem": "Equipment",
    "AssetItemCategory":"Equipment Category",
    "AssetItem Ecri": "Equipment Identification",
    "EquipmentType":"Equipment Type",
    "AssetItem Utility": "Equipment Utility",
    "AssetItem Group": "Equipment Group",
    "AssetItem Type": "Equipment Type",
    "AssetItem UOM": "Equipment UOM",
    "AssetItem Risk Class": "Risk Class",
    "AssetItemCategoryOption": "Equipment Category Option",
    "AssetItemGroupOption":"Equipment Group Option",
    "Supplier Manufacturer": "Supplier / Manufacturer",
    "SupplierManufacturerStatus":"Supplier Manufacturer Status",
    "CustomerStatus":"Customer Status",
    "Assign Asset Item":"Assign Equipment",
    "CoverageType":"Coverage / Type",
    "MeasuringPeriod":"Measuring Period",
    "PenaltyUOM":"Penalty UOM",
    "PenaltyUom":"Penalty UOM",
    "LaborManagement":"Labor Management",
    "ProjectStage":"Project Stage",
    "projectStage":"Project Stage",
    "ProjectStatus":"Project Status",
    "projectStatus":"Project Status",
    "RoomSpace":"Room Space",
    "RoomSpaceCategory":"Room Space Category",
    "roomSpaceCategory":"Room space Category",
    "RoomSpaceItem":"Equipment",
    "Allocate Asset / Item":"Equipment",
    "SparePartManagement":"Spare Part Management",
    "AssignTicket":"Assign Ticket",
    "Ticket":"Ticket / Request",
    "TicketStatus":"Ticket Status",
    "WorkOrder":"Work Order",
    "WorkOrderFailureType":"Failure Type",
    "WorkOrderStatus":"Work Order Status",
    "WorkOrderType":"Work Order Type",
    "WorkOrderCategory":"Work Order Category",
    "PlanPreventiveMaintenance":"Plan Preventive Maintenance",
    "Name en":"Name in english",
    "Name ar":"Name in arabic",
    "MedicalUse":"Medical Use ?",
    "AssetDetails":"Equipment Details",
    "CommonName":"Equipment Identification",
    "commonName":"Equipment Identification",
    "SerialNumber":"Serial Number",
    "serialNumber":"SerialNumber",
    "BatchNo.":"Batch No.",
    "LOTNO.":"LOT NO.",
    "TechnicalDetails":"Technical Details",
    "RiskClass":"Risk Class",
    "IntendedUse":"Intended Use",
    "ShelfLife":"Shelf Life",
    "CutSheet":"Cut Sheet",
    "Issparepart":"Is spare part ?",
    "OptionTitleEn":"Option Title in english",
    "OptionTitleAr":"Option Title in arabic",
    "ecriName":"Name",
    "ecriCode":"Code",
    "Asset/ItemCategory":"Equipment Category",
    "Embedlocation":"Embed location",
    "FacilityFloor":"Facility Floor",
    "facilityBuilding":"Department/Medical Center",
    "facilityRoom":"facility Room",
    "facilityDepartment":"facility Department",
    "facilityRoomRef.":"facility Room Ref.",
    "CustomerSLA":"Customer SLA",
    "customerSLA":"Customer SLA",
    "GeneralDetails":"General Details",
    "AssetName":"Equipment Identification",
    "SoftwareVersion":"Software Version",
    "ManufacturingDate":"Manufacturing Date",
    "PurchaseOrder":"Purchase Order",
    "PODate":"Purchase Order Date",
    "POReference":"Purchase Order Reference",
    "POValue":"Purchase Order Value",
    "DocumentParent":"Document Group",
    "ChildCategory":"Document Group Type",
    "OptionKey":"Option Key",
    "OptionValue":"Option Value",
    "assign_asset_item":"Assign Equipment",
    "contract_value":"contract value",
    "coverage_type": "Coverage Type",
    "response_time":"Response time",
    "repair_time":"Repair time",
    "measuring_period":"measuring period",
    "from_date":"from date",
    "to_date":"to date",
    "penalty_rate":"penalty rate",
    "penalty_uom":"penalty UOM",
    "import_grace_period":"import grace period",
    "purchase_requisition_ref":"purchase requisition ref",
    "supplier_quotation_ref":"supplier quotation ref",
    "purchase_order_ref":"purchase order ref",
    "asset_code":"Equipment code",
    "penalty_uom_id":"Penalty UOM",
    "est_cost":"Est cost",
    "actual_cost":"Actual cost",
    "projectPhase":"Project Phase",
    "ProjectDetails":"Project Details",
    "ProjectName":"Project Name",
    "ProjectVersion":"Project Version",
    "ProjectReference":"Project Reference",
    "RoomSpaceName":"Room Space Name",
    "RoomSpaceRef":"RoomSpace Ref",
    "RoomQuantity":"Room Quantity",
    "roomQuantity":"Room Quantity",
    "Manufacturer_part_no":"Manufacturer part number",
    "manufacturer_part_no":"Manufacturer part number",
    "wo_reference":"work order reference",
    "created_date":"created date",
    "start_date":"start date",
    "finish_date":"finish date",
    "closed_date":"closed date",
    "Closed_date":"closed date",
    "Requested_by":"Requested by",
    "assigned_to":"Assigned To",
    "failure_type":"failure type",
    "Requester_signing_off":"Requester signing off",
    "Contract_id":"Contract",
    "priority_id":"priority",
    "Customer Category":"Customer Category",
    "Customer Status":"Customer Status",
    "Assign Asset / Item Status":"Assign Equipment Status",
    "ProjectPhase":"Project Phase",
    "Project Stage":"Stage",
    "Project Status":"Project Status",
    "Room Space Category":"Room Space Category",
    "Room Space Item":"Room Space Item",
    "Work Order Failure Type":"Work Order Failure Type",
    "Work Order Status":"Work Order Status",
    "Work Order Type":"Work Order Type",
    "Work Order Category":"Work Order Category",
    "Customer status":"Customer status",
    "customer Status":"customer Status",
    "RoomsSpaces":"Room Spaces",
    "RoomSpace Name":"RoomSpace Name",
    "AssignAssetItem":"Assign Equipment",
    "AssetLocation":"Equipment Location",
    "assignAssetItem":"Assign Equipment",
    "measuringPeriod":"Measuring Period",
    "roomSpaceRef":"Room Space Ref",
    "created_by":"created by",
    "requested_by":"Requested by",
    "request_description":"request description",
    "closed_by":"closed by",
    "requester_signing_off":"requester signing off",
    "contract_id":"contract",
    "failureType":"failure Type",
    "PMFrequency":"PM Frequency",
    "QAFrequency":"QA Frequency",
    "CalibrationFrequency":"Calibration Frequency",
    "InstallationDate":"Installation Date",
    "InServiceDate":"In Service Date",
    "ExpireDate":"Expire Date",
    "Welcome to AEON ":"Welcome to AEON",
    "AssetItem View":"Equipment View",
    "Customer View":"Customer View",
    "isMedicalUse":"Is Medical Use ?",
    "isSparePart":"Is Spare Part ?",
    "SupplierManufacturer":"Supplier / Manufacturer",
    "Additional":"Configuration",
    "Role":"User Group",
    "AssetItemRiskClass":"Equipment Risk Class",
    "CreateAssetItem":"Create Equipment",
    "Asset/ItemMasterData":"Equipment Master Data",
    "Asset/ItemStatus":"Equipment Status ",
    "Supplier/Manufacturer":"Supplier / Manufacture",
    "WarrantyStartDate":"Warranty Start Date",
    "WarrantyEndDate":"Warranty End Date",
    "Asset/ItemRef.":"Equipment Ref.",
    "TechnicalDetials":"Technical Detials",
    "Intendeduse":"Intended use",
    "Asset/ItemCommonName":"Code",
    "ECRIName":"Name",
    "SupplierStatus":"Supplier Status",
    "SupplierRanking":"Supplier Ranking",
    "DepreciationRate":"Depreciation Rate",
    "PMType":"PM Type",
    "Asset/ItemName":"Equipment Identification",
    "PurchaseOrderDate":"Purchase Order Date",
    "Asset/ItemGroup":"Equipment Group",
    "Procurement/Installation":"Procurement / Installation",
    "DownloadDocument":"Download Document",
    "ItemReference":"Item Reference",
    "LOTNo.":"LOTNo.",
    "Asset/ItemRef.Generation":"Equipment Ref. Generation",
    "TitleorImage":"Title or Image",
    "Reset":"Reset",
    "CustomerName":"Customer Name",
    "CustomerCategory":"Customer Category",
    "SiteName":"Site",
    "SiteBuilding":"Site Building",
    "SiteDepartment":"Site Department",
    "SiteRoom":"Site Room",
    "SiteWarehouse":"Site Warehouse",
    "TicketType":"Ticket Type",
    "TicketSubType":"Ticket Sub Type",
    "FromDate":"From Date",
    "ToDate":"To Date",
    "toDate":"To Date",
    "Contract_ref":"Contract ref",
    "intendeduse":"Intended use",
    "ContractType":"Contract Type",
    "PlanPreventiveMaintenanceType":"Plan Preventive Maintenance Type",
    "AssetItemUtilityInput":"Equipment Utility Input",
    "AssetItemDocumentationGroup":"Document Group",
    "AssetItemDocumentationGroupType":"Document Type",
    "DocumentationGroup":"Document Group",
    "DocumentationGroupType":"Document Type",
    "AssignAssetItemStatus":"Equipment Status",
    "PODateFrom":"PO Date From",
    "PODateTo":"PO Date To",
    "InstallationDateFrom":"Installation Date From",
    "InstallationDateTo":"Installation Date To",
    "AssignAsset/ItemRef.":"Assign Equipment Ref.",
    "SubCustomer":"Sub Customer",
    "StartDateFrom":"Start Date From",
    "StartDateTo":"Start Date To",
    "AlternativeAsset/ItemRef. ":"Alternative Equipment Ref. ",
    "EcriName":"Name",
    "EcriCode":"Code",
    "AlternativeAsset/ItemRef.":"Alternative Equipment Ref.",
    "TicketCategory":"Ticket Category",
    "TicketSub-Category":"Ticket Sub-Category",
    "ConvertedtoWO":"Converted to WO",
    "AllocateAsset/ItemRef.":"Allocate Equipment Ref.",
    "WOCategory":"WO Category",
    "WOType":"WO Type",
    "WOStatus":"WO Status",
    "WODateFrom":"WO Date From",
    "WODateTo":"WO Date To",
    "Assignedto":"Assigned To",
    "RequestedBy":"Requested By",
    "ClosedBy":"Closed By",
    "CreatedBy":"Created By",
    "FailureType":"Failure Type",
    "created_on":"Created on",
    "AllocateAsset/Item":"Equipment",
    "StartDate":"Start Date",
    "EndDate":"End Date",
    "ContractRef.":"Contract Ref.",
    "coverageType":"Coverage Type",
    "InventoryTaking":"Inventory Taking",
    "ProjectFacility":"Project Facility",
    "ProjectFacilityBuilding":"Project Facility Building",
    "ProjectFacilityBuildingFloor":"Project Facility Building Floor",
    "TypicalSpace":"Typical Space",
    "SpaceRef.":"Space Ref.",
    "Asset/Items":"Equipments",
    "Assets/Items":"Equipments",
    "WorkOrderCost":"WorkOrder Cost",
    "WorkOrderAttachment":"WorkOrder Attachment",
    "WorkOrderStatusHistory":"WorkOrder Status History",
    "WorkOrderActionOption":"WorkOrder Action Option",
    "WorkOrderAction":"WorkOrder Action",
    "PeriodName":"Period Name",
    "Preview Attachment":"Preview Attachment",
    "Report problem":"Report problem",
    "Facility":"Site",
    "Facilities":"Site",
    "ControlNo.":"Control No.",
    "EquipmentName":"Equipment identification",
    "SerialNo.":"Serial No.",
    "ModelNo.":"Model No.",
    "SubCategory":"Sub Category",
    "WarrantyPeriod(Y)":"Warranty Period(Y)",
    "facilities":"Site",
    "Subcontract":"Sub Contract",
    "EquipmentData":"Equipment Data",
    "EquipmentAllocation":"Equipment Allocation",
    "SparePart":"Spare Part",
    "MedicalCenters":"Medical Centers",
    "SupervisorDelegate":"Supervisor Delegate",
    "PrimarySupervisor":"Primary Supervisor",
    "InReport":"In Report ",
    "IsPrimaryActive?":"Is Primary Active ?",
    "ResponseTime/Day":"Response Time / Day",
    "PaymentTerms":"Payment Terms",
    "VisitValue":"Visit Value",
    "CallCenterContact":"Call Center Contact",
    "CallCenterEmail":"Call Center Email",
    "All the pending Work Orders":"All the pending Work Orders",
    "LastPPM":"Last PPM",
    "NextPPM":"Next PPM",
    "PPMWO":"PPM WO",
    "CorrectiveWO":"Corrective WO",
    "AllPendingStatus":"All Pending Status",
    'code':'Code',
    'assigned':'Assigned',
    'delegate':'Delegate',
    'name':'Name',
    "assigned to":"Assigned To",
    "fail date":"Fail Date",
    "ContactName":"Contact Name",
    "Technican":"Technician",
    "quantity":"Quantity",
    "status":"Status",
    "TechnicanDelegate":"Technician Delegate",
    "PrimaryTechnican":"Primary Technician",
    "thisfieldisrequiredwhenwarrantystatusisyes":"this field is required when warranty status is yes",
    "ECRI":"Equipment identification",
    "AutogeneratePPM":"Auto generate PPM",
    "AutomaticallyGeneratePlanPreventiveMaintenanceforequipmentofthedepartmentbythesystem":"Automatically Generate Plan Preventive Maintenance for equipment of the department by the system",
    "Chooseanoption":"Choose an option",
    "thisfieldisrequiredwhenprimaryinactive":"this field is required when primary is inactive",
    "Mapurl":"Map url",
    "at row number": "At row number",
}
