import React, {useEffect, useState} from 'react'
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../config.js";
import {trans} from "../../utils";

const Index = (ComponentToRenderData) => {
    let history = useHistory();
    const params = useParams();
    const SystemUrl = ComponentToRenderData.SystemUrl;
    const BackendUrl = ComponentToRenderData.BackendUrl;
    const token = localStorage.getItem('token')
    const [data, setData ] = useState([]);
    const search = window.location.search;
    let URLParams = new URLSearchParams(search);
    const routeChange = path => {
        history.push(path);
    }

    useEffect(() => {
        axios(config.SystemUrl + `Contract/${params.id}?include=supplier,currency,coverage_type,measuring_period,penalty_uom,payment_term,contacts`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => setData(result.data.data));
    }, [])


    return (
        <>
            <div className="card card-custom mb-5 p-6 bg-gray-100">
                <div className="row">
                    <div className="col-md-4">
                        <div className="card mb-5">
                            <span  className="p-20 text-center badge-light-primary">
                                <h4> {trans(data.contract_ref)}</h4>
                            </span>
                            {data.contract_type &&
                                <span className="p-2 bg-light text-center">
                                    <h4>{trans(data.contract_type.title_en)}</h4>
                                </span>
                            }
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade active show" id="kt_techincal_tab" role="tabpanel">
                                <div className="card">
                                    <div className="card-header card-header-stretch">
                                        <div className="card-title d-flex align-items-center">
                                            <h4 className="mb-1">{trans('Contract Details')}</h4>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <div className="fs-6">
                                            { data.supplier && data.supplier.hasOwnProperty("name") &&
                                            <>
                                                <div className="fw-bolder mt-3">{trans('Supplier')}</div>
                                                <div className="text-gray-600">{trans(data.supplier.name)}</div>
                                            </>
                                            }
                                            { data.contract_value &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Visit Value')}</div>
                                                <div className="text-gray-600">{trans(data.contract_value)}</div>
                                            </>
                                            }
                                            { data.currency && data.currency.hasOwnProperty("title") &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Currency')}</div>
                                                <div className="text-gray-600">{trans(data.currency.title)}</div>
                                            </>
                                            }

                                            { data.coverage_type && data.coverage_type.hasOwnProperty("title") &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Coverage type')}</div>
                                                <div className="text-gray-600">{trans(data.coverage_type.title)}</div>
                                            </>
                                            }
                                            { data.response_time &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Response Time/Day')}</div>
                                                <div className="text-gray-600">{trans(data.response_time)}</div>
                                            </>
                                            }
                                            { data.payment_term &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Payment Term')}</div>
                                                <div className="text-gray-600">{trans(data.payment_term.title)}</div>
                                            </>
                                            }
                                            { data.measuring_period && data.measuring_period.hasOwnProperty("title") &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Measuring period')}</div>
                                                <div className="text-gray-600">{trans(data.measuring_period.title)}</div>
                                            </>
                                            }
                                            { data.penalty_uom && data.penalty_uom.hasOwnProperty("title") &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Penalty UOM')}</div>
                                                <div className="text-gray-600">{trans(data.penalty_uom.title)}</div>
                                            </>
                                            }
                                            { data.penalty_rate &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Penalty rate')}</div>
                                                <div className="text-gray-600">{trans(data.penalty_rate)}</div>
                                            </>
                                            }
                                            { data.response_time &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Response time')}</div>
                                                <div className="text-gray-600">{trans(data.response_time)}</div>
                                            </>
                                            }
                                            { data.from_date &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Date from')}</div>
                                                <div className="text-gray-600">{trans(data.from_date)}</div>
                                            </>
                                            }
                                            { data.to_date &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Date to')}</div>
                                                <div className="text-gray-600">{trans(data.to_date)}</div>
                                            </>
                                            }
                                            { data.import_grace_period &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Import grace period')}</div>
                                                <div className="text-gray-600">{trans(data.import_grace_period)}</div>
                                            </>
                                            }
                                            { data.attachment_url &&
                                            <>
                                                <div className="separator mb-2 mt-2"></div>
                                                <div className="fw-bolder mt-3">{trans('Contract PDF')}</div>
                                                <a href={config.BackendUrl+data.attachment} target='_blank'>Download Attachment</a>
                                            </>
                                            }
                                            { data.contacts && data.contacts.length > 0 ?
                                                <>
                                                    <table className="table mt-5">
                                                        <thead>
                                                        <tr>
                                                            <th scope="col">Contact</th>
                                                            <th scope="col">Phone</th>
                                                            <th scope="col">Email</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <>
                                                            {data.contacts.map((contact) =>
                                                                <tr>
                                                                    <th>{contact.contact}</th>
                                                                    <td>{contact.phone}</td>
                                                                    <td>{contact.email}</td>
                                                                </tr>
                                                            )}
                                                        </>
                                                        </tbody>
                                                    </table>
                                                </> : ""
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Index;
