import React, {useEffect, useState} from 'react';
import CustomDataTable from "../CustomDataTable";
import ReactDOM from "react-dom";
import {trans} from "../../utils";
import $ from "jquery";

const Finish = ({params,data,sendRequest}) =>{

    const columns = [
        {data : 'id' , title: '#'},
        {data :"created_on",title:trans("Date")},
        {data :"failure_types[ ,].title_en",title:trans("Failure Type")},
    ];
    const columnsComment = [
        {data :"user.name",title: trans("Created By")},
        {data :"comment",title:trans("Comment")},
        {data :"created_at",title:trans("Date")},
    ];

    const handleCommentChange = event => {
        setComment({...comment,comment: event.target.value} );
    };

    const [comment, setComment] = useState({
        comment:'',
        custom_function : true,
    });

    const handleCommentForm = event =>
    {
        event.preventDefault();
        sendRequest(comment,"WorkOrderComment",function (){
            $("#comment").DataTable().ajax.reload()
            setComment({
                comment:'',
            })
        })
    }

    return(
       <div className="card card-custom card-stretch gutter-b">
            <div className="card-header">
                <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">{trans('Comments')}</h3>
                </div>
                <div className="card-toolbar d-flex my-4">
                    <button type="button" className="btn  btn-light-warning waves-effect waves-light" data-toggle="modal" data-target="#CommentstModal" >
                        {trans('Add comment')}</button>
                </div>
            </div>
            <div className="card-body p-9">
                <CustomDataTable columns={columnsComment} url={"WorkOrderComment?wo_id="+params.id+"&include=user"} table_id="comment" />
            </div>
           <div className="card-header">
               <div className="card-body p-9">
                   <div className="card-title m-0">
                       <h3 className="fw-bolder m-0">{trans('Finish Comments')}</h3>
                   </div>
               </div>
            </div>
            <div className="card-body p-9">
                <CustomDataTable columns={columns}   url={"getWorkOrderFinish?wo_id="+params.id} table_id="reopen" />
            </div>

           <div className="modal fade" id="CommentstModal" tabIndex="-1" role="dialog" aria-labelledby="CommentstModal" aria-hidden="true">
               <div className="modal-dialog" role="document">
                   <div className="modal-content">
                       <div className="modal-header">
                           <h5 className="modal-title" id="exampleModalLabel">{trans('Add a comment')}</h5>
                           <button type="button" className="close" data-dismiss="modal"
                                   aria-label="Close">
                               <span aria-hidden="true">&times;</span>
                           </button>
                       </div>
                       <form onSubmit={handleCommentForm}>
                           <div className="modal-body">
                               <div className="form-group">
                                   <label htmlFor="message-text"
                                          className="col-form-label">{trans('comments')} *:</label>
                                   <input
                                       className="form-control"
                                       type="text" name="notes"
                                       value={comment.comment}
                                       onChange={handleCommentChange}
                                   />
                               </div>
                           </div>
                           <div className="modal-footer">
                               <button type="button" className="btn btn-secondary"
                                       data-dismiss="modal">{trans('Close')}
                               </button>
                               <button onClick={handleCommentForm} className="btn btn-primary" data-dismiss="modal"  type="submit">{trans('Submit')}</button>
                           </div>
                       </form>
                   </div>
               </div>
           </div>

       </div>
    )

}
export default Finish
