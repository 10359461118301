var websiteConfig = require("./config.json");
const currentURL = window.location.href
const hostname = new URL(currentURL).hostname
const subdomain = hostname.split('.')[0]
const extractedSubdomain = (() => {
    let postfixToRemove = websiteConfig["SubDomainPostfix"]
    // Remove protocol and www (if present)
    let currentURL = window.location.href
    let hostname = new URL(currentURL).hostname
    let subdomain = hostname.split('.')[0]
    // Remove postfix from subdomain
    if (postfixToRemove && subdomain.endsWith(postfixToRemove)) {
      subdomain = subdomain.slice(0, -postfixToRemove.length);
    }
    return subdomain;
  })();

websiteConfig["SubDomain"] = subdomain;
websiteConfig["extractedSubdomain"] = extractedSubdomain;
websiteConfig["SystemUrl"] = websiteConfig["SystemUrl"].replace("*",subdomain+"-api")
websiteConfig["FrontUrl"] = websiteConfig["FrontUrl"].replace("*",subdomain)
websiteConfig["BackendUrl"] = websiteConfig["BackendUrl"].replace("*",subdomain+"-api")

export default websiteConfig
