import React, {useEffect,useState} from 'react'
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../config.js";
import { Chart } from "react-google-charts";


const Charts = (ComponentToRenderData) => {
    let history = useHistory();
    const params = useParams();
    const token = localStorage.getItem('token')
    const SystemUrl = ComponentToRenderData.SystemUrl;
    const [data, setData] = useState([])
    const routeChange = path => {
        history.push(path);
    }
    useEffect(() => {
        axios.get(config.SystemUrl + `finishTaking?room_space_id=${params.room_id}&inventory_taking_id=${params.it_id}`)
            .then(result => {
                setData(result.data.data)
            })
    },[])
    return (
        <>
            <div className="row mb-3">
                <div className="col-md-12 p-0">
                    <div className="">
                        <div className=" align-items-center bg-template p-3">
                            <h3 className="card-title fw-bolder text-white text-center mb-0">Finish</h3>
                        </div>
                        <Chart
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={data}
                            options={{
                                title: 'Inventory Taking Status',

                            }}
                            rootProps={{ 'data-testid': '1' }}
                        />
                        <div className="btn_fixed">
                            <button className="w-100 btn btn-light-primary fw-bold m-0 pt-4 pb-4 border-0 rounded-0"
                                    onClick={() => {
                                        let url = "/InventoryTaking/";
                                        routeChange(url);
                                    }}>
                                Return to inventory taking
                            </button>
                            <button className="w-100 btn btn-light-info fw-bold m-0 pt-4 pb-4 border-0 rounded-0"
                                    onClick={() => {
                                        let url = "/InventoryTaking/Scan/" + params.it_id;
                                        routeChange(url);
                                    }}>
                               Back to choose anthor room
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Charts;
