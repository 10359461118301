import React, { useEffect, useState } from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Users from "./users";
import AddUser from "./addUser";
import userEdit from "./userEdit";
import axios from "axios";

const UserTable = () => {

    //Read User
    const [data, setData ] = useState([]);

    useEffect(() => {
        axios
            .get("https://jsonplaceholder.typicode.com/users")
            .then(result => setData(result.data));
    }, []);

    // Create User
    const addUser  = async (user) => {
        const res = await fetch('https://jsonplaceholder.typicode.com/users/', {
            method : 'POST',
            headers : {
                'Content-Type' : "application/json"
            },
            body: JSON.stringify(user)
        })
        const dataUser = await res.json()
        setData([...data , dataUser])
    }

    // Update User
    const UpdateUser = async (id) => {
        const res = await fetch(`https://jsonplaceholder.typicode.com/users/${id}` , {
            method : 'PUT',
            headers : {
                'Content-Type' : "application/json"
            },
            body: JSON.stringify(UpdateUser)
        })
        const data = await res.json()
        return data
    }

    // Delete User
    const deleteUser = async (id) => {
        await fetch(`https://jsonplaceholder.typicode.com/users/${id}`,
            {
                method:'DELETE'
            })
        setData(data.filter((user) => user.id !== id))
    };
    return (

        <div className="App">
            <Router>
                <Switch>
                    <Route path='/users' >
                        <Users data={data} onDelete={deleteUser} />
                    </Route>
                    <Route path='/AddUser' >
                        <AddUser onAdd={addUser}/>
                    </Route>
                    <Route path='/userEdit/' component={userEdit} />
                </Switch>
            </Router>
        </div>
    );
}
export default  UserTable