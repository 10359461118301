import React, {useEffect, useState} from 'react';
import CustomDataTable from "../CustomDataTable";
import {trans} from "../../utils";
import axios from "axios";
import config from "../../../config.js";
import {toast} from "react-toastify";

const SparePartReport = ({dataSpare,data5}) =>{
    const CurrentModuleObj = JSON.parse(localStorage.getItem("WOSparePart"));
    console.log(CurrentModuleObj);

    const [pendingSpare, setPendingSpare] = useState([]);
    const [approvedSpare, setApprovedSpare] = useState([]);
    useEffect(() => {
        if(dataSpare.length > 0){
            setPendingSpare(dataSpare.filter(par => par.status != "approved"))
            setApprovedSpare(dataSpare.filter(par => par.status == "approved"))
        }
    }, [dataSpare]);


        return(
        <>
            <div className="col-md-12">
                <h4 className="left">Parts Used</h4>
                <h4 className="right">القطع المستخدمة</h4>
                <br />
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th scope="col" className="text-center fw-bolder">
                                رقم القطعة<br/>
                                Part No.
                            </th>
                            <th scope="col" className="text-center fw-bolder">
                                وصف القطعة<br/>
                                Part Description
                            </th>
                            <th scope="col" className="text-center fw-bolder">
                                الكمية<br/>
                                Quantity
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {approvedSpare.length > 0 ?
                            approvedSpare.map(part => (
                                <tr key={part.spare_part.id}>
                                    <th scope="row" className="text-center fw-bolder">{part.spare_part.id}</th>
                                    <td className="text-center fw-bolder">{part.spare_part.name}</td>
                                    <td className="text-center fw-bolder">{part.approved_quantity}</td>
                                </tr>
                            )):<h5 className="text-center">No Spare Parts Yet</h5>}
                        </tbody>
                    </table>
                </div>
            </div>
            {/*<div className="col-md-6">*/}
            {/*    <h4 className="left">Parts Required</h4>*/}
            {/*    <h4 className="right">القطع المطلوبة</h4>*/}
            {/*    <br />*/}
            {/*    <div className="table-responsive">*/}
            {/*        <table className="table table-bordered">*/}
            {/*            <thead>*/}
            {/*            <tr>*/}
            {/*                <th scope="col" className="text-center fw-bolder">*/}
            {/*                    رقم القطعة<br/>*/}
            {/*                    Part No.*/}
            {/*                </th>*/}
            {/*                <th scope="col" className="text-center fw-bolder">*/}
            {/*                    وصف القطعة<br/>*/}
            {/*                    Part Description*/}
            {/*                </th>*/}
            {/*                <th scope="col" className="text-center fw-bolder">*/}
            {/*                    الكمية<br/>*/}
            {/*                    Quantity*/}
            {/*                </th>*/}
            {/*                <th scope="col" className="text-center fw-bolder">*/}
            {/*                    الحالة<br/>*/}
            {/*                    Status*/}
            {/*                </th>*/}
            {/*            </tr>*/}
            {/*            </thead>*/}
            {/*            <tbody>*/}
            {/*            {pendingSpare.length > 0 ?*/}
            {/*                pendingSpare.map(part => (*/}
            {/*                    <tr key={part.spare_part.id}>*/}
            {/*                        <th scope="row" className="text-center fw-bolder">{part.spare_part.id}</th>*/}
            {/*                        <td className="text-center fw-bolder">{part.spare_part.name}</td>*/}
            {/*                        <td className="text-center fw-bolder">{part.quantity}</td>*/}
            {/*                        <td className="text-center fw-bolder">{part.status}</td>*/}

            {/*                    </tr>*/}
            {/*                )):<h5 className="text-center">No Spare Parts Yet</h5>}*/}
            {/*            </tbody>*/}
            {/*        </table>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>


    )

}
export default SparePartReport
