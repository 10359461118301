import React, {useEffect, useState} from 'react';
import $ from "jquery";
import CustomDataTable from "../CustomDataTable";
import {trans} from "../../utils";
import axios from "axios";
import config from "../../../config.js";
import {toast} from "react-toastify";

const Attachment = ({sendRequest,params,data,dataDocument,dataDocumentType}) =>{

    const [attachment, setAttachment] = useState({
        title:'',
        attachment_file:'',
        document_category_parent_id:'-1',
        document_category_child_id:'-1',
    });

    const [chainedDocType , setChainedDocType] = useState([]);

    const token = localStorage.getItem('token')
    const [dataAttachment, setDataAttachment ] = useState([]);
    const [dataNewAttachment, setDataNewAttachment ] = useState([]);
    useEffect(() => {
        axios(config.SystemUrl + `WorkOrderAttachment?wo_id=${params.id}&include=parent,child,user`, {
            headers: {
                "Accept": "application/json",
                "lang": "en",
                'Authorization' : "Bearer " + token
            }
        })
            .then(result => setDataAttachment(result.data.data));

    }, [dataNewAttachment])

    const handleApprove = (id) =>
    {
        axios.delete(config.SystemUrl + `WorkOrderAttachment/${id}` ,
            { headers:{
                    _method : 'delete',
                    custom_function : true,
                }
            }).then(response => {
            toast.success('Success');
            setDataNewAttachment(!dataNewAttachment);
        }).catch(err => {
            if(err.response.data.msg)
                toast.error(err.response.data.msg, { position: toast.POSITION.UPPER_RIGHT });
            else
                toast.error(err.response.data.message, { position: toast.POSITION.UPPER_RIGHT });

        });
    }

    const handleTitleChange = event =>{
        setAttachment({...attachment,title: event.target.value} );
    }

    const handleGroupChange = event => {
        setAttachment({...attachment,document_category_parent_id: event.target.value} );
        let types = dataDocumentType.filter(el=>el.asset_item_documentation_group_id == event.target.value);
        setChainedDocType(types);
    }

    const handleTypeChange = event => {
        setAttachment({...attachment,document_category_child_id: event.target.value} );
    }

    const handleAttachmentFileChange = event =>{
        let files = event.target.files || event.dataTransfer.files;
        setAttachment({...attachment,attachment_file: files[0]} );
    }
    const handleAttachmentsForm = event =>
    {
        event.preventDefault();
        sendRequest(attachment,"WorkOrderAttachment",function (response){
            console.log(response.status);
            if (response.status === 200) {
                // Handle success
                console.log('Request successful:', response.message);
                
                // Additional success handling logic if needed
                document.getElementById('closemodal').click()

                setDataNewAttachment(!dataNewAttachment);
                setAttachment({
                  title: '',
                  attachment_file: '',
                  document_category_parent_id: '-1',
                  document_category_child_id: '-1'
                });
                document.getElementById("attachment_file").value = null;

              } else {
                // Handle failure
                console.error('Request failed:', response.error);
                // Additional error handling logic if needed
              }
        })
    }

    return(
        <div className="card card-custom card-stretch gutter-b">
            <div className="card-header">
                <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">{trans('Attachments')}</h3>
                </div>
                {
                    <div className="card-toolbar d-flex my-4">
                        { data.status_data  &&
                        (data.status_data.title_en == "Active" || data.status_data.title_en == "Pending for Spare Part"  ) && (data.is_assignee || data.is_admin) &&
                        data.wo_actions && data.wo_actions.can_add_attachment == true ?
                            <button type="button" className="btn btn btn-light-primary waves-effect waves-light" data-toggle="modal" data-target="#AttachmentModal">
                                <i className="bi bi-plus fs-3"></i> {trans('Add a new Attachments')}
                            </button>:""}
                    </div>}
            </div>
            <div className="modal fade" id="AttachmentModal" tabIndex="-1" role="dialog"
                 aria-labelledby="AttachmentModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{trans('Add a new attachment')}</h5>
                            <button type="button" className="close" id="closemodal" data-dismiss="modal"
                                    aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form onSubmit={handleAttachmentsForm} encType="multipart/form-data">

                            <div className="modal-body">

                                <div className="form-group">
                                    <label htmlFor="message-text"
                                           className="col-form-label">{trans('title')} :</label>
                                    <input
                                        className="form-control"
                                        type="text" name="title"  value={attachment.title}
                                        onChange={handleTitleChange}
                                        autoFocus required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="recipient-name"
                                           className="col-form-label">{trans('Document Group')} :</label>
                                    <select className="form-select form-select-sm"
                                            aria-label=".form-select-sm example"
                                            type="text" name="assign"
                                            onChange={handleGroupChange}
                                            value={attachment.document_category_parent_id}
                                            required
                                    >
                                        <option value="-1"  selected disabled>{trans('Select an option')}</option>
                                        {dataDocument.map(title => (
                                            <option key={title.id} value={title.id} >{trans(title.title)}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="recipient-name"
                                           className="col-form-label">{trans('Document Type')} :</label>
                                    <select className="form-select form-select-sm"
                                            aria-label=".form-select-sm example"
                                            type="text" name="assign"
                                            onChange={handleTypeChange}
                                            value={attachment.document_category_child_id}
                                            required
                                    >
                                        <option value="-1" selected disabled>{trans('Select an option')}</option>
                                        {chainedDocType.map(title => (
                                            <option key={title.id} value={title.id} >{trans(title.title)}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message-text"
                                           className="col-form-label">{trans('Attachment')} :</label>
                                    <input
                                        className="form-control"
                                        type="file" name="attachment_file"
                                        id="attachment_file"
                                        onChange={handleAttachmentFileChange}
                                        autoFocus required />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                        data-dismiss="modal">{trans('Close')}
                                </button>
                                <button onClick={handleAttachmentsForm} className="btn btn-primary" type="submit">{trans('Submit')}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="card-body p-9">
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th scope="col" className="text-center fw-bolder">
                            {trans("Created By")}
                        </th>
                        <th scope="col" className="text-center fw-bolder">
                            {trans("Title")}
                        </th>
                        <th scope="col" className="text-center fw-bolder">
                            {trans("Documentation Group")}
                        </th>

                        <th scope="col" className="text-center fw-bolder">
                             {trans("Documentation Type")}
                        </th>
                        <th scope="col" className="text-center fw-bolder">
                            {trans("File")}
                        </th>
                        <th scope="col" className="text-center fw-bolder">
                               {trans("Action")}
                        </th>

                    </tr>
                    </thead>
                    <tbody>
                    {dataAttachment.length > 0 ?
                        dataAttachment.map(attachment => (
                            <tr key={attachment.id}>
                                <th scope="row" className="text-center fw-bolder">{attachment.user.name}</th>
                                <td className="text-center fw-bolder">{attachment.title}</td>
                                <td className="text-center fw-bolder">{attachment.parent.title}</td>
                                <td className="text-center fw-bolder">{attachment.child.title}</td>
                                <td className="text-center fw-bolder">
                                    <a href={attachment.attachment_url_base} target="_blank">Show Attachment</a>
                                </td>
                                {   data && data.status_data && data.wo_actions &&
                                    (data.status_data.title_en == "Active" || data.status_data.title_en == "Report Reopened" || data.status_data.title_en == "Pending for Spare Part") &&
                                    data.wo_actions.can_submit_report == true ? 
                                    (data.is_assignee || data.is_admin) &&
                                    <td className="text-center fw-bolder">
                                        <button type="button" className="btn btn-light-danger waves-effect waves-light"
                                                onClick={() => {
                                                    let id = attachment.id;
                                                    handleApprove(id);
                                                }}
                                        >
                                            {trans('Delete')}</button>
                                    </td>
                                    :
                                    ""
                                }
                            </tr>
                        )):<h5 className="text-center">No Attachments Yet</h5>}
                    </tbody>
                </table>
            </div>
        </div>
    )

}
export default Attachment
