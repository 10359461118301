import LogoutRequestHandler from "./LogoutRequestHandler";
import {trans} from '../utils'
const Logout = () => {

    return (
        <span  onClick={LogoutRequestHandler}  id="logout-button">
            {trans('Logout')}
        </span>
    );
};

export default Logout;
